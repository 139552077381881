import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { 
    Stack,
    Paper, 
    Divider, 
    Typography, 
    Button,
    Box,
    Tab,
    Grid
} from "@mui/material";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import { motion } from 'framer-motion'
import AddItemImage from "../../../Components/masterEntry/ItemEditOptions/AddItemImage";
import AddItemSize from "../../../Components/masterEntry/ItemEditOptions/AddItemSize";
import ItemSizeList from "../../../Components/masterEntry/ItemEditOptions/ItemSizeList";
import ItemImageList from "../../../Components/masterEntry/ItemEditOptions/ItemImageList";
import LocationImage from "./LocationImage/LocationImage";
import Neighbourhood from "./Neighbourhood/Neighbourhood";
import NeighbourhoodList from "./Neighbourhood/NeighbourhoodList";
import LocationImageList from "./LocationImage/LocationImageList";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const LocationAdvance = () => {

    const location = useLocation()
    const navigate = useNavigate()

    const [formData, setFormData] = useState(location.state)
    const [openAddImage, setOpenAddImage] = useState(false)
    const [openAddSize, setOpenAddSize] = useState(false)
    const [isEdited, setIsEdited] = useState(false)

    const [tabOpen, setTabOpen] = useState('neighbourhood')

    const assignItemData = ()=>{
        const data = location.state
        console.log(data)
        // setFormData(data)
    }
    useEffect(()=>{
        assignItemData()
    },[])

    // const abc = () => {
    //     console.log(formData)
    //     console.log(company_type)
    // }

    const handleCloseImage = () => {
        setOpenAddImage(false);
    };

    const handleOpenImage = () => {
        setOpenAddImage(true);
    };

    const handleCloseSize = () => {
        setOpenAddSize(false);
    };

    const handleOpenSize = () => {
        setOpenAddSize(true);
    };

    const handleTabSwitch = (e, value)=>{
        setTabOpen(value)
    }



    return (
        <>
            <Stack
                direction='column'
                justifyContent='center'
                alignItems='center'
            >
                <Paper
                    sx={{
                        width: '90%',
                        margin: 5,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 3,
                        pb: 5
                    }}
                >
                    <LocationImage itemData={formData} handleClose={handleCloseImage} open={openAddImage} isEdited={isEdited} setIsEdited={setIsEdited} />
                    <Neighbourhood itemData={formData} handleClose={handleCloseSize} open={openAddSize} isEdited={isEdited} setIsEdited={setIsEdited} />
                    <Grid
                        container 
                        width='100%'
                        alignItems='center'
                        justifyContent='center'
                    >
                        <Grid item md={0.5} sm={0.5} xs={0.5} >
                            <Button 
                                variant="outlined"
                                startIcon={<ArrowBackIcon />}
                                size="small"
                                onClick={()=>{navigate(-1)}}
                            >
                                Back
                            </Button>
                        </Grid>
                        <Grid item md={11.5} sm={11.5} xs={11.5}>
                            <motion.h4 
                                style={{ 
                                    textAlign: 'center',
    
                                }}
                                initial={{ opacity: 0, y: -20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.1 }}

                            >
                                Advance Details
                            </motion.h4>
                        </Grid>
                    </Grid>
                    <Divider
                        sx={{
                            backgroundColor: '#33333366',
                            my: 2,
                            width: '100%'
                        }}
                    />
                    {/* <Button variant="contained" onClick={abc}>abc</Button> */}
                    <Stack
                        direction='row'
                        justifyContent='space-around'
                        alignItems='center'
                        spacing={5}
                    >
                        <Button
                            onClick={handleOpenSize}
                            sx={{
                                minWidth: '8rem',
                                aspectRatio: 1 / 1,
                            }}
                            variant="outlined"
                        >
                            Add Neighbourhood
                        </Button>
                        <Button
                            onClick={handleOpenImage}
                            color="secondary"
                            sx={{
                                minWidth: '8rem',
                                aspectRatio: 1 / 1,
                            }}
                            variant="outlined"
                        >
                            Add Image
                        </Button>
                    </Stack>
                    <Divider
                        sx={{
                            backgroundColor: '#33333366',
                            mt: 2,
                            width: '100%'
                        }}
                    />



                    <TabContext value={tabOpen}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList
                                onChange={handleTabSwitch}
                                value={tabOpen}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs example"
                            >
                                <Tab label="Neighbourhood" value={'neighbourhood'} />
                                <Tab label="Image" value={'image'} />
                            </TabList>
                        </Box>
                        <TabPanel value={'neighbourhood'} >
                            <NeighbourhoodList itemData={formData} isEdited={isEdited} setIsEdited={setIsEdited}/>
                        </TabPanel>
                        <TabPanel value={'image'}>
                            <LocationImageList itemData={formData} isEdited={isEdited} setIsEdited={setIsEdited}/>
                        </TabPanel>
                    </TabContext>



                </Paper>
            </Stack>

        </>
    )
}
export default LocationAdvance