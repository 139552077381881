import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import {  useSelector } from 'react-redux';

//FUNCTION
const AccountMasterEntry = () => {
    const navigate = useNavigate();
    const { isLogin } = useSelector((state) => state.GlobalVariables);
    if (isLogin === false) {
        navigate('/admin-login');
    }
    const open = useSelector((state) => state.sideExpand.open)

    return (
        <>
            <Outlet />
        </>
    )
}
export default AccountMasterEntry