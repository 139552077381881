import { IconButton, Tooltip } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import EditIcon from '@mui/icons-material/Edit';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import PrintTwoToneIcon from '@mui/icons-material/PrintTwoTone';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';

const ActionButtons = ({ handlePrint, handleEdit, handleEOptions, id, noPrint = false, eoption = false }) => (
    <div>
        {!noPrint && (
            <Tooltip title="Print" placement="top">
                <IconButton onClick={() => handlePrint(id)}>
                    <PrintTwoToneIcon />
                </IconButton>
            </Tooltip>
        )}
        <Tooltip title="Edit" placement="top">
            <IconButton onClick={() => handleEdit(id)}>
                <EditTwoToneIcon />
            </IconButton>
        </Tooltip>
        {eoption && (
            <Tooltip title="E-Options" placement="top">
                <IconButton onClick={() => handleEOptions(id)}>
                    <MiscellaneousServicesIcon />
                </IconButton>
            </Tooltip>
        )}
    </div>
);

export default ActionButtons;
