import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import React, { memo } from 'react';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import CustomerVoucherCard from './CustomerVoucherCard';

//FUNCTION
const CustomerVoucherPopup = ({ openVoucher, handleVoucherClose, customer_id, invoice_no=null }) => {
    return (
        <>
            <Dialog
                open={openVoucher}
                onClose={handleVoucherClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={false}
                PaperProps={{
                    sx: {
                        width: '90%',
                        height: '80%',
                    },
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box flex={1} textAlign="center">
                            <Typography variant="h5" color="#1e61b4" fontSize={{ xs: '16px', md: '22px' }} fontWeight={700}>
                                Customer Voucher
                            </Typography>
                        </Box>
                        <Box position="absolute" right={10}>
                            <IconButton onClick={handleVoucherClose}>
                                <DisabledByDefaultIcon color="error" sx={{fontSize:30}} />
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <CustomerVoucherCard customer_id={customer_id} invoice_no={invoice_no} />
                </DialogContent>
            </Dialog>
        </>
    )
}

export default memo(CustomerVoucherPopup);
