import React, { memo, useEffect, useState } from 'react';
import { Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import CheckAndReturn from '../../../../utility/CheckAndReturn';

//FUNCTION
const PurchaseOrderItemsCard = ({ purchaseOrderItems }) => {
    const [purchaseAmount, setPurchaseAmount] = useState(0);

    useEffect(() => {
        let totalPurchaseAmount = 0;

        purchaseOrderItems.forEach(row => {
            totalPurchaseAmount += Number(row.amount ?? 0);
        });

        setPurchaseAmount(totalPurchaseAmount);

        return () => {
            setPurchaseAmount(0);
        }
    }, [purchaseOrderItems]);

    return (
        <>
            {
                purchaseOrderItems.map((item, index) => (
                    <Card sx={{ marginBottom: '16px', padding: '16px', backgroundColor: '#f9f9ff' }} key={index}>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" fontWeight={600}>{item.item_name} </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row" spacing={1}>
                                        <Typography variant="body1" color="textSecondary">Code:</Typography>
                                        <Typography variant="body1">{item.item_code}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row" spacing={1}>
                                        <Typography variant="body1" color="textSecondary">Quantity:</Typography>
                                        <Typography variant="body1">{item.qty}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row" spacing={1}>
                                        <Typography variant="body1" color="textSecondary">Rate:</Typography>
                                        <Typography variant="body1">₹{item.rate}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row" spacing={1}>
                                        <Typography variant="body1" color="textSecondary">Amount:</Typography>
                                        <Typography variant="body1">₹{item.amount}</Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                ))
            }
            
            <Stack direction={'row'} justifyContent={'center'}>
                <Typography variant='body1' sx={{ fontSize: '16px', fontWeight: 600 }}>
                    Purchase Amount:
                </Typography>
                <Typography variant='body1' sx={{ fontSize: '16px', fontWeight: 600, color: 'green' }}>
                    ₹{CheckAndReturn.roundToInteger(purchaseAmount ?? 0)}
                </Typography>
            </Stack>
        </>
    );
};

export default memo(PurchaseOrderItemsCard);
