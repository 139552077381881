import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import SaveIcon from '@mui/icons-material/Save';
import UpdateIcon from '@mui/icons-material/Update';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    // DialogContentText,
    DialogActions,
    IconButton,
    Typography,
    Stack,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    ButtonGroup
} from '@mui/material'
import configServ from '../../../services/config';
import Checkbox from '@mui/joy/Checkbox';
import AlertDialog from "../../AlertDialog/AlertDialog";
import { useDispatch, useSelector } from 'react-redux';
import { triggerLoader } from '../../../redux/reducers/LoaderTrigger';

//FUNCTION
function AddLineItem({ itemData, handleClose, open, isChanged }) {
    //#region code
    const dispatch = useDispatch();
    const { user_id, company_id } = useSelector((state) => state.GlobalVariables);
    
    const [formData, setFormData] = useState({});
    const [batchList, setBatchList] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState('');

    const fetchBatch = async () => {
        try {
            const res = await configServ.getBatch({ "company_id": company_id });
            if (res.status === 200) {
                setBatchList(res.data);
            }
            else {
                setBatchList([]);
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        fetchBatch();
    }, []);

    const handleOpenDialog = (content) => {
        setDialogContent(content);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setDialogContent('');
    };

    const changeHandler = (e) => {
        const { name, value } = e.target
        setFormData((state) => ({
            ...state,
            [name]: value
        }));
    }

    const checkHandler = (e) => {
        const { name, checked } = e.target
        setFormData((state) => ({
            ...state,
            [name]: checked
        }));
    }

    const handleSubmit = async () => {
        const error = await validate();
        if (error === false) {
            return false;
        }
        const dataToSend = {
            ...formData,
            "company_id": company_id,
            "admin_id": user_id,
            "product_id": itemData.id
        }
        try {
            dispatch(triggerLoader());
            if (!isEdit) {
                const result = await configServ.createLineItems(dataToSend);
                if (result.status === 200) {
                    handleOpenDialog(result.message);
                    isChanged();
                    clear();
                }
                else if (result.status === 500) {
                    handleOpenDialog(result.message || "An error occurred.");
                }
            }
        }
        catch (err) {
            console.log(err);
        }
        finally {
            dispatch(triggerLoader());
        }
    }

    const validate = async () => {
        try {
            const errors = {};
            if (!formData.quantity) {
                errors.quantity = "required";
            }
            if (!formData.batch) {
                errors.batch = "required";
            }
            setValidationErrors(errors);
            return Object.values(errors).every((error) => error === "");
        }
        catch (e) {
            console.log(e);
        }
    }

    const clear = () => {
        setFormData({});
        setValidationErrors({});
    }
    //#endregion

    return (
        <>
            <AlertDialog
                open={dialogOpen}
                setOpen={handleCloseDialog}
                title="Alert"
                message={dialogContent}
                buttonTitle="Ok"
            />

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='md'
                fullWidth={true}
            >
                <DialogTitle id="alert-dialog-title">
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='h5' color={'#1e61c5'}>{isEdit ? 'Update' : 'Create'} Line Item</Typography>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <Typography variant='body' color='text.secondary'>Choose Quantity of Line Items for {itemData.item_name}:</Typography>
                        <Grid container alignItems={'center'} spacing={2}>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    name='quantity'
                                    type='number'
                                    variant="outlined"
                                    label='Quantity'
                                    size='small'
                                    onChange={changeHandler}
                                    value={formData.quantity || ''}
                                    error={!!validationErrors.quantity}
                                />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <FormControl sx={{ minWidth: 300 }}>
                                    <InputLabel size='small' id="demo-simple-select-label" error={!!validationErrors.batch}>Batch</InputLabel>
                                    <Select
                                        size='small'
                                        labelId="demo-simple-select-label"
                                        variant="outlined"
                                        id="batch"
                                        name='batch'
                                        value={formData.batch || ''}
                                        label="Batch"
                                        onChange={changeHandler}
                                        error={!!validationErrors.batch}
                                    >
                                        {batchList.map((item, index) => (
                                            <MenuItem value={item.id}>{item.batch_name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                        </Grid>
                    </Stack>

                </DialogContent>
                <DialogActions>
                    <Grid container item xs={12} md={12} alignItems={'center'} justifyContent={'flex-end'} spacing={2}>
                    <Button onClick={clear} variant='contained' color='secondary' startIcon={<RotateLeftIcon/>} sx={{m:1}}>
                        Reset
                    </Button>
                    <Button onClick={handleSubmit} variant='contained' color='primary' startIcon={isEdit?<UpdateIcon/>:<SaveIcon/>} sx={{m:1}}>
                        {isEdit ? 'Update' : 'Submit'}
                    </Button>
                    </Grid>
                </DialogActions>
            </Dialog>

        </>
    )
}

export default AddLineItem;