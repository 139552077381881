import React, { useState, useEffect, memo } from "react";
import {
  TextField,
  IconButton,
  Grid,
  Stack,
  Autocomplete,
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import configServ from "../../services/config";
import { useSelector } from "react-redux";
import CheckAndReturn from "../../utility/CheckAndReturn";
import UploadListImage from "../Upload/UploadListImage";
import { toast } from "react-hot-toast";


const initialRow = {
  entity_id: "",
  inv_id: "",
  invoice_number: "",
  invoice_amount: "",
  invoice_date: "",
  transaction_id: "",
  amount: "",
  image: null,
};

//FUNCTION
const AddVoucherCustomerDetailList = ({
  voucherDetails,
  editVoucherDetails,
  payment_mode,
  setError,
  reset,
}) => {
  //#region code
  const { company_id } = useSelector((state) => state.GlobalVariables);
  const [rows, setRows] = useState([{ ...initialRow }]);
  const [customerList, setCustomerList] = useState([]);
  const [invoiceList, setInvoiceList] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setRows([{ ...initialRow }]);
  }, [reset]);

  const fetchEditData = async () => {
    await fetchInvoiceByCustomerID(editVoucherDetails[0].customer_id);
    const detail = editVoucherDetails.map((item) => ({
      entity_id: item.customer_id,
      inv_id: item.inv_id ?? "",
      invoice_number: item.invoice_number ?? "",
      invoice_date: item.date ?? "",
      transaction_id: item.transaction_id ?? "",
      amount: item.amount,
      image: item.image,
      fileType: item.fileType,
    }));
    setRows(detail);
  };

  useEffect(() => {
    if (editVoucherDetails) {
      fetchEditData();
    }
  }, [editVoucherDetails]);

  const fetchCustomerList = async () => {
    try {
      const result = await configServ.get_customer({
        company_id: company_id,
      });
      setCustomerList(result);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchInvoiceByCustomerID = async (customer_id) => {
    try {
      const dataToSend = {
        customer_id: customer_id,
      };
      const res = await configServ.getInvoiceByCustomerID(dataToSend);
      if (res.status === 200) {
        setInvoiceList(res.data);
      } else {
        setInvoiceList([]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchCustomerList();
  }, []);

  useEffect(() => {
    validateRows();
  }, [rows]);

  useEffect(() => {
    voucherDetails(rows);
  }, [rows]);

  const handleAddRow = () => {
    setRows([...rows, { ...initialRow }]);
  };

  const handleDeleteRow = (index) => {
    const newRows = rows.filter((_, i) => i !== index);
    setRows(newRows);
  };

  const handleChange = async (index, field, value) => {
    const newRows = [...rows];
    newRows[index][field] = value;

    if (field === "entity_id") {
      const customer = customerList.find((x) => x.id === value);
      newRows[index].fname = customer.fname;
      await fetchInvoiceByCustomerID(value);
    } else if (field === "invoice_number") {
      const selectedInvoice = invoiceList.find(
        (i) => i.invoice_number === value
      );
      newRows[index].inv_id = selectedInvoice ? selectedInvoice.id : "";
      newRows[index].total_amount = selectedInvoice
        ? CheckAndReturn.roundToInteger(selectedInvoice.total_amount ?? 0)
        : "";
      newRows[index].invoice_amount = selectedInvoice
        ? CheckAndReturn.roundToInteger(selectedInvoice.remaining_amount ?? 0)
        : "";
      newRows[index].invoice_date = selectedInvoice
        ? CheckAndReturn.transformToDate3(selectedInvoice.date ?? "")
        : "";
    } else if (field === "amount") {
      const invoiceAmount = parseFloat(newRows[index].invoice_amount);
      const enteredAmount = parseFloat(value);
      if (enteredAmount > invoiceAmount) {
        newRows[index].amount = invoiceAmount;
        alert("Amount cannot exceed the invoice amount.");
      } else {
        newRows[index].amount = enteredAmount;
      }
    }

    setRows(newRows);
  };

  const validateRows = () => {
    const hasError = rows.some((row) => !row.amount);
    setError(hasError);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //image to base64 converter
  const convertToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileUpload = async (event, index) => {
    const newRows = [...rows];
    const file = event.target.files[0];

    if (!file) return;
    const fileType = file.type;

    // Validate file type and extension
    if (fileType.startsWith("image/")) {
      const allowedExtensions = [
        "image/jpeg",
        "image/png",
        "image/webp",
        "image/jpg",
      ];
      if (!allowedExtensions.includes(fileType)) {
        toast.error(
          "Only JPEG, PNG, WEBP, or JPG formats are allowed for images."
        );
        return;
      }
    } else if (fileType !== "application/pdf") {
      alert("Only images and PDFs are supported.");
      return;
    }

    // Check file size
    const maxSize = file.type.startsWith("image/")
      ? 200 * 1024
      : 1 * 1024 * 1024; // 200 KB for images, 1 MB for PDFs
    if (file.size > maxSize) {
      toast.error(
        file.type.startsWith("image/")
          ? "Image size should not exceed 200 KB."
          : "PDF size should not exceed 1 MB."
      );
      return;
    }

    // Check file type

    if (fileType.startsWith("image/")) {
      // Convert image to Base64
      const base64Image = await convertToBase64(file);
      newRows[index].image = base64Image;
      newRows[index].fileType = "image";
    } else if (fileType === "application/pdf") {
      // Convert PDF to Base64
      const base64Pdf = await convertToBase64(file);
      //   setBase64pdf(base64Pdf)
      //   console.log(base64Pdf); // Ensure this contains the expected Base64 format
      //   newRows[index].pdf = `data:application/pdf;base64,${base64Pdf}`; // Prepend PDF MIME type
      newRows[index].image = base64Pdf; // Prepend PDF MIME type
      newRows[index].fileType = "pdf";
    } else {
      alert("Only images and PDFs are supported.");
      return;
    }

    setRows(newRows);
  };

  const handleRemoveImage = (index) => {
    const newRows = [...rows];
    newRows[index].image = null; // Clear image/PDF URL
    newRows[index].fileType = null; // Clear file type
    setRows(newRows); // Update state
  };

  //#endregion

  return (
    <Grid container spacing={1} px={2} pb={2}>
      {rows.map((row, index) => (
        <>
          <UploadListImage
            open={open}
            handleClose={handleClose}
            index={index}
            handleFileUpload={handleFileUpload}
            handleRemoveImage={handleRemoveImage}
            image={row.image || row.pdf} // Pass the correct field based on the file type
            fileType={row.fileType} // Pass file type to determine preview logic
          />
          <Grid container item spacing={1} key={index}>
            <Grid item xs={12} sm={6} md={3}>
              <Autocomplete
                disablePortal
                fullWidth
                size="small"
                id="entity_id"
                name="entity_id"
                options={customerList}
                getOptionLabel={(option) => option.fname + "~" + option.address}
                sx={{ background: "white" }}
                value={
                  customerList.find(
                    (customer) => customer.id === row.entity_id
                  ) || null
                }
                onChange={(event, newValue) => {
                  if (newValue) {
                    handleChange(index, "entity_id", newValue.id);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Account"
                    error={!row.entity_id}
                  />
                )}
              />
            </Grid>
            {invoiceList.length > 0 && (
              <>
                <Grid item xs={12} sm={6} md={2}>
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="invoice_number"
                    name="invoice_number"
                    value={row.invoice_number}
                    options={invoiceList.map((option) => option.invoice_number)}
                    onChange={(event, newValue) =>
                      handleChange(index, "invoice_number", newValue)
                    }
                    sx={{ backgroundColor: "white" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="invoice_number"
                        variant="standard"
                        value={row.invoice_number}
                        label={
                          row.invoice_number
                            ? `Date:${row.invoice_date}`
                            : "Invoice"
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <TextField
                    disabled
                    fullWidth
                    size="small"
                    variant="standard"
                    sx={{ background: "white" }}
                    label={
                      row.invoice_number
                        ? `Total=₹${row.total_amount ?? 0}`
                        : "Inv Amt"
                    }
                    InputLabelProps={{ shrink: true }}
                    value={
                      row.invoice_amount ? `Remains=₹${row.invoice_amount}` : ""
                    }
                    onChange={(e) =>
                      handleChange(index, "invoice_amount", e.target.value)
                    }
                  />
                </Grid>
              </>
            )}
            {/* {payment_mode === 1 && */}
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                size="small"
                sx={{ background: "white" }}
                variant="standard"
                label={"Trans. Id"}
                value={row.transaction_id}
                onChange={(e) =>
                  handleChange(index, "transaction_id", e.target.value)
                }
                fullWidth
              />
            </Grid>
            {/* } */}
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                size="small"
                type="number"
                variant="standard"
                sx={{ background: "white" }}
                label={"Amount"}
                value={row.amount}
                onChange={(e) => handleChange(index, "amount", e.target.value)}
                required
                fullWidth
                error={!row.amount}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={1}>
              <Stack direction={"row"}>
                <IconButton
                  onClick={handleAddRow}
                  disabled={index !== rows.length - 1}
                >
                  <AddBoxIcon color="primary" />
                </IconButton>
                <IconButton onClick={() => handleOpen(index)}>
                  <CameraAltIcon />
                </IconButton>
                <IconButton
                  onClick={() => handleDeleteRow(index)}
                  disabled={index === 0}
                >
                  <Delete color="error" />
                </IconButton>
              </Stack>
            </Grid>
          </Grid>
        </>
      ))}
    </Grid>
  );
};

export default memo(AddVoucherCustomerDetailList);
