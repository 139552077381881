import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import QRCodePrintComponent from './QRCodePrintComponent';
import { Box, Button, ButtonGroup } from '@mui/material';

const PrintLineItem = ({ selectedItem, handleClose }) => {
  const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <Box sx={{m:2, mb:0}}>
          <ButtonGroup>
            <Button variant='contained' color='primary' onClick={handlePrint}>Print QR Code</Button>
            <Button variant='contained' color='error' onClick={()=>handleClose()} >Cancel</Button>
          </ButtonGroup>
          <Box sx={{m:0}}>
            <QRCodePrintComponent ref={componentRef} selectedItem={selectedItem}/>
          </Box>
        </Box>
    );
}

export default PrintLineItem;
