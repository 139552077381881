import React, { memo, useEffect, useRef, useState } from "react";
import { saveAs } from "file-saver";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Box,
  Tooltip,
  CircularProgress,
  Grid,
  FormControl,
  NativeSelect,
  Select,
  InputLabel,
  Typography,
  IconButton,
  Stack,
  TableSortLabel,
  Button,
  TextField,
  Menu,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";

import PrintIcon from "@mui/icons-material/Print";
import DownloadIcon from "@mui/icons-material/Download";
import "../Style.css";
import SearchBox from "../Search/SearchBox";
import CheckAndReturn from "../../utility/CheckAndReturn";
import configServ from "../../services/config";
import { useSelector } from "react-redux";
import useDebounce from "../hooks/useDebounce";
import { useReactToPrint } from "react-to-print";
import DownloadGstInvoiceReport from "../Download/DownloadGstInvoiceReport";
import * as XLSX from "xlsx";

import { useLocation } from "react-router-dom";

import Swal from "sweetalert2";
import useDeviceDetect from "../Detect/useDeviceDetect ";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff";
import SaveIcon from "@mui/icons-material/Save";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-hot-toast";
import LoaderNew from "../Loader/LoaderNew";

const GstInvoiceReport = ({ showActionButton = false, row }) => {
  const location = useLocation();
  const { isMobile } = useDeviceDetect();

  // Determine if the current page is GstTransactionReport
  const isGstTransactionPage =
    location.pathname === "/transaction/Gst-transaction-report";

  //#region code
  const { company_id, companyName } = useSelector(
    (state) => state.GlobalVariables
  );
  const [count, setCount] = useState(0);
  const rowsPerPageOptions = [20, 50, 100, 200];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
  const [searchBy, setSearchBy] = useState("fname");
  const [search, setSearch] = useState(null);
  const debounceSearch = useDebounce(search, 1000);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("date");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [invoiceList, setInvoiceList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isPrint, setIsPrint] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [filteredReport, setFilteredReport] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [selectAll, setSelectAll] = useState(false);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [showUnpaidOnly, setShowUnpaidOnly] = useState("all");
  const [totalAmount, setTotalAmount] = useState(0);

  const dateRef = useRef(null);
  const remarksRef = useRef(null);

  const fetchGstInvoiceReport = async () => {
    try {
      setLoading(true);
      let unpaidValue = null;
    let paidValue = null;

    if (showUnpaidOnly === "unpaid") {
      unpaidValue = true;
    } else if (showUnpaidOnly === "paid") {
      paidValue = true;
    }
      // const unpaidValue = showUnpaidOnly ? true : false;
      const dataToSend = {
        company_id: company_id,
        search: search,
        page: page,
        per_page: rowsPerPage,
        order_by: orderBy,
        order: order,
        start_date: startDate,
        end_date: endDate,
        unpaid: unpaidValue,  // Send unpaid value if unpaid is selected
        paid: paidValue,      // Send paid value if paid is selected
      };
      const res = await configServ.getGstInvoiceReport(dataToSend);
      if (res.status === 200) {
        const data = res.data.map((item) => ({
          id: item.id,
          is_gst_paid: item.is_gst_paid,
          gstin: item.gstin,
          fname: item.fname,
          invoice_number: item.invoice_number,
          date: CheckAndReturn.transformToDate3(item.date),
          total_amount: CheckAndReturn.roundToInteger(item.total_amount),
          taxable_amt: CheckAndReturn.roundToInteger(item.taxable_amt),

          cgst:
            item.tax_type === "CGST+SGST"
              ? CheckAndReturn.roundToInteger(item.Total_TaxAmount) / 2
              : 0,
          sgst:
            item.tax_type === "CGST+SGST"
              ? CheckAndReturn.roundToInteger(item.Total_TaxAmount) / 2
              : 0,
          igst:
            item.tax_type === "IGST"
              ? CheckAndReturn.roundToInteger(item.Total_TaxAmount)
              : 0,
          paid: item.paid || false,
        }));
        setInvoiceList(data);
        setCount(res.total);
      } else {
        setInvoiceList([]);
        setCount(0);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };


  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    fetchGstInvoiceReport();
  }, [page, rowsPerPage, order, orderBy, startDate, endDate, showUnpaidOnly]);

  useEffect(() => {
    if (page === 0) {
      fetchGstInvoiceReport();
    } else {
      setPage(0);
    }
  }, [debounceSearch]);

  // Function to handle select all or deselect all
  const handleSelectAllChange = () => {
    if (selectAll) {
      // Deselect all
      setSelectedInvoices([]);
      setTotalAmount(0); // Reset total amount to 0
    } else {
      // Select all invoices
      const allSelectedInvoices = invoiceList.map((invoice) => invoice.id);

      const totalAmount = invoiceList.reduce((total, invoice) => {
        return total + (invoice.cgst + invoice.sgst + invoice.igst);
      }, 0);

      setSelectedInvoices(allSelectedInvoices);
      setTotalAmount(totalAmount); // Update total amount for all selected
    }

    setSelectAll(!selectAll); // Toggle select all state
  };

  const headers = [
    { id: "gstin", label: "GSTIN" },
    { id: "fname", label: "PARTY NAME" },
    { id: "invoice_number", label: "BILL NO" },
    { id: "date", label: "DATE" },
    { id: "total_amount", label: "INVOICE VALUE" },
    { id: "taxable_amt", label: "TAXABLE AMOUNT" },
    { id: "Total_TaxAmount", label: "CGST" },
    { id: "Total_TaxAmount", label: "SGST" },
    { id: "Total_TaxAmount", label: "IGST" },
    // { id: "Total_TaxAmount", label: "TOTAL TAX AMOUNT" },
    // Conditionally add the Action column with "Paid" and the checkbox below it
    ...(isGstTransactionPage
      ? [
          {
            id: "action",
            label: (
              <>
                <div>Paid</div> {/* Header "Paid" */}
                <Checkbox
                  checked={selectAll} // The selectAll state controls the checkbox
                  onChange={handleSelectAllChange} // Change handler for the checkbox
                  style={{ padding: 0 }} // Remove default padding for better alignment
                />
              </>
            ),
          },
        ]
      : []),
  ];

  const downloadExcel = async () => {
    try {
      const dataToSend = {
        company_id: company_id,
        start_date: startDate,
        end_date: endDate,
        unpaid: showUnpaidOnly ? true : false,
        fetch_all: true, // This triggers fetching all data without pagination
      };

      const res = await configServ.getGstInvoiceReport(dataToSend);

      if (res.status === 200) {
        const invoiceListData = res.data.map((order) => ({
          GSTIN: order.gstin,
          PARTY_NAME: order.fname,
          BILL_NO: order.invoice_number,
          DATE: CheckAndReturn.transformToDate3(order.date),
          INVOICE_VALUE: CheckAndReturn.roundToInteger(order.total_amount),
          TAXABLE_AMOUNT: CheckAndReturn.roundToInteger(order.taxable_amt),
          cgst:
            order.tax_type === "CGST+SGST"
              ? CheckAndReturn.roundToInteger(order.Total_TaxAmount) / 2
              : 0,
          sgst:
            order.tax_type === "CGST+SGST"
              ? CheckAndReturn.roundToInteger(order.Total_TaxAmount) / 2
              : 0,
          igst:
            order.tax_type === "IGST"
              ? CheckAndReturn.roundToInteger(order.Total_TaxAmount)
              : 0,
        }));

        // Generate Excel with the downloaded data
        const worksheet = XLSX.utils.json_to_sheet([]);
        const workbook = XLSX.utils.book_new();

        XLSX.utils.sheet_add_aoa(
          worksheet,
          [["GST INVOICE REPORT"], [`Company Name: ${companyName}`]],
          { origin: "A1" }
        );

        XLSX.utils.sheet_add_json(worksheet, invoiceListData, { origin: "A4" });

        const wscols = [
          { wch: 20 },
          { wch: 40 },
          { wch: 30 },
          { wch: 30 },
          { wch: 30 },
          { wch: 30 },
        ];
        worksheet["!cols"] = wscols;

        XLSX.utils.book_append_sheet(workbook, worksheet, "Gst Data");

        const today = new Date();
        const day = String(today.getDate()).padStart(2, "0");
        const month = String(today.getMonth() + 1).padStart(2, "0");
        const year = today.getFullYear();
        const formattedDate = `${day}-${month}-${year}`;

        XLSX.writeFile(workbook, `GST_INVOICE_${formattedDate}.xlsx`);

        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your Excel file has been downloaded",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      console.error("Error while downloading Excel:", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

  const headerCellStyle = {
    fontSize: 12, // Adjust the font size as needed
    fontWeight: "bold",
    backgroundColor: "#4CB5F5",
    color: "white",
  };

  const cellStyle = {
    py: 0,
    my: 0,
  };

  const evenRowStyle = {
    background: "#f5f5f5",
  };

  const oddRowStyle = {
    background: "white",
  };

  const rowStyle = {
    "&:hover": {
      background: "#f1f9fe",
    },
  };

  const handleDownloadClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDownloadClose = () => {
    setAnchorEl(null);
  };

  //   action button
  const handleCheckboxChange = (invoiceId) => {
    setSelectedInvoices((prevSelectedInvoices) => {
      let updatedSelectedInvoices;

      if (prevSelectedInvoices.includes(invoiceId)) {
        // If already selected, remove from the list
        updatedSelectedInvoices = prevSelectedInvoices.filter(
          (id) => id !== invoiceId
        );
      } else {
        // If not selected, add to the list
        updatedSelectedInvoices = [...prevSelectedInvoices, invoiceId];
      }

      // Recalculate the total amount based on the updated selection
      const updatedTotalAmount = invoiceList
        .filter((invoice) => updatedSelectedInvoices.includes(invoice.id))
        .reduce(
          (total, invoice) =>
            total + (invoice.cgst + invoice.sgst + invoice.igst),
          0
        );

      // Update the total amount
      setTotalAmount(updatedTotalAmount);

      return updatedSelectedInvoices;
    });
  };

  // Model Started
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  const handleClickOpen = () => {
    Swal.fire({
      title: "Do you want to save the changes?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Save",
      denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        // If the user confirms, open the dialog box
        // Swal.fire("Saved!", "", "success");
        setOpen(true); // This will open the dialog box
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };
  const handleClose = () => {
    setOpen(false);
  };
  // model end

  const handleSaveChanges = async () => {
    const dateValue = dateRef.current.value;

    if (!dateValue) {
      toast.error("Please select a date before submitting.");
      return; // Stop the function execution if the date is not provided
    }

    const remarksValue = remarksRef.current.value;

    const updates = selectedInvoices
      .map((invoiceNumber) => {
        const currentInvoice = invoiceList.find(
          (invoice) => invoice.id === invoiceNumber
        );

        if (currentInvoice) {
          return {
            id: currentInvoice.id,
            is_gst_paid: currentInvoice.is_gst_paid === 1 ? 0 : 1, // Toggle between paid and unpaid
            gst_paid_on: dateValue,
            gst_paid_remark: remarksValue || null,
          };
        }
        return null;
      })
      .filter((update) => update !== null);

    if (updates.length > 0) {
      try {
        setLoading(true);

        const res = await configServ.createInvoiceGst(updates);

        if (res.status === 200) {
          toast.success("Invoice updates sent successfully!");

          // Update the invoice list to reflect the new is_gst_paid status
          const updatedInvoiceList = invoiceList.map((invoice) => {
            const updatedInvoice = updates.find(
              (update) => update.id === invoice.id
            );
            return updatedInvoice
              ? { ...invoice, is_gst_paid: updatedInvoice.is_gst_paid }
              : invoice;
          });

          setInvoiceList(updatedInvoiceList); // Update the state with the new data

          // Clear selections after saving
          fetchGstInvoiceReport();
          setSelectedInvoices([]); // Clear selected invoices
          setSelectAll(false); // Reset the select-all state
          handleClose(); // Close the dialog after successful submission
        } else {
          toast.error("An error occurred while updating the invoice.");
        }
      } catch (e) {
        toast.error("An error occurred while updating the invoice.");
      } finally {
        setLoading(false);
      }
    } else {
      toast.error("No valid invoices selected.");
    }
  };

  //   ending of action button

  const handleDownloadData = async () => {
    try {
      const dataToSend = {
        company_id: company_id,
        search: filteredReport === false ? null : search,
        page: filteredReport === false ? null : page,
        per_page: filteredReport === false ? null : rowsPerPage,
        order_by: filteredReport === false ? "date" : orderBy,
        order: filteredReport === false ? "asc" : order,
        start_date: filteredReport === false ? null : startDate,
        end_date: filteredReport === false ? null : endDate,
      };
      const res = await configServ.getGstInvoiceReport(dataToSend);
      if (res.status === 200) {
        const data = res.data.map((item) => ({
          gstin: item.gstin,
          fname: item.fname,
          invoice_number: item.invoice_number,
          date: CheckAndReturn.transformToDate3(item.date),
          total_amount: CheckAndReturn.roundToInteger(item.total_amount),
          taxable_amt: CheckAndReturn.roundToInteger(item.taxable_amt),
          cgst:
            item.tax_type === "CGST+SGST"
              ? CheckAndReturn.roundToInteger(item.Total_TaxAmount) / 2
              : 0,
          sgst:
            item.tax_type === "CGST+SGST"
              ? CheckAndReturn.roundToInteger(item.Total_TaxAmount) / 2
              : 0,
          igst:
            item.tax_type === "IGST"
              ? CheckAndReturn.roundToInteger(item.Total_TaxAmount)
              : 0,
        }));
        await DownloadGstInvoiceReport(data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleSearchBy = (event) => {
    setSearchBy(event.target.value);
  };

  const gstInvoiceReportRef = useRef();

  const handlePrintData = () => {
    setIsPrint(true);
  };

  useEffect(() => {
    if (isPrint === true) {
      handleTablePrint();
      setIsPrint(false);
    }
  }, [isPrint]);

  const handleTablePrint = useReactToPrint({
    content: () => gstInvoiceReportRef.current,
    documentTitle: "Gst Invoice Report",
  });

  const handleStartDate = (event) => {
    try {
      const date = event.target.value;
      setStartDate(date);
    } catch (e) {
      console.log(e);
    }
  };

  const handleEndDate = (event) => {
    try {
      const date = event.target.value;
      setEndDate(date);
    } catch (e) {
      console.log(e);
    }
  };

  //#endregion

  return (
    <>
      <Grid container spacing={2} alignItems="center">
        {/* Full-width Title */}
        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: "22px",
              fontWeight: "bold",
              color: "#1e61b4",
              textAlign: "center",
            }}
          >
            {isGstTransactionPage ? "Gst Transaction" : "GST Invoice Report"}
          </Typography>
        </Grid>

        {/* Download, Print, Save Buttons & Checkbox */}
        <Grid item xs={6} sm={1} md={1}>
          <Button
            variant="contained"
            onClick={handleDownloadClick}
            startIcon={<DownloadIcon />}
            title="Download"
            sx={{
              backgroundColor: "#4bcf6b",
              color: "white",
              fontSize: "0.75rem", // Smaller text size
              padding: "5px 10px", // Compact padding
              "&:hover": { backgroundColor: "#00b92f" },
              width: "100%", // Full width
            }}
          />
        </Grid>
        <Grid item xs={6} sm={1} md={1}>
          <Button
            variant="contained"
            onClick={handlePrintData}
            startIcon={<PrintIcon />}
            title="Print"
            sx={{
              backgroundColor: "#349af1",
              color: "white",
              "&:hover": {
                backgroundColor: "#0868F8",
                color: "white",
              },
              width: { xs: "100%", md: "auto" }, // Full width on small devices
            }}
          />
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleDownloadClose}
          >
            <MenuItem>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filteredReport}
                    onClick={() => setFilteredReport(!filteredReport)}
                  />
                }
                label="With Filter"
              />
            </MenuItem>
            {/* <MenuItem onClick={handleDownloadData}>Download Excel</MenuItem> */}
            <MenuItem
              onClick={() => {
                handleDownloadData();
                handleMenuClose();
              }}
            >
              Download Excel
            </MenuItem>
          </Menu>
        </Grid>
        {isGstTransactionPage && (
          <Grid item xs={6} sm={1} md={1}>
            <Button
              variant="contained"
              startIcon={<SaveIcon />}
              onClick={handleClickOpen}
              sx={{
                backgroundColor: "#1e61b4",
                color: "white",
                fontSize: "0.75rem", // Smaller text size
                padding: "5px 10px", // Compact padding
                width: "100%", // Full width
              }}
            />
          </Grid>
        )}

        <Grid item xs={6} sm={1} md={1.5} >
          <FormControl fullWidth>
            <InputLabel id="payment-status-label">Payment Status</InputLabel>
            <Select
              labelId="payment-status-label"
               value={showUnpaidOnly}
              onChange={(e) => setShowUnpaidOnly(e.target.value)}
              label="Payment Status"
              sx={{ fontSize: "0.75rem", whiteSpace: "nowrap", width: "100%" }}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="paid">Paid</MenuItem>
              <MenuItem value="unpaid">Unpaid</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* Date Pickers (From and To) */}
        <Grid item xs={6} sm={2} md={1.5}>
          <TextField
            size="small"
            type="date"
            label="From"
            InputLabelProps={{ shrink: true }}
            onChange={handleStartDate}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={2} md={1.5}>
          <TextField
            size="small"
            type="date"
            label="To"
            InputLabelProps={{ shrink: true }}
            onChange={handleEndDate}
            fullWidth
          />
        </Grid>

        {/* Search By Dropdown */}
        <Grid item xs={6} sm={3} md={1.5}>
          <FormControl fullWidth>
            <InputLabel variant="standard" htmlFor="uncontrolled-native">
              Search By
            </InputLabel>
            <NativeSelect
              value={searchBy}
              onChange={handleSearchBy}
              inputProps={{ name: "searchBy", id: "uncontrolled-native" }}
            >
              <option value={"fname"}>Customer</option>
            </NativeSelect>
          </FormControl>
        </Grid>

        {/* Search Box */}
        <Grid item xs={12} sm={4} md={3}>
          <SearchBox
            search={search}
            setSearch={setSearch}
            fullWidth
            sx={{
              width: "100%", // Full width in the grid
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          {isMobile ? (
            <>
              {" "}
              {isGstTransactionPage && showUnpaidOnly && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectAll}
                      onChange={handleSelectAllChange}
                    />
                  }
                  label="All paid"
                />
              )}
            </>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>

      {isMobile ? (
        <>
          <Grid container alignItems={"center"} spacing={1} mt={1}>
            <Grid container item xs={6} md={6} justifyContent={"center"}>
              <FormControl fullWidth size="small">
                <InputLabel>Sort By</InputLabel>
                <Select
                  fullWidth
                  size="small"
                  name="order_by"
                  label="Sort By"
                  value={orderBy}
                  onChange={(e) => setOrderBy(e.target.value)}
                  sx={{ background: "white" }}
                >
                  {headers.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid container item xs={6} md={6} justifyContent={"center"}>
              <FormControl fullWidth size="small">
                <InputLabel>Order</InputLabel>
                <Select
                  fullWidth
                  size="small"
                  name="order"
                  label="Order"
                  value={order}
                  onChange={(e) => setOrder(e.target.value)}
                  sx={{ background: "white" }}
                >
                  <MenuItem key={1} value={"asc"}>
                    {"Ascending"}
                  </MenuItem>
                  <MenuItem key={2} value={"desc"}>
                    {"Descending"}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>{" "}
          <Grid container spacing={2} mt={1}>
            {loading ? (
              <Grid item xs={12} textAlign="center">
                <LoaderNew />
              </Grid>
            ) : invoiceList.length > 0 ? (
              invoiceList.map((row, rowIndex) => (
                <Grid item xs={12} key={rowIndex}>
                  <Card variant="outlined" sx={{ mb: 2 }}>
                    <CardContent>
                      <Typography variant="h6">
                        S.No. {page * rowsPerPage + rowIndex + 1}
                      </Typography>
                      <Typography variant="body1">
                        <strong>GSTIN:</strong> {row.gstin}
                      </Typography>
                      <Typography variant="body1">
                        <strong>Party Name:</strong> {row.fname}
                      </Typography>
                      <Typography variant="body1">
                        <strong>Bill No:</strong> {row.invoice_number}
                      </Typography>
                      <Typography variant="body1">
                        <strong>Date:</strong> {row.date}
                      </Typography>
                      <Typography variant="body1">
                        <strong>Invoice Value:</strong> ₹{row.total_amount}
                      </Typography>
                      <Typography variant="body1">
                        <strong>Taxable Value:</strong> ₹{row.taxable_amt}
                      </Typography>
                      <Typography variant="body1">
                        <strong>CGST:</strong> {row.cgst}
                      </Typography>
                      <Typography variant="body1">
                        <strong>SGST:</strong> {row.sgst}
                      </Typography>
                      <Typography variant="body1">
                        <strong>IGST:</strong> {row.igst}
                      </Typography>
                      <Typography variant="body1">
                        <strong>TOTAL TAX AMOUNT</strong>{" "}
                        {row.cgst + row.sgst + row.igst}
                      </Typography>
                    </CardContent>
                    {/* {showActionButton && (
                      <CardActions>
                        <Tooltip title={paymentStatus}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={paymentStatus === "Paid"}
                                onChange={handlePaymentChange}
                                color="primary" // You can change this to success or error based on your design preference
                              />
                            }
                            label={paymentStatus}
                          />
                        </Tooltip>
                      </CardActions>

                    )} */}
                    {isGstTransactionPage && (
                      <CardActions align="left" sx={cellStyle}>
                        <Checkbox
                          checked={
                            selectedInvoices.includes(row.id)
                              ? !row.is_gst_paid
                              : row.is_gst_paid === 1
                          }
                          onChange={() => handleCheckboxChange(row.id)}
                        />
                        <label htmlFor="">paid</label>
                      </CardActions>
                    )}
                  </Card>
                </Grid>
              ))
            ) : (
              <Grid item xs={12} textAlign="center">
                <Typography variant="body1" color="red">
                  No data found.
                </Typography>
              </Grid>
            )}
            {!loading && invoiceList.length > 0 && (
              <Box mt={2}>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Box>
            )}
          </Grid>
        </>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100vh", // Full height of the viewport
            }}
          >
            <Box
              sx={{
                flex: 1,
                height: "100vh", // Adjust the height to fit the viewport (reduce space for header/footer)
              }}
            >
              <TableContainer
                component={Paper}
                elevation={0}
                sx={{
                  minWidth: 800,
                  maxHeight: "100%", // Ensure the table container respects the Box height
                }}
              >
                <Table stickyHeader ref={gstInvoiceReportRef}>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={headerCellStyle}>S.No.</TableCell>
                      {headers.map((column) => (
                        <TableCell
                          key={column.id}
                          sortDirection={
                            orderBy === column.id && column.id !== "action"
                              ? order
                              : false
                          }
                          sx={{
                            ...headerCellStyle,
                            width: (() => {
                              switch (column.id) {
                                case "Total_TaxAmount":
                                  // Add conditional check for isGstTransactionPage
                                  if (isGstTransactionPage) {
                                    return "60px";
                                  } else {
                                    return "80px"; // Different width for isGstTransactionPage
                                  }
                                case "taxable_amt":
                                  // Add conditional check for isGstTransactionPage
                                  if (isGstTransactionPage) {
                                    return "80px";
                                  } else {
                                    return "auto"; // Different width for isGstTransactionPage Total_TaxAmount
                                  }
                                case "total_amount":
                                  // Add conditional check for isGstTransactionPage
                                  if (isGstTransactionPage) {
                                    return "100px";
                                  } else {
                                    return "auto"; // Different width for isGstTransactionPage
                                  }

                                // Add more cases as needed for other columns
                                default:
                                  return "auto"; // Default width for other columns
                              }
                            })(),
                            maxWidth: (() => {
                              switch (column.id) {
                                case "Total_TaxAmount":
                                  // Add conditional check for isGstTransactionPage
                                  if (isGstTransactionPage) {
                                    return "60px";
                                  } else {
                                    return "80px"; // Different width for isGstTransactionPage
                                  }
                                case "taxable_amt":
                                  // Add conditional check for isGstTransactionPage
                                  if (isGstTransactionPage) {
                                    return "80px";
                                  } else {
                                    return "auto"; // Different width for isGstTransactionPage Total_TaxAmount
                                  }
                                case "total_amount":
                                  // Add conditional check for isGstTransactionPage
                                  if (isGstTransactionPage) {
                                    return "100px";
                                  } else {
                                    return "auto"; // Different width for isGstTransactionPage
                                  }

                                // Add more max-widths for other columns if needed
                                default:
                                  return "none";
                              }
                            })(),
                          }}
                        >
                          {/* Check if the column is "Paid" (i.e., action column), and render without TableSortLabel */}
                          {column.id === "action" ? (
                            <>
                              <div>Paid</div>
                              <Checkbox
                                checked={selectAll}
                                onChange={handleSelectAllChange}
                                style={{ padding: 0 }}
                              />
                            </>
                          ) : (
                            <TableSortLabel
                              active={orderBy === column.id}
                              direction={orderBy === column.id ? order : "asc"}
                              onClick={() => handleRequestSort(column.id)}
                            >
                              {column.label}
                            </TableSortLabel>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {loading === false ? (
                      invoiceList.length > 0 ? (
                        invoiceList.map((row, rowIndex) => (
                          <TableRow
                            key={rowIndex}
                            sx={{
                              ...rowStyle,
                              ...(rowIndex % 2 === 0
                                ? evenRowStyle
                                : oddRowStyle),
                            }}
                          >
                            <TableCell sx={cellStyle}>
                              <Stack direction={"row"} spacing={1}>
                                <Typography variant="body1">
                                  {page * rowsPerPage + rowIndex + 1}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell sx={cellStyle}>{row.gstin}</TableCell>
                            <TableCell sx={cellStyle}>{row.fname}</TableCell>
                            <TableCell sx={cellStyle}>
                              {row.invoice_number}
                            </TableCell>
                            <TableCell sx={cellStyle}>{row.date}</TableCell>
                            <TableCell sx={cellStyle}>
                              ₹{row.total_amount}
                            </TableCell>
                            <TableCell sx={cellStyle}>
                              ₹{row.taxable_amt}
                            </TableCell>
                            <TableCell sx={cellStyle}>{row.cgst}</TableCell>
                            <TableCell sx={cellStyle}>{row.sgst}</TableCell>
                            <TableCell sx={cellStyle}>{row.igst}</TableCell>
                            {/* <TableCell sx={cellStyle}>
                            {row.cgst + row.sgst + row.igst}
                          </TableCell> */}

                            {/* {
                                            (isDownload === false && isPrint === false) &&
                                            <TableCell sx={cellStyle}>
                                                <Stack direction={'row'}>
                                                    <VisibilityIcon />
                                                </Stack>
                                            </TableCell>
                                        } */}
                            {isGstTransactionPage && (
                              <TableCell align="left" sx={cellStyle}>
                                <Checkbox
                                  checked={
                                    selectedInvoices.includes(row.id)
                                      ? !row.is_gst_paid
                                      : row.is_gst_paid === 1
                                  }
                                  onChange={() => handleCheckboxChange(row.id)}
                                />
                              </TableCell>
                            )}
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={12}>
                            <Box textAlign={"center"}>
                              <Typography variant="body1" color={"red"}>
                                No data found.
                              </Typography>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={12} sx={{ textAlign: "center" }}>
                          <div>
                            <LoaderNew />
                          </div>
                        </TableCell>
                      </TableRow>
                    )}

                    {emptyRows > 0 && (
                      <TableRow style={{ height: 1 * emptyRows }}>
                        <TableCell colSpan={headers.length + 2} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>

                <TablePagination
                  rowsPerPageOptions={rowsPerPageOptions}
                  component="div"
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            </Box>
          </Box>
        </>
      )}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{ zIndex: 10000 }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          GST Transaction
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {/* Display the newTotalAmount */}
          <div style={{ marginBottom: "16px" }}>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", color: "#1976d2" }}
            >
              Total Amount: ₹{totalAmount} {/* Format number */}
            </Typography>
          </div>

          <form>
            <div>
              <TextField
                size="small"
                required
                type="date"
                label="Required"
                InputLabelProps={{ shrink: true }}
                inputRef={dateRef}
                defaultValue={new Date().toISOString().split("T")[0]} // Set default to current date
              />
            </div>

            <div style={{ marginTop: "16px" }}>
              <TextField
                id="outlined-basic"
                label="Remarks"
                variant="outlined"
                defaultValue=""
                inputRef={remarksRef}
              />
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleSaveChanges}>
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default GstInvoiceReport;
