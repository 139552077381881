import React from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Box,
  Container,
} from "@mui/material";

const SubscriptionOrder = () => {
  return (
    <Container>
      <Box sx={{ border: "1px solid black", p: 2, mt: 2 }}>
        <Typography
          variant="h6"
          align="center"
          sx={{ mb: 2, fontWeight: "bold" }}
        >
          Subscription Order
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Customer Detail"
              variant="outlined"
              multiline
              rows={2}
            />
          </Grid>
          <Grid item xs={6} sm={2}>
            <TextField fullWidth label="Item" variant="outlined" />
          </Grid>
          <Grid item xs={6} sm={2}>
            <TextField fullWidth label="Rate" variant="outlined" />
          </Grid>
          <Grid item xs={6} sm={2}>
            <TextField fullWidth label="Sub Start Date" variant="outlined" />
          </Grid>
          <Grid item xs={6} sm={2}>
            <TextField fullWidth label="End Date" variant="outlined" />
          </Grid>
          <Grid item xs={6} sm={2}>
            <TextField fullWidth label="Sub Frequency" variant="outlined" />
          </Grid>
          <Grid item xs={6} sm={2}>
            <TextField fullWidth label="Quantity" variant="outlined" />
          </Grid>
          <Grid item xs={6} sm={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{ height: "100%" }}
            >
              Add
            </Button>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{ height: "100%" }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default SubscriptionOrder;
