import React, { useState, useEffect } from 'react';
import {
    Paper,
    TextField,
    Button,
    FormControlLabel,
    Checkbox,
    Container,
    Grid
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import configServ from '../../services/config';
import SuperCategoryTable from './SuperCategoryTable'
import AlertDialog from "../../Components/AlertDialog/AlertDialog";
import CardHeadMenu from '../../Components/Card/CardHeadMenu/CardHeadMenu'
import { triggerLoader } from '../../redux/reducers/LoaderTrigger'

//FUNCTION
function SuperCategory() {
    //#region code
    const dispatch = useDispatch();
    const { user_id, company_id, noRoomService } = useSelector((state) => state.GlobalVariables);
    const open = useSelector((state) => state.sideExpand.open)
    const [formData, setFormData] = useState({
        status: true,
        isPrimary: false
    });
    const [validationErrors, setValidationErrors] = useState({});
    const [itemList, setItemList] = useState([])
    const [isChanged, setIsChanged] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [dialogOpen, setDialogOpen] = useState(false);
    const [title, setTitle] = useState('Alert');
    const [dialogContent, setDialogContent] = useState('');
    const [buttonType, setButtonType] = useState(null);
    const [isCreate, setIsCreate] = useState(false);

    const handleOpenDialog = (heading, content, type) => {
        setTitle(heading);
        setDialogContent(content);
        setButtonType(type);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setTitle('Alert');
        setDialogContent('');
        setButtonType(null);
    };

    const changeHandler = (e) => {
        const { name, value } = e.target;

        //Update form data
        setFormData((state) => ({
            ...state,
            [name]: value
        }));

        // Reset the validation error when the user starts typing
        if (validationErrors[name]) {
            setValidationErrors((prevValidationError) => ({
                ...prevValidationError,
                [name]: '',
            }));
        }
    }

    const handleChangeCheckbox = (e) => {
        const { name, checked } = e.target
        setFormData((state) => ({
            ...state,
            [name]: checked
        }))
    }

    const validateForm = async () => {
        let errors = {};
        if (!formData.name) {
            errors.name = "required";
        }
        setValidationErrors(errors);
        return Object.values(errors).every(error => error === "");
    }

    const handleSubmit = async (e) => {
        dispatch(triggerLoader());
        const dataToSend = {
            ...formData,
            "service_name": noRoomService,
            "created_by": user_id
        }

        const isValid = await validateForm();
        if (isValid === false) {
            return false;
        }

        try {
            if (!isEdit) {
                const res = await configServ.createSuperCategory(dataToSend);
                if (res.status === 200) {
                    setIsChanged(!isChanged)
                    clear();
                    handleOpenDialog('Success', res.message, 'success');
                }
                else if (res.status === 400) {
                    handleOpenDialog('Warning', res.message || "Failed to create super category.", 'warning');
                }
                else {
                    handleOpenDialog('Error', res.message || "Failed to create super category.", 'error');
                }
            }
            else {
                const res = await configServ.editSuperCategory(dataToSend);
                if (res.status === 200) {
                    setIsChanged(!isChanged)
                    clear();
                    handleOpenDialog('Success', res.message, 'success');
                }
                else if (res.status === 400) {
                    handleOpenDialog('Warning', res.message || "Failed to update super category.", 'warning');
                }
                else {
                    handleOpenDialog('Error', res.message || "Failed to update super category.", 'error');
                }
            }
        }
        catch (err) {
            console.log(err);
            handleOpenDialog('Error', "An unexpected error has occurred.", 'error');
        }
        finally {
            dispatch(triggerLoader());
        }
    }

    const fetchItemList = async () => {
        try {
            const dataToSend = {
                "company_id": company_id,
                "search_by": null,
                "search": null,
                "per_page": null,
                "page": null
            }
            const res = await configServ.getItemList(dataToSend);
            if (res.status === 200) {
                if (res.data.length > 0) {
                    const result = res.data.filter(x => x.status === 1);
                    setItemList(result);
                }
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchItemList()
    }, []);

    const clear = () => {
        setFormData({
            status: true,
            isPrimary: false,
            service_name: noRoomService
        });
        setValidationErrors({});
        setIsEdit(false);
    }

    const handleEditData = (data) => {
        setIsEdit(true);
        setIsCreate(true);
        setFormData(data[0]);
    }

    const handlePopup = () => {
        setIsCreate(!isCreate);
        //setIsEdit(false);
    }
    //#endregion

    return (
        <>
            <AlertDialog
                open={dialogOpen}
                setOpen={handleCloseDialog}
                title={title}
                message={dialogContent}
                buttonTitle="Ok"
                buttonType={buttonType}
            />
            <CardHeadMenu
                prefix={"na"}
                suffix={"na"}
                title={"SUPER CATEGORY"}
                handlePopup={handlePopup}
                isEdit={isEdit}
                create={isCreate}
            />
            {isCreate &&
                <Paper
                    elevation={0}
                    sx={{
                        width: {
                            md: '70%',
                            sm: '90%',
                            xs: '100%'
                        },
                        padding: 4,
                        margin: 0,
                        border: '1px solid #55555522',
                        backgroundColor: '#e3f3fc'
                    }}
                >
                    <Grid container alignItems={'center'} justifyContent={'center'} spacing={1}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                size='small'
                                label='Name'
                                name='name'
                                sx={{
                                    backgroundColor: 'white'
                                }}
                                value={formData.name || ''}
                                onChange={changeHandler}
                                error={!!validationErrors.name}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControlLabel
                                control={<Checkbox />}
                                name='status'
                                checked={Boolean(formData.status)}
                                onChange={handleChangeCheckbox}
                                label='Status'
                            />
                            <FormControlLabel
                                control={<Checkbox />}
                                name='isPrimary'
                                checked={Boolean(formData.isPrimary)}
                                onChange={handleChangeCheckbox}
                                label='Is Primary'
                            />
                        </Grid>
                        <Grid item xs={0} md={6}></Grid>
                        <Grid item xs={12} md={3}>
                            <Button
                                variant="contained"
                                color={'secondary'}
                                onClick={clear}
                                sx={{
                                    width: {
                                        xs: '100%',
                                        sm: '7rem'
                                    },
                                    marginRight: '10px'
                                }}
                            >
                                {<RotateLeftIcon sx={{ marginRight: '5px' }} />}
                                {'Reset'}
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Button
                                variant='contained'
                                sx={{
                                    width: {
                                        xs: '100%',
                                        sm: '7rem'
                                    },
                                }}
                                onClick={handleSubmit}
                            >
                                {isEdit ? 'Update' : 'Submit'}
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            }
            <Container>
                <SuperCategoryTable handleEditData={handleEditData} isChanged={isChanged} />
            </Container>
        </>
    )
}

export default SuperCategory