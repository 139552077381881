import React, { Suspense, lazy, useEffect, useState } from "react";
import { Stack, Paper, Divider, Typography, RadioGroup, Button, Radio } from "@mui/material";
import OutstandingReportTable from "../../../Components/Card/TransactionCards/OutstandingReport/OutstandingReportTable";
import { useNavigate } from "react-router-dom";
import configServ from "../../../services/config";
import { useSelector } from "react-redux";
import OutstandingReportCard from "../../../Components/Card/TransactionCards/OutstandingReport/OutstandingReportCard";

//const OutstandingReportTable = lazy(()=>import('../../../Components/Card/TransactionCards/OutstandingReport/OutstandingCustomerTable'));

const OutstandingReport = () => {
    //const { user_id, company_id } = useSelector((state) => state.GlobalVariables);
    const navigate = useNavigate();
    //const [isLoading, setIsLoading] = useState(true);
    const [isChanged, setIsChanged] = useState(false);

    const handleEdit = async (params) => {
        try {
            setIsChanged(!isChanged);
        }
        catch (e) {
            console.log(e);
        }
    }

    // const goToPay = () => {
    //     navigate('pay')
    //   };

    return (
        <>
            <OutstandingReportCard />
            <OutstandingReportTable isChanged={isChanged} handleEdit={handleEdit} />
        </>
    )
}
export default OutstandingReport;