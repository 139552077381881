import React, { useState, useEffect } from "react";
import Header from "../../Components/header/Header/Header";
import Sidebar from "../../Components/sidebar/SideBar";
import {
  Box,
  Paper,
  Typography,
  Stack,
  Divider,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { useSelector } from "react-redux";
import configServ from "../../services/config";
import StateList from "../../Components/List/StateList";
import ProfilePicture from "../../Components/Assets/images/profilePicture.jpg";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { toast } from "react-hot-toast";


function Profile() {
  const { user_id, is_super, is_admin } = useSelector((state) => state.GlobalVariables);
  const open = useSelector((state) => state.sideExpand.open);
  const [formData, setFormData] = useState({});
  const [isEditable, setIsEditable] = useState(false); // Track if the form is editable

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFormData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const submitHandler = async () => {
    try {
      const dataToSend = {
        ...formData,
        organisationalUser: (Boolean(is_super) === false && Boolean(is_admin) === false) ? true : false,
      }
      const response = await configServ.updateAdminDetails(dataToSend);
      if (response.status === 200) {
        await fetchUser();
        toast.success("Admin updated successfully");
      }
    } catch (error) {
      toast.error("Failed to update admin details");
    }
  };


  const fetchUser = async () => {
    try {
      const dataToSend = {
        user_id: user_id,
        organisationalUser: (Boolean(is_super) === false && Boolean(is_admin) === false) ? true : false,
      };
      const res = await configServ.getAdminDetails(dataToSend);
      if(res.status === 200){
        setFormData(res.data);
      }
      else{
        setFormData({});
      }
    }
    catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <>
      <Box>
        <Stack
          direction={{
            md: "row",
            sm: "column-reverse",
            xs: "column-reverse",
          }}
          justifyContent="space-around"
          alignItems={{ md: "flex-start", sm: "center", xs: "center" }}
          sx={{
            padding: 2,
          }}
          spacing={2}
        >
          <Paper
            elevation={1}
            sx={{
              width: {
                md: "55%",
              },
              padding: 4,
            }}
          >
            <Typography color="#333" variant="body" fontSize="large">
              My account
            </Typography>
            <br />
            <Divider />
            <br />
            <Stack direction="column" spacing={2} sx={{ alignItems: "left" }}>
              <Typography
                variant="body"
                fontSize="small"
                color="text.secondary"
              >
                USER INFORMATION
              </Typography>
              <br />
              <Stack
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                spacing={1}
              >
                <TextField
                  fullWidth
                  size="small"
                  label="Username"
                  name="username"
                  value={formData.username || ""}
                  onChange={changeHandler}
                  InputProps={{
                    readOnly: true, // Toggle readOnly based on isEditable
                  }}
                />
                <TextField
                  fullWidth
                  size="small"
                  label="Email Address"
                  type="email"
                  name="email"
                  value={formData.email || ""}
                  onChange={changeHandler}
                  InputProps={{
                    readOnly: true, // Toggle readOnly based on isEditable
                  }}
                />
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                spacing={1}
              >
                <TextField
                  fullWidth
                  size="small"
                  label="Name"
                  name="name"
                  value={formData.name || ""}
                  onChange={changeHandler}
                  InputProps={{
                    readOnly: !isEditable, // Toggle readOnly based on isEditable
                  }}
                />
                <TextField
                  fullWidth
                  size="small"
                  label="Mobile"
                  type="number"
                  name="mobile"
                  value={formData.mobile || ""}
                  onChange={changeHandler}
                  InputProps={{
                    readOnly: true, // Toggle readOnly based on isEditable
                  }}
                />
              </Stack>
              <Divider />
              <Typography
                variant="body"
                fontSize="small"
                color="text.secondary"
              >
                CONTACT INFORMATION
              </Typography>
              <br />
              <Stack
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                spacing={1}
              >
                <TextField
                  fullWidth
                  size="small"
                  label="Address"
                  name="address"
                  value={formData.address || ""}
                  onChange={changeHandler}
                  InputProps={{
                    readOnly: !isEditable, // Toggle readOnly based on isEditable
                  }}
                />
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                spacing={1}
              >
                <FormControl fullWidth size="small">
                  <InputLabel>State</InputLabel>
                  <Select
                    disabled={!isEditable} // Disable when not editable
                    name="state"
                    label="Select"
                    value={formData.state || ""}
                    onChange={changeHandler}
                  >
                    {StateList.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  size="small"
                  label="Pin"
                  type="number"
                  name="pincode"
                  value={formData.pincode || ""}
                  onChange={changeHandler}
                  InputProps={{
                    readOnly: !isEditable, // Toggle readOnly based on isEditable
                  }}
                />
              </Stack>
              <Button
                variant="contained"
                sx={{
                  width: {
                    xs: "50%",
                    sm: "30%",
                    md: "20%",
                  },
                }}
                onClick={() => {
                  if (isEditable) {
                    submitHandler(); // Call submitHandler if in editable mode
                  }
                  setIsEditable((prev) => !prev); // Toggle edit mode
                }}
              >
                {isEditable ? <SaveIcon /> : <EditIcon />}{" "}
                {/* Use icons instead of text */}
              </Button>
            </Stack>
          </Paper>
          <Paper
            sx={{
              width: {
                md: "35%",
              },
              padding: 4,
            }}
          >
            <Stack
              direction="column"
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <img
                src={
                  formData.image && formData.image !== "0"
                    ? formData.image
                    : ProfilePicture
                }
                alt="Profile"
                width="50%"
                style={{
                  borderRadius: 150,
                }}
              />

              <input
                type="file"
                style={{
                  border: "1px solid #33333355",
                  width: "80%",
                }}
              />
              <Button
                variant="contained"
                sx={{
                  width: {
                    xs: "60%",
                    sm: "40%",
                    md: "30%",
                  },
                }}
              >
                Upload
              </Button>
            </Stack>
          </Paper>
        </Stack>
      </Box>
    </>
  );
}

export default Profile;
