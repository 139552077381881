import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import { topUsers } from "./topUser"; // Assuming topUsers is a list of users
import configServ from "../../services/config";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import LoaderBackdrop from "../../Components/Loader/LoaderBackdrop";

// Function to generate random password
const generateRandomPassword = () => {
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let password = "";
  for (let i = 0; i < 8; i++) {
    password += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return password;
};
const CustomerPassword = () => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [generatedPassword, setGeneratedPassword] = useState("");
  const [customers, setCustomers] = useState([]);
  const { company_id, user_id } = useSelector((state) => state.GlobalVariables);
  const [loader, setLoader] = useState(false);


  const handleGeneratePassword = () => {
    const newPassword = generateRandomPassword();
    setGeneratedPassword(newPassword);
  };

  const fetchCustomerList = async () => {
    setLoader(true);
    try {
      const result = await configServ.get_customer({
        company_id: company_id,
      });
      setCustomers(result);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchCustomerList();
  }, []);
  const handleSave = async () => {
    setLoader(true); // Show the loader before starting the process
    try {
      if (selectedUser) {
        const data = {
          Admin_id: user_id,
          company_id: company_id,
          Customer_ID: selectedUser.id,
          Customer_Name: selectedUser.fname,
          Generated_Password: generatedPassword, // Required for confirmation
        };
        console.log(data);
        const res = await configServ.changeCustomerPassByAdmin(data);
        if (res.status === 200) {
          toast.success(res.message || "Password updated successfully!");
          // Reset form fields
          setSelectedUser(null);
          setGeneratedPassword("");
        }
      } else {
        console.log("No user selected.");
      }
    } catch (error) {
      toast.error(
        error.message || "An error occurred while updating the password."
      );
      console.error(error);
    } finally {
      setLoader(false); // Hide the loader after the process completes
    }
  };

  return (
    <>
      {/* Autocomplete to select user */}
      <LoaderBackdrop open={loader} />
      <Autocomplete
        disablePortal
        options={customers} // Use the customers fetched from the API
        getOptionLabel={(option) => option.fname || ""} // Display the fname of each customer
        sx={{ width: 300, marginBottom: 2, mt: 2 }}
        renderInput={(params) => <TextField {...params} label="Select User" />}
        onChange={(event, value) => setSelectedUser(value)} // Set the selected customer
      />

      {/* TextField to display the generated password */}
      <TextField
        label="Generated Password"
        value={generatedPassword}
        InputProps={{ readOnly: true }}
        sx={{ width: 300, marginBottom: 2 }}
      />

      {/* Button to generate password */}
      <Button
        variant="contained"
        onClick={handleGeneratePassword}
        disabled={!selectedUser} // Disable if no user is selected
      >
        Generate Password
      </Button>
      {/* save to database */}
      <Button
        sx={{ mt: 2 }}
        variant="contained"
        onClick={handleSave}
        disabled={!selectedUser}
      >
        Save
      </Button>
    </>
  );
};

export default CustomerPassword;
