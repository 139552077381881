import React, { memo } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

//FUNCTION
const AdminSubHeader = ({ prefix, suffix, title, handleReset, handleSave, handlePopup, isEdit, handleOTI, create, showCreate = true }) => {
    // const dispatch = useDispatch();
    // const app = useSelector((state) => state.GlobalVariables.noRoomService);
    // const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <>
            <Grid container alignItems="center" sx={{ pt: 0 }} spacing={1}>
                <Grid item xs={2} md={2}>
                    {/* <FormControl fullWidth variant="standard" sx={{ mb: 1 }}>
                        <InputLabel id="demo-simple-select-standard-label">Application</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={app}
                            label="Application"
                            onChange={(e) => handleApplication(e.target.value)}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={'dcrm'}>DCRM</MenuItem>
                            <MenuItem value={'noroom'}>NoRoom</MenuItem>
                        </Select>
                    </FormControl> */}
                </Grid>
                <Grid container item xs={8} md={8} alignItems={'center'} justifyContent={'center'}>
                    <Typography
                        variant='button'
                        sx={{
                            textAlign: 'center',
                            fontWeight: 'bolder', // Add the fontWeight property to make the text bolder
                            fontSize: { xs: '16px', md: '24px' }, // Add the fontSize property to increase the text size
                            color: '#1e61b4',
                        }}
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.1 }}
                    >
                        {title}
                    </Typography>
                </Grid>
                <Grid item xs={2} md={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {
                        showCreate === true &&
                        <Button
                            variant="contained"
                            color={!create ? "primary" : "error"}
                            size='small'
                            onClick={handlePopup}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center', // Center content horizontally
                                alignItems: 'center', // Center content vertically
                            }}
                            title={!create ? 'Add' : 'Close'}
                        >
                            {!create ? <AddIcon /> : <CloseIcon />}
                            {/* {!isMobile && (!create ? 'Add' : 'Close')} */}
                        </Button>
                    }
                </Grid>
            </Grid>
        </>
    );
};

export default memo(AdminSubHeader);
