import React, { memo, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Box, Dialog, DialogContent, Paper, Typography } from '@mui/material';
import SupplierLedgerTable from './SupplierLedgerTable';
import SupplierLedgerCard from './SupplierLedgerCard';
import useDeviceDetect from '../Detect/useDeviceDetect ';
import PopupDialogTitle from '../Dialog/PopupDialogTitle';
import Calculation from '../../utility/Calculations';
import { triggerLoader } from '../../redux/reducers/LoaderTrigger';
import { useDispatch } from 'react-redux';

//FUNCTION
const SupplierLedgerPopup = ({ openSupplierLedger, handleSupplierLedgerClose, supplierLedger, selectedSupplier }) => {
    const { isMobile } = useDeviceDetect();
    const dispatch = useDispatch();
    const supplierRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => supplierRef.current,
        documentTitle: 'Supplier Ledger',
    });

    const handleDownload = () => {
        try {
            dispatch(triggerLoader());
            const input = supplierRef.current;

            html2canvas(input, { scale: 1 }) // Adjust the scale to reduce resolution (e.g., 1 for lower resolution)
                .then(canvas => {
                    const imgData = canvas.toDataURL('image/jpeg', 0.75); // Use 'image/jpeg' and reduce quality (0.75 is 75% quality)
                    const pdf = new jsPDF();
                    const imgProps = pdf.getImageProperties(imgData);
                    const pdfWidth = pdf.internal.pageSize.getWidth();
                    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

                    pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight); // Use 'JPEG' instead of 'PNG'
                    const name = selectedSupplier.fname ?? 'SupplierLedger';
                    const date = Calculation.GetCurrentDateString();
                    const pdfName = name + '_' + date + '.pdf';
                    setTimeout(() => {
                        pdf.save(pdfName);
                        dispatch(triggerLoader());
                    }, 5000);
                })
                .catch(error => {
                    console.error('Error generating PDF: ', error);
                    dispatch(triggerLoader()); // Stop loader if there's an error
                });
        }
        catch (e) {
            console.log(e);
            dispatch(triggerLoader()); // Stop loader if there's an error in try block
        }
    };

    return (
        <>
            <Dialog
                open={openSupplierLedger}
                onClose={handleSupplierLedgerClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={false}
                PaperProps={{
                    sx: {
                        width: '80%',
                        height: '80%',
                    },
                }}
            >
                
                <PopupDialogTitle handleDownload={handleDownload} handlePrint={handlePrint} handleClose={handleSupplierLedgerClose}/>
                <DialogContent>
                    {
                        isMobile === true ?
                            <Box component={Paper} sx={{ padding: '16px', backgroundColor: '#fff', overflowY: 'auto', maxHeight: '80vh' }} ref={supplierRef}>
                                {supplierLedger.length > 0 ? (
                                    <SupplierLedgerCard supplierLedger={supplierLedger} selectedSupplier={selectedSupplier} />
                                ) : (
                                    <Typography variant="h6" align="center" color="red">
                                        No items found.
                                    </Typography>
                                )}
                            </Box>
                            :
                            <Box component={Paper} sx={{ padding: '16px', backgroundColor: '#fff', overflowY: 'auto', maxHeight: '80vh' }} ref={supplierRef}>
                            <SupplierLedgerTable supplierLedger={supplierLedger} selectedSupplier={selectedSupplier}/>
                            </Box>
                    }
                </DialogContent>
            </Dialog>
        </>
    )
}

export default memo(SupplierLedgerPopup);
