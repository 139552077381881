import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { CartItem } from "./CartItem";
import configServ from "../../../services/config";
import { triggerLoader } from "../../../redux/reducers/LoaderTrigger";
import { clear } from '../../../redux/reducers/Cart'
import { useNavigate } from "react-router-dom";
import AlertDialog from "../../../Components/AlertDialog/AlertDialog";
import Swal from "sweetalert2";
import Calculation from "../../../utility/Calculations";

const Order = () => {
    //#region code
    const { user_id, company_id, currency } = useSelector((state) => state.GlobalVariables);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState('');

    const handleOpenDialog = (content) => {
        setDialogContent(content);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setDialogContent('');
    };
    const dispatch = useDispatch()
    const cart = useSelector((state) => state.cart)
    const navigate = useNavigate()

    const getCurrentDate = () => {
        const currentDate = new Date();
        const day = currentDate.getDate().toString().padStart(2, '0');
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const year = currentDate.getFullYear();

        return `${year}-${month}-${day}`
    }

    const generateOrderNumber = () => {
        const min = 100000;
        const max = 999999;
        const number = Math.floor(Math.random() * (max - min + 1)) + min;
        return `ORD${number}`
    };

    const fetchUserName = async () => {
        const id = {
            "user_id": user_id
        }
        if (id.user_id !== undefined) {
            // const res = await http.post('/api/profiledetail',id )
            const res = await configServ.profiledetail(id)
            if (res.success) {
                return res.success.name
            } else {
                console.error('Error while fetchiing user data')
            }
        } else {
            console.log('There is a login error, please login again')
        }
    }

    const itemAssign = async () => {
        try {
            const selectedItemsArray = cart.cartValue.map((item) => ({
                "company_id": company_id,
                "itemname": item.itemname,
                "hsn_code": item.hsn_code,
                "Taxrate": item.Taxrate,
                "discount": item.discount,
                "qty": item.qty,
                "rate": item.rate,
                "tax_amount": item.qty * Calculation.taxAmount(item.rate, item.discount, item.Taxrate),
                "amount": item.qty * Calculation.netAmount(item.rate, item.discount, item.Taxrate),
                "itemid": item.id,
                "item_code": item.item_code,
                "size_id": item.size_id,
                "issizeAdded": item.issizeAdded,
                "item_size": item.item_size
            }));
            
            return selectedItemsArray
        } catch (e) {
            console.log(e)
        }
    }

    const handleOrder = async (e) => {
        e.preventDefault();
        dispatch(triggerLoader());
        const dataToSend = {
            "order_no": generateOrderNumber(),
            "customer_id": user_id,
            "company_id": company_id,
            "order_date": getCurrentDate(),
            "partyname": await fetchUserName(),
            "shiptoaddress": cart.shiptoaddress,
            "billtoaddress": cart.billtoaddress,
            "with_gst": 1,
            "currency": currency,
            "tax_amount": cart.cartTaxAmount,
            "total_amount": cart.cartNetAmount,
            "item": []
        }
        dataToSend.item = await itemAssign();
        try {
            const res = await configServ.placeOrder(dataToSend);
            if (res.status === 200) {
                Swal.fire({
                    title: "Order!",
                    text: "Your order is successfully placed!",
                    icon: "success"
                });

                navigate('/Purchase-Order');
                dispatch(clear());
            }
            else {
                Swal.fire({
                    icon: "error",
                    title: "Order!",
                    text: "Failed to place order. Something went wrong!",
                });
            }
        }
        catch (e) {
            console.log(e);
            Swal.fire({
                icon: "error",
                title: "Order!",
                text: "Failed to place order. Something went wrong!",
            });
        }
        finally{
            dispatch(triggerLoader());
        }
    }
    //#endregion

    return (
        <>
            <AlertDialog
                open={dialogOpen}
                setOpen={handleCloseDialog}
                title="Alert"
                message={dialogContent}
                buttonTitle="Ok"
            />
            <Grid container alignItems={'center'} justifyContent={'center'} spacing={1}>
                <Grid container xs={12} md={6} alignItems={'center'} spacing={1} p={3} sx={{ background: '#fffff5'}} border='1px solid #33333355'>
                    <Grid container item xs={12} justifyContent={'center'}>
                        <Typography variant="h6"><b>ORDER CONFIRMATION</b></Typography>
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="body">Bill to address</Typography>
                            <Typography variant="body2" fontWeight='bold' color='text'>{cart.billtoaddress}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="body">Ship to address</Typography>
                            <Typography variant="body2" fontWeight='bold' color='text'>{cart.shiptoaddress}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body" fontWeight='bold'>Product(s)</Typography>
                    </Grid>
                    {/* <Divider sx={{ bgcolor: '#999', width: '100%' }} /> */}
                    {
                        cart.cartValue.map((item) => (

                            <Grid key={item.id} item xs={12}>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography variant="body">{item.itemname}   <span style={{ fontWeight: 'bold', marginLeft: '10px' }}>x  {item.qty}</span></Typography>
                                    <Typography variant="body2" fontWeight='bold'>{Calculation.netAmount(item.rate, item.discount, item.Taxrate)}</Typography>
                                </Box>
                            </Grid>
                        ))
                    }
                    <br />
                    <Divider sx={{ bgcolor: '#999', width: '100%' }} />
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="body">Total Amount</Typography>
                            <Typography variant="body2" fontWeight='bold' color='text'>{currency} {cart.cartNetAmount}</Typography>
                        </Box>
                    </Grid>
                    <Divider sx={{ bgcolor: '#999', width: '100%' }} />
                    <br />
                    <Grid container item xs={12} justifyContent={'center'}>
                        <Button
                            variant="contained"
                            color="success"
                            onClick={handleOrder}
                            sx={{
                                mt: 3,
                                mb: 2,
                                width: "200px",
                                minWidth: '200px',
                                ":hover": {
                                    backgroundColor: 'black'
                                },
                                alignSelf: {
                                    xs: 'center',
                                    md: 'flex-start'
                                }
                            }}
                        >
                            Confirm Order
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}
export default Order;