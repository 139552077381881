import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Paper,
  TablePagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
  Stack,
  Box,
  IconButton,
  TableSortLabel,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  TextField,
} from "@mui/material";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import isBetween from "dayjs/plugin/isBetween";
import advancedFormat from "dayjs/plugin/advancedFormat";
import dayjs from "dayjs";
import { LicenseInfo } from "@mui/x-license";
import RefreshIcon from "@mui/icons-material/Refresh";

import { motion, AnimatePresence } from "framer-motion";
import { useSelector } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PrintIcon from "@mui/icons-material/Print";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import CustPOdetail from "./CustPOdetail";
import MiniLoader from "../MiniLoader/MiniLoader";
import configServ from "../../../services/config";
import { triggerLoader } from "../../../redux/reducers/LoaderTrigger";
import { useDispatch } from "react-redux";
import AlertDialog from "../../../Components/AlertDialog/AlertDialog";
import LoaderNew from "../../../Components/Loader/LoaderNew";
import SearchIcon from "@mui/icons-material/Search";
dayjs.extend(weekOfYear);
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(isBetween);
dayjs.extend(advancedFormat);
const CustPurchaseTable = () => {
  LicenseInfo.setLicenseKey(
    "e0d9bb8070ce0054c9d9ecb6e82cb58fTz0wLEU9MzI0NzIxNDQwMDAwMDAsUz1wcmVtaXVtLExNPXBlcnBldHVhbCxLVj0y"
  );
  const [page, setPage] = useState(0);
  const rowsPerPageOptions = [20, 50, 100, 200];
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
  const [open, setOpen] = useState(false);
  const [itemId, setItemId] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("id");
  const handleOpenDialog = (content) => {
    setDialogContent(content);
    setDialogOpen(true);
  };
  const { user_id, company_id, is_admin } = useSelector(
    (state) => state.GlobalVariables
  );
  const [tableData, setTableData] = useState([]);
  const [miniLoader, setMiniLoader] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState([null, null]);
  // const PayLoad = {
  //   company_id: company_id,
  //   customer_id: user_id,
  //   is_admin: is_admin,
  //   sortColumn: orderBy,
  //   sortOrder: order,
  //   startDate: startDate,
  //   endDate : endDate,
  // };
  const handleReset = () => {
    setSelectedDateRange("");
    fetchTableData();
  };

  useEffect(() => {
    const [selectedStartDate, selectedEndDate] = selectedDateRange;
    fetchTableData(
      orderBy,
      order,
      selectedStartDate ? selectedStartDate.format("YYYY-MM-DD") : null,
      selectedEndDate ? selectedEndDate.format("YYYY-MM-DD") : null
    );
  }, [orderBy, order, selectedDateRange]); // Add selectedDateRange as a dependency

  const fetchTableData = async (sortColumn, sortOrder, startDate, endDate) => {
    setMiniLoader(true);
    try {
      const PayLoad = {
        company_id: company_id,
        customer_id: user_id,
        is_admin: is_admin,
        sortColumn,
        sortOrder,
        startDate,
        endDate,
      };
      const result = await configServ.customer_order_list(PayLoad);
      setTableData(result.data);
    } catch (err) {
      console.log(err);
    } finally {
      setMiniLoader(false);
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    fetchTableData(property, isAsc ? "desc" : "asc");
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDialogContent("");
  };
  const dispatch = useDispatch();

  const columns = [
    {
      id: "id",
      label: "SR. NO",
      minWidth: 170,
    },
    {
      id: "order_no",
      label: "ORDER NO",
      minWidth: 170,
      align: "right",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "order_date",
      label: "ORDER DATE",
      minWidth: 170,
      align: "right",
      format: (value) => value.toLocaleString("en-US"),
    },
    // {
    //     id: 'order_type',
    //     label: 'ORDER TYPE',
    //     minWidth: 170,
    //     align: 'right',
    //     format: (value) => value.toLocaleString('en-US'),
    // },
    {
      id: "order_status",
      label: "ORDER STATUS",
      minWidth: 170,
      align: "right",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "delivery_date",
      label: "DELIVERY DATE",
      minWidth: 170,
      align: "right",
      format: (value) => value.toFixed(2),
    },
    {
      id: "validitydate",
      label: "VALIDITY",
      minWidth: 170,
      align: "right",
      format: (value) => value.toFixed(2),
    },
    {
      id: "total_amount",
      label: " TOTAL AMOUNT",
      minWidth: 170,
      align: "right",
      format: (value) => value.toFixed(2),
    },
    {
      id: "action",
      label: "ACTION",
      minWidth: 170,
      align: "right",
      format: (value) => value.toFixed(2),
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handlePrint = async (order_id) => {
    const data = {
      order_id: order_id,
    };
    // console.log(data)
    dispatch(triggerLoader());
    try {
      const result = await configServ.printSalesOrder(data);
      // console.log(result)
      const blobFile = new Blob([result], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blobFile);
      window.open(url);
      dispatch(triggerLoader());
    } catch (err) {
      console.log(err);
      handleOpenDialog("Print failed");
      dispatch(triggerLoader());
    }
  };

  const handleOrderDetail = (id) => {
    setItemId(id);
    setOpen(!open);
  };

  return (
    <>
      <AlertDialog
        open={dialogOpen}
        setOpen={handleCloseDialog}
        title="Alert"
        message={dialogContent}
        buttonTitle="Ok"
      />
      {open && <CustPOdetail open={open} setOpen={setOpen} id={itemId} />}

      <Grid
        container
        alignItems={"center"}
        spacing={1}
        mt={1}
        sx={{
          display: { xs: "flex", md: "none" }, // Show on small screens, hide on medium and larger screens
        }}
      >
        <Grid item xs={6} justifyContent={"center"}>
          <FormControl fullWidth size="small">
            <InputLabel>Sort By</InputLabel>
            <Select
              fullWidth
              size="small"
              name="order_by"
              label="Sort By"
              value={orderBy}
              onChange={(e) => setOrderBy(e.target.value)}
              sx={{ background: "white" }}
            >
              {columns.map((item, index) => (
                <MenuItem key={index} value={item.id}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} justifyContent={"center"}>
          <FormControl fullWidth size="small">
            <InputLabel>Order</InputLabel>
            <Select
              fullWidth
              size="small"
              name="order"
              label="Order"
              value={order}
              onChange={(e) => setOrder(e.target.value)}
              sx={{ background: "white" }}
            >
              <MenuItem key={1} value={"asc"}>
                {"Ascending"}
              </MenuItem>
              <MenuItem key={2} value={"desc"}>
                {"Descending"}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems={"center"}
        spacing={1}
        mt={1}
        sx={{
          display: { xs: "flex", md: "flex" },
          mb: { md: 2 },
        }}
        justifyContent={"flex-end"}
      >
        <Grid item xs={11} md={5}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRangePicker
              startText="Start Date"
              endText="End Date"
              value={selectedDateRange}
              onChange={(newValue) => {
                const [startDate, endDate] = newValue;
                console.log(
                  "Start Date:",
                  startDate ? startDate.format("YYYY-MM-DD") : "null"
                );
                console.log(
                  "End Date:",
                  endDate ? endDate.format("YYYY-MM-DD") : "null"
                ); // Logs the selected date range
                setSelectedDateRange(newValue);
              }}
              renderInput={(startProps, endProps) => (
                <>
                  <TextField
                    {...startProps}
                    fullWidth
                    size="small"
                    variant="outlined"
                  />
                  <TextField
                    {...endProps}
                    fullWidth
                    size="small"
                    variant="outlined"
                  />
                </>
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={1} sm={1} md={1} display="flex" justifyContent="center">
          <IconButton onClick={handleReset} sx={{ color: "green" }}>
            <RefreshIcon />
          </IconButton>
        </Grid>
      </Grid>

      <Paper sx={{ width: "100%" }}>
        <TableContainer
          sx={{ maxHeight: 440, display: { xs: "none", md: "block" } }}
          component={motion.div}
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 440 }}
          transition={{ duration: 0.3, delay: 0.2 }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    sortDirection={orderBy === column.id ? order : false}
                    sx={{
                      fontSize: 14,
                      fontWeight: "bold",
                      backgroundColor: "#4CB5F5",
                      color: "white",
                    }}
                  >
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : "asc"}
                      onClick={() => handleRequestSort(column.id)}
                    >
                      <b>{column.label}</b>
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {miniLoader ? (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    <Stack
                      justifyContent="center"
                      alignItems="center"
                      sx={{ minHeight: 200 }}
                    >
                      <LoaderNew />{" "}
                      {/* Display loader when miniLoader is true */}
                    </Stack>
                  </TableCell>
                </TableRow>
              ) : tableData.length > 0 ? (
                tableData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        backgroundColor: index % 2 === 0 ? "#f5f5f5" : "white",
                      }}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                    >
                      <TableCell sx={{ minWidth: 100 }}>
                        <Tooltip title="View Details" arrow>
                          <VisibilityIcon
                            onClick={() => handleOrderDetail(row.id)}
                            color="primary"
                            sx={{
                              mr: "20px",
                              transition: "100ms",
                              cursor: "pointer",
                              ":hover": { transform: "scale(1.15)" },
                            }}
                          />
                        </Tooltip>
                        {index + 1}
                      </TableCell>
                      <TableCell>{row.order_no || "N/A"}</TableCell>
                      <TableCell>{row.order_date || "N/A"}</TableCell>
                      <TableCell>{row.order_status || "N/A"}</TableCell>
                      <TableCell>{row.delivery_date || "N/A"}</TableCell>
                      <TableCell>{row.validitydate || "N/A"}</TableCell>
                      <TableCell>{row.total_amount || "N/A"}</TableCell>
                      <TableCell>
                        <Stack direction="row">
                          <Tooltip title="Print" placement="top-start" arrow>
                            <Box
                              onClick={() => handlePrint(row.id)}
                              sx={{
                                borderRadius: 10,
                                width: "fit-content",
                                transition: "150ms",
                                cursor: "pointer",
                                padding: 0.5,
                                ":hover": { backgroundColor: "#99999955" },
                              }}
                            >
                              <PrintIcon />
                            </Box>
                          </Tooltip>
                          <Tooltip title="Edit" placement="top-start" arrow>
                            <Box
                              sx={{
                                borderRadius: 10,
                                width: "fit-content",
                                transition: "150ms",
                                cursor: "pointer",
                                padding: 0.5,
                                ":hover": { backgroundColor: "#99999955" },
                              }}
                            >
                              <DriveFileRenameOutlineIcon />
                            </Box>
                          </Tooltip>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={columns.length}>
                    <Typography variant="h6">No Records to show</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Mobile view */}
        <Box
          sx={{
            display: { xs: "block", md: "none" },
            padding: 2,
            border: "1px solid #ddd",
            borderRadius: "8px",
            marginTop: 2,
            boxShadow: "0px 4px 10px rgba(255, 255, 255, 0.5)",
          }}
        >
          {miniLoader ? (
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{ minHeight: 200 }}
            >
              <LoaderNew />{" "}
              {/* Show loader on mobile view when miniLoader is true */}
            </Stack>
          ) : tableData.length > 0 ? (
            tableData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <Box
                  key={row.id}
                  sx={{
                    padding: 2,
                    borderBottom: "1px solid #ddd",
                    backgroundColor: index % 2 === 0 ? "#f5f5f5" : "white",
                    boxShadow: "0px 2px 5px rgba(255, 255, 255, 0.7)",
                    borderRadius: "4px",
                    marginBottom: 1,
                  }}
                >
                  <Typography variant="body1">
                    Order No: {row.order_no || "N/A"}
                  </Typography>
                  <Typography variant="body2">
                    Order Date: {row.order_date || "N/A"}
                  </Typography>
                  <Typography variant="body2">
                    Status: {row.order_status || "N/A"}
                  </Typography>
                  <Typography variant="body2">
                    Delivery Date: {row.delivery_date || "N/A"}
                  </Typography>
                  <Typography variant="body2">
                    Validity Date: {row.validitydate || "N/A"}
                  </Typography>
                  <Typography variant="body2">
                    Total Amount: {row.total_amount || "N/A"}
                  </Typography>
                  <Stack direction="row" spacing={1}>
                    <Tooltip title="View Details" arrow>
                      <IconButton onClick={() => handleOrderDetail(row.id)}>
                        <VisibilityIcon color="primary" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Print" arrow>
                      <IconButton onClick={() => handlePrint(row.id)}>
                        <PrintIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit" arrow>
                      <IconButton>
                        <DriveFileRenameOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Box>
              ))
          ) : (
            <Typography align="center">No Records to show</Typography>
          )}
        </Box>

        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};
export default CustPurchaseTable;
