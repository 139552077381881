import React, { useState, useEffect } from "react";
import Footer from "../../../Components/Footer/Footer";
import CustomerSideBar from "../../../Components/sidebar/CustomerSideBar";
import CustHeader from "../../../Components/header/Header/CustHeader";
import AddInvoiceToPay from "./AddInvoiceToPay";
import Skeleton from "@mui/material/Skeleton";
import {
  Box,
  Stack,
  Paper,
  Divider,
  Typography,
  Button,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useSelector } from "react-redux";
import { SessionVerification } from "../../../Components/SessionManagement/SessionManagement";
import { Autocomplete } from "@mui/joy";
import configServ from "../../../services/config";
import MoneyIcon from "@mui/icons-material/Money";
import AlertDialog from "../../../Components/AlertDialog/AlertDialog";
import { MultiSelect } from "react-multi-select-component";
import { useNavigate } from "react-router-dom";
import qrCode from "../../../Components/Assets/images/qrCode.png";
import SupportButton from "../../../pages/Welcome/SupportButton";

const Pay = () => {
  //#region code
  const { user_id, company_id, id } = useSelector(
    (state) => state.GlobalVariables
  );
  const navigate = useNavigate(); // Initialize the navigation function
  const open = useSelector((state) => state.sideExpand.open);
  const [invoicelist, setInvoiceList] = useState([]);
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [summary, setSummary] = useState({});
  const [totalDue, setTotalDue] = useState(0);
  const [paySource, setPaySource] = useState(["cash", "bank", "upi"]);
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({
    pay_against: "invoice_pay",
    payment_mode: "", // Initialize to the first payment source or empty
  });
  const [customer, setCustomer] = useState({});
  const [invoiceToPay, setInvoiceToPay] = useState([
    {
      id: 1,
      invoice_id: 0,
      invoice_no: "",
      due: 0,
      amountPaying: 0,
    },
  ]);
  const [totalAmountToPay, setTotalAmountToPay] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [invoice, setInvoice] = useState([]);
  const [selectedInvoices, setSelectedInvoices] = useState("");
  const [invoiceAmount, setInvoiceAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const handleOpenDialog = (content) => {
    setDialogContent(content);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDialogContent("");
  };
  const handleAddItem = () => {
    setInvoiceToPay((state) => [
      ...state,
      {
        id: invoiceToPay[invoiceToPay.length - 1].id + 1,
        invoice_no: "",
        due: 0,
        amountPaying: 0,
      },
    ]);
  };

  // Define the function to handle invoice selection and calculate total amounts
  const handleInvoiceSelection = (selected) => {
    if (selected.length === 0) {
      // Clear the selected invoice
      setSelectedInvoices("");
      setInvoiceAmount(0);
      setTotalAmount(0);
      setTotalAmountToPay(0);
    } else {
      const lastSelectedInvoice = selected[0];
      const selectedInvoiceNumber = lastSelectedInvoice.value;

      const selectedInvoiceDetail = invoice.find(
        (inv) => inv.invoice_number === selectedInvoiceNumber
      );

      if (selectedInvoiceDetail) {
        const totalAmount = Number(selectedInvoiceDetail.total_amount);

        setSelectedInvoices(selectedInvoiceNumber); // Set as a single string
        setInvoiceAmount(totalAmount);
        setTotalAmount(totalAmount);
        setTotalAmountToPay(totalAmount);
      }
    }
  };

  const delItem = (id) => {
    const updatedItemList = invoiceToPay.filter((item) => item.id !== id);
    setInvoiceToPay(updatedItemList);
    // console.log(updatedItemList)
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "pay_against") {
      // Reset form data and states based on the selected option
      setFormData({
        pay_against: value,
        payment_mode: "",
      });
      setSelectedInvoices();
      setInvoiceAmount(0);
      setTotalAmount(0);
      setTotalAmountToPay(0);
    } else {
      // Update other form data fields normally
      setFormData((state) => ({
        ...state,
        [name]: value,
      }));
    }
  };

  const fetchCustomerDetails = async () => {
    try {
      setLoader(true);
      const result = await configServ.getCustomerById({ customer_id: user_id });
      setCustomer(result.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  const fetchInvoiceList = async () => {
    try {
      const result = await configServ.GetUnpaidCustomerInvoiceList({
        user_id: user_id,
      });
      console.log(result);
      setInvoice(result.data);
    } catch (err) {
      console.log(err);
    }
  };

  const amountToPayCalculation = (e) => {
    let total = 0;
    if (formData.pay_against === "invoice_pay") {
      invoiceToPay.map((item) => {
        if (isNaN(item.amountPaying)) {
          return;
        }
        total = total + parseFloat(item.amountPaying.toFixed(2));
      });
      setTotalAmountToPay(total);
    } else {
      setTotalAmountToPay(parseFloat(e.target.value));
    }
  };

  useEffect(() => {
    amountToPayCalculation();
  }, []);

  useEffect(() => {
    fetchInvoiceList();
    fetchCustomerDetails();
  }, []);

  const handleSubmit = async () => {
    if (!formData.payment_mode) {
      // Validation check
      handleOpenDialog("Please select a payment mode"); // Show alert
      return; // Prevent submission
    } else if (!totalAmountToPay) {
      handleOpenDialog("Please enter a valid amount to pay");
      return;
    } else if (!formData.remarks) {
      // Validation check
      handleOpenDialog("Please write Remark's"); // Show alert
      return; // Prevent submission
    } else {
      const dataToSend = {
        customer_id: user_id,
        amount: totalAmountToPay,
        invoice_numbers: selectedInvoices,
        ...formData, // Ensure formData contains required fields
        company_id: company_id, // Ensure company_id is valid
      };
      console.log(dataToSend);
      try {
        const result = await configServ.createCustomerUnregisterPayment(
          dataToSend
        );

        // Check if the status is 200
        if (result.status === 200) {
          handleOpenDialog("Payment successful");
          setTimeout(() => {
            navigate("/customer-payment", { state: { payment: "yes" } });
          }, 2000); // 2-second delay
        } else {
          handleOpenDialog("Payment failed");
        }

        clear();
      } catch (err) {
        console.log(err);
        handleOpenDialog("Payment failed");
      }
    }
  };

  useEffect(() => {
    // const getUser = async () => {
    //   const res = await configServ.profiledetail({ user_id: user_id });
    //   if (res.success) {

    //   }
    //   else {
    //     console.error("Error while fetchiing user data");
    //   }
    // };

    const getDashboardSummary = async () => {
      try {
        const res = await configServ.dashboardsummary({
          user_id: id,
          company_id: company_id,
          user_idd: user_id,
        });
        setSummary({
          outstanding_amount: res.outstanding_amount,
        });
      } catch (err) {
        console.log(err);
      }
    };
    //getUser();
    getDashboardSummary();
  }, []);

  const handleInvoiceToPay = (data, id) => {
    const updatedInvoiceToPay = invoiceToPay.map((item) => {
      return item.id === id
        ? {
            ...item,
            invoice_id: data.invoice.id,
            invoice_no: data.invoice.invoice_number,
            due: data.balance,
          }
        : item;
    });
    setInvoiceToPay(updatedInvoiceToPay);
  };

  const clear = () => {
    setFormData({
      pay_against: "invoice_pay",
    });
    setSelectedInvoices();
    setInvoiceAmount(0);
    setTotalAmount(0);
    setTotalAmountToPay(0);
  };

  return (
    <>
      <AlertDialog
        open={dialogOpen}
        setOpen={handleCloseDialog}
        title="Alert"
        message={dialogContent}
        buttonTitle="Ok"
      />
      <Grid
        container
        spacing={2}
        sx={{ backgroundColor: "#f5f5f5", padding: 2 }}
      >
        <Grid
          item
          xs={12}
          md={12} // Adjust width as per screen size
          sm={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }} // Stack content vertically
        >
          <Typography
            variant="h6"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <b>Customer Name:</b>&nbsp;
            {loader ? (
              <Skeleton
                variant="text"
                width={230}
                sx={{ display: "inline-block" }}
              />
            ) : (
              <span>{customer.contact_name}</span>
            )}
          </Typography>
          <Typography
            variant="h6"
            sx={{ display: "flex", alignItems: "center", mt: 1 }}
          >
            <b>Due Amount:</b>&nbsp;
            {loader ? (
              <Skeleton
                variant="text"
                width={230}
                sx={{ display: "inline-block" }}
              />
            ) : (
              <span>{summary.outstanding_amount}</span>
            )}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        sx={{ padding: 2, backgroundColor: "#f0f4ff" }}
      >
        {/* Left Section */}
        <Grid item xs={12} md={6}>
          <FormControl>
            <RadioGroup
              row
              name="pay_against"
              onChange={handleChange}
              value={formData.pay_against}
            >
              <FormControlLabel
                value="invoice_pay"
                control={<Radio size="small" />}
                label="Pay against invoice(s)"
              />
              <FormControlLabel
                value="no_invoice_pay"
                control={<Radio size="small" />}
                label="Pay without invoice"
              />
            </RadioGroup>
          </FormControl>
          {formData.pay_against === "invoice_pay" && (
            <Stack
              spacing={2}
              sx={{
                width: "100%",
                padding: 2,
                backgroundColor: "#ffffff",
                borderRadius: 2,
                boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              {/* Invoice Number */}
              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <select
                  value={selectedInvoices || ""}
                  onChange={(event) => {
                    const selectedValue = event.target.value;
                    if (selectedValue === "") {
                      handleInvoiceSelection([]); // Pass an empty array if selection is cleared
                    } else {
                      handleInvoiceSelection([
                        { label: selectedValue, value: selectedValue },
                      ]); // Keep only the last selected item
                    }
                  }}
                  style={{
                    borderRadius: "4px",
                    borderColor: "#d0d0d0",
                    padding: "8px",
                    width: "100%",
                  }}
                >
                  <option value="" disabled>
                    Select Invoice(s)
                  </option>
                  {invoice.map((inv) => (
                    <option key={inv.invoice_number} value={inv.invoice_number}>
                      {inv.invoice_number}
                    </option>
                  ))}
                </select>
                {selectedInvoices && selectedInvoices.length > 0 && (
                  <button
                    onClick={() => handleInvoiceSelection([])} // Clear selection on click
                    style={{
                      background: "transparent",
                      border: "none",
                      cursor: "pointer",
                      fontSize: "16px",
                      color: "#999",
                    }}
                    title="Clear selection"
                  >
                    ✖
                  </button>
                )}
              </div>

              {/* Invoice Amount */}
              <TextField
                label="Invoice Amount"
                fullWidth
                size="small"
                value={invoiceAmount}
                InputProps={{
                  readOnly: true,
                  sx: { backgroundColor: "white", borderRadius: "4px" },
                }}
              />

              {/* Total Amount */}
              <TextField
                size="small"
                label="Total Amount"
                fullWidth
                value={totalAmount}
                InputProps={{
                  readOnly: true,
                  sx: { backgroundColor: "white", borderRadius: "4px" },
                }}
              />
            </Stack>
          )}

          {formData.pay_against === "no_invoice_pay" && (
            <Stack
              spacing={2}
              sx={{
                width: "100%",
                padding: 2,
                backgroundColor: "#ffffff",
                borderRadius: 2,
                boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <TextField
                label="Amount To Pay"
                type="number"
                size="small"
                fullWidth
                sx={{ backgroundColor: "white", borderRadius: "4px" }}
                onChange={amountToPayCalculation}
              />
            </Stack>
          )}

          <Stack
            spacing={2}
            sx={{
              width: "100%",
              padding: 2,
              backgroundColor: "#ffffff",
              borderRadius: 2,
              boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            <FormControl fullWidth size="small" sx={{ marginBottom: 2 }}>
              <TextField
                label="Remarks"
                name="remarks" // Match this with the formData key
                value={formData.remarks || ""} // Bind to formData.remarks
                onChange={(e) =>
                  setFormData({ ...formData, remarks: e.target.value })
                } // Update formData.remarks
                size="small"
              />
            </FormControl>

            <FormControl fullWidth size="small">
              <InputLabel>Payment mode</InputLabel>
              <Select
                required
                name="payment_mode" // Make sure this matches the formData key
                label="Payment mode"
                value={formData.payment_mode || ""} // Bind directly to formData.pay_source
                onChange={(e) =>
                  setFormData({ ...formData, payment_mode: e.target.value })
                } // Update formData.pay_source directly
              >
                {paySource.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Typography variant="body1" align="right" sx={{ marginTop: 1 }}>
              Total Amount: <strong>{totalAmountToPay}</strong>
            </Typography>

            <Button variant="contained" fullWidth onClick={handleSubmit}>
              Proceed
            </Button>
          </Stack>
        </Grid>

        {/* Right Section */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex-col",
            alignItems: "flex-start",
            marginTop: { md: "37px", xs: "2px" }, // Apply 37px for md and reset for smaller devices
          }}
        >
          <Box
            sx={{
              width: "100%",
              padding: 2,
              backgroundColor: "#ffffff",
              borderRadius: 2,
              boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
              textAlign: "center",
            }}
          >
            {formData.payment_mode === "bank" ? (
              <>
                <Typography variant="h6">Bank Details</Typography>
                <Typography>Account No: XXXXXXXX</Typography>
                <Typography>IFSC Code: ABCD12345</Typography>
                <Typography>Bank Name: XYZ Bank</Typography>
              </>
            ) : formData.payment_mode === "upi" ? (
              <>
                <Typography variant="h6">Scan to Pay</Typography>
                <img
                  src={qrCode}
                  alt="QR Code"
                  style={{ width: "100%", maxWidth: 200 }}
                />
                <Typography
                  variant="body1"
                  align="center"
                  sx={{ marginTop: 1 }}
                >
                  UPI ID 1: <strong>example1@upi</strong>
                </Typography>
                <Typography variant="body1" align="center">
                  UPI ID 2: <strong>example2@upi</strong>
                </Typography>
              </>
            ) : (
              <Typography variant="body1">
                Please select a payment mode to view details.
              </Typography>
            )}
          </Box>
          <Box sx={{ marginTop: 2, textAlign: "center" }}>
            <SupportButton />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
export default Pay;
