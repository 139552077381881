import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

//FUNCTION
export default function AlertDialog({ open, setOpen, title, message, buttonTitle, buttonType }) {

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ backgroundColor: 'transparent' }}
      >
        <DialogContent sx={{
          minWidth: { xs: 300, md: 500 }
        }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {
              buttonType === 'success' ?
                <CheckCircleOutlineIcon color='success' style={{ fontSize: 80 }} /> :
                (
                  buttonType === 'error' ?
                    <HighlightOffIcon color='error' style={{ fontSize: 80 }} />
                    :
                    (
                      buttonType === 'warning' ?
                        <ErrorOutlineIcon color='warning' style={{ fontSize: 80 }} /> :
                        <InfoOutlinedIcon color='primary' style={{ fontSize: 80 }} />
                    )
                )
            }

          </div>
          <h5 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <b>{title}</b>
          </h5>
          <DialogContentText id="alert-dialog-description" sx={{ paddingTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        >
          <Button variant='contained' onClick={handleClose} color={buttonType || 'primary'} sx={{ fontSize: '16px', px: '30px', borderRadius: '20px', marginBottom:'10px' }} autoFocus>
            {buttonTitle || 'Ok'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}