import React, { memo, useState } from 'react';
import { Box, CircularProgress, FormControl, Grid, InputAdornment, InputLabel, NativeSelect, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import configServ from '../../services/config';
import ActionButtons from '../../Components/Buttons/ActionButtons ';
import CheckAndReturn from '../../utility/CheckAndReturn';
import { useEffect } from 'react';
import DataGridTable from '../../Components/DataGridTable/DataGridTable';
import { useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import LoaderNew from '../../Components/Loader/LoaderNew';

//FUNCTION
const CatalogTable = ({ handleEditData, isChanged, pagination = true }) => {
    //#region code
    const { company_id } = useSelector((state) => state.GlobalVariables);
    const rowsPerPageOptions = [20, 50, 100, 200];
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
    const [brandList, setBrandList] = useState([]);
    const [count, setCount] = useState(0);
    const [searchBy, setSearchBy] = useState('catalog_name');
    const [search, setSearch] = useState('');
    const [catalogDataList, setCatalogDataList] = useState([]);
    const [catalogList, setCatalogList] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchCatalogList = async () => {
        try {
            const dataToSend = {
                "company_id": company_id,
                "search_by": searchBy,
                "search": search,
                "per_page": rowsPerPage,
                "page": page
            }
            const res = await configServ.getCatalog(dataToSend);
            // const result = res.map((item, index) => ({
            //     id: item.id,
            //     catalog_name: item.catalog_name,
            //     currentlyActive: item.currentlyActive ? 'Active' : 'Inactive',
            //     created_by: item.admin.name,
            // }));
            if (res.status) {
                setCatalogList(res.data);
                setCount(res.total);
            }
        }
        catch (error) {
            console.log(error);
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchCatalogList();
    }, [isChanged, page, rowsPerPage, search]);

    const handleEdit = (params) => {
        scrollToTop();
        //const data = catalogDataList.filter(x => x.id === params.id);
        handleEditData(params);
    }

    const handleSearchBy = (event) => {
        try {
            setSearchBy(event.target.value);
        }
        catch (error) {
            console.log(error);
        }
    }

    const handleSearch = (event) => {
        try {
            const data = event.target.value;
            if (data === "") {
                setSearch(null);
            }
            else {
                setSearch(data);
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const headers = ['CATALOG NAME', 'IS ACTIVE', 'ACTION'];

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const headerCellStyle = {
        fontSize: 14,
        fontWeight: 'bold',
        backgroundColor: '#4CB5F5',
        color: 'white',
    };

    const cellStyle = {
        py: 0,
        my: 0,
    }

    const evenRowStyle = {
        background: '#f5f5f5',
    };

    const oddRowStyle = {
        background: 'white',
    };

    const rowStyle = {
        '&:hover': {
            background: '#f1f9fe',
        },
    };

    //#endregion

    if (loading) {
        return (
            <Box
                sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            >
                <LoaderNew />
            </Box>
        )
    }
    else {
        return (
            <>
                {/* search starts here */}
                <Grid container alignItems="flex-end" justifyContent="flex-start" sx={{ pt: 1 }} spacing={1} direction={'row'}>
                    <Grid item xs={12} md={6} ml={0} justifyContent={'flex-start'}>
                        <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>Catalog List: {count}</Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Box sx={{ minWidth: 120, marginLeft: '0px' }}>
                            <FormControl
                                fullWidth
                            >
                                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                    Search By
                                </InputLabel>
                                <NativeSelect
                                    onChange={handleSearchBy}
                                    inputProps={{
                                        name: 'searchBy',
                                        id: 'uncontrolled-native',
                                    }}
                                >
                                    <option value={'catalog_name'}>Catalog Name</option>
                                </NativeSelect>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={3} mr={{ xs: 0, md: 0 }} justifyContent={'flex-end'}>
                        <TextField
                            id="outlined-basic"
                            size="small"
                            fullWidth
                            placeholder="search"
                            variant="outlined"
                            onChange={handleSearch}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon style={{ fill: 'grey' }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
                {/* search ends here */}

                <TableContainer component={Paper} sx={{ py: 1 }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={headerCellStyle}>S.No.</TableCell>
                                {headers.map((header, index) => (
                                    <TableCell key={index} style={headerCellStyle}>
                                        {header}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {catalogList.map((row, rowIndex) => (
                                <TableRow key={rowIndex}
                                    sx={{
                                        ...rowStyle,
                                        ...(rowIndex % 2 === 0 ? evenRowStyle : oddRowStyle),
                                    }}
                                >
                                    <TableCell sx={cellStyle}>{page * rowsPerPage + rowIndex + 1}</TableCell>
                                    <TableCell sx={cellStyle}>{row.catalog_name}</TableCell>
                                    <TableCell sx={cellStyle}>{row.currentlyActive === 1 ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />}</TableCell>
                                    <TableCell sx={cellStyle}>
                                        <Box sx={{ borderRadius: 10, width: 'fit-content', transition: '100ms', cursor: 'pointer', padding: 0.5, ":hover": { backgroundColor: '#99999955' } }}
                                            onClick={() => { handleEdit(row); scrollToTop() }}
                                        >
                                            <Tooltip title='Edit' arrow>
                                                <EditIcon color='primary' />
                                            </Tooltip>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 1 * emptyRows }}>
                                    <TableCell colSpan={headers.length + 2} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    {pagination && (
                        <TablePagination
                            rowsPerPageOptions={rowsPerPageOptions}
                            component="div"
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    )}
                </TableContainer>
            </>
        );
    }
};

export default memo(CatalogTable);
