import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    Box,
    Tooltip,
    IconButton,
    Typography,
    Stack,
    Grid,
    Divider,
    Checkbox,
    Button
} from '@mui/material';

// import NestedTable from './NestedTable';

import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect } from 'react';

const OrderView = ({ order, handleEdit, pagination = true, selectedItems, setSelectedItems }) => {
    const rowsPerPageOptions = [20, 50, 100, 200];
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
    const [nestedId, setNestedId] = useState('');
    const [expanded, setExpanded] = useState(false);

    // const [selectedItems, setSelectedItems] = useState([]);
    const [data, setData] = useState([]);

    useEffect(() => {
        // console.log(order)
        setData(order.items || [])
    }, [order])

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const headers = ['ITEM NAME', 'ITEM CODE', 'QUANTITY', ''];

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    const paginatedData = pagination
        ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : data;

    const headerCellStyle = {
        fontSize: 14, // Adjust the font size as needed
        fontWeight: 'bold',
        backgroundColor: '#4CB5F5',
        color: 'white',
    };

    const iconCellStyle = {
        width: 40, // Adjust the width as needed
    };

    const handleNestedTable = (id) => {
        setNestedId(id)
        setExpanded(true)
        // console.log(id)
    }
    const handleClose = () => {
        setExpanded(false)
    }

    const handleCheck = (data) => {
        // console.log(data)
        const alreadyInObject = selectedItems.filter((item) => data.id == item.id);
        if (alreadyInObject.length === 0) {
            setSelectedItems((state) => (
                [
                    ...state,
                    data
                ]
            ))
        } else {
            setSelectedItems((state) => {
                const newState = state.filter((item) => data.id !== item.id)
                return(
                    [
                        ...newState
                    ]
                )
            })
        }
    }


    return (
        <>
            <Typography
                variant='h6'
                textAlign='center'
                pt={2}
            >
                Order Summary
            </Typography>
            {/* <Button onClick={abc}>abc</Button> */}
            <Stack
                direction='row'
                justifyContent='center'
                alignItems='center'
                sx={{
                    width: '100%',
                    mb: 2
                }}
            >
                <Divider
                    variant="middle"
                    sx={{
                        width: '30%',
                        backgroundColor: '#33333355'
                    }}
                />
            </Stack>
            <Grid
                container
                my={1}
                spacing={2}
            >
                <Grid item sm={4}>
                    <Typography variant='body2'><span style={{ fontWeight: 'bold' }}>Customer Name :</span> {order.partyname}</Typography>
                </Grid>
                <Grid item sm={4}>
                    <Typography variant='body2'><span style={{ fontWeight: 'bold' }}>Order Number :</span> {order.order_no}</Typography>
                </Grid>
                <Grid item sm={4}>
                    <Typography variant='body2'><span style={{ fontWeight: 'bold' }}>PO Number :</span> {order.po_number || 'N/A'}</Typography>
                </Grid>
            </Grid>
            <TableContainer component={Paper} elevation={0} sx={{ px: 5 }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={headerCellStyle}>S.No.</TableCell>
                            {headers.map((header, index) => (
                                <TableCell key={index} style={headerCellStyle}>
                                    {header}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedData.map((row, rowIndex) => (
                            <TableRow key={rowIndex}>
                                <TableCell>{page * rowsPerPage + rowIndex + 1}</TableCell>
                                <TableCell>{row.itemname}</TableCell>
                                <TableCell>{row.item_code || "N/A"}</TableCell>
                                <TableCell>{row.qty || "N/A"}</TableCell>
                                <TableCell><Checkbox onChange={() => handleCheck(row)} /></TableCell>
                            </TableRow>
                        ))}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 1 * emptyRows }}>
                                <TableCell colSpan={headers.length + 2} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                {pagination && (
                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                )}
            </TableContainer>
        </>
    );
};

export default OrderView;
