import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Checkbox, Box } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';



function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

const rows = [
{
    id:1,
    category_name:'Primary',
    description:'',
    derive_unit:'',
    conversion_factor:'',
    status:true
},
{
    id:2,
    category_name:'Primary',
    description:'',
    derive_unit:'',
    conversion_factor:'',
    status:false
},
{
    id:3,
    category_name:'Primary',
    description:'blaah blaah blaah',
    derive_unit:'pc',
    conversion_factor:12,
    status:true
},
{
    id:4,
    category_name:'Primary',
    description:'testing testing',
    derive_unit:'',
    conversion_factor:'',
    status:false
},
{
    id:5,
    category_name:'Primary',
    description:'',
    derive_unit:'',
    conversion_factor:'',
    status:true
},
{
    id:6,
    category_name:'Primary',
    description:'',
    derive_unit:'',
    conversion_factor:'',
    status:true
},
{
    id:7,
    category_name:'Primary',
    description:'testing testing',
    derive_unit:'pc',
    conversion_factor:8,
    status:false
},
{
    id:8,
    category_name:'Primary',
    description:'',
    derive_unit:'',
    conversion_factor:'',
    status:true
},
{
    id:9,
    category_name:'Primary',
    description:'',
    derive_unit:'',
    conversion_factor:'',
    status:true
},
{
    id:10,
    category_name:'Primary',
    description:'testing testing',
    derive_unit:'',
    conversion_factor:'',
    status:false
},
{
    id:11,
    category_name:'Primary',
    description:'',
    derive_unit:'',
    conversion_factor:'',
    status:true
},
{
    id:12,
    category_name:'Primary',
    description:'',
    derive_unit:'',
    conversion_factor:'',
    status:true
},
];

export default function CreateReceiptsTable({title}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);


  const columns = [
    { id: 'slno', label: 'Sl.No.', minWidth: 170 },
    { id: 'name', label: `Name`, minWidth: 100 },
      {
        id: 'amount',
        label: 'Amount',
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
      },
    {
      id: 'trans_number',
      label: `Trans Number`,
      minWidth: 170,
      align: 'right',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'bank_ref',
      label: 'Bank Ref Number',
      minWidth: 170,
      align: 'right',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'date',
      label: 'Date',
      minWidth: 170,
      align: 'right',
      format: (value) => value.toFixed(2),
    },
      {
        id: 'action',
        label: 'Action',
        minWidth: 170,
        align: 'right',
        format: (value) => value.toFixed(2),
      },
  ];


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: '100%' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                //   align={column.align}
                //   style={{ top: 0, minWidth: column.minWidth }}
                >
                  <b>{column.label}</b>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                        <TableCell >{row.id}</TableCell>
                        <TableCell >{row.category_name}</TableCell>
                        <TableCell >{row.derive_unit}</TableCell>
                        <TableCell >{row.conversion_factor}</TableCell>
                        <TableCell >{row.description}</TableCell>
                        <TableCell >{row.description}</TableCell>
                        <TableCell ><Box sx={{borderRadius:10,width:'fit-content',transition:'100ms',cursor:'pointer',padding:0.5,":hover":{backgroundColor:'#99999955'}}}><EditIcon/></Box></TableCell>                
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}