import React, { useEffect, useState } from 'react';
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Container,
    Grid,
    Typography,
    Box
} from '@mui/material';
import OuterHeader1 from '../../Components/header/Header/OuterHeader1';
import { useSelector } from 'react-redux';
import configServ from '../../services/config';

const RegistrationForm = () => {
    const lightMode = useSelector(state => state.GlobalVariables.lightMode);
    const { user_id, company_id } = useSelector((state) => state.GlobalVariables);
    const [companyName, setCompanyName] = useState([]);
    const [towerList, setTowerList] = useState([]);
    const [evenList, setEventList] = useState([]);
    const [formData, setFormData] = useState({
        firmName: '',
        name: '',
        contactPerson: '',
        tower: '',
        address: '',
        mobileNo: '',
        email: '',
        event: '',
    });
    const [errors, setErrors] = useState({});

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const fetchFirmList = async () => {
        try {
            const req = {
                user_id: 1
            };
            const res = await configServ.getFirmList(req);
            if (res) {
                setCompanyName(res);
            }
        } catch (error) {
            console.error("Error fetching firm list", error);
        }
    };
    const fetchTowerList = async () => {
        try {
            const req = {
                company_id: 1
            };
            const res = await configServ.getTowerList(req);
            if (res) {
                setTowerList(res);
            }
        } catch (error) {
            console.error("Error fetching firm list", error);
        }
    };
    const fetchEventList = async () => {
        try {
            const res = await configServ.getEventList();
            if (res.status = 200) {
                setEventList(res.data);
            }
        } catch (error) {
            console.error("Error fetching firm list", error);
        }
    };
    useEffect(() => {
        fetchFirmList();
        fetchTowerList();
        fetchEventList();
    }, []);

    useEffect(() => {
        // Check if companyName array is populated before updating formData
        if (companyName.length > 0) {
            setFormData((prevData) => ({
                ...prevData,
                firmName: companyName[0]?.id || ''
            }));
        }
    }, [companyName]);

    const validate = () => {
        let tempErrors = {};
        tempErrors.name = formData.name ? '' : 'required';
        tempErrors.contactPerson = formData.contactPerson ? '' : 'required';
        tempErrors.address = formData.address ? '' : 'required';

        if (formData.mobileNo) {
            tempErrors.mobileNo = /^[0-9]{10,12}$/.test(formData.mobileNo) ? '' : 'Mobile No. should be 10 to 12 digits';
        }

        if (formData.email) {
            tempErrors.email = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email) ? '' : 'Email is not valid';
        }

        setErrors(tempErrors);
        return Object.values(tempErrors).every(x => x === '');
    };

    const recet = () => {
        setFormData({
            firmName: '',
            name: '',
            contactPerson: '',
            tower: '',
            address: '',
            mobileNo: '',
            email: '',
            event: ''
        })
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (validate()) {
            // Handle form submission
            try {
                const req = {
                    company_id: formData.firmName,
                    fname: formData.name,
                    contact_name: formData.contactPerson,
                    address: formData.address,
                    tower: formData.tower,
                    email: formData.email,
                    mobile: formData.mobileNo,
                    status: 1,
                    product_id: formData.event
                }
                const res = await configServ.registation(req);
                if (res.status === 200) {
                    alert(res.message)
                    recet();
                }
                else {
                    alert(res.message)
                }
            } catch (error) {
                console.error('Error submitting form', error);
            }

        }
    };

    return (
        <Box sx={{ width: '100vw', height: 'auto', backgroundColor: lightMode ? '#ffffff' : '#090e34', position: 'relative' }}>
            <OuterHeader1 />
            <Container maxWidth="xs" sx={{ mt: 4, position: 'relative', zIndex: 1 }}>
                <Box sx={{ p: 2, boxShadow: 3, borderRadius: 2, backgroundColor: '#fff' }}>
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography variant="h5" component="h1" gutterBottom>
                            Registration Form
                        </Typography>
                    </Box>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="firm-name-label">Firm Name</InputLabel>
                                    <Select
                                        labelId="firm-name-label"
                                        id="firm-name"
                                        name="firmName"
                                        value={formData.firmName}
                                        onChange={handleChange}
                                        label="Firm Name"
                                    >
                                        {companyName.map(company => (
                                            <MenuItem key={company.id} value={company.id}>
                                                {company.CompanyName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="name"
                                    name="name"
                                    label="Name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    error={!!errors.name}
                                    helperText={errors.name}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="contact-person"
                                    name="contactPerson"
                                    label="Contact Person Name"
                                    value={formData.contactPerson}
                                    onChange={handleChange}
                                    error={!!errors.contactPerson}
                                    helperText={errors.contactPerson}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="tower-label">Tower</InputLabel>
                                    <Select
                                        labelId="tower-label"
                                        id="tower"
                                        name="tower"
                                        value={formData.tower}
                                        onChange={handleChange}
                                        label="Tower"
                                    >
                                        {Array.isArray(towerList) && towerList.map(item => (
                                            <MenuItem key={item.id} value={item.address}>
                                                {item.address}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="address"
                                    name="address"
                                    label="Address/Flat No."
                                    value={formData.address}
                                    onChange={handleChange}
                                    error={!!errors.address}
                                    helperText={errors.address}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="mobile-no"
                                    name="mobileNo"
                                    label="Mobile No."
                                    value={formData.mobileNo}
                                    onChange={handleChange}
                                    error={!!errors.mobileNo}
                                    helperText={errors.mobileNo}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="email"
                                    name="email"
                                    label="Email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    error={!!errors.email}
                                    helperText={errors.email}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="event-label">Event</InputLabel>
                                    <Select
                                        labelId="event-label"
                                        id="event"
                                        name="event"
                                        value={formData.event}
                                        onChange={handleChange}
                                        label="Event"
                                    >
                                        {Array.isArray(evenList) && evenList.map(item => (
                                            <MenuItem key={item.id} value={item.id}>
                                                {item.item_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Button type="submit" variant="contained" color="primary" fullWidth>
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Container>
        </Box>
    );
};

export default RegistrationForm;
