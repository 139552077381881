import { Button, Dialog, DialogContent, DialogTitle, Grid, TextField, Paper, Typography, List, ListItem, ListItemText, Divider } from '@mui/material'
import React, { useEffect, useState } from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import CancelIcon from '@mui/icons-material/Cancel';
import configServ from '../../../services/config';
import { useSelector } from 'react-redux';
import AlertDialog from '../../AlertDialog/AlertDialog';

//FUNCTION
const DeactivateBatch = ({ deactivateOpen, handleDeactivate, selectedItem, isChanged }) => {
    //#region code
    const { company_id } = useSelector((state) => state.GlobalVariables);
    const [formData, setFormData] = useState({});
    const [validationErrors, setValidationErrors] = useState({});
    const [batchList, setBatchList] = useState([]);

    const fetchBatch = async () => {
        try {
            const res = await configServ.getBatch({ "company_id": company_id });
            if (res.status === 200) {
                setBatchList(res.data);
            }
            else {
                setBatchList([]);
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        fetchBatch();
    }, []);

    const handleBatch = (params) => {
        try {
            if (params) {
                const batch = batchList.filter(x => x.id === params)[0];
                return batch.batch_name;
            }
            else {
                return 'N/A';
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleSelectedItem = (params) => {
        try {
            if (params === undefined || params === null) {
                return '';
            }
            return params
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleFormData = (params) => {
        try {
            const { name, value } = params.target;
            setFormData((prev) => ({
                ...prev,
                ...selectedItem,
                [name]: value
            }));
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            const error = await validate();
            if (!error) {
                return false;
            }
            const res = await configServ.deactivateBatch(formData);
            if (res.status === 200) {
                isChanged();
                handleReset();
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const validate = async () => {
        try {
            const errors = {};
            if (!formData.deactivation_reason) {
                errors.deactivation_reason = "required"
            }

            setValidationErrors(errors);
            return Object.values(errors).every((error) => error === "");
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleReset = () => {
        clear();
        handleDeactivate();
    }

    const clear = () => {
        setFormData({});
        setValidationErrors({});
    }
    //#endregion

    return (
        <>
            <Dialog
                open={deactivateOpen}
                onClose={handleDeactivate}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='md'
                fullWidth={true}
            >
                <DialogTitle>
                    <Typography variant='h5' color={'#1e61c5'}>De-activate Batch</Typography>
                </DialogTitle>
                <DialogContent>
                    <Paper elevation={1} style={{ padding: '0px', margin: '0px' }}>
                        <List>
                            <Grid container spacing={0}>
                                {/* <Grid item xs={6} style={{ margin: 0 }}>
                                    <ListItem>
                                        <ListItemText
                                            primary={
                                                <>
                                                    <b>Line Item ID:</b>
                                                    {` ${handleSelectedItem(selectedItem.product_line_item_id)}`}
                                                </>
                                            }
                                        />

                                    </ListItem>
                                </Grid>
                                <Grid item xs={6} style={{ margin: 0 }}>
                                    <ListItem>
                                        <ListItemText
                                            primary={
                                                <>
                                                    <b>Product ID:</b>
                                                    {` ${handleSelectedItem(selectedItem.product_id)}`}
                                                </>
                                            }
                                        />

                                    </ListItem>
                                </Grid>
                                <Divider /> */}
                                <Grid item xs={6} style={{ margin: 0 }}>
                                    <ListItem>
                                        <ListItemText
                                            primary={
                                                <>
                                                    <b>Batch:</b>
                                                    {` ${handleBatch(selectedItem.batch_id)}`}
                                                </>
                                            }
                                        />

                                    </ListItem>
                                </Grid>
                                <Divider />
                                <Grid item xs={6} style={{ margin: 0 }}>
                                    <ListItem>
                                        <ListItemText
                                            primary={
                                                <>
                                                    <b>UUID:</b>
                                                    {` ${handleSelectedItem(selectedItem.uuid)}`}
                                                </>
                                            }
                                        />

                                    </ListItem>
                                </Grid>
                            </Grid>
                        </List>
                    </Paper>
                    <form onSubmit={handleSubmit} onReset={handleReset}>
                        <Grid container alignItems={'center'} justifyContent={'space-between'}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    id="outlined-multiline-static"
                                    label="Reason To De-activate"
                                    name='deactivation_reason'
                                    multiline
                                    rows={3}
                                    maxRows={8}
                                    sx={{ my: 2 }}
                                    value={formData.deactivation_reason}
                                    onChange={handleFormData}
                                    error={!!validationErrors.deactivation_reason}
                                />
                            </Grid>
                            <Grid container item xs={12} md={6} justifyContent={'center'}>
                                <Button
                                    type='submit'
                                    variant='contained'
                                    color='error'
                                    sx={{ m: 1 }}
                                    startIcon={<WarningIcon />}
                                >
                                    De-activate
                                </Button>
                                <Button
                                    type='reset'
                                    variant='contained'
                                    color='secondary'
                                    sx={{ m: 1 }}
                                    startIcon={<CancelIcon />}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default DeactivateBatch;
