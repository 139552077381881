import React from "react";
import { SessionVerification } from "../../../Components/SessionManagement/SessionManagement";
import CustHeader from "../../../Components/header/Header/CustHeader";
import CustomerSideBar from "../../../Components/sidebar/CustomerSideBar";
import { Box, Stack, Paper, Divider, Typography, Button } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSelector } from "react-redux";
import CartDetails from "../../Component/CartDetails/CartDetails";
import CustAddress from "../../Component/CustAddress/CustAddress";
import Order from "../../Component/CartDetails/Order";
import CardEmpty from "../../Component/Cart/CartEmpty";

const steps = ["Cart", "Address", "Order"];

//START FUNCTION
const CustCart = () => {
  //#region code
  const open = useSelector((state) => state.sideExpand.open);
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const cartValue = useSelector((state) => state.cart.cartValue);

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  //#endregion

  return (
    <>
        <Stack direction="column" justifyContent="center" alignItems="center">
          <Paper
            sx={{
              width: { xs: "100%", md: "100%" },
              margin: 3,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: 3,
              background: "#e3f3fc",
            }}
          >
            <br />

            {cartValue.length !== 0 && (
              <Box sx={{ width: "100%" }}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepOptional(index)) {
                      labelProps.optional = (
                        <Typography variant="caption"></Typography>
                      );
                    }
                    if (isStepSkipped(index)) {
                      stepProps.completed = false;
                    }
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                {activeStep === steps.length ? (
                  <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                      All steps completed - you&apos;re finished
                    </Typography>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
                    <br />
                    {activeStep === 0 && (
                      <CartDetails handleNext={handleNext} />
                    )}
                    {activeStep === 1 && (
                      <CustAddress handleNext={handleNext} />
                    )}
                    {activeStep === 2 && <Order />}
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      <Button
                        variant="contained"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                        startIcon={<ArrowBackIcon />}
                      >
                        Back
                      </Button>
                      <Box sx={{ flex: "1 1 auto" }} />
                      {/* <Button onClick={handleNext}>
                                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                        </Button> */}
                    </Box>
                  </React.Fragment>
                )}
              </Box>
            )}
            {cartValue.length === 0 && (
              <Box>
                <Typography variant="h5" color={"error"}>
                  <CardEmpty />
                </Typography>
              </Box>
            )}
          </Paper>
        </Stack>
    </>
  );
};
export default CustCart;
