import React, { memo, useEffect, useState } from 'react';
import { Card, CardContent, Typography, Box, Grid, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CheckAndReturn from '../../../utility/CheckAndReturn';

const AccountMasterLedgerCard = ({ accountMaster }) => {
    const navigate = useNavigate();
    const [paidAmount, setPaidAmount] = useState(0);
    const [receivedAmount, setReceivedAmount] = useState(0);

    useEffect(() => {
        let totalPaidAmount = 0;
        let totalReceivedAmount = 0;

        accountMaster.forEach(row => {
            totalPaidAmount += Number(Boolean(row.is_credit) === true ? row.amount ?? 0 : 0);
            totalReceivedAmount += Number(Boolean(row.is_credit) === false ? row.amount ?? 0 : 0);
        });

        setPaidAmount(CheckAndReturn.roundToInteger(totalPaidAmount));
        setReceivedAmount(CheckAndReturn.roundToInteger(totalReceivedAmount));

        return () => {
            setPaidAmount(0);
            setReceivedAmount(0);
        }
    }, [accountMaster]);

    const handleVoucherClick = (voucher, entity, voucher_type) => {
        try {
            if(voucher_type === 'journal'){
                navigate('/transaction/journal-entry', { state: { voucher_no: voucher, entity: entity } });
            }
            else{
                navigate('/transaction/create-vouchers', { state: { voucher_no: voucher, entity: entity } });
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    return (
        <Box>
            <Grid container spacing={1} mt={2}>
                <Grid container item xs={12} md={4} justifyContent={{ xs: 'center', md: 'flex-start' }}>
                    <Stack direction={'row'}>
                        <Typography variant='body1' sx={{ fontSize: '16px', fontWeight: 600 }}>
                            Paid Amount:
                        </Typography>
                        <Typography variant='body1' sx={{ fontSize: '16px', fontWeight: 600, color: 'green' }}>
                            ₹{paidAmount}
                        </Typography>
                    </Stack>
                </Grid>
                <Grid container item xs={12} md={4} justifyContent={{ xs: 'center', md: 'center' }}>
                    <Stack direction={'row'}>
                        <Typography variant='body1' sx={{ fontSize: '16px', fontWeight: 600 }}>
                            Received Amount:
                        </Typography>
                        <Typography variant='body1' sx={{ fontSize: '16px', fontWeight: 600, color: 'green' }}>
                            ₹{receivedAmount}
                        </Typography>
                    </Stack>
                </Grid>
                <Grid container item xs={12} md={4} justifyContent={{ xs: 'center', md: 'flex-end' }}>
                    <Stack direction={'row'}>
                        <Typography variant='body1' sx={{ fontSize: '16px', fontWeight: 600 }}>
                            Balance:
                        </Typography>
                        <Typography variant='body1' sx={{ fontSize: '16px', fontWeight: 600, color: 'green' }}>
                            ₹{receivedAmount - paidAmount}
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>

            {accountMaster.length > 0 ? (
                accountMaster.map((row, index) => (
                    <Card key={row.item_id} sx={{ marginBottom: 2, backgroundColor: '#f9f9ff' }}>
                        <CardContent>
                            <Typography variant="body2" color="textSecondary" gutterBottom>
                                Date: {CheckAndReturn.transformToDate3(row.date ?? '')}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" gutterBottom>
                                Type: {row.type}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" gutterBottom>
                                Voucher Type: {row.entity_type}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" gutterBottom>
                                Credit/Debit: {Boolean(row.is_credit) === true? 'Credit':'Debit'}
                            </Typography>
                            {
                                (row.entity_type !== 'sale' && row.entity_type !== 'purchase') ?
                                    <Typography
                                        variant="body2"
                                        color="primary"
                                        sx={{ cursor: 'pointer' }}
                                        onClick={() => handleVoucherClick(row.voucher_no, row.entity_type, row.voucher_type)}
                                    >
                                        {row.voucher_no !== '' ? (row.voucher_type === 'receipt' ? `Received from(${row.voucher_no ?? ''})` : `Payment(${row.voucher_no ?? ''})`) : ''}
                                    </Typography>
                                    :
                                    <Typography
                                        variant="body2"
                                        color="primary"
                                    >
                                        {row.voucher_no}
                                    </Typography>
                            }
                            <Typography variant="body2" color="textSecondary" gutterBottom>
                                Amount: ₹{CheckAndReturn.roundToInteger(row.amount)}
                            </Typography>
                        </CardContent>
                    </Card>
                ))
            ) : (
                <Box sx={{ textAlign: 'center', color: 'red', fontSize: '16px', marginTop: 2 }}>
                    <p><b>No Item Found.</b></p>
                </Box>
            )}
        </Box>
    );
};

export default memo(AccountMasterLedgerCard);
