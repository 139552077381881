import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import LoaderNew from "../../../Components/Loader/LoaderNew";

import { motion, AnimatePresence } from "framer-motion";
import { Dialog, IconButton, Stack, Typography, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import configServ from "../../../services/config";
import wronging from "../../../Components/Assets/images/wrongimg.jpg";

// function createData(name, code, population, size) {
//   const density = population / size;
//   return { name, code, population, size, density };
// }

export default function CustNestedTable({ data, open, setOpen }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [NestedTableData, setNestedTableData] = useState([]);
  const [miniLoader, setMiniLoader] = useState(false);

  const fetchNestedTableData = async () => {
    setMiniLoader(true);
    try {
      const result = await configServ.invoice_detail({ invoice_id: data.id });
      // console.log(result)
      setNestedTableData(result);
      setMiniLoader(false);
    } catch (err) {
      console.log(err);
      setMiniLoader(false);
    }
  };

  useEffect(() => {
    fetchNestedTableData();
  }, [open]);
  const handleClose = () => {
    setOpen(false);
  };
  const columns = [
    { id: "slno", label: "SR. NO.", minWidth: 170 },
    {
      id: "item_name",
      label: "ITEM NAME",
      minWidth: 170,
      align: "right",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "qty",
      label: `QTY`,
      minWidth: 170,
      align: "right",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "rate",
      label: "RATE",
      minWidth: 170,
      align: "right",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "discount",
      label: "DISCOUNT",
      minWidth: 170,
      align: "right",
      format: (value) => value.toFixed(2),
    },
    {
      id: "amount",
      label: "AMOUNT",
      minWidth: 170,
      align: "right",
      format: (value) => value.toFixed(2),
    },
  ];

  return (
    <>
      {/* <Paper sx={{Width:'90%'}}> */}
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md" // Control dialog width
        sx={{
          height: "500px",
          maxHeight: "500px",
          "@media (max-width:600px)": {
            // Mobile view adjustments
            maxWidth: "auto", // width on small screens
            height: "auto", // Allow height to adjust automatically
          },
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            sx={{
              mt: 2,
              ml: 2,
              color: "text.secondary",
              textAlign: "center", // Center the text for better appearance
              flexGrow: 1, // Allow title to take up available space
              fontWeight: "bold", // Make the text bold
              fontSize: "1.25rem", // Adjust font size as needed (e.g., 1.25rem for larger text)
            }}
            variant="h6" // Change to a larger variant for better prominence
          >
            Invoice details for {data.invoice_number}
          </Typography>

          <IconButton onClick={handleClose}>
          <img
              src={wronging}
              alt=""
              style={{ width: "24px", height: "24px" }}
            />
          </IconButton>
        </Stack>
        <TableContainer
          sx={{
            width: "100%",
            overflow: "auto",
            display: { xs: "none", md: "block" }, // Hide on small screens
          }}
          component={motion.div}
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: "100%", opacity: 1 }}
          transition={{ duration: 0.15 }}
          exit={{ height: 0, opacity: 0, transition: { duration: 0.1 } }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    sx={{
                      fontSize: 14,
                      fontWeight: "bold",
                      backgroundColor: "#4CB5F5",
                      color: "white",
                    }}
                  >
                    <b>{column.label}</b>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {miniLoader ? (
                // Show loader if miniLoader is true
                <TableRow>
                  <TableCell align="center" colSpan={columns.length}>
                    <LoaderNew />
                  </TableCell>
                </TableRow>
              ) : NestedTableData.length > 0 ? (
                // Show data rows if data is available
                NestedTableData.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      backgroundColor: index % 2 === 0 ? "#f5f5f5" : "white",
                    }}
                  >
                    <TableCell sx={{ minWidth: 100 }}>{index + 1}</TableCell>
                    <TableCell>{row.itemname}</TableCell>
                    <TableCell>{row.qty}</TableCell>
                    <TableCell>{row.rate}</TableCell>
                    <TableCell>{row.discount}</TableCell>
                    <TableCell>{row.amount}</TableCell>
                  </TableRow>
                ))
              ) : (
                // Show "Data Not Found" if no data and not loading
                <TableRow>
                  <TableCell align="center" colSpan={columns.length}>
                    Data Not Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {/* mobile view */}
        <Box
          sx={{
            display: { xs: "block", md: "none" }, // Show on small screens only
            padding: 2,
            border: "1px solid #ddd",
            borderRadius: "8px",
            marginTop: 2,
            boxShadow: "0px 4px 10px rgba(255, 255, 255, 0.5)", // White shadow effect
          }}
        >
          {miniLoader ? (
            // Show loader if miniLoader is true
            <Box sx={{ display: "flex", justifyContent: "center", padding: 2 }}>
              <LoaderNew /> {/* Your loader component */}
            </Box>
          ) : NestedTableData.length > 0 ? (
            NestedTableData.map((row, index) => (
              <Box
                key={row.id}
                sx={{
                  padding: 1,
                  borderBottom: "1px solid #ddd",
                  backgroundColor: index % 2 === 0 ? "#f5f5f5" : "white",
                  boxShadow: "0px 2px 5px rgba(255, 255, 255, 0.7)", // White shadow for each card
                  borderRadius: "4px", // Slight rounding for individual cards
                  marginBottom: 1, // Space between cards
                }}
              >
                <Typography variant="body1">{row.itemname}</Typography>
                <Typography variant="body2">Qty: {row.qty}</Typography>
                <Typography variant="body2">Rate: {row.rate}</Typography>
                <Typography variant="body2">
                  Discount: {row.discount}
                </Typography>
                <Typography variant="body2">Amount: {row.amount}</Typography>
              </Box>
            ))
          ) : (
            <Typography align="center">Data Not Found</Typography>
          )}
        </Box>
      </Dialog>

      {/* </Paper> */}
    </>
  );
}
