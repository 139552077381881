import React from 'react';
import QRCode from 'qrcode.react';
import { Box, Stack } from '@mui/material';

const QRCodePrintComponent = React.forwardRef((props, ref) => {
    const { selectedItem } = props;
    return (
        <>
            <div ref={ref} style={{ marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Stack direction={'column'} sx={{ m: 0, p: 0 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <QRCode value={`http://demo.d-crm.in/product-info?itemId=${selectedItem.product_id}&lineItemId=${selectedItem.line_item_id}&uuid=${selectedItem.uuid}`} />
                    </Box>
                    <p style={{ textAlign: 'center', margin:0 }}>{selectedItem.product_name}</p>
                    <p style={{ textAlign: 'center', margin:0 }}>{selectedItem.uuid}</p>
                </Stack>
            </div>
        </>
    )
}
);

export default QRCodePrintComponent;