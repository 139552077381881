import React, { memo } from 'react';
import { Box, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';

const PopUpTitle = ({ title, handleClose }) => {
    return (
        <DialogTitle id="alert-dialog-title" sx={{ m: 0, p: 0 }}>
            <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                <Grid container item xs={11} justifyContent={'center'}>
                    <Typography variant='h5' p={2} color={'#1e61b4'}>
                        <b>{title}</b>
                    </Typography>
                </Grid>
                <Grid container item xs={1} justifyContent={'flex-end'}>
                    <IconButton onClick={() => handleClose()}>
                        <DisabledByDefaultIcon sx={{ fontSize: 32 }} color="error" />
                    </IconButton>
                </Grid>
            </Box>
        </DialogTitle>
    )
}

export default memo(PopUpTitle);
