import React, { useEffect, useState } from "react";
import ItemTable from "../../Components/Card/MaterEntryCards/ItemTable";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Form } from "react-bootstrap";
import {
  Button,
  RadioGroup,
  Select,
  MenuItem,
  TextField,
  Tooltip,
  FormControl,
  InputLabel,
  Grid,
  Autocomplete,
  FormControlLabel,
  Checkbox,
  InputAdornment,
} from "@mui/material";
import MyFormControlLabel from "@mui/material/FormControlLabel";
import { Radio } from "@mui/material";
import configServ from "../../services/config";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from '@mui/icons-material/Close';
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import NextPlanIcon from '@mui/icons-material/NextPlan';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Stack, Paper, Divider, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import CardHeadMenu from "../Card/CardHeadMenu/CardHeadMenu";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import SaveIcon from "@mui/icons-material/Save";
import UpdateIcon from "@mui/icons-material/Update";
import { triggerLoader } from "../../redux/reducers/LoaderTrigger";
import AlertDialog from "../AlertDialog/AlertDialog";
import CheckAndReturn from "../../utility/CheckAndReturn";

//FUNCTION
const Items = () => {
  //#region code
  const { user_id, company_id, noRoomService } = useSelector((state) => state.GlobalVariables);
  const { company_type } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const [formDataInitial, setFormDataInitial] = useState({
    item_type: "single",
    item_code: "",
    SubscriptionStatus: "Monthly",
    openingquantity: "0",
    discount: "0",
    rate_unit: null,
    costprice: null,
    status: true,
  });
  const [formData, setFormData] = useState(formDataInitial);
  const [categoryList, setCategoryList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [superCategoryList, setSuperCategoryList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [hsnList, setHsnList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [companyType, setCompanyType] = useState(company_type);
  const [hsn, setHsn] = useState("");

  const [tableData, setTableData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  const [openAddImage, setOpenAddImage] = useState(false);
  const [openAddSize, setOpenAddSize] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [isItemName, setIsItemName] = useState(false);
  const [isItemCode, setIsItemCode] = useState(true);
  const navigate = useNavigate();
  const [associatedItem, setAssociatedItem] = useState([
    {
      id: 1,
      item_id: "",
      qty: "",
    },
  ]);
  const [isCreate, setIsCreate] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [title, setTitle] = useState('Alert');
  const [dialogContent, setDialogContent] = useState('');
  const [buttonType, setButtonType] = useState(null);

  const handleOpenDialog = (heading, content, type) => {
    setTitle(heading);
    setDialogContent(content);
    setButtonType(type);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setTitle('Alert');
    setDialogContent('');
    setButtonType(null);
  };

  const handlePopup = () => {
    try {
      if (isCreate === true) {
        clear();
      }
      setIsCreate(!isCreate);
    }
    catch (error) {
      console.log(error);
    }
  };

  const addAssociatedItem = () => {
    setAssociatedItem((state) => [
      ...state,
      {
        id: associatedItem.length + 1,
        item_id: "",
        qty: "",
      },
    ]);
  };
  const delAssociatedItem = (id) => {
    const updatedItemList = associatedItem.filter((item) => item.id !== id);
    setAssociatedItem(updatedItemList);
  };
  const handleChangeAssociatedItem = (id, event) => {
    const { name, value } = event.target;
    setAssociatedItem((state) =>
      state.map((item) => (item.id === id ? { ...item, [name]: value } : item))
    );
    if (name === "item_id") {
      const selectedItem = tableData.filter((item) => value === item.id);
      setAssociatedItem((state) =>
        state.map((item) =>
          item.id === id
            ? { ...item, item_name: selectedItem[0].item_name }
            : item
        )
      );
    }
  };

  const fetchItemList = async () => {
    try {
      const dataToSend = {
        "company_id": company_id,
        "search_by": null,
        "search": null,
        "per_page": null,
        "page": null
      }
      const res = await configServ.getItemList(dataToSend);
      if (res.status === 200) {
        const result = res.data;
        if (result.length > 0) {
          setTableData(result);
        }
      }
    }
    catch (error) {
      console.log(error);
    }
  };

  const fetchSuperCategory = async () => {
    try {
      const res = await configServ.getSuperCategory();
      const superCategoryResponse = res.data.filter(x => x.status === 1);
      superCategoryResponse.map((item) => {
        if (item.isPrimary === 1) {
          setFormData((prev) => ({
            ...prev,
            superCategory: item.id
          }));
          setFormDataInitial((prev) => ({
            ...prev,
            superCategory: item.id
          }));
        }
      });
      const result = superCategoryResponse.sort((a, b) => a.name.localeCompare(b.name))
      setSuperCategoryList(result);
    }
    catch (e) {
      console.log(e);
    }
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;

    //Update form data
    setFormData((state) => ({
      ...state,
      [name]: value,
    }));

    // Reset the validation error when the user starts typing
    if (validationErrors[name]) {
      setValidationErrors((prevValidationError) => ({
        ...prevValidationError,
        [name]: '',
      }));
    }
  };

  const checkHandler = (e) => {
    const { name, checked } = e.target;
    setFormData((state) => ({
      ...state,
      [name]: checked,
    }));
  };

  const fetchCategory = async () => {
    try {
      //const result = await configServ.getcategoryByBrand({ "brand_id": formData.brand_id });
      const dataToSend = {
        "company_id": company_id,
        "search_by": null,
        "search": null,
        "per_page": null,
        "page": null
      }
      const res = await configServ.getcategory(dataToSend);
      if (res.status === 200) {
        const categoryResponse = res.data.filter(x => x.status === 1);
        categoryResponse.map((item) => {
          if (item.isPrimary === 1) {
            setFormData((prev) => ({
              ...prev,
              category_id: item.categoryid.toString()
            }));
            setFormDataInitial((prev) => ({
              ...prev,
              category_id: item.categoryid.toString()
            }));
          }
        });
        const result = categoryResponse.sort((a, b) => a.categoryname.localeCompare(b.categoryname))
        setCategoryList(result);
      }
    }
    catch (error) {
      console.log(error);
    }
  };

  const fetchBrand = async () => {
    try {
      const dataToSend = {
        "company_id": company_id,
        "search_by": null,
        "search": null,
        "per_page": null,
        "page": null
      }
      const res = await configServ.getBrand(dataToSend);
      if (res.status === 200) {
        const brand_res = res.data.filter(x => x.status === 1);
        brand_res.map((item) => {
          if (item.isPrimary === 1) {
            setFormData((prev) => ({
              ...prev,
              brand_id: item.id.toString()
            }));
            setFormDataInitial((prev) => ({
              ...prev,
              brand_id: item.id.toString()
            }));
          }
        });
        const result = brand_res.sort((a, b) => a.brand_name.localeCompare(b.brand_name));
        setBrandList(result);
      }
    }
    catch (error) {
      console.log(error);
    }
  };

  const fetchGroup = async () => {
    try {
      const dataToSend = {
        "company_id": company_id,
        "search_by": null,
        "search": null,
        "per_page": null,
        "page": null
      }
      const res = await configServ.getGroup(dataToSend);
      if (res.status === 200) {
        const groupResponse = res.data.filter(x => x.status === 1);
        groupResponse.map((item) => {
          if (item.isPrimary === 1) {
            setFormData((prev) => ({
              ...prev,
              group_id: item.id
            }));
            setFormDataInitial((prev) => ({
              ...prev,
              group_id: item.id
            }));
          }
        });
        const result = groupResponse.sort((a, b) => a.group_name.localeCompare(b.group_name));
        setGroupList(result);
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  // const fetchGroup = async () => {
  //   try {
  //     const res = await configServ.groupByCategory({
  //       category_id: formData.category_id,
  //     });

  //     if (res.status === 200) {

  //       const groupResponse = res.result.filter(x => x.status === 1);
  //       groupResponse.map((item) => {
  //         if (item.isPrimary === 1) {
  //           setFormData((prev) => ({
  //             ...prev,
  //             group_id: item.id
  //           }));
  //         }
  //       });
  //       setGroupList(groupResponse);
  //     }
  //     else if (res.status === 404) {
  //       const res = await configServ.getGroup({
  //         company_id: company_id,
  //       });
  //       if (res.status === 200) {
  //         const groupResponse = res.result.filter(x => x.status === 1);
  //         groupResponse.map((item) => {
  //           if (item.isPrimary === 1) {
  //             setFormData((prev) => ({
  //               ...prev,
  //               group_id: item.id
  //             }));
  //           }
  //         });
  //         setGroupList(groupResponse);
  //       }
  //       else {
  //         setGroupList([]);
  //       }
  //     }
  //     else {
  //       setGroupList([]);
  //     }
  //   }
  //   catch (error) {
  //     console.log(error);
  //   }
  // };

  const fetchLocation = async () => {
    try {
      const res = await configServ.getLocation({
        company_id: company_id,
      });
      const locationResponse = res.filter(x => x.status === 1);
      locationResponse.map((item) => {
        if (item.isPrimary === 1) {
          setFormData((prev) => ({
            ...prev,
            location_id: item.id
          }));
          setFormDataInitial((prev) => ({
            ...prev,
            location_id: item.id
          }));
        }
      });
      const result = locationResponse.sort((a, b) => a.location_name.localeCompare(b.location_name));
      setLocationList(result);
    }
    catch (error) {
      console.log(error);
    }
  };

  const fetchUnit = async () => {
    try {
      const dataToSend = {
        "company_id": company_id,
        "search_by": null,
        "search": null,
        "per_page": null,
        "page": null
      }
      const res = await configServ.getUnit(dataToSend);
      if (res.status === 200) {
        const unitResponse = res.data.filter(x => x.status === 1);
        unitResponse.map((item) => {
          if (item.isPrimary === 1) {
            setFormData((prev) => ({
              ...prev,
              unit: item.units_name
            }));
            setFormDataInitial((prev) => ({
              ...prev,
              unit: item.units_name
            }));
          }
        });
        const result = unitResponse.sort((a, b) => a.units_name.localeCompare(b.units_name))
        setUnitList(result);
      }
    }
    catch (error) {
      console.log(error);
    }
  };

  const fetchGstDetails = async () => {
    try {
      const dataToSend = {
        "company_id": company_id,
        "search_by": null,
        "search": null,
        "per_page": null,
        "page": null
      }
      const res = await configServ.getHsnList(dataToSend);
      if (res.status === 200) {
        const hsnResponse = res.data.filter(x => x.ActiveFlage === 1);
        hsnResponse.map((item) => {
          const hsn_sac = item.hsncode;
          if (item.isPrimary === 1) {
            setHsn(hsn_sac);
            setFormData((prev) => ({
              ...prev,
              hsncode: hsn_sac,
              tax_rate: item.GSTRate,
            }));
            setFormDataInitial((prev) => ({
              ...prev,
              hsncode: hsn_sac,
              tax_rate: item.GSTRate
            }));
          }
        });
        setHsnList(hsnResponse);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSuperCategory();
    fetchCategory();
    fetchBrand();
    fetchGroup()
    fetchUnit();
    fetchGstDetails();
    fetchLocation();
    setValidationErrors({});
  }, []);

  // useEffect(()=>{
  //     if(formData.brand_id){
  //         fetchCategory();
  //     }
  // },[formData.brand_id]);

  // useEffect(() => {
  //   if (formData.category_id) {
  //     fetchGroup();
  //   }
  // }, [formData.category_id]);

  useEffect(() => {
    if (formData.item_type === "composite") {
      fetchItemList();
    }
  }, [formData.item_type]);

  // const itemUpdated = () => {
  //     setIsItemUpdated(!isItemUpdated)
  // }

  const handleEdit = async (data) => {
    setIsCreate(true);
    setValidationErrors({});
    setCompanyType(data.type);
    setHsn(data.hsncode);
    
    setFormData(data);

    setIsEdit(true);

    if (data.isItemAssociated === 1) {
      fetchAssociatedItem(data.id);
    }
  };

  const fetchAssociatedItem = async (id) => {
    try {
      const result = await configServ.getAssociateItem({
        associatedWith_id: id,
      });
      setAssociatedItem(result);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    try {
      dispatch(triggerLoader());
      const itemData = {
        ...formData,
        items: associatedItem,
        hsncode: hsn,
        type: companyType,
        company_id: company_id,
        user_id: user_id,
      };

      const error = handleFormValidation();
      if (error === false) {
        return false;
      }
      setValidationErrors({});
      if (!isEdit) {
        const res = await configServ.createItem(itemData);
        if (res.status === 200) {
          handleOpenDialog('Success', "Item created successfully.", 'success');
          clear();
          setIsChanged(!isChanged);
        }
        else if (res.status === 400) {
          handleOpenDialog('Warning', res.message || "Failed to create item", 'warning');
        }
        else {
          handleOpenDialog('Error', "Failed to update item.", 'error');
        }
      }
      else {
        const res = await configServ.updateItem(itemData);
        if (res.status === 200) {
          handleOpenDialog('Success', res.message || "Item updated successfully.", 'success');
          clear();
          setIsChanged(!isChanged);
        }
        else {
          handleOpenDialog('Error', "Failed to update item.", 'error');
        }
      }
    }
    catch (e) {
      console.log(e);
      handleOpenDialog('Error', "An unexpected error has occurred.", 'error');
    }
    finally {
      dispatch(triggerLoader());
    }
  };

  const clear = () => {
    setFormData(formDataInitial);
    setIsEdit(false);
    setIsItemName(false);
    setIsItemCode(true);
    fetchGstDetails();
    setCompanyType(company_type);
    setValidationErrors({});
    setAssociatedItem([
      {
        id: 1,
        item_id: "",
        qty: "",
      },
    ]);
  };

  // const handleCloseImage = () => {
  //   setOpenAddImage(false);
  // };

  // const handleOpenImage = () => {
  //   setOpenAddImage(true);
  // };

  // const handleCloseSize = () => {
  //   setOpenAddSize(false);
  // };

  // const handleOpenSize = () => {
  //   setOpenAddSize(true);
  // };

  // const formArrayZeroToN = (number) => {
  //   if (
  //     typeof number !== "number" ||
  //     !Number.isInteger(number) ||
  //     number <= 0
  //   ) {
  //     throw new Error("Input must be a positive integer");
  //   }

  //   return Array.from({ length: number }, (_, index) => index + 1);
  // };

  const handleHSN = (selectedhsn) => {
    if (selectedhsn === null) {
      setHsn("");
      setFormData((state) => ({
        ...state,
        hsncode: "",
        tax_rate: "",
      }));
    }
    else {
      const hsn = hsnList.filter(
        (x) => (x.hsncode) === selectedhsn.hsncode.toString()
      )[0];
      setHsn(hsn.hsncode);
      setFormData((state) => ({
        ...state,
        hsncode: hsn.hsncode,
        tax_rate: hsn.GSTRate,
      }));
    }
  };

  const handleFormValidation = () => {
    const errors = {};
    if (!formData.brand_id) {
      errors.brand_id = "required";
    }
    if (!formData.category_id) {
      errors.category_id = "required";
    }
    if (!formData.group_id) {
      errors.group_id = "required";
    }
    if (!formData.location_id) {
      errors.location_id = "required";
    }
    if (!formData.item_name) {
      errors.item_name = "required";
    }
    else if (!isItemName && !isEdit) {
      errors.item_name = "required";
    }
    // if (!formData.item_code) {
    //   errors.item_code = "required";
    // }
    else if (!isItemCode && !isEdit) {
      errors.item_code = "required";
    }
    if (CheckAndReturn.checkError(formData.hsncode)) {
      errors.hsncode = "required";
    }
    if (!formData.unit) {
      errors.unit = "required";
    }
    if (CheckAndReturn.checkError(formData.openingquantity)) {
      errors.openingquantity = "required";
    }
    if (CheckAndReturn.checkError(formData.discount)) {
      errors.discount = "required";
    }
    if (CheckAndReturn.checkError(formData.tax_rate)) {
      errors.tax_rate = "required";
    }
    if (CheckAndReturn.checkError(formData.rate_unit)) {
      errors.rate_unit = "required";
    }
    if (CheckAndReturn.checkError(formData.costprice)) {
      errors.costprice = "required";
    }
    setValidationErrors(errors);
    return Object.values(errors).every((error) => error === "");
  };

  const checkItemCode = async () => {
    try {
      const dataToSend = {
        item_code: formData.item_code,
      }
      const res = await configServ.checkItemCode(dataToSend);
      if (res.status === 200) {
        setIsItemCode(true);
      }
      else {
        setIsItemCode(false);
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (formData.item_code && formData.item_code.length > 3) {
      checkItemCode();
    }
    else if (formData.item_code && formData.item_code.length < 4) {
      setIsItemCode(false);
    }
    else if (CheckAndReturn.checkError(formData.item_code)) {
      setIsItemCode(true);
    }
    else {
      setIsItemCode(false);
    }
  }, [formData.item_code]);

  const checkItemName = async () => {
    try {
      const dataToSend = {
        item_name: formData.item_name,
      }
      const res = await configServ.checkItemName(dataToSend);
      if (res.status === 200) {
        setIsItemName(true);
      }
      else {
        setIsItemName(false);
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (formData.item_name) {
      checkItemName();
    }
  }, [formData.item_name]);

  const handleLabel = (params) => {
    try {
      return <span>{params}<span style={{ color: 'red' }}>*</span></span>
    }
    catch (e) {
      console.log(e);
    }
  }

  //#endregion

  return (
    <>
      <AlertDialog
        open={dialogOpen}
        setOpen={handleCloseDialog}
        title={title}
        message={dialogContent}
        buttonTitle="Ok"
        buttonType={buttonType}
      />
      <CardHeadMenu
        prefix={"Na"}
        suffix={"Na"}
        title={"ITEMS"}
        handlePopup={handlePopup}
        isEdit={isEdit}
        create={isCreate}
      />

      {isCreate === true && (
        <Container>
          <Grid
            container
            alignItems="center"
            justifyContent="flex-start"
            spacing={1}
          >
            <Grid item xs={6} md={3}>
              <h5>Business Type</h5>
            </Grid>
            <Grid container item xs={6} md={9} justifyContent={'flex-start'}>
              <RadioGroup
                name="use-radio-group"
                defaultValue="first"
                row
                onChange={(e) => {
                  setCompanyType(e.target.value);
                }}
              >
                <MyFormControlLabel
                  value={"product"}
                  label="Sales"
                  control={<Radio size="small" />}
                  checked={
                    company_type === "product"
                      ? true
                      : false
                  }
                  disabled={
                    company_type === "service"
                  }
                />
                <MyFormControlLabel
                  value={"service"}
                  label="Service"
                  control={<Radio size="small" />}
                  checked={
                    company_type === "service"
                      ? true
                      : false
                  }
                  disabled={
                    company_type === "product"
                  }
                />
              </RadioGroup>
            </Grid>
          </Grid>

          <motion.div
            style={{
              width: "100%",
              backgroundColor: "#e3f3fc",
              padding: 20,
              borderRadius: "10px",
              border: "none",
            }}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.1, delay: 0.1 }}
          >
            <Grid
              container
              alignItems="flex-end"
              justifyContent="flex-start"
              spacing={1}
            >
              <Grid item xs={12} md={12} justifyContent={"flex-start"}>
                <Typography sx={{ fontSize: "16px", fontWeight: "bolder" }}>
                  Item Detail
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Autocomplete
                  size="small"
                  sx={{ backgroundColor: "white" }}
                  id="brand"
                  options={brandList}
                  getOptionLabel={(option) => option.brand_name}
                  value={
                    brandList.find(
                      (item) => item.id.toString() === (formData.brand_id || "").toString()
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    changeHandler({
                      target: {
                        name: "brand_id",
                        value: newValue?.id || "",
                      },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="brand_id"
                      label={handleLabel("Brand")}
                      variant="outlined"
                      fullWidth
                      error={!!validationErrors.brand_id}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Autocomplete
                  id="category"
                  size="small"
                  sx={{ backgroundColor: "white" }}
                  options={categoryList}
                  getOptionLabel={(option) => option.categoryname}
                  value={
                    categoryList.find(
                      (item) =>
                        item.categoryid.toString() === (formData.category_id || "").toString()
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    changeHandler({
                      target: {
                        name: "category_id",
                        value: newValue?.categoryid || "",
                      },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="category_id"
                      label={handleLabel("Category")}
                      variant="outlined"
                      fullWidth
                      error={!!validationErrors.category_id}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Autocomplete
                  id="group"
                  size="small"
                  sx={{ backgroundColor: "white" }}
                  options={groupList}
                  getOptionLabel={(option) => option.group_name}
                  value={
                    groupList.find(
                      (item) => item.id === formData.group_id
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    changeHandler({
                      target: {
                        name: "group_id",
                        value: newValue?.id || "",
                      },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="group_id"
                      label={handleLabel("Group")}
                      variant="outlined"
                      fullWidth
                      error={!!validationErrors.group_id}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Autocomplete
                  id="location"
                  size="small"
                  sx={{ backgroundColor: "white" }}
                  options={locationList}
                  getOptionLabel={(option) => option.location_name}
                  value={
                    locationList.find(
                      (item) => item.id === formData.location_id
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    changeHandler({
                      target: {
                        name: "location_id",
                        value: newValue?.id || "",
                      },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="location_id"
                      label={handleLabel("Location")}
                      variant="outlined"
                      fullWidth
                      error={!!validationErrors.location_id}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  disabled={isEdit}
                  id="item_name"
                  size="small"
                  sx={{ backgroundColor: "white" }}
                  name="item_name"
                  label={handleLabel("Item Name")}
                  variant="outlined"
                  fullWidth
                  value={formData.item_name || ''}
                  onChange={changeHandler}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {isItemName || isEdit ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />}
                      </InputAdornment>
                    )
                  }}
                  error={!!validationErrors.item_name}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  disabled={isEdit}
                  id="item_code"
                  name="item_code"
                  label={handleLabel("Item Code")}
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={CheckAndReturn.checkTextNullAndUndefined(formData.item_code)}
                  onChange={changeHandler}
                  error={!!validationErrors.item_code}
                  sx={{ backgroundColor: "white" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {isItemCode || isEdit ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />}
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="itemType">Item Type</InputLabel>
                  <Select
                    id="itemType"
                    size="small"
                    sx={{ backgroundColor: "white" }}
                    name="item_type"
                    value={formData.item_type || null}
                    onChange={changeHandler}
                    label={handleLabel("Item Type")}
                  >
                    <MenuItem value="single">Single</MenuItem>
                    <MenuItem value="composite">Composite</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <Autocomplete
                  id="superCategory"
                  size="small"
                  sx={{ backgroundColor: "white" }}
                  options={superCategoryList}
                  getOptionLabel={(option) => option.name}
                  value={
                    superCategoryList.find(
                      (item) => item.id === formData.superCategory
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    changeHandler({
                      target: {
                        name: "superCategory",
                        value: newValue?.id || "",
                      },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="superCategory"
                      label="Super Category"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  id="itemDescription"
                  size="small"
                  sx={{ backgroundColor: "white" }}
                  name="description"
                  label="Item Description"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={2}
                  value={formData.description || ''}
                  onChange={changeHandler}
                />
              </Grid>
              <Grid item xs={12} md={12} justifyContent={"flex-start"}>
                <Typography sx={{ fontSize: "16px", fontWeight: "bolder" }}>
                  Price Detail
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Autocomplete
                  id="hsncode"
                  size="small"
                  sx={{ backgroundColor: "white" }}
                  options={hsnList}
                  getOptionLabel={(option) => option.hsncode}
                  value={
                    hsnList.find(
                      (item) => item.hsncode === formData.hsncode
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    handleHSN(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="hsncode"
                      label={handleLabel("HSN/SAC")}
                      variant="outlined"
                      fullWidth
                      error={!!validationErrors.hsncode}
                    />
                  )}
                />
              </Grid>
              {/* <Grid item xs={12} md={3}>
                        <TextField
                            id="sku"
                            name="sku"
                            label="SKU"
                            variant="outlined"
                            fullWidth
                            size="small"
                            value={formData.sku || ''}
                            onChange={changeHandler}
                            sx={{ backgroundColor: 'white' }}
                        />
                    </Grid> */}
              <Grid item xs={12} md={3}>
                <Autocomplete
                  id="unit"
                  size="small"
                  sx={{ backgroundColor: "white" }}
                  options={unitList}
                  getOptionLabel={(option) => option.units_name}
                  value={
                    unitList.find(
                      (item) => item.units_name === formData.unit
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    changeHandler({
                      target: {
                        name: "unit",
                        value: newValue?.units_name || "",
                      },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="unit"
                      label={handleLabel("Unit")}
                      variant="outlined"
                      fullWidth
                      error={!!validationErrors.unit}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  id="unitPrice"
                  size="small"
                  sx={{ backgroundColor: "white" }}
                  name="rate_unit"
                  label={handleLabel("Unit Price")}
                  variant="outlined"
                  type="number"
                  fullWidth
                  value={CheckAndReturn.checkTextNullAndUndefined(formData.rate_unit)}
                  onChange={changeHandler}
                  error={!!validationErrors.rate_unit}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  id="openingQuantity"
                  size="small"
                  sx={{ backgroundColor: "white" }}
                  name="openingquantity"
                  label={handleLabel("Opening Quantity")}
                  type="number"
                  variant="outlined"
                  fullWidth
                  value={CheckAndReturn.checkTextNullAndUndefined(formData.openingquantity)}
                  onChange={changeHandler}
                  error={!!validationErrors.openingquantity}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  id="discount"
                  size="small"
                  sx={{ backgroundColor: "white" }}
                  name="discount"
                  label={handleLabel("Discount(%)")}
                  type="number"
                  variant="outlined"
                  fullWidth
                  value={CheckAndReturn.checkTextNullAndUndefined(formData.discount)}
                  onChange={changeHandler}
                  error={!!validationErrors.discount}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  id="taxRate"
                  size="small"
                  sx={{ backgroundColor: "white" }}
                  name="tax_rate"
                  label={handleLabel("Tax Rate")}
                  variant="outlined"
                  type="number"
                  fullWidth
                  value={CheckAndReturn.checkTextNullAndUndefined(formData.tax_rate)}
                  defaultValue={0}
                  onChange={changeHandler}
                  error={!!validationErrors.tax_rate}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  id="costPrice"
                  size="small"
                  sx={{ backgroundColor: "white" }}
                  name="costprice"
                  label={handleLabel("Cost Price")}
                  variant="outlined"
                  type="number"
                  fullWidth
                  value={CheckAndReturn.checkTextNullAndUndefined(formData.costprice)}
                  onChange={changeHandler}
                  error={!!validationErrors.costprice}
                />
              </Grid>
              {/* <Grid item xs={12} md={3}>
                          <Autocomplete
                              id="superCategory"
                              size="small"
                              sx={{backgroundColor:'white'}}
                              options={superCategoryList}
                              getOptionLabel={(option) => option.name}
                              value={superCategoryList.find((item) => item.id === formData.superCategory) || null}
                              onChange={(event, newValue) => {
                                  changeHandler({ target: { name: 'superCategory', value: newValue?.id || '' } });
                              }}
                              renderInput={(params) => (
                                  <TextField
                                  {...params}
                                  name="superCategory"
                                  label="Super Category"
                                  variant="outlined"
                                  fullWidth
                                  />
                              )}
                          />
                      </Grid> */}
              {company_type === "service" && (
                <Grid item xs={12} md={3}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="subscriptionStatus">
                      Subscription Status
                    </InputLabel>
                    <Select
                      id="subscriptionStatus"
                      size="small"
                      sx={{ backgroundColor: "white" }}
                      name="SubscriptionStatus"
                      value={formData.SubscriptionStatus || null}
                      onChange={changeHandler}
                      label="Subscription Status"
                    >
                      <MenuItem value="Monthly">Monthly</MenuItem>
                      <MenuItem value="Yearly">Yearly</MenuItem>
                      <MenuItem value="Trial">Trial</MenuItem>
                    </Select>
                  </FormControl>
                  {/* <Form.Label>Subscription Status</Form.Label>
                        <Form.Select
                            name='SubscriptionStatus'
                            value={formData.SubscriptionStatus || ''}
                            onChange={changeHandler}
                        >
                            <option value="Monthly">Monthly</option>
                            <option value="Yearly">Yearly</option>
                            <option value="Trial">Trial</option>
                        </Form.Select> */}
                </Grid>
              )}

              {formData.item_type === "composite" && (
                <Grid
                  container
                  alignItems="flex-end"
                  justifyContent="flex-end"
                  mt={2}
                  spacing={1}
                  sx={{
                    borderRadius: 2,
                    border: "1px solid #33333322",
                    marginTop: 2,
                    marginLeft: 2,
                  }}
                >
                  {associatedItem.map((item, index) => (
                    <Stack
                      key={item.id}
                      direction={{ xs: 'column', md: 'row' }}
                      width="100%"
                      justifyContent="space-between"
                      spacing={2}
                      padding={2}
                    >
                      <FormControl sx={{ width: "100%" }} size="small">
                        <InputLabel>Item</InputLabel>
                        <Select
                          placeholder="Item Name"
                          label="Item Name"
                          sx={{
                            width: "100%",
                            backgroundColor: "white",
                          }}
                          name="item_id"
                          onChange={(e) => {
                            handleChangeAssociatedItem(item.id, e);
                          }}
                          value={item.item_id || ''}
                        >
                          {tableData.map((item, index) => (
                            <MenuItem key={index} value={item.id}>
                              {item.item_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <TextField
                        label="Quantity"
                        size="small"
                        type="number"
                        sx={{
                          width: "100%",
                          backgroundColor: "white",
                        }}
                        name="qty"
                        onChange={(e) => {
                          handleChangeAssociatedItem(item.id, e);
                        }}
                        value={item.qty || ''}
                      />
                      <Stack direction="row" spacing={2}>
                        {associatedItem.length > 1 && (
                          <Button
                            title="Remove"
                            color="error"
                            size="small"
                            variant="contained"
                            onClick={() => {
                              delAssociatedItem(item.id);
                            }}
                          >
                            <RemoveCircleOutlineIcon />
                          </Button>
                        )}
                        <Button
                          title="Add"
                          color='primary'
                          onClick={addAssociatedItem}
                          size="small"
                          variant="contained"
                        >
                          <AddIcon />
                        </Button>
                      </Stack>
                    </Stack>
                  ))}
                </Grid>
              )}

              <Grid
                container
                alignItems="flex-end"
                justifyContent="flex-end"
                mt={2}
                spacing={1}
              >
                <Grid container item xs={12} md={isEdit ? 6 : 8} justifyContent={'flex-start'}>
                  <FormControlLabel
                    name='status'
                    control={<Checkbox checked={Boolean(formData.status) || false} />}
                    onChange={checkHandler}
                    label="Status"
                    style={{ marginRight: '20px' }}
                  />
                </Grid>
                <Grid item xs={6} md={2}>
                  <Button
                    fullWidth
                    color={'secondary'}
                    onClick={clear}
                    variant="contained"
                    size="small"
                    title={"Reset"}
                    startIcon={<RotateLeftIcon />}
                  >
                    {"Reset"}
                  </Button>
                </Grid>
                <Grid item xs={6} md={2}>
                  <Button
                    fullWidth
                    onClick={handleSubmit}
                    variant="contained"
                    color={isEdit ? "success" : "primary"}
                    startIcon={isEdit ? <UpdateIcon /> : <SaveIcon />}
                    size="small"
                  >
                    {isEdit ? "Update" : "Save"}
                  </Button>
                </Grid>
                {isEdit === true && (
                  <Grid item xs={6} md={2}>
                    <Button
                      fullWidth
                      color="primary"
                      onClick={() => {
                        navigate("advance", { state: formData });
                      }}
                      variant="contained"
                      size="small"
                      startIcon={<NextPlanIcon />}
                    >
                      Advance
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </motion.div>
        </Container>
      )}

      <ItemTable
        handleEdit={handleEdit}
        isChanged={isChanged}
      />
    </>
  );
};

export default Items;
