import React, { useState, useEffect } from "react";
import { SessionVerification } from "../../../Components/SessionManagement/SessionManagement";
import CustHeader from "../../../Components/header/Header/CustHeader";
import CustomerSideBar from "../../../Components/sidebar/CustomerSideBar";
import Footer from "../../../Components/Footer/Footer";

import { motion } from "framer-motion";
import { Box, Stack, Paper, Divider, Typography, Button } from "@mui/material";
import CustPurchaseTable from "../../Component/CustPurchaseOrder/CustPurchaseTable";
import http from "../../../services/http";
import configServ from "../../../services/config";

const CustPurchaseOrder = () => {
  return (
    <>
      <motion.h4
        style={{ textAlign: "center" }}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.1 }}
      >
        Purchase Order List
      </motion.h4>
      <Stack direction="column" justifyContent="flex-start" width="100%" >
        <Divider />
      </Stack>

      <CustPurchaseTable />
    </>
  );
};
export default CustPurchaseOrder;
