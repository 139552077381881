import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    Box,
    Tooltip,
    Dialog,
    IconButton,
    Typography,
    Slide,
    Button,
    Stack
} from '@mui/material';
import configServ from '../../services/config';

//icons
import CloseIcon from '@mui/icons-material/Close';

const NestedTable = ({ handleClose, open, id, pagination = true }) => {
    const [data, setData] = useState([])
    const rowsPerPageOptions = [20, 50, 100, 200];
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);

    const headers = ['ITEM NAME', 'QUANTITY', 'RATE', 'DISCOUNT', 'AMOUNT'];

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    const headerCellStyle = {
        fontSize: 14, // Adjust the font size as needed
        fontWeight: 'bold',
        backgroundColor: '#4CB5F5',
        color: 'white',
    };

    const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  const paginatedData = pagination
    ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : data;

    const fetchItemDetails = async () => {
        try {
            const result = await configServ.itemDetails(id);
            // console.log(result)
            setData(result)
            // setMiniLoader(false)
        } catch (err) {
            console.log(err)
            // setMiniLoader(false)
        }
    }

    useEffect(() => {
        fetchItemDetails()
    }, [])

    const abc = () => {
        console.log(data)
    }

    return (
        <Dialog
            maxWidth='lg'
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <Stack direction='row' justifyContent='flex-end' p={1}>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
            </Stack>
            {/* <Button onClick={abc}>abc</Button> */}


            <TableContainer component={Paper} sx={{ px: 5 }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={headerCellStyle}>S.No.</TableCell>
                            {headers.map((header, index) => (
                                <TableCell key={index} style={headerCellStyle}>
                                    {header}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedData.map((row, rowIndex) => (
                            <TableRow key={rowIndex}>
                                <TableCell>{page * rowsPerPage + rowIndex + 1}</TableCell>
                                <TableCell>{row.itemname}</TableCell>
                                <TableCell>{row.qty}</TableCell>
                                <TableCell>{row.rate}</TableCell>
                                <TableCell>{row.discount}</TableCell>
                                <TableCell>{row.amount}</TableCell>
                            </TableRow>
                        ))}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 1 * emptyRows }}>
                                <TableCell colSpan={headers.length + 2} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                {pagination && (
                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                )}
            </TableContainer>


        </Dialog>
    );
};

export default NestedTable;
