import React, { useEffect, useState } from "react";
import { Container } from 'react-bootstrap';
import { Stack, Paper, Button } from "@mui/material";
import PurchaseOrderCard from "../../../Components/Card/TransactionCards/PurchaseOrder/PurchaseOrderCard";
import PurchaseOrderTable from "../../../Components/Card/TransactionCards/PurchaseOrder/PurchaseOrderTable";

//FUNCTION
const PurchaseOrder = () => {
    const [editData, setEditData] = useState(null);
    const [isChanged, setIsChanged] = useState(false);
    
    const handleEdit = (data) => {
        setEditData(data);
    }

    return (
        <>
            <Container>
                <PurchaseOrderCard editData={editData} setEditData={setEditData} isChanged={isChanged} setIsChanged={setIsChanged} />
            </Container>

            <Stack direction='column' justifyContent='flex-start' width='100%' my={2}>
                <PurchaseOrderTable handleEditData={handleEdit} isChanged={isChanged} />
            </Stack>
        </>
    )
}
export default PurchaseOrder;