import { InputAdornment, TextField } from '@mui/material';
import React from 'react';

import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

//FUNCTION
const SearchBox = ({ disabled=false, search, setSearch }) => {
    
    const handleSearch = (event) => {
        try {
            setSearch(event.target.value);
        }
        catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <TextField
                disabled={disabled}
                id="outlined-basic"
                size="small"
                fullWidth
                placeholder="search"
                variant="outlined"
                value={search || ''}
                onChange={handleSearch}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon style={{ fill: 'grey' }} />
                        </InputAdornment>
                    ),
                    endAdornment: search && (
                        <InputAdornment position="end">
                            <CloseIcon style={{ fill: 'grey', cursor: 'pointer' }} onClick={() => setSearch(null)} />
                        </InputAdornment>
                    ),
                }}
            />
        </>
    )
}

export default SearchBox
