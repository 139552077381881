import React, { useState } from "react";
import AccountMasterCard from "../../Components/Card/AccountMasterCards/AccountMasterCard";
import { Divider } from "@mui/material";
import { useSelector } from 'react-redux'
import AccountMasterTable from "../../Components/Table/AccountMaster/AccountMasterTable";

//FUNCTION
const AccountMaster = () => {
    //#region code
    const open = useSelector((state) => state.sideExpand.open);
    const [editData, setEditData] = useState({})
    const [isChanged, setIsChanged] = useState(false)

    const handleEdit = (data) => {
        setEditData(data[0]);
    }

    //#endregion

    return (
        <>
            <AccountMasterCard editData={editData} setEditData={setEditData} isChanged={isChanged} setIsChanged={setIsChanged} />

            <Divider />
            <AccountMasterTable handleEditData={handleEdit} isChanged={isChanged} />
        </>
    )
}
export default AccountMaster