import React, { useState, useEffect } from "react";
import Header from "../../Components/header/Header/Header";
import Sidebar from "../../Components/sidebar/SideBar";
import { useDispatch, useSelector } from "react-redux";
import InvoiceTable from "../../Components/Card/InvoiceCards/InvoiceTable";
import { Container } from "react-bootstrap";
import axios from "axios";
import configServ from "../../services/config";
import LocationTable from "./LocationTable";
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import { SessionVerificationAdmin } from "../../Components/SessionManagement/SessionManagementAdmin";
import { useNavigate } from "react-router-dom";
import SaveIcon from '@mui/icons-material/Save';
import UpdateIcon from '@mui/icons-material/Update';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import {
    Grid,
    TextField,
    Box,
    Typography,
    Stack,
    Paper,
    Button,
    Divider,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
    Checkbox,
    Tooltip,
    IconButton,
    Autocomplete
} from "@mui/material";
import CardHeadMenu from "../../Components/Card/CardHeadMenu/CardHeadMenu";
import AlertDialog from "../../Components/AlertDialog/AlertDialog";
import City from "./City";
import { triggerLoader } from "../../redux/reducers/LoaderTrigger";
import { rSetShowFab } from "../../redux/reducers/GlobalVariables";

//FUNCTION
const Location = () => {
    //#region code
    const dispatch = useDispatch();
    const { user_id, companyCountry, companyState } = useSelector((state) => state.GlobalVariables);
    const open = useSelector((state) => state.sideExpand.open);
    const { company_type } = useSelector((state) => state.admin);
    const navigate = useNavigate();
    const initialFormData = {
        country: companyCountry,
        state: companyState,
        company_type, company_type,
        status: true,
        isPrimary: false
    };
    const [formData, setFormData] = useState(initialFormData);
    const [validationErrors, setValidationErrors] = useState({});
    const [cityOpen, setCityOpen] = useState(false);
    const [countryList, setCountryList] = useState([]);
    const [countryId, setCountryId] = useState('');
    const [country, setCountry] = useState('');
    const [stateList, setStateList] = useState([]);
    const [stateId, setStateId] = useState([]);
    const [state, setState] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [cityId, setCityId] = useState([]);
    const [city, setCity] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [isCreate, setIsCreate] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [title, setTitle] = useState('Alert');
    const [dialogContent, setDialogContent] = useState('');
    const [buttonType, setButtonType] = useState(null);

    const fetchCountries = async () => {
        try {
            const res = await configServ.getCountry();
            if (res.status === 200) {
                setCountryList(res.data);
            }
            else {
                setCountryList([]);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const fetchStates = async () => {
        try {
            const countryId = countryList.find(x => x.country === formData.country).PK;
            const res = await configServ.getState({ "country": countryId });
            if (res.status === 200) {
                setStateList(res.data);
            }
            else{
                setStateList([]);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    // const fetchCity = async () => {
    //     try {
    //         const res = await configServ.getCity({ "state_id": stateId });
    //         if (res.status === 200) {
    //             setCityList(res.data);
    //         }
    //     }
    //     catch (e) {
    //         console.log(e);
    //     }
    // }

    // useEffect(() => {
    //     fetchCity();
    // }, [stateId]);

    const handleOpenDialog = (heading, content, type) => {
        setTitle(heading);
        setDialogContent(content);
        setButtonType(type);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        clear();
        setDialogOpen(false);
        setTitle('Alert');
        setDialogContent('');
        setButtonType(null);
    };

    useEffect(() => {
        fetchCountries();
    }, []);

    useEffect(() => {
        if (formData.country && countryList.length > 0) {
            fetchStates();
        }
        else{
            fetchCountries();
        }
    }, [formData.country,countryList]);

    // const fetchCities = async () => {
    //     const res = await axios.post('https://countriesnow.space/api/v0.1/countries/state/cities', { "country": `${formData.country}`, "state": `${formData.state}` })
    //     if (!res.error) {
    //         // setCityList(res.data.data)
    //         setCitiesToList(res.data.data)
    //         // console.log(res.data.data)
    //     }
    // }
    // const setCitiesToList = (data) => {
    //     if (data.length === 0) {
    //         return;
    //     }
    //     data.map((item) => {
    //         setCityList((state) => ([
    //             ...state,
    //             {
    //                 // ...item,
    //                 label: item,
    //                 name: 'state',
    //             }
    //         ]))
    //     })
    // }

    const validate = async () => {
        let errors = {};
        if (!formData.location_name) {
            errors.location_name = "required"
        }
        if (!formData.country) {
            errors.country = "required"
        }
        if (!formData.state) {
            errors.state = "required"
        }
        if (!formData.city) {
            errors.city = "required"
        }
        if (!formData.company_type) {
            errors.company_type = "required"
        }

        setValidationErrors(errors);
        return Object.values(errors).every(error => error === "");
    }

    const handleSubmit = async () => {
        try {
            dispatch(triggerLoader());
            const dataToSend = {
                ...formData,
                admin_id: user_id,
                company_type: company_type
            }
            const isValidated = await validate();
            if (isValidated === false) {
                return false;
            }
            if (!isEdit) {
                const res = await configServ.addLocation(dataToSend);
                if (res.status === 200) {
                    setIsChanged(!isChanged);
                    clear();
                    handleOpenDialog('Success', res.message, 'success');
                }
                else if (res.status === 400) {
                    handleOpenDialog('Warning', res.message || "Failed to create location.", 'warning');
                }
                else {
                    handleOpenDialog('Error', res.message || "Failed to create location.", 'error');
                }
            }
            else {
                const res = await configServ.editLocation(dataToSend);
                if (res.status === 200) {
                    setIsChanged(!isChanged);
                    clear();
                    handleOpenDialog('Success', res.message, 'success');
                }
                else if (res.status === 400) {
                    handleOpenDialog('Warning', res.message || "Failed to update location.", 'warning');
                }
                else {
                    handleOpenDialog('Error', res.message || "Failed to update location.", 'error');
                }
            }
        }
        catch (err) {
            console.log(err);
            handleOpenDialog('Error', "An unexpected error has occurred.", 'error');
        }
        finally {
            dispatch(triggerLoader());
        }
    }

    const clear = () => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            state: "",
            city: "",
            location_name: "",
            // Reset the other fields if necessary
        }));
        setValidationErrors({});
        setStateId('');
        setCityId('');
        setStateList([]);
        setCityList([]);
        setIsEdit(false);
    
        // If country is selected, trigger the fetchStates again
        if (formData.country) {
            fetchStates();
        }
    };
    

    const handleChange = (e) => {
        const { name, value } = e.target;

        //Update form data
        setFormData((state) => ({
            ...state,
            [name]: value
        }));

        // Reset the validation error when the user starts typing
        if (validationErrors[name]) {
            setValidationErrors((prevValidationError) => ({
                ...prevValidationError,
                [name]: '',
            }));
        }
    }

    const handleChangeCheckbox = (e) => {
        const { name, checked } = e.target
        setFormData((state) => ({
            ...state,
            [name]: checked
        }))
    }

    // useEffect(() => {
    //     if (formData.state !== undefined) {
    //         fetchCities()
    //     }
    // }, [formData]);

    const handleEditData = async (data) => {
        setIsCreate(true);
        if (data[0].country_id) {
            setCountryId(data[0].country_id);
            setCountry(data[0].country);
        }
        if (data[0].state_id) {
            setStateId(data[0].state_id);
            setState(data[0].state);
        }
        setFormData(data[0]);
        setIsEdit(true);
    }

    // const handleEditCountryStateCity = async (params) => {
    //     try {
    //         if (params.country) {
    //             const countryData = countryList.filter(x => x.country_name.toLowerCase() === params.country.toLowerCase())[0];
    //             setCountryId(countryData.id);
    //             setCountry(countryData.country_name);
    //         }
    //         else if (params.state) {
    //             const stateData = stateList.filter(x => x.state_name.toLowerCase() === params.state.toLowerCase())[0];
    //             setStateId(stateData.id);
    //             setState(stateData.state_name);
    //         }
    //         else if (params.city) {
    //             const cityData = cityList.filter(x => x.city_name.toLowerCase() === params.state.toLowerCase())[0];
    //             setCityId(cityData.id);
    //             setCity(cityData.city_name);
    //         }
    //     }
    //     catch (e) {
    //         console.log(e);
    //     }
    // }

    const handlePopup = () => {
        try {
            clear();
            dispatch(rSetShowFab(isCreate))
            setIsCreate(!isCreate);
        }
        catch (error) {
            console.log(error);
        }
    };

    const handleCityOpen = () => {
        try {
            setCityOpen(!cityOpen);
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleCityClose = () => {
        setCityOpen(false);
    }
    //#endregion

    return (
        <>
            <AlertDialog
                open={dialogOpen}
                setOpen={handleCloseDialog}
                title={title}
                message={dialogContent}
                buttonTitle="Ok"
                buttonType={buttonType}
            />
            <City open={cityOpen} handleClose={handleCityClose} />

            <Container>
                <CardHeadMenu prefix={'Na'} suffix={'Na'} title={'LOCATIONS'} handlePopup={handlePopup} create={isCreate} />
            </Container>

            {
                isCreate && <>
                    <Container>
                        <div
                            style={{
                                width: '100%',
                                backgroundColor: '#e3f3fc',
                                padding: 20,
                                borderRadius: '10px',
                                border: 'none',
                                margin: '10px',
                            }}
                        >

                            <Grid container alignItems={'center'}>
                                <Button size={'small'} variant="contained" startIcon={<AddIcon />} onClick={handleCityOpen}>
                                    ADD CITY
                                </Button>
                            </Grid>
                            <Grid container alignItems="flex-end" justifyContent="flex-start" style={{ padding: 10 }} spacing={1} >
                                <Grid item xs={12} md={12} justifyContent={'flex-start'}>
                                    <Typography sx={{ fontSize: '16px', fontWeight: 'bolder' }}>Location Detail</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <TextField
                                        label={<span>Location Name<span style={{ color: 'red' }}>*</span></span>}
                                        variant="outlined"
                                        name='location_name'
                                        type="text"
                                        fullWidth
                                        size="small"
                                        sx={{ backgroundColor: 'white' }}
                                        onChange={handleChange}
                                        value={formData.location_name || ''}
                                        error={!!validationErrors.location_name}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <TextField
                                        label="Address"
                                        variant="outlined"
                                        name='address'
                                        type="text"
                                        fullWidth
                                        size="small"
                                        sx={{ backgroundColor: 'white' }}
                                        onChange={handleChange}
                                        value={formData.address || ''}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <TextField
                                        label="Latitude"
                                        variant="outlined"
                                        name='latitude'
                                        type="text"
                                        fullWidth
                                        size="small"
                                        sx={{ backgroundColor: 'white' }}
                                        onChange={handleChange}
                                        value={formData.latitude || ''}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <TextField
                                        label="Longitude"
                                        variant="outlined"
                                        name='longitude'
                                        type="text"
                                        fullWidth
                                        size="small"
                                        sx={{ backgroundColor: 'white' }}
                                        onChange={handleChange}
                                        value={formData.longitude || ''}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3} md={3}>
                                    <FormControl fullWidth size='small'>
                                        <InputLabel error={!!validationErrors.country}>Country<span style={{ color: 'red' }}>*</span></InputLabel>
                                        <Select fullWidth size='small' sx={{ backgroundColor: 'white' }}
                                            name="country" label={<span>Country<span style={{ color: 'red' }}>*</span></span>}
                                            value={formData.country || ""} onChange={handleChange} error={!!validationErrors.country}
                                        >
                                            <MenuItem value="">Select Country</MenuItem>
                                            {countryList.map((item, index) => (
                                                <MenuItem key={index} value={item.country}>{item.country}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={3} md={3}>
                                    <FormControl fullWidth size='small'>
                                        <InputLabel error={!!validationErrors.state}>State<span style={{ color: 'red' }}>*</span></InputLabel>
                                        <Select fullWidth size='small' sx={{ backgroundColor: 'white' }}
                                            name="state" label={<span>State*<span style={{ color: 'red' }}>*</span></span>}
                                            value={formData.state || ""} onChange={handleChange}
                                            error={!!validationErrors.state}
                                        >
                                            <MenuItem value="">Select State</MenuItem>
                                            {stateList.map((item, index) => (
                                                <MenuItem key={index} value={item.State}>{item.State}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={3} md={3}>
                                    <TextField
                                        label={<span>City<span style={{ color: 'red' }}>*</span></span>}
                                        variant="outlined"
                                        name='city'
                                        type="text"
                                        fullWidth
                                        size="small"
                                        sx={{ backgroundColor: 'white' }}
                                        onChange={handleChange}
                                        value={formData.city || ''}
                                        error={!!validationErrors.city}
                                    />                                            </Grid>
                                <Grid item xs={12} sm={3} md={3}>
                                    <TextField
                                        label="Pin"
                                        variant="outlined"
                                        name='pin'
                                        type="number"
                                        fullWidth
                                        size="small"
                                        sx={{ backgroundColor: 'white' }}
                                        onChange={handleChange}
                                        value={formData.pin || ''}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3} md={3}>
                                    <FormControl fullWidth size='small'>
                                        <InputLabel error={!!validationErrors.company_type}>Company Type<span style={{ color: 'red' }}>*</span></InputLabel>
                                        <Select error={!!validationErrors.company_type}
                                            name='company_type' label='Company Type'
                                            value={formData.company_type || ''} onChange={handleChange} sx={{ backgroundColor: 'white' }}>
                                            <MenuItem value='product'>product</MenuItem>\
                                            <MenuItem value='service'>service</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={3} md={9} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                    <FormControlLabel
                                        control={<Checkbox />}
                                        label="Status"
                                        name="status"
                                        onChange={handleChangeCheckbox}
                                        checked={Boolean(formData.status || '')}
                                    />
                                    <FormControlLabel
                                        name='isPrimary'
                                        control={<Checkbox checked={Boolean(formData.isPrimary) || false} />}
                                        onChange={handleChangeCheckbox}
                                        label="Is Primary"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} >
                                    <Stack
                                        // border={1}
                                        direction={{ xs: 'row', sm: 'row' }}
                                        justifyContent={isEdit ? 'flex-end' : 'flex-end'}
                                        alignItems={'center'}
                                    >
                                        {/* <Button
                                                        variant="contained"
                                                        color="info"
                                                        onClick={() => { navigate('advance', { state: formData }) }}
                                                        sx={{
                                                            width: {
                                                                xs: '100%',
                                                                sm: 'auto'
                                                            },
                                                            marginRight: '10px'
                                                        }}
                                                        hidden={isEdit ? false : true}
                                                    >
                                                        Advance Options
                                                    </Button> */}
                                        <Stack width={{ xs: '100%', sm: 'auto' }} direction={{ xs: 'row', sm: 'row' }}>
                                            <Button
                                                variant="contained"
                                                color={'secondary'}
                                                onClick={clear}
                                                sx={{
                                                    width: {
                                                        xs: '100%',
                                                        sm: '7rem'
                                                    },
                                                    marginRight: '10px'
                                                }}
                                            >
                                                {<RotateLeftIcon sx={{ marginRight: '5px' }} />}
                                                {'Reset'}
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color={isEdit ? 'success' : 'primary'}
                                                onClick={handleSubmit}
                                                sx={{
                                                    width: {
                                                        xs: '100%',
                                                        sm: '7rem'
                                                    }
                                                }}
                                                startIcon={isEdit ? <UpdateIcon sx={{ marginRight: '10px' }} /> : <SaveIcon sx={{ marginRight: '10px' }} />}
                                            >
                                                {isEdit ? ' Update' : ' Save'}
                                            </Button>
                                        </Stack>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </div>
                    </Container>
                </>}

            <Divider />

            <Container>
                <LocationTable handleEditData={handleEditData} isChanged={isChanged} />
            </Container>
        </>

    )

}

export default Location;