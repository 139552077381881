import React from 'react'
import CardHeadMenu from '../../../Components/Card/CardHeadMenu/CardHeadMenu';
import AllLedgers from '../../../Components/report/allLedgers/AllLedgers';

const AllLedgersReport = () => {
    return (
        <>
            <CardHeadMenu prefix={"Na"} suffix={"Na"} title={"All Ledger Report "} showCreate={false} />

            <AllLedgers />
        </>
    )
}

export default AllLedgersReport;
