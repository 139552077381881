import React from 'react';
import { Box, Grid, Typography, Container } from '@mui/material';

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#f8f9fa',
        py: { xs: 1, md: 1 },  // Adjust padding for mobile (xs) and larger screens (md)
        mt: 'auto',  // This pushes the footer to the bottom
        borderTop: '1px solid #e0e0e0',
        width: '100%', // Ensure the footer stretches to full width
        textAlign: "center", // Center the content inside the footer
      }}
    >
      <Container maxWidth="lg">
        <Box mt={0}>
          <Grid container alignItems={'center'}>
            <Grid container item xs={8} justifyContent={'center'}>
              <Typography
                variant="body2"
                align="center"
                color="textSecondary"
                sx={{ fontSize: { xs: '0.65rem', md: '0.85rem' } }} // Adjust font size for mobile
              >
                © {new Date().getFullYear()} DCRM. All rights reserved.
              </Typography>
            </Grid>
            <Grid container item xs={4} justifyContent={'center'}>
              <Typography
                variant="body2"
                align="center"
                color="textSecondary"
                sx={{ fontSize: { xs: '0.65rem', md: '0.85rem' } }} // Adjust font size for mobile
              >
                info@ricitech.in
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
