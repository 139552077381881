
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
//import Cookies from 'universal-cookie';
import Cookies from "universal-cookie/cjs/Cookies";
import { rSetLogin } from '../../redux/reducers/GlobalVariables';
import jwtDecode from 'jwt-decode';
//import { useHistory } from 'react-router-dom';

export const SessionVerificationAdmin = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const cookie = new Cookies();

    // Get the value of the cookie named 'dcrm_token'
    const token = cookie.get('dcrm_token');

    if (token !== null && token !== undefined) {
        try {
            const decodedToken = jwtDecode(token); // Decode the token
            const currentTime = (Date.now() + 5.5 * 60 * 60 * 1000) / 1000; // Get current time in seconds

            // Check if the token is expired
            if (decodedToken.exp > currentTime) {

            }
            else {
                localStorage.setItem('session', 'expired');
                dispatch(rSetLogin(false));
                navigate('/admin-login');
            }
        } catch (error) {
            console.error('Error decoding token:', error);
        }
    }
    else {
        localStorage.setItem('session', 'expired');
        dispatch(rSetLogin(false));
        navigate('/admin-login');
    }
}
