import React, { useState } from 'react';
import {
    Box, CircularProgress, FormControl, Grid, InputAdornment, InputLabel, NativeSelect, TextField, Typography, Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Tooltip
} from '@mui/material';
import configServ from '../../services/config';
import ActionButtons from '../../Components/Buttons/ActionButtons ';
import CheckAndReturn from '../../utility/CheckAndReturn';
import { useEffect } from 'react';
import DataGridTable from '../../Components/DataGridTable/DataGridTable';
import SearchIcon from '@mui/icons-material/Search';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import LoaderNew from '../../Components/Loader/LoaderNew';

//FUNCTION
const SuperCategoryTable = ({ handleEditData, isChanged, pagination = true }) => {
    //#region code
    const [superCategoryDataList, setSuperCategoryDataList] = useState([]);
    const [superCategoryList, setSuperCategoryList] = useState([]);
    const [loading, setLoading] = useState(true);
    const rowsPerPageOptions = [20, 50, 100, 200];
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
    const [count, setCount] = useState(0);
    const [searchBy, setSearchBy] = useState('name');

    useEffect(() => {
        fetchSuperCategoryList();
    }, [isChanged]);

    const headers = ['NAME', 'STATUS', 'PRIMARY', 'ACTION'];

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, superCategoryList.length - page * rowsPerPage);

    const paginatedData = pagination
        ? superCategoryList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : superCategoryList;

    const headerCellStyle = {
        fontSize: 14, // Adjust the font size as needed
        fontWeight: 'bold',
        backgroundColor: '#4CB5F5',
        color: 'white',
    };

    const cellStyle = {
        p: 0,
        m: 0,
    }

    const evenRowStyle = {
        background: '#f5f5f5',
    };

    const oddRowStyle = {
        background: 'white',
    };

    const rowStyle = {
        '&:hover': {
            background: '#f1f9fe',
        },
    };

    const handleSearchBy = (event) => {
        try {
            setSearchBy(event.target.value);
        }
        catch (error) {
            console.log(error);
        }
    }

    const handleSearch = (event) => {
        try {
            const search = event.target.value;
            let dataList = [];
            if (search === '') {
                dataList = superCategoryDataList;
            }
            else if (searchBy === 'name') {
                dataList = superCategoryDataList.filter(x => x.name.toLowerCase().includes(search.toLowerCase()))
            }

            setSuperCategoryList(dataList);
            setCount(dataList.length);
        }
        catch (error) {
            console.log(error);
        }
    }

    const fetchSuperCategoryList = async () => {
        try {
            setLoading(true);
            const res = await configServ.getSuperCategory();
            if (res.status === 200) {
                setSuperCategoryDataList(res.data);
                setSuperCategoryList(res.data);
                setCount(res.data.length);
            }
            else {
                setSuperCategoryDataList([]);
                setSuperCategoryList([]);
                setCount(0);
            }
        }
        catch (e) {
            console.log(e);
        }
        finally {
            setLoading(false);
        }
    }

    const handleEdit = (params) => {
        scrollToTop()
        const data = superCategoryDataList.filter(x => x.id === params.id);
        handleEditData(data)
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    //#endregion

    return (
        <>
            {/* search starts here */}
            <Grid container alignItems="flex-end" justifyContent="flex-start" sx={{ pt: 1 }} spacing={1} direction={'row'}>
                <Grid item xs={12} md={6} justifyContent={'flex-start'}>
                    <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>Super Categories: {count}</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Box sx={{ minWidth: 120, marginLeft: '0px' }}>
                        <FormControl
                            fullWidth
                        >
                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                Search By
                            </InputLabel>
                            <NativeSelect
                                onChange={handleSearchBy}
                                inputProps={{
                                    name: 'searchBy',
                                    id: 'uncontrolled-native',
                                }}
                            >
                                <option value={'name'}>Name</option>
                            </NativeSelect>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} md={3} mr={{ xs: 0, md: 0 }} justifyContent={'flex-end'}>
                    <TextField
                        id="outlined-basic"
                        size="small"
                        fullWidth
                        placeholder="search"
                        variant="outlined"
                        onChange={handleSearch}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon style={{ fill: 'grey' }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
            </Grid>
            {/* search ends here */}

            <TableContainer sx={{ py: 1 }} elevation={0}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={headerCellStyle}>S.No.</TableCell>
                            {headers.map((header, index) => (
                                <TableCell key={index} style={headerCellStyle}>
                                    {header}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    {
                        loading === true ?
                            <TableRow>
                                <TableCell colSpan={12} sx={{ textAlign: 'center' }}>
                                    <Box>
                                        <LoaderNew />
                                    </Box>
                                </TableCell>
                            </TableRow>
                            :
                            <TableBody>
                                {paginatedData.length > 0 ?
                                    paginatedData.map((row, rowIndex) => (
                                        <TableRow key={rowIndex}
                                            sx={{
                                                ...rowStyle,
                                                ...(rowIndex % 2 === 0 ? evenRowStyle : oddRowStyle),
                                            }}
                                        >
                                            <TableCell sx={cellStyle}>{page * rowsPerPage + rowIndex + 1}</TableCell>
                                            <TableCell sx={cellStyle}>{row.name}</TableCell>
                                            <TableCell sx={cellStyle}>{row.status ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />}</TableCell>
                                            <TableCell sx={cellStyle}>{row.isPrimary ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />}</TableCell>
                                            <TableCell sx={cellStyle}>
                                                <Box sx={{ borderRadius: 10, width: 'fit-content', transition: '100ms', cursor: 'pointer', padding: 0.5 }}>
                                                    <Tooltip title='EDIT' arrow>
                                                        <EditIcon color='primary' sx={{ ":hover": { backgroundColor: '#99999955' } }} onClick={() => { handleEdit(row); scrollToTop() }} />
                                                    </Tooltip>
                                                    {/* <Tooltip title='DELETE' arrow>
                                                    <DeleteTwoToneIcon color='error' sx={{ ":hover": { backgroundColor: '#99999955' } }} onClick={() => { handleDelete(row); scrollToTop() }} />
                                                </Tooltip> */}
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                    :
                                    <TableRow>
                                        <TableCell colSpan={12} sx={{ textAlign: 'center' }}>
                                            <Box>
                                                <Typography sx={{ color: 'red', fontSize: 'bold' }}>No data found.</Typography>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                }
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 1 * emptyRows }}>
                                        <TableCell colSpan={headers.length + 2} />
                                    </TableRow>
                                )}
                            </TableBody>
                    }
                </Table>

                {pagination && (
                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                )}
            </TableContainer>
        </>
    );
};

export default SuperCategoryTable;
