import React, { useState } from "react";
import { useSelector } from "react-redux";
import jwtDecode from "jwt-decode";
import Header from "../../Components/header/Header/Header";
// import Footer from "../../Components/Footer/Footer";
import Sidebar from "../../Components/sidebar/SideBar";
import { SessionVerificationAdmin } from "../../Components/SessionManagement/SessionManagementAdmin";
import toast from "react-hot-toast";
import {
  Stack,
  Box,
  TextField,
  Button,
  Paper,
  Typography,
} from "@mui/material";
import configServ from "../../services/config";

function ChangePassword() {
  //#region code
  const { user_id } = useSelector((state) => state.GlobalVariables);
  const open = useSelector((state) => state.sideExpand.open);
  const initialError = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const initialPasswords = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };
  const [errors, setErrors] = useState(initialError);
  const [success, setSuccess] = useState(false);
  const [passwords, setPasswords] = useState(initialPasswords);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswords((prevPasswords) => ({
      ...prevPasswords,
      [name]: value,
    }));
  };

  const isValidPassword = async (passwords) => {
    if (!passwords.currentPassword) {
      setErrors({ currentPassword: "Current password is required" });
      return false;
    }

    if (!passwords.newPassword) {
      setErrors({ newPassword: "New password is required" });
      return false;
    }

    if (passwords.newPassword.length < 8) {
      setErrors({ newPassword: "New password must be at least 8 characters" });
      return false;
    }

    if (!passwords.confirmPassword) {
      setErrors({ confirmPassword: "Confirm password is required" });
      return false;
    }

    if (passwords.confirmPassword.length < 8) {
      setErrors({
        confirmPassword: "Confirm password must be at least 8 characters",
      });
      return false;
    }

    // Password strength check
    // const hasUpperCase = /[A-Z]/.test(passwords.newPassword);
    // const hasLowerCase = /[a-z]/.test(passwords.newPassword);
    // const hasDigit = /\d/.test(passwords.newPassword);
    // const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(passwords.newPassword);

    //   if (!(hasUpperCase && hasLowerCase && hasDigit && hasSpecialChar)) {
    //     setErrors({
    //         newPassword: "New password must include at least one uppercase letter, one lowercase letter, one digit, and one special character"
    //     });
    //     return false;
    // }

    if (passwords.newPassword !== passwords.confirmPassword) {
      setErrors({
        newPassword: "Passwords do not match",
        confirmPassword: "Passwords do not match",
      });
      return false;
    }

    // Password is valid
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let cleanPassword = await isValidPassword(passwords);
      if (!cleanPassword) {
        return;
      }

      setErrors({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      });

      const data = {
        admin_id: user_id, // Fetch from Redux or decoded JWT
        current_password: passwords.currentPassword,
        new_password: passwords.newPassword,
        new_password_confirmation: passwords.confirmPassword, // Required for confirmation
      };

      const res = await configServ.adminchangePassword(data);
     
      // Success Message
      if (res.status === 200) {
        toast.success(res.message || "Password updated successfully!");
        handleSuccess();
      }
      // Error Handling
      else {
        toast.error(
          res.data.message || "An error occurred while updating the password!"
        );
        setErrors({
          currentPassword: "Current password is incorrect",
        });
      }
    } catch (error) {
      // Backend or Network Error
      toast.error(error.response?.data?.message || "Something went wrong!");
    }
  };

  const handleSuccess = () => {
    setSuccess(true);
    setErrors(initialError);
    setPasswords(initialPasswords);
  };
  //#endregion

  return (
    <>
      <Box
        sx={{
          marginLeft: { xs: 0, sm: open ? 33 : 8 },
          transition: "margin-left 200ms",
          minHeight: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // backgroundColor: "#f5f5f5", // Subtle background
          padding: { xs: 2, sm: 4 },
        }}
      >
        <Paper
          elevation={3} // Adds shadow
          sx={{
            width: "100%",
            maxWidth: "500px", // Ensures it doesn’t stretch too wide
            padding: 4,
            borderRadius: 2, // Rounded corners
            backgroundColor: "white",
          }}
        >
          <Typography
            variant="h5"
            fontWeight="bold"
            textAlign="center"
            gutterBottom
            sx={{
              fontSize: { xs: "1.2rem", sm: "1.5rem" },
              color: "#1976d2", // Primary color
            }}
          >
            Change Password
          </Typography>

          {success && (
            <Typography
              variant="body2"
              color="success.main"
              textAlign="center"
              sx={{
                marginBottom: 2,
                fontWeight: 500,
              }}
            >
              Password updated successfully!
            </Typography>
          )}

          <Stack spacing={3}>
            <TextField
              required
              fullWidth
              label="Current Password"
              type="password"
              name="currentPassword"
              variant="outlined"
              value={passwords.currentPassword}
              onChange={handleChange}
              error={!!errors.currentPassword}
              helperText={errors.currentPassword}
              InputProps={{
                sx: { backgroundColor: "#fff" },
              }}
            />

            <TextField
              fullWidth
              label="New Password"
              type="password"
              name="newPassword"
              variant="outlined"
              value={passwords.newPassword}
              onChange={handleChange}
              error={!!errors.newPassword}
              helperText={errors.newPassword}
              InputProps={{
                sx: { backgroundColor: "#fff" },
              }}
            />

            <TextField
              fullWidth
              label="Confirm New Password"
              type="password"
              name="confirmPassword"
              variant="outlined"
              value={passwords.confirmPassword}
              onChange={handleChange}
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword}
              InputProps={{
                sx: { backgroundColor: "#fff" },
              }}
            />

            <Button
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              onClick={handleSubmit}
              sx={{
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
            >
              Update Password
            </Button>
          </Stack>
        </Paper>
      </Box>

      {/* <Footer /> */}
    </>
  );
}

export default ChangePassword;
