import React, { useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import configServ from '../../services/config';
import ActionButtons from '../../Components/Buttons/ActionButtons ';
import CheckAndReturn from '../../utility/CheckAndReturn';
import { useEffect } from 'react';
import DataGridTable from '../../Components/DataGridTable/DataGridTable';
import AlertDialog from "../../Components/AlertDialog/AlertDialog";
import { useSelector } from 'react-redux';
import LoaderNew from '../../Components/Loader/LoaderNew';

const SubscriptionTable = ({ handleEditData, isChanged }) => {
  //#region code
  const { company_id } = useSelector((state) => state.GlobalVariables);
  const [locationDataList, setLocationDataList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [loading, setLoading] = useState(true);
  const columns = [
    { field: 'sn', headerName: 'S.NO.', width: 80 },
    { field: 'customer_name', headerName: 'CUSTOMER NAME', width: 150 },
    { field: 'product_name', headerName: 'PRODUCT', width: 150 },
    { field: 'billing_type', headerName: 'BILLING CYCLE', width: 110 },
    { field: 'subs_valid_from', headerName: 'REGISTRATION FROM', width: 150 },
    { field: 'subs_valid_to', headerName: 'VALID TO', width: 130 },
    { field: 'price', headerName: 'AMOUNT', width: 110 },
    { field: 'status', headerName: 'STATUS', width: 100 },
    {
      field: 'action',
      headerName: 'ACTION',
      width: 130,
      renderCell: (params) => (
        <ActionButtons
          handlePrint={handlePrint}
          handleEdit={handleEdit}
          id={params.id}
          noPrint
        />
      ),
    },
  ];

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');

  const handleOpenDialog = (content) => {
    setDialogContent(content);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDialogContent('');
  };

  useEffect(() => {
    fetchLocationList();
  }, [isChanged]);

  const fetchLocationList = async () => {
    try {
      const res = await configServ.getSubscriptionList({ company_id: company_id });
      const result = res.map((item, index) => ({
        sn: index + 1,
        id: item.id,
        customer_name: item.customer_name,
        product_name: item.product_name || 'N/A',
        billing_type: item.billing_type || 'N/A',
        subs_valid_from: item.subs_valid_from,
        subs_valid_to: item.subs_valid_to,
        price: item.price,
        status: item.status === 1 ? 'Subscribed' : 'Expired',
      }));
      setLocationDataList(res);
      setLocationList(result);
      setLoading(false);
    }
    catch (error) {
      console.log(error);
    }
  }

  const handlePrint = (params) => {
    handleOpenDialog(JSON.stringify(params));
  }

  const handleEdit = (params) => {
    const data = locationDataList.filter(x => x.id === params);
    handleEditData(data)
  }
  //#endregion

  if (loading) {
    return (
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <LoaderNew />
      </Box>
    )
  }
  else {
    return (
      <>
        <AlertDialog
          open={dialogOpen}
          setOpen={handleCloseDialog}
          title="Alert"
          message={dialogContent}
          buttonTitle="Ok"
        />
        <DataGridTable rows={locationList} columns={columns} />
      </>
    );
  }
};

export default SubscriptionTable;
