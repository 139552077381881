import React, { memo, useEffect, useState } from 'react';
import { Card, CardContent, Typography, Box, CircularProgress } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import configServ from '../../services/config';
import { useSelector } from 'react-redux';
import CheckAndReturn from '../../utility/CheckAndReturn';
import LoaderNew from '../Loader/LoaderNew';

const FollowUpCard = ({ data, isChanged }) => {
    const { company_id } = useSelector((state) => state.GlobalVariables);
    const [followupData, setFollowupData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (data) {
            fetchFollowUp();
        }
    }, [isChanged]);

    const fetchFollowUp = async () => {
        try {
            setLoading(true);
            const dataToSend = {
                entity_id: data.id,
                entity_type: data.entity_type,
                company_id: company_id
            };
            const res = await configServ.getFollowUp(dataToSend);
            if (res.status === 200) {
                setFollowupData(res.data);
            } 
            else {
                setFollowupData([]);
            }
        } 
        catch (e) {
            console.log(e);
        } 
        finally {
            setLoading(false);
        }
    };

    return (
        <Box>
            {loading ? (
                <Box sx={{ textAlign: 'center', margin: 2 }}>
                    <LoaderNew />
                </Box>
            ) : (
                followupData.length > 0 ? (
                    followupData.map((row, index) => (
                        <Card key={index} sx={{ marginBottom: 2, padding: '16px', backgroundColor: '#f9f9ff' }}>
                            <CardContent>
                                <Typography variant="body2" gutterBottom>
                                    Remarks: <span style={{fontWeight:500, fontSize:'16px'}}>{row.remarks}</span>
                                </Typography>
                                <Typography variant="body2" color="textSecondary" gutterBottom>
                                    Follow Up: {CheckAndReturn.transformToDate3(row.follow_up_date)}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" gutterBottom>
                                    Created At: {CheckAndReturn.transformToDate3(row.created_at)}
                                </Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography variant="body2" color="textSecondary" gutterBottom>
                                        Status:
                                    </Typography>
                                    {row.status === 1 ? <CheckCircleIcon color="success" sx={{ ml: 1 }} /> : <CancelIcon color="error" sx={{ ml: 1 }} />}
                                </Box>
                            </CardContent>
                        </Card>
                    ))
                ) : (
                    <Box sx={{ textAlign: 'center', color: 'red', fontSize: '16px', marginTop: 2 }}>
                        <p><b>No Records Found.</b></p>
                    </Box>
                )
            )}
        </Box>
    );
};

export default memo(FollowUpCard);
