import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Box,
  Tooltip,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Button,
  Stack
} from '@mui/material';
import configServ from '../../services/config';
import InvoiceTable2 from './InvoiceTable2';

//icons
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';

const AllInvoices = ({ handleClose, open, setOpen }) => {
  //#region code
  const { company_id } = useSelector((state) => state.GlobalVariables);
  const [tableData, setTableData] = useState([])

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const fetchAllInvoiceList = async () => {
    const data = {
      'company_id': company_id
    }
    try {
      const result = await configServ.invoiceListAdmin(data);
      setTableData(result)
      // setMiniLoader(false)
    } catch (err) {
      console.log(err)
      // setMiniLoader(false)
    }
  }

  useEffect(() => {
    fetchAllInvoiceList()
  }, []);
  //#endregion

  return (
    <Dialog
      maxWidth='lg'
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <Stack direction='row' justifyContent='flex-end' p={1}>
        {/* <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" color='primary'>
                List of all invoices
            </Typography> */}
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </Stack>
      <InvoiceTable2 data={tableData} />
    </Dialog>
  );
};

export default AllInvoices;
