import React, { memo } from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogActions, Button,
    IconButton, Typography, Box
} from '@mui/material';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';

const ConfirmationDialog = ({ message, onClose, onConfirm }) => {
    
    const handleClose = (response) => {
        onConfirm(response);
        onClose();
    };

    return (
        <Dialog
            open={Boolean(message != null ? true : false)}
            onClose={() => handleClose(false)}
            aria-labelledby="confirmation-dialog-title"
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle id="confirmation-dialog-title">
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6" color="primary" fontWeight={600}>
                        Confirm
                    </Typography>
                </Box>
                <Box position="absolute" right={16} top={6}>
                    <IconButton
                        aria-label="close"
                        onClick={() => handleClose(false)}
                    >
                        <DisabledByDefaultIcon sx={{ fontSize: 32 }} color="error" />
                    </IconButton>
                </Box>
            </DialogTitle>

            <DialogContent dividers>
                <Typography variant="body1" color="textSecondary">
                    {message ? message : "Are you sure you want to proceed with this action?"}
                </Typography>
            </DialogContent>

            <DialogActions>
                <Button
                    onClick={() => handleClose(false)}
                    variant="outlined"
                    color="primary"
                >
                    No
                </Button>
                <Button
                    onClick={() => handleClose(true)}
                    variant="contained"
                    color="primary"
                >
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default memo(ConfirmationDialog);
