import React, { memo, useEffect, useState } from 'react'
import AlertDialog from '../AlertDialog/AlertDialog';
import CardHeadMenu from '../Card/CardHeadMenu/CardHeadMenu';
import {
    Button,
    MenuItem,
    FormControl,
    InputLabel,
    Grid,
    FormControlLabel,
    Checkbox,
    Typography,
    Select,
    TextField,
    Autocomplete,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import SaveIcon from "@mui/icons-material/Save";
import UpdateIcon from "@mui/icons-material/Update";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import AddVoucherList from './AddVoucherList';
import configServ from '../../services/config';
import { triggerLoader } from '../../redux/reducers/LoaderTrigger';
import Calculation from '../../utility/Calculations';
import AddCustomerVoucherData from './AddCustomerVoucherData';

//FUNCTION
const CustomerVoucherCard = ({ customer_id, invoice_no }) => {
    //#region code
    const dispatch = useDispatch();
    //const navigate = useNavigate();
    const { user_id, company_id, is_super } = useSelector((state) => state.GlobalVariables);

    const [customer, setCustomer] = useState(null);
    const [error, setError] = useState(false);
    const formDataInitial = {
        generate_voucher_no: true,
        voucher_type: 'receipt',
        is_credit: false,
        payment_mode: 2,
        transaction_date: Calculation.GetCurrentDate(),
        status: true
    }
    const [invoices, setInvoices] = useState([]);
    const [accountList, setAccountList] = useState([]);
    const [formData, setFormData] = useState(formDataInitial);
    const [validationErrors, setValidationErrors] = useState({});
    const [dialogOpen, setDialogOpen] = useState(false);
    const [title, setTitle] = useState('Alert');
    const [dialogContent, setDialogContent] = useState('');
    const [buttonType, setButtonType] = useState(null);
    const [totalAmount, setTotalAmount] = useState(0);
    const [voucherDetails, setVoucherDetails] = useState([]);
    const [entity_type,setEntity_type] = useState('customer');
    const [loading, setLoading] = useState(false);

    const handleOpenDialog = (heading, content, type) => {
        setTitle(heading);
        setDialogContent(content);
        setButtonType(type);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        handleReset();
        setDialogOpen(false);
        setTitle('Alert');
        setDialogContent('');
        setButtonType(null);
    };

    useEffect(() => {
        if (customer_id !== null) {
            fetchCustomerDetail();
            fetchInvoiceByCustomerID();
        }
        else {
            setCustomer(null);
            setInvoices([]);
        }
    }, [customer_id]);

    const fetchCustomerDetail = async () => {
        try {
            const res = await configServ.getCustomerById({
                "customer_id": customer_id,
            });
            if (res.status === 200) {
                setCustomer(res.data);
            }
            else {
                setCustomer(null);
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    const fetchAccountMasterList = async (params) => {
        try {
            const dataToSend = {
                "is_super": is_super,
                "user_id": user_id,
                "company_id": company_id,
                "search_by": 'accountclassified',
                "search": params,
                "per_page": null,
                "page": null
            }
            const res = await configServ.getAccountMasters(dataToSend);
            if (res.status === 200) {
                const result = res.data;
                setAccountList(result);
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (formData.payment_mode) {
            fetchAccountMasterList(formData.payment_mode);
        }
    }, [formData.payment_mode]);

    useEffect(() => {
        if (formData.voucher_type === 'payment') {
            setFormData((prev) => ({
                ...prev,
                'is_credit': true,
            }));
            setEntity_type('customer');
        }
        else if (formData.voucher_type === 'receipt') {
            setFormData((prev) => ({
                ...prev,
                'is_credit': false,
            }));
            setEntity_type('customer');
        }
    }, [formData.voucher_type]);

    const handleFormData = (event) => {
        try {
            const { name, value } = event.target;

            //Update form
            setFormData((prev) => ({
                ...prev,
                [name]: value
            }));

            // Reset the validation error when the user starts typing
            if (validationErrors[name]) {
                setValidationErrors((prevValidationError) => ({
                    ...prevValidationError,
                    [name]: '',
                }));
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleChangeCheckbox = (e) => {
        const { name, checked } = e.target;
        setFormData((state) => ({
            ...state,
            [name]: checked,
        }));
    };

    const fetchInvoiceByCustomerID = async () => {
        try {
            const dataToSend = {
                "customer_id": customer_id
            }
            const res = await configServ.getInvoiceByCustomerID(dataToSend);
            if (res.status === 200) {
                setInvoices(res.data);
            }
            else {
                setInvoices([]);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const validate = async () => {
        try {
            const errors = {};
            if (!formData.voucher_type) {
                errors.voucher_type = 'Voucher type is required.'
            }
            if (formData.generate_voucher_no === false) {
                if (!formData.voucher_no) {
                    errors.voucher_no = 'Voucher No is required.'
                }
            }
            if (!formData.payment_mode) {
                errors.payment_mode = 'Payment mode is required.'
            }
            if (!formData.account) {
                errors.account = 'Account is required.'
            }
            setValidationErrors(errors);

            return Object.values(errors).every(error => error === "");
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setLoading(true);
            dispatch(triggerLoader());

            const validateCard = await validate();
            if (validateCard === false || error === true) {
                return false;
            }

            const dataToSend = {
                generate_voucher_no: formData.generate_voucher_no,
                voucher_type: formData.voucher_type,
                is_credit: formData.is_credit,
                payment_mode: formData.payment_mode,
                transaction_date: formData.transaction_date,
                remarks: formData.remarks,
                status: formData.status,
                voucher_no: formData.voucher_no,
                account: formData.account,
                total_amount: totalAmount,
                company_id: company_id,
                user_id: user_id,
                customers: voucherDetails
            }
            const res = await configServ.createVouchers(dataToSend);
            if (res.status === 200) {
                handleOpenDialog('Success', res.message || 'Voucher created successfully.', 'success');
            }
            else if (res.status === 400) {
                handleOpenDialog('Warning', res.message || "Failed to create voucher.", 'warning');
            }
            else {
                handleOpenDialog('Error', res.message || "Failed to create voucher.", 'error');
            }
        }
        catch (e) {
            console.log(e);
            handleOpenDialog('Error', "An unexpected error has occurred.", 'error');
        }
        finally {
            setLoading(false);
            dispatch(triggerLoader());
        }
    }

    const handleReset = () => {
        try {
            setFormData(formDataInitial);
            setValidationErrors({});
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleVoucherDetail = (params) => {
        try {
            const details = params.map(item => ({
                ...item,
                customer_id: customer_id,
                entity_type: entity_type,
            }));
            setVoucherDetails(details);
            // Calculate the total amount using reduce
            const totalAmount = details.reduce((acc, item) => {
                return acc + Number(item.amount);
            }, 0);
            setTotalAmount(totalAmount);
        }
        catch (e) {
            console.log(e);
        }
    }
    //#endregion

    return (
        <>
            <AlertDialog
                open={dialogOpen}
                setOpen={handleCloseDialog}
                title={title}
                message={dialogContent}
                buttonTitle="Ok"
                buttonType={buttonType}
            />

            <motion.div style={{ width: "100%", backgroundColor: "#e3f3fc", padding: 20, borderRadius: '10px', border: 'none' }}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.1, delay: 0.1 }}
            >
                <form onSubmit={handleSubmit} onReset={handleReset}>
                    <Grid
                        container
                        alignItems="flex-end"
                        justifyContent="flex-start"
                        sx={{ p: 0, }}
                        spacing={1}
                    >
                        <Grid item xs={12} p={0}>
                            <FormControlLabel
                                control={<Checkbox />}
                                checked={Boolean(formData.generate_voucher_no)}
                                name="generate_voucher_no"
                                onChange={handleChangeCheckbox}
                                label="Auto Generate"
                                sx={{ fontWeight: "bold", m: 0, p: 0 }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField
                                disabled={formData.generate_voucher_no}
                                fullWidth
                                size='small'
                                sx={{ backgroundColor: 'white' }}
                                label={'Voucher No'}
                                name='voucher_no'
                                value={formData.voucher_no || ""}
                                onChange={handleFormData}
                                error={!!validationErrors.voucher_no}
                            />
                        </Grid>
                        {
                            customer !== null &&
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    disabled
                                    type='text'
                                    fullWidth
                                    size='small'
                                    sx={{ background: 'white' }}
                                    value={(customer.fname ?? '') + "~" + (customer.address ?? '')}
                                    label={'Customer'}
                                />
                            </Grid>
                        }
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth size='small'>
                                <InputLabel error={!!validationErrors.voucher_type}>Voucher Type</InputLabel>
                                <Select
                                    size={'small'}
                                    sx={{ backgroundColor: 'white' }}
                                    label="Voucher Type"
                                    name='voucher_type'
                                    value={formData.voucher_type || ""}
                                    onChange={handleFormData}
                                    error={!!validationErrors.voucher_type}
                                >
                                    <MenuItem value={'payment'}>
                                        Payment
                                    </MenuItem>
                                    <MenuItem value={'receipt'}>
                                        Receipt
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth size='small'>
                                <InputLabel error={!!validationErrors.payment_mode}>Payment Mode</InputLabel>
                                <Select
                                    size={'small'}
                                    sx={{ backgroundColor: 'white' }}
                                    label="Payment Mode"
                                    name='payment_mode'
                                    value={formData.payment_mode || ""}
                                    onChange={handleFormData}
                                    error={!!validationErrors.payment_mode}
                                >
                                    <MenuItem value={1}>
                                        Bank
                                    </MenuItem>
                                    <MenuItem value={2}>
                                        Cash
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth size='small'>
                                <InputLabel error={!!validationErrors.account}>Account</InputLabel>
                                <Select
                                    size={'small'}
                                    sx={{ backgroundColor: 'white' }}
                                    label="Account"
                                    name='account'
                                    value={formData.account || ""}
                                    onChange={handleFormData}
                                    error={!!validationErrors.account}
                                >
                                    {accountList.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.accountname}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {/* <Grid item xs={12} sm={6} md={4}>
                            <TextField
                                label="Transaction Date"
                                type="date"
                                fullWidth
                                size='small'
                                name='transaction_date'
                                value={formData.transaction_date || ""}
                                onChange={handleFormData}
                                sx={{ backgroundColor: 'white' }}
                                InputLabelProps={{ shrink: true, }}
                                error={!!validationErrors.transaction_date}
                            />
                        </Grid> */}
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField
                                label="Remarks"
                                type="text"
                                fullWidth
                                size='small'
                                name='remarks'
                                value={formData.remarks || ""}
                                onChange={handleFormData}
                                sx={{ backgroundColor: 'white' }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Typography variant='body1' color={'green'}>Net Amount: ₹{totalAmount}</Typography>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid container item xs={12} md={6} justifyContent={'flex-start'}>
                                <FormControlLabel
                                    control={<Checkbox />}
                                    name="status"
                                    checked={Boolean(formData.status)}
                                    onChange={handleChangeCheckbox}
                                    label="Status"
                                    sx={{ fontWeight: "bold" }}
                                />
                            </Grid>
                            <Grid container item xs={12} md={6} justifyContent={'flex-end'}>
                                {/* <Button
                                    type='reset'
                                    color={'secondary'}
                                    startIcon={<RotateLeftIcon />}
                                    variant='contained'
                                    sx={{ mx: 1, cursor: 'pointer' }}
                                >
                                    Reset
                                </Button> */}
                                <Button
                                    disabled={loading}
                                    type='submit'
                                    color={'primary'}
                                    startIcon={<SaveIcon />}
                                    variant='contained'
                                    sx={{ mx: 1, cursor: 'pointer' }}
                                >
                                    {'Save'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </motion.div>

            <div>
                <motion.div
                    style={{
                        width: '100%',
                        backgroundColor: '#f2f0fc',
                        paddingTop: 0,
                        paddingBottom: 0,
                        borderRadius: '10px',
                        border: 'none',
                        margin: '20px 0px',
                    }}
                    initial={{ opacity: 0, y: 15 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.1, delay: 0.1 }}
                >
                    <div>
                        <AddCustomerVoucherData invoices={invoices} customer_id={customer_id} voucherDetails={handleVoucherDetail} setError={setError} payment_mode={formData.payment_mode} invoice_no={invoice_no} />
                    </div>
                </motion.div>
            </div>
        </>
    )
}

export default memo(CustomerVoucherCard);
