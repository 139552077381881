import React, { memo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import FollowUp from './FollowUp';

//FUNCTION
const FollowUpPopup = memo(({ data, closeFollowup }) => {

    return (
        <>
            <Dialog
                open={!!data}
                onClose={closeFollowup}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={false}
                PaperProps={{
                    sx: {
                        width: '80%',
                        height: '80%',
                    },
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box flex={1} textAlign="center">
                            <Typography variant="h5" color="#1e61b4" fontWeight={700}>
                                Follow up
                            </Typography>
                        </Box>
                        <Box position="absolute" right={16}>
                            <IconButton onClick={closeFollowup}>
                                <DisabledByDefaultIcon color="error" />
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <FollowUp data={data} />
                </DialogContent>
            </Dialog>
        </>
    )
});

export default memo(FollowUpPopup);
