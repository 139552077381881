import React, { memo, useEffect, useState } from 'react';
import { Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import CheckAndReturn from '../../../utility/CheckAndReturn';
import { useNavigate } from 'react-router-dom';

const InvoiceItemCard = ({ invoiceItems }) => {
    const navigate = useNavigate();
    const [invoiceAmount, setInvoiceAmount] = useState(0);

    useEffect(() => {
        let totalInvoiceAmount = 0;

        invoiceItems.forEach(row => {
            totalInvoiceAmount += Number(row.amount ?? 0);
        });

        setInvoiceAmount(totalInvoiceAmount);

        return () => {
            setInvoiceAmount(0);
        }
    }, [invoiceItems]);

    const handleItemClick = (params) => {
        try {
            const data = params;
            navigate('/master-entry/item', { state: data });
        }
        catch (e) {
            console.log(e);
        }
    }

    return (
        <>
            {
                invoiceItems.map((item, index) => (
                    <Card sx={{ marginBottom: '16px', padding: '16px', backgroundColor: '#f9f9ff' }} key={item.id}>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" fontWeight={600}>
                                        <span className='linkStyle' onClick={() => handleItemClick(item.itemname)}>{item.itemname}</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row" spacing={1}>
                                        <Typography variant="body1" color="textSecondary">Code:</Typography>
                                        <Typography variant="body1">{item.itemcode}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row" spacing={1}>
                                        <Typography variant="body1" color="textSecondary">Quantity:</Typography>
                                        <Typography variant="body1">{item.qty}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row" spacing={1}>
                                        <Typography variant="body1" color="textSecondary">Rate:</Typography>
                                        <Typography variant="body1">₹{item.rate}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row" spacing={1}>
                                        <Typography variant="body1" color="textSecondary">Discount(%):</Typography>
                                        <Typography variant="body1">{item.discount}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row" spacing={1}>
                                        <Typography variant="body1" color="textSecondary">Tax(%):</Typography>
                                        <Typography variant="body1">{item.tax_rate}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row" spacing={1}>
                                        <Typography variant="body1" color="textSecondary">Amount:</Typography>
                                        <Typography variant="body1">₹{item.amount}</Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                ))
            }
            <Stack direction={'row'} justifyContent={'center'}>
                <Typography variant='body1' sx={{ fontSize: '16px', fontWeight: 600 }}>
                    Invoice Amount:
                </Typography>
                <Typography variant='body1' sx={{ fontSize: '16px', fontWeight: 600, color: 'green' }}>
                    ₹{CheckAndReturn.roundToInteger(invoiceAmount ?? 0)}
                </Typography>
            </Stack>
        </>
    );
};

export default memo(InvoiceItemCard);
