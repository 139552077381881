import React, { useState } from 'react'
import { useSelector } from 'react-redux'

// import Header from '../../../Components/header/Header/Header'
import Footer from '../../../Components/Footer/Footer'
import CustomerSideBar from '../../../Components/sidebar/CustomerSideBar'

import { Stack, Box, TextField, Button, Paper, Typography } from '@mui/material'
import { SessionVerification } from '../../../Components/SessionManagement/SessionManagement'
import CustHeader from '../../../Components/header/Header/CustHeader'
import validator from 'validator';
import http from '../../../services/http'
import configServ from '../../../services/config'
import AlertDialog from '../../../Components/AlertDialog/AlertDialog'

function CustChangePassword() {
    //#region code
    const { id, user_id, email } = useSelector((state) => state.GlobalVariables);
    const open = useSelector((state) => state.sideExpand.open)
    const [formData, setFormData] = useState({
        "email": email
    });
    const [errorData, setErrorData] = useState({});
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState('');

    //validation checker
    const valCheck = () => {
        if (
            errorData.current_password?.err === false &&
            errorData.new_password?.err === false &&
            errorData.c_password?.err === false
        ) {
            return true
        } else {
            return false
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData((state) => ({
            ...state,
            [name]: value
        }))

        switch (name) {
            case 'new_password':
                valNpassword(value)
                break;
            case 'current_password':
                valPassword(value)
                break;
            case 'c_password':
                valCpassword(value)
                break;
            default:
                console.log('Invalid input field key received')
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        let isValid = valCheck()
        if (isValid) {
            try {
                const result = await configServ.resetCustomerPassword({ ...formData, "user_id": id });

                if (result.status === 200) {
                    setDialogContent(result.message);
                    setDialogOpen(true);
                }
                else if (result.status === 401 || result.status === 404) {
                    setDialogContent(result.message);
                    setDialogOpen(true);
                }
                else {
                    setDialogContent("An error occurred.");
                    setDialogOpen(true);
                }
            }
            catch (err) {
                setDialogContent("Error while changing password");
                setDialogOpen(true);
            }

        }
    }

    //function for setting error message  
    const handleError = (key, msg, isErr) => {
        setErrorData((state) => ({
            ...state,
            [key]: {
                text: msg,
                err: isErr
            }
        }))
    }

    const valPassword = (password) => {
        if (password === '' || password === undefined) {
            handleError('current_password', 'Field cannot be empty', true)
        }
        else {
            handleError('current_password', '', false)
        }
    }
    const valNpassword = (password) => {
        if (password === '' || password === undefined) {
            handleError('new_password', 'Field cannot be empty', true)
        }
        else if (password.length < 8) {
            handleError('new_password', 'Minimum 8 characters', true)
        }
        // else if (!validator.isStrongPassword(password)) {
        //     handleError('new_password', 'Minimum 8 characters, atleast 1 upper case, 1 lower case, 1 number and 1 special character', true)
        // } 
        else {
            handleError('new_password', '', false)
        }
    }
    const valCpassword = (c_password) => {
        if (c_password === '' || c_password === undefined) {
            handleError('c_password', 'Field cannot be empty', true)
        }
        else if (formData.new_password !== c_password) {
            handleError('c_password', 'Password and Confirm Password are not same', true)
        }
        else {
            handleError('c_password', '', false)
        }
    }

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setDialogContent('');
    };
    //#endregion

    return (
        <>
            <AlertDialog open={dialogOpen} setOpen={handleCloseDialog} title="Alert" message={dialogContent} buttonTitle="Ok" />
            <Typography
                variant='h5'
                sx={{
                    fontSize: {
                        md: 'large',
                        sm: 'medium',
                        xs: 'medium'
                    }
                }}
            >
                Change Password
            </Typography>
            <TextField
                label="Current Password"
                type="password"
                name='current_password'
                fullWidth
                margin="normal"
                required
                size='small'
                onChange={handleChange}
                helperText={errorData.current_password?.text || ''}
                error={errorData.current_password?.err}
            />

            <TextField
                label="New Password"
                type="password"
                name='new_password'
                fullWidth
                margin="normal"
                required
                size='small'
                onChange={handleChange}
                helperText={errorData.new_password?.text || ''}
                error={errorData.new_password?.err}
            />

            <TextField
                label="Confirm New Password"
                type="password"
                name='c_password'
                fullWidth
                margin="normal"
                required
                size='small'
                onChange={handleChange}
                helperText={errorData.c_password?.text || ''}
                error={errorData.c_password?.err}
            />


            <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
            >
                Update
            </Button>

        </>
    )
}

export default CustChangePassword