import { Box } from "@mui/material";
import { memo } from "react";

const InvoiceTabPanel = ({ children, value, index }) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
        >
            {value === index && (
                <Box
                    sx={{
                        px: 2,
                        minHeight: '250px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    {children}
                </Box>
            )}
        </div>
    );
};

export default memo(InvoiceTabPanel);