import React, { memo, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { Grid, Stack, Typography } from '@mui/material';
import CheckAndReturn from '../../../../utility/CheckAndReturn';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#4CB5F5',//theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const PurchaseBillItemTable = ({ purchaseBillItems }) => {
    const [purchaseAmount, setPurchaseAmount] = useState(0);

    useEffect(() => {
        let totalPurchaseAmount = 0;

        purchaseBillItems.forEach(row => {
            totalPurchaseAmount += Number(row.total_amount ?? 0);
        });

        setPurchaseAmount(totalPurchaseAmount);

        return () => {
            setPurchaseAmount(0);
        }
    }, [purchaseBillItems]);

    return (
        <>
            <TableContainer component={Paper}>
                <Box sx={{ overflowX: 'auto' }}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="left">S.No</StyledTableCell>
                                <StyledTableCell align="left">Name</StyledTableCell>
                                <StyledTableCell align="left">Code</StyledTableCell>
                                <StyledTableCell align="left">Quantity</StyledTableCell>
                                <StyledTableCell align="left">Rate</StyledTableCell>
                                <StyledTableCell align="left">Tax(%)</StyledTableCell>
                                <StyledTableCell align="left">Amount</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {purchaseBillItems.length > 0 ? (
                                purchaseBillItems.map((row, index) => (
                                    <StyledTableRow key={row.item_id}>
                                        <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            {row.item_name}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">{row.item_code}</StyledTableCell>
                                        <StyledTableCell align="left">{row.quantity}</StyledTableCell>
                                        <StyledTableCell align="left">₹{row.rate}</StyledTableCell>
                                        <StyledTableCell align="left">{row.tax_rate??0}</StyledTableCell>
                                        <StyledTableCell align="left">₹{row.total_amount}</StyledTableCell>
                                    </StyledTableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={12} sx={{ textAlign: 'center', color: 'red', fontSize: '16px' }}>
                                        <div>
                                            <p><b>No Item Found.</b></p>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Box>
            </TableContainer>

            <Grid container spacing={1} mt={2}>
                <Grid container item xs={11} md={11} justifyContent={'flex-end'}>
                    <Stack direction={'row'}>
                        <Typography variant='body1' sx={{ fontSize: '16px', fontWeight: 600 }}>
                            Purchase Amount:
                        </Typography>
                        <Typography variant='body1' sx={{ fontSize: '16px', fontWeight: 600, color: 'green' }}>
                            ₹{CheckAndReturn.roundToInteger(purchaseAmount ?? 0)}
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
        </>
    );
};

export default memo(PurchaseBillItemTable);
