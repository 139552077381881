import React, { memo, useState } from 'react'
import CardHeadMenu from '../../CardHeadMenu/CardHeadMenu';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

//FUNCTION
const OutstandingReportCard = () => {
    //#region code
    const navigate = useNavigate();
    const [isCreate, setIsCreate] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const goToPay = () => {
        navigate('/transaction/create-vouchers')
    };

    const handlePopup = () => {
        try {
            goToPay();
        }
        catch (e) {
            console.log(e);
        }
    }

    //#endregion
    return (
        <>
            <CardHeadMenu prefix={'Na'} suffix={'Na'} title={'Outstanding Report'} handlePopup={handlePopup} isEdit={isEdit} create={isCreate} showCreate={false} />
        </>
    )
}

export default memo(OutstandingReportCard);