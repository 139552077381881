import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Container } from "react-bootstrap";
import UnregisterPaymentCard from "../../Components/UnregisterPayment/UnregisterPaymentCard";
import UnregisterPaymentTable from "../../Components/UnregisterPayment/UnregisterPaymentTable";

//FUNCTION
const UnregisterPayment = () => {
    //#region code
    const open = useSelector((state) => state.sideExpand.open);
    const [editData, setEditData] = useState({});
    const [isChanged, setIsChanged] = useState(false);

    const handleEditData = (data) => {
        setEditData(data);
    }

    //#endregion

    return (
        <>
            <Container>
                <UnregisterPaymentCard editData={editData} setEditData={setEditData} setIsChanged={setIsChanged} isChanged={isChanged} />

                <UnregisterPaymentTable handleEditData={handleEditData} isChanged={isChanged} />
            </Container>
        </>
    )
}
export default UnregisterPayment;