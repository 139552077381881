import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Box,
  Tooltip,
  IconButton,
  Grid,
  FormControl,
  InputLabel,
  NativeSelect,
  TextField,
  InputAdornment,
  Typography,
  CircularProgress,
  Stack,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useSelector } from "react-redux";
import configServ from "../../services/config";
import useDeviceDetect from "../../Components/Detect/useDeviceDetect ";
import LoaderNew from "../Loader/LoaderNew";

//FUNCTION
const CityTable = ({ handleEdit, isChanged, pagination = true }) => {
  //#region code
  //const { company_id } = useSelector((state) => state.GlobalVariables);

  const rowsPerPageOptions = [10, 20, 50];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [searchBy, setSearchBy] = useState("city_name");
  const [cityList, setCityList] = useState([]);
  const [filterCityList, setFilterCityList] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const { isMobile } = useDeviceDetect();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const fetchCity = async () => {
    try {
      setLoading(true);
      const res = await configServ.getAllCities();
      if (res.status === 200) {
        setCityList(res.data);
        setFilterCityList(res.data);
        setCount(res.data.length);
      } else {
        setCityList([]);
        setFilterCityList([]);
        setCount(0);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCity();
  }, [isChanged]);

  const headers = ["City NAME", "STATE", "STATUS", "ACTION"];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

  const paginatedData = pagination
    ? filterCityList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : filterCityList;

  const headerCellStyle = {
    fontSize: 14, // Adjust the font size as needed
    fontWeight: "bold",
    backgroundColor: "#4CB5F5", // Set the header background color to blue
    color: "white",
  };

  const evenRowStyle = {
    background: "#f5f5f5",
  };

  const oddRowStyle = {
    background: "white",
  };

  const rowStyle = {
    "&:hover": {
      background: "#f1f9fe",
    },
  };

  const handleSearchBy = (event) => {
    try {
      setSearchBy(event.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (event) => {
    try {
      const search = event.target.value;
      let data = [];
      if (search === "") {
        data = cityList;
      } else if (searchBy === "city_name") {
        data = cityList.filter((x) =>
          x.city_name.toLowerCase().includes(search.toLowerCase())
        );
      }

      setFilterCityList(data);
      setCount(data.length);
    } catch (error) {
      console.log(error);
    }
  };
  //#endregion

  if (loading) {
    return (
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <LoaderNew />
      </Box>
    );
  } else {
    return (
      <>
        {/* search starts here */}
        <Grid
          container
          alignItems="flex-end"
          justifyContent="flex-start"
          style={{ padding: 10, paddingTop: 0 }}
          spacing={1}
          direction={"row"}
        >
          <Grid item xs={12} md={6} ml={4} justifyContent={"flex-start"}>
            <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
              City List:{count}
            </Typography>
          </Grid>
          <Grid item xs={4} md={2}>
            <Box sx={{ minWidth: 120, marginLeft: "10px" }}>
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  Search By
                </InputLabel>
                <NativeSelect
                  onChange={handleSearchBy}
                  inputProps={{
                    name: "searchBy",
                    id: "uncontrolled-native",
                  }}
                >
                  <option value={"name"}>City Name</option>
                </NativeSelect>
              </FormControl>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            mr={{ xs: 4, md: 4 }}
            justifyContent={"flex-end"}
          >
            <TextField
              id="outlined-basic"
              size="small"
              fullWidth
              placeholder="search"
              variant="outlined"
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ fill: "grey"  }} />
                  </InputAdornment>
                ),
              }}
             
            />
          </Grid>
        </Grid>
        {/* search ends here */}

        {/* Batch Table starts here */}
        {isMobile ? (
          <>
            {" "}
            <Grid container spacing={2} mt={1}>
              {paginatedData.length > 0 ? (
                paginatedData.map((row, rowIndex) => (
                  <Grid item xs={12} key={rowIndex}>
                    <Paper elevation={3} sx={{ p: 2 }}>
                      <Stack spacing={1}>
                        <Box sx={{ fontWeight: "bold" }}>
                        <strong>S.No. :</strong> {page * rowsPerPage + rowIndex + 1}. 
                        </Box>
                        <Box>
                          <strong>City Name:</strong> {row.name}
                        </Box>
                        <Box>
                          <strong>State:</strong> {row.state}
                        </Box>
                        <Box>
                          <strong>Status:</strong>{" "}
                          {row.status === 1 ? (
                            <CheckCircleIcon color="success" />
                          ) : (
                            <CancelIcon color="error" />
                          )}
                        </Box>
                        <Box>
                          <Tooltip title="Edit" arrow>
                            <IconButton
                              onClick={() => {
                                handleEdit(row);
                                scrollToTop();
                              }}
                            >
                              <EditIcon color="primary" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Stack>
                    </Paper>
                  </Grid>
                ))
              ) : (
                <Grid item xs={12} textAlign="center">
                  <Box sx={{ color: "red", fontWeight: 600 }}>
                    No data found.
                  </Box>
                </Grid>
              )}
              {pagination && (
                <Grid item xs={12}>
                  <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Grid>
              )}
            </Grid>
          </>
        ) : (
          <>
            <TableContainer component={Paper} sx={{ px: 5 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell sx={headerCellStyle}>S.No.</TableCell>
                    {headers.map((header, index) => (
                      <TableCell key={index} style={headerCellStyle}>
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedData.map((row, rowIndex) => (
                    <TableRow
                      key={rowIndex}
                      sx={{
                        ...rowStyle,
                        ...(rowIndex % 2 === 0 ? evenRowStyle : oddRowStyle),
                      }}
                    >
                      <TableCell>{page * rowsPerPage + rowIndex + 1}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.state}</TableCell>
                      <TableCell>
                        {row.status === 1 ? (
                          <CheckCircleIcon color="success" />
                        ) : (
                          <CancelIcon color="error" />
                        )}
                      </TableCell>
                      <TableCell>
                        <div>
                          <Tooltip title="Edit" arrow>
                            <IconButton
                              onClick={() => {
                                handleEdit(row);
                                scrollToTop();
                              }}
                            >
                              <EditIcon color="primary" />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 1 * emptyRows }}>
                      <TableCell colSpan={headers.length + 2} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              {pagination && (
                <TablePagination
                  rowsPerPageOptions={rowsPerPageOptions}
                  component="div"
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </TableContainer>
          </>
        )}

        {/* Batch Table ends here */}
      </>
    );
  }
};

export default CityTable;
