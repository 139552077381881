import React from "react";
import {
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";

const headerCellStyle = {
  fontSize: 14, // Adjust the font size as needed
  fontWeight: "bold",
  backgroundColor: "#4CB5F5", // Set the header background color to blue
  color: "white",
};

const columns = [
  { field: "id", headerName: "ID", width: 70,  },
  { field: "date", headerName: "Dates", width: 130 },
  { field: "timeOfDispatch", headerName: "Time of Dispatch", width: 180 },
  { field: "remarks", headerName: "Remarks", width: 250 },
];

// Sample static data
const rows = [
  {
    id: 1,
    date: "2024-08-01",
    timeOfDispatch: "10:00 AM",
    remarks: "First dispatch",
  },
  {
    id: 2,
    date: "2024-08-02",
    timeOfDispatch: "12:00 PM",
    remarks: "Second dispatch",
  },
  {
    id: 3,
    date: "2024-08-03",
    timeOfDispatch: "02:00 PM",
    remarks: "Third dispatch",
  },
  {
    id: 4,
    date: "2024-08-04",
    timeOfDispatch: "03:00 PM",
    remarks: "Fourth dispatch",
  },
  {
    id: 5,
    date: "2024-08-05",
    timeOfDispatch: "04:00 PM",
    remarks: "Fifth dispatch",
  },
  {
    id: 6,
    date: "2024-08-06",
    timeOfDispatch: "05:00 PM",
    remarks: "Sixth dispatch",
  },
  {
    id: 7,
    date: "2024-08-07",
    timeOfDispatch: "06:00 PM",
    remarks: "Seventh dispatch",
  },
  {
    id: 8,
    date: "2024-08-08",
    timeOfDispatch: "07:00 PM",
    remarks: "Eighth dispatch",
  },
  {
    id: 9,
    date: "2024-08-09",
    timeOfDispatch: "08:00 PM",
    remarks: "Ninth dispatch",
  },
];

const paginationModel = { page: 0, pageSize: 5 };

const SubscriptionDispatch = () => {
  return (
    <>
      <Grid
        container
        item
        xs={12}
        md={12}
        justifyContent={"center"}
        sx={{ mt: 1 }}
      >
        <Typography
          sx={{ fontSize: "22px", fontWeight: "bold", color: "#1e61b4" }}
        >
          Dispatch Detail
        </Typography>
      </Grid>

      {/* Select Customer */}
      <Grid
        container
        item
        xs={12}
        justifyContent="center"
        spacing={2}
        sx={{ mt: 2 }}
      >
        {/* Select Customer */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="select-customer-label">Select Customer</InputLabel>
            <Select
              labelId="select-customer-label"
              id="select-customer"
              label="Select Customer"
            >
              {/* Add MenuItems here */}
              <MenuItem value={1}>Customer 1</MenuItem>
              <MenuItem value={2}>Customer 2</MenuItem>
              <MenuItem value={3}>Customer 3</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* Subscription Ids */}
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="subscription-ids"
            label="Subscription Ids"
            variant="outlined"
          />
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
        md={12}
        justifyContent="flex-start" // Aligns content to the start
        sx={{ mt: 1 }}
      >
        <Typography
          sx={{ fontSize: "22px", fontWeight: "bold", color: "#1e61b4" }}
        >
          List of dates of subscription
        </Typography>
      </Grid>

      {/* <TableContainer component={Paper} sx={{ py: 1 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>S.No.</TableCell>
              {headers.map((header, index) => (
                <TableCell key={index} sx={{ fontWeight: "bold" }}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {itemList.length > 0 ? (
              itemList.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  <TableCell>{rowIndex + 1}</TableCell>
                  <TableCell>{row.date}</TableCell>
                  <TableCell>{row.timeOfDispatch}</TableCell>
                  <TableCell>{row.remarks}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={headers.length + 1}
                  sx={{ textAlign: "center" }}
                >
                  <Box sx={{ color: "red", fontWeight: 600 }}>
                    No data found.
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer> */}
       <Paper sx={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{ pagination: { paginationModel } }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
        sx={{ border: 0,
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#4CB5F5', // Set the header background color to blue
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            color: 'white', // Set the header text color to white
            fontWeight: 'bold', // Set the font weight to bold
            fontSize: 14, // Adjust the font size as needed
          }, }}
      />
    </Paper>
    </>
  );
};

export default SubscriptionDispatch;
