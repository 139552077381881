import React, { memo } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { CircularProgress, Grid, Stack, Typography } from '@mui/material';
import CheckAndReturn from '../../utility/CheckAndReturn';
import LoaderNew from '../Loader/LoaderNew';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#4CB5F5',//theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        paddingTop: 1,
        paddingBottom: 1,
        marginTop: 0,
        marginBottom: 0,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

//FUNCTION
const ItemLedgerTable = ({ itemLedger, selectedItem, itemLedgerLoading }) => {

    return (
        <>
            <Grid container alignItems={'center'} spacing={2} mb={1}>
                <Grid container item xs={12} md={4} justifyContent={{ xs: 'center', md: 'flex-start' }}>
                    <Stack direction={'row'}>
                        <Typography variant='body1' fontWeight={600}>
                            Opening Quantity:
                        </Typography>
                        <Typography variant='body1' ml={2}>
                            {itemLedgerLoading === true ? <LoaderNew /> : selectedItem.openingquantity}
                        </Typography>
                    </Stack>
                </Grid>
                <Grid container item xs={12} md={4} justifyContent={{ xs: 'center', md: 'center' }}>
                    <Box flex={1} textAlign="center">
                        <Typography variant="h5" color="#1e61b4" fontWeight={700}>
                            {
                                itemLedgerLoading === true ?
                                    <LoaderNew />
                                    :
                                    selectedItem.item_name
                            }
                        </Typography>
                    </Box>
                </Grid>
                <Grid container item xs={12} md={4} justifyContent={{ xs: 'center', md: 'flex-end' }}>
                    <Stack direction={'row'}>
                        <Typography variant='body1' fontWeight={600}>
                            Actual Quantity:
                        </Typography>
                        <Typography variant='body1' ml={2}>
                            {itemLedgerLoading === true ? <LoaderNew /> : selectedItem.actualquantity}
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>

            <TableContainer component={Paper}>
                <Box sx={{ overflowX: 'auto' }}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="left">S.No</StyledTableCell>
                                <StyledTableCell align="left">Type</StyledTableCell>
                                <StyledTableCell align="left">Inv No/Bill No</StyledTableCell>
                                <StyledTableCell align="left">Quantity</StyledTableCell>
                                <StyledTableCell align="left">Rate</StyledTableCell>
                                <StyledTableCell align="left">Amount</StyledTableCell>
                                <StyledTableCell align="left">Date</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        {
                            itemLedgerLoading === true ?
                                <TableRow>
                                    <TableCell colSpan={12} sx={{ textAlign: 'center' }}>
                                        <Box>
                                            <LoaderNew />
                                        </Box>
                                    </TableCell>
                                </TableRow>
                                :
                                <TableBody>
                                    {itemLedger.length > 0 ? (
                                        itemLedger.map((row, index) => (
                                            <StyledTableRow key={row.item_id}>
                                                <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                                <StyledTableCell align="left">{row.type}</StyledTableCell>
                                                <StyledTableCell align="left">{row.entity_number}</StyledTableCell>
                                                <StyledTableCell align="left">{row.quantity}</StyledTableCell>
                                                <StyledTableCell align="left">{row.rate}</StyledTableCell>
                                                <StyledTableCell align="left">{CheckAndReturn.roundToInteger(row.amount)}</StyledTableCell>
                                                <StyledTableCell align="left">{CheckAndReturn.transformToDate3(row.date)}</StyledTableCell>
                                            </StyledTableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={12} sx={{ textAlign: 'center', color: 'red', fontSize: '16px' }}>
                                                <div>
                                                    <p><b>No Item Found.</b></p>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                        }
                    </Table>
                </Box>
            </TableContainer>
        </>
    );
};

export default memo(ItemLedgerTable);
