import React from "react";
import GstInvoiceReport from "./GstInvoiceReport";


const GstTransactionReport = () => {
  return (
    <div>
      {/*  */}

      <GstInvoiceReport showActionButton={true} />
    </div>
  );
};

export default GstTransactionReport;
