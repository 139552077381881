import React, { useState, useEffect, useRef } from "react";
import { Fab, Zoom, Tooltip, Typography, Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import BorderClearIcon from "@mui/icons-material/BorderClear";
import { Link, useLocation } from "react-router-dom";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { rSetShowFab } from "../../redux/reducers/GlobalVariables";
import { useDispatch } from "react-redux";

const FloatingActionButton = () => {
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const [position, setPosition] = useState({ x: 25, y: 25 }); // Track position of the FAB
  const [dragging, setDragging] = useState(false); // State to track dragging
  
  const dispatch = useDispatch(); // Initialize dispatch
  const location = useLocation(); // Get current location

  const fabRef = useRef(null); // Reference for the FAB button

  const handleFabClick = () => {
    if (!dragging) {
      // Only toggle submenu if not dragging
      setOpenSubMenu(!openSubMenu);
    }
    setDragging(false); // Reset dragging state after click
  };

  useEffect(() => {
    setOpenSubMenu(false);
    dispatch(rSetShowFab(true)); // Dispatch action when location changes
  }, [location, dispatch]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (fabRef.current && !fabRef.current.contains(event.target)) {
        setOpenSubMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Handle dragging the FAB
  const handleMouseDown = (e) => {
    e.preventDefault();
    setDragging(true);
    const startX = e.clientX || e.touches[0].clientX;
    const startY = e.clientY || e.touches[0].clientY;
    const initX = position.x;
    const initY = position.y;

    const handleMouseMove = (moveEvent) => {
      const currentX = moveEvent.clientX || moveEvent.touches[0].clientX;
      const currentY = moveEvent.clientY || moveEvent.touches[0].clientY;
      
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      
      // Calculate new position and constrain within screen bounds
      let newX = initX + (currentX - startX);
      let newY = initY + (startY - currentY); // Flip the Y-axis calculation

      // Constrain the position within the viewport
      newX = Math.max(0, Math.min(newX, windowWidth - 60));  // Assuming FAB size of 60px
      newY = Math.max(0, Math.min(newY, windowHeight - 60)); // Assuming FAB size of 60px

      setPosition({
        x: newX,
        y: newY,
      });
    };

    const handleMouseUp = () => {
      setDragging(false);
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("touchmove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("touchend", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("touchmove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("touchend", handleMouseUp);
  };

  return (
    <Box
      ref={fabRef}
      sx={{ ...fabContainerStyle, left: position.x, bottom: position.y }} // Dynamic position
      onMouseDown={handleMouseDown}
      onTouchStart={handleMouseDown}
    >
      {/* Submenu that appears on clicking FAB */}
      <Zoom in={openSubMenu}>
        <Box sx={subMenuStyle}>
          <Tooltip title="Sales Order" arrow placement="left">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Link to="/sales-order" style={fabLinkStyle}>
                <Fab color="primary" size="small" sx={fabButtonStyle}>
                  <BorderClearIcon sx={iconStyle} />
                </Fab>
              </Link>
              <Typography variant="caption" sx={{ mt: 0.5 }}>
                Sales Order
              </Typography>
            </Box>
          </Tooltip>
          <Tooltip title="Invoice" arrow placement="left">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Link to="/invoice" style={fabLinkStyle}>
                <Fab color="primary" size="small" sx={fabButtonStyle}>
                  <InsertDriveFileIcon sx={iconStyle} />
                </Fab>
              </Link>
              <Typography variant="caption" sx={{ mt: 0.5 }}>
                Invoice
              </Typography>
            </Box>
          </Tooltip>

          <Tooltip title="Stock Update" arrow placement="left">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Link to="/transaction/stockUpdate" style={fabLinkStyle}>
                <Fab color="primary" size="small" sx={fabButtonStyle}>
                  <ShowChartIcon sx={iconStyle} />
                </Fab>
              </Link>
              <Typography variant="caption" sx={{ mt: 0.5 }}>
                Stock Update
              </Typography>
            </Box>
          </Tooltip>
          <Tooltip title="Cash Voucher" arrow placement="left">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Link to="/transaction/create-vouchers" style={fabLinkStyle}>
                <Fab color="primary" size="small" sx={fabButtonStyle}>
                  <MonetizationOnIcon sx={iconStyle} />
                </Fab>
              </Link>
              <Typography variant="caption" sx={{ mt: 0.5 }}>
                Cash Voucher
              </Typography>
            </Box>
          </Tooltip>
        </Box>
      </Zoom>

      {/* Main FAB button */}
      <Fab
        color="secondary"
        onClick={handleFabClick}
        sx={{ ...fabStyle, ...fabButtonStyle }}
      >
        {openSubMenu ? (
          <CloseIcon sx={iconStyle} />
        ) : (
          <AddIcon sx={iconStyle} />
        )}
      </Fab>
    </Box>
  );
};

// Enhanced styles
const fabContainerStyle = {
  position: "fixed",
  display: "flex",
  flexDirection: "column",
  alignItems: "center", // Center align the submenu with the main button
  zIndex: 1,
};

const subMenuStyle = {
  display: "flex",
  flexDirection: "column",
  marginBottom: 3,
  alignItems: "center", // Center submenu items
  gap: 1, // More spacing for a cleaner look
  "& > *": {
    transition: "transform 0.3s ease",
    "&:hover": {
      transform: "scale(1.1)", // Slightly enlarge on hover
    },
  },
};

const fabStyle = {
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.12)", // Softer shadow
  transition: "background-color 0.3s ease", // Smoother transition
};

const fabButtonStyle = {
  borderRadius: "50%", // Ensure all buttons are fully rounded
};

const fabLinkStyle = {
  textDecoration: "none",
};

const iconStyle = {
  fontSize: "1.2rem", // Slightly smaller icons for a professional look
};

export default FloatingActionButton;
