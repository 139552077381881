import React from 'react';
import { Box, Stack, IconButton, Button, DialogTitle } from '@mui/material';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import PrintIcon from '@mui/icons-material/Print';
import DownloadIcon from '@mui/icons-material/Download';

const PopupDialogTitle = ({ handleDownload, handlePrint, handleClose, show = true }) => {
    return (
        <>
            <DialogTitle id="alert-dialog-title" sx={{ pb: 0 }}>
                <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                    <Box flex={1} textAlign="flex-start">
                        {
                            show &&
                            <Stack direction={'row'} spacing={2}>
                                <Button
                                    variant='contained' onClick={handleDownload}
                                    startIcon={<DownloadIcon sx={{ ml: 2 }} />} title='Download'
                                    sx={{
                                        backgroundColor: '#4bcf6b',
                                        color: 'white',
                                        '&:hover': {
                                            backgroundColor: '#00b92f',
                                            color: 'white',
                                        }
                                    }}
                                />
                                <Button
                                    variant='contained' onClick={handlePrint}
                                    startIcon={<PrintIcon sx={{ ml: 2 }} />} title='Print'
                                    sx={{
                                        backgroundColor: '#349af1',
                                        color: 'white',
                                        '&:hover': {
                                            backgroundColor: '#0868F8',
                                            color: 'white',
                                        }
                                    }}
                                />
                            </Stack>
                        }
                    </Box>
                    <Box position="absolute" right={16} top={6}>
                        <IconButton onClick={handleClose}>
                            <DisabledByDefaultIcon sx={{ fontSize: 32 }} color="error" />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
        </>
    )
}

export default PopupDialogTitle
