
import React from 'react'
import { Box, CircularProgress, Grid } from '@mui/material'
import LoaderNew from './LoaderNew'

function Loader() {
  return (
    <>

      <Box sx={{
        position: 'fixed',
        height: '100%',
        width: '100%',
        zIndex: 9999,
        backgroundColor: '#00000099',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      >
        <Grid container item xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            //minHeight: "200px", // Optional: set a minimum height for better centering
          }}
        >
          <LoaderNew />
        </Grid>
      </Box>

    </>
  )
}

export default Loader