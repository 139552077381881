import React, { useState, useEffect } from "react";
import UnitsTable from "../Card/MaterEntryCards/UnitsTable";

import { Stack, Paper, Button, Divider, Typography, Tooltip, IconButton, Grid, InputLabel, Select, Checkbox, TextField, FormControl, MenuItem } from "@mui/material";
import { motion } from 'framer-motion'
import configServ from "../../services/config";
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import AlertDialog from "../AlertDialog/AlertDialog";
import SaveIcon from '@mui/icons-material/Save';
import UpdateIcon from '@mui/icons-material/Update';
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import CardHeadMenu from "../Card/CardHeadMenu/CardHeadMenu";
import { triggerLoader } from "../../redux/reducers/LoaderTrigger";

//FUNCTION
const Unit = () => {
    //#region code
    const dispatch = useDispatch();
    const { user_id, company_id } = useSelector((state) => state.GlobalVariables);
    const formDataInitial = {
        typeofunits: "Simple",
        status: true,
        isPrimary: false
    };
    const [formData, setFormData] = useState(formDataInitial);
    const [validationErrors, setValidationErrors] = useState({});
    const [simpleUnitList, setSimpleUnitList] = useState([])
    const [isChanged, setIsChanged] = useState(false)
    const [isEdit, setIsEdit] = useState(false);
    const [isCreate, setIsCreate] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [title, setTitle] = useState('Alert');
    const [dialogContent, setDialogContent] = useState('');
    const [buttonType, setButtonType] = useState(null);

    const handleOpenDialog = (heading, content, type) => {
        setTitle(heading);
        setDialogContent(content);
        setButtonType(type);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setTitle('Alert');
        setDialogContent('');
        setButtonType(null);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        //Update form data
        setFormData((state) => ({
            ...state,
            [name]: value
        }));

        // Reset the validation error when the user starts typing
        if (validationErrors[name]) {
            setValidationErrors((prevValidationError) => ({
                ...prevValidationError,
                [name]: '',
            }));
        }

        if ((name === 'typeofunits') && (name !== 'Compound')) {
            setFormData((state) => ({
                ...state,
                derive_unit: '',
                conversion_factor: ''
            }))
        }
        if ((name === 'typeofunits') && (value !== 'Simple')) {
            setFormData((state) => ({
                ...state,
                derive_unit: null,
                conversion_factor: null
            }))
        }
    }
    const handleChangeCheckbox = (e) => {
        const { name, checked } = e.target
        setFormData((state) => ({
            ...state,
            [name]: checked
        }))
    }

    const handleFormValidation = () => {
        const errors = {}
        if (!formData.units_name) {
            errors.units_name = "required";
        }
        setValidationErrors(errors);
        return Object.values(errors).every((error) => error === "");
    }

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();

            dispatch(triggerLoader());

            const error = handleFormValidation();
            if (error === false) {
                return false;
            }
            const dataToSend = {
                ...formData,
                company_id: company_id
            }
            if (!isEdit) {
                const res = await configServ.addUnit(dataToSend);
                if (res.status === 200) {
                    setIsChanged(!isChanged);
                    clear();
                    handleOpenDialog('Success', res.message, 'success');
                }
                else if (res.status === 400) {
                    handleOpenDialog('Warning', res.message || "Failed to create unit.", 'warning');
                }
                else {
                    handleOpenDialog('Error', res.message || "Failed to add unit.", 'error');
                }
            }
            else {
                const res = await configServ.editUnit(dataToSend);
                if (res.status === 200) {
                    setIsChanged(!isChanged);
                    clear();
                    handleOpenDialog('Success', res.message, 'success');
                }
                else {
                    handleOpenDialog('Error', res.message || "Failed to update unit.", 'error');
                }
            }
        }
        catch (err) {
            console.log(err);
            handleOpenDialog('Error', "An unexpected error has occurred.", 'error');
        }
        finally {
            dispatch(triggerLoader());
        }
    }

    const fetchSimpleUnitList = async () => {
        try {
            const result = await configServ.getSimpleUnit({ "company_id": company_id });
            setSimpleUnitList(result)
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchSimpleUnitList();
    }, [isChanged])

    const handleEdit = (data) => {
        setFormData(data);
        setIsEdit(true);
        setIsCreate(true);
        setFormData((prev) => ({
            ...prev,
            status: true
        }));
    }

    const handlePopup = () => {
        setIsCreate(!isCreate);
    }

    const clear = () => {
        setFormData(formDataInitial);
        setValidationErrors({});
        setIsEdit(false)
    }
    //#endregion

    return (
        <>
            <AlertDialog
                open={dialogOpen}
                setOpen={handleCloseDialog}
                title={title}
                message={dialogContent}
                buttonTitle="Ok"
                buttonType={buttonType}
            />
            <CardHeadMenu
                prefix={"Na"}
                suffix={"Na"}
                title={"UNITS"}
                handlePopup={handlePopup}
                isEdit={isEdit}
                create={isCreate}
            />
            {isCreate &&
                <motion.div style={{ width: "100%", backgroundColor: "#e3f3fc", padding: 20, borderRadius: '10px', border: 'none' }}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.1, delay: 0.1 }}
                >
                    <Grid container alignItems="flex-end" justifyContent="flex-start" spacing={1}>
                        <Grid item xs={12} md={12} justifyContent={'flex-start'}>
                            <Typography sx={{ fontSize: '16px', fontWeight: 'bolder' }}>Unit Detail</Typography>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <FormControl size="small" fullWidth sx={{ backgroundColor: 'white' }}>
                                <InputLabel>Type of Units*</InputLabel>
                                <Select size="small" name="typeofunits" label="Type of Units*" value={formData.typeofunits || ''} onChange={handleChange}>
                                    <MenuItem value="Simple">Simple</MenuItem>
                                    <MenuItem value="Compound">Compound</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <TextField
                                size="small"
                                sx={{ backgroundColor: 'white' }}
                                label="Unit Name*"
                                name="units_name"
                                value={formData.units_name || ''}
                                onChange={handleChange}
                                error={validationErrors.units_name}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={2} hidden={formData.typeofunits === 'Compound' ? false : true}>
                            <FormControl size="small" fullWidth sx={{ backgroundColor: 'white' }}>
                                <InputLabel>Derive Unit</InputLabel>
                                <Select label='Derive Unit' size="small" name="derive_unit" value={formData.derive_unit || ''} onChange={handleChange}>
                                    <MenuItem value=''>Select</MenuItem>
                                    {simpleUnitList.map((item, index) => (
                                        <MenuItem key={index} value={item.units_name}>{item.units_name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={2} hidden={formData.typeofunits === 'Compound' ? false : true}>
                            <TextField
                                size="small"
                                sx={{ backgroundColor: 'white' }}
                                label="Conversion factor"
                                name="conversionfactor"
                                value={formData.conversionfactor || ''}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                size="small"
                                sx={{ backgroundColor: 'white' }}
                                label="Description"
                                name="description"
                                value={formData.description || ''}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Stack direction='row' spacing={1}>
                                <Form.Label style={{ cursor: 'pointer', marginTop: 11, marginRight: 10 }}>
                                    <Checkbox
                                        onChange={handleChangeCheckbox}
                                        checked={Boolean(formData.status) || false}
                                        name="status"
                                    />
                                    Status</Form.Label>

                                <Form.Label style={{ cursor: 'pointer', marginTop: 11, marginRight: 10 }}>
                                    <Checkbox
                                        name='isPrimary'
                                        checked={Boolean(formData.isPrimary) || false}
                                        onChange={handleChangeCheckbox}
                                    />
                                    isPrimary</Form.Label>
                            </Stack>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} justifyContent="space-between" px={2} py={1}>
                        <Grid
                            item
                            container
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={2}
                        >
                            <Grid item>
                                <Button
                                    onClick={clear}
                                    variant="contained"
                                    color='secondary'
                                    startIcon={<RotateLeftIcon />}
                                >
                                    Reset
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={handleSubmit}
                                    autoFocus
                                    variant="contained"
                                    color={isEdit ? 'success' : 'primary'}
                                    startIcon={isEdit ? <UpdateIcon /> : <SaveIcon />}
                                >
                                    {isEdit ? "Update" : "Save"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* <Grid container alignItems="flex-end" justifyContent="flex-start" spacing={1}>
                            <Grid item xs={12} md={2}>
                                <Form.Label>Type of Units*</Form.Label>
                                <InputLabel>Type of Units*</InputLabel>
                                <Select name="typeofunits" value={formData.typeofunits || ''} onChange={handleChange}>
                                    <option value="Simple">Simple</option>
                                    <option value="Compound">Compound</option>
                                </Select>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Form.Label>Units Name*</Form.Label>
                                <Form.Control name="units_name" value={formData.units_name || ''} onChange={handleChange} type="" placeholder={`Enter unit`} />
                            </Grid>
                            <Grid item xs={12} md={2} hidden={formData.typeofunits === 'Compound' ? false : true}>
                                <Form.Label>Derive Unit</Form.Label>
                                <Form.Select name="derive_unit" value={formData.derive_unit || ''} onChange={handleChange}>
                                    <option value=''>Select</option>
                                    {
                                        simpleUnitList.map((item, index) => (
                                            <option key={index} value={item.units_name}>{item.units_name}</option>
                                        ))
                                    }
                                </Form.Select>
                            </Grid>
                            <Grid item xs={12} md={2} hidden={formData.typeofunits === 'Compound' ? false : true}>
                                <Form.Label>Conversion factor</Form.Label>
                                <Form.Control name="conversionfactor" value={formData.conversionfactor || ''} onChange={handleChange} type="" placeholder={`Ex-12pcs`} />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Form.Label>Description</Form.Label>
                                <Form.Control name="description" value={formData.description || ''} onChange={handleChange} type="" placeholder='Enter Description' />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Form.Label style={{ cursor: 'pointer', marginTop: 11 }}> <Form.Check onChange={handleChangeCheckbox} checked={Boolean(formData.status) || false} name="status" inline aria-label="option 1" size={100} />Status</Form.Label>
                                
                                <Form.Label style={{ cursor: 'pointer', marginTop: 11, }}> <Form.Check name='isPrimary' checked={Boolean(formData.isPrimary) || false} onChange={handleChangeCheckbox} inline aria-label="option 2" size={100} />isPrimary</Form.Label>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Stack direction='row' spacing={1}>
                                <Button variant="primary" onClick={handleSubmit}>{isEdit?'Update':'Save'}</Button>
                                {
                                isEdit && (<Tooltip title='Reset'>
                                    <IconButton onClick={clear} >
                                        <RotateLeftIcon />
                                    </IconButton>        
                                </Tooltip>)
                                }   
                            </Stack>
                            </Grid>
                        </Grid> */}
                </motion.div>
            }

            <UnitsTable handleEdit={handleEdit} isChanged={isChanged} />
        </>
    )
}
export default Unit;