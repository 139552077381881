import React, { memo, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import CheckAndReturn from "../../../utility/CheckAndReturn";
import { Grid, Stack, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import configServ from "../../../services/config";
import { triggerLoader } from "../../../redux/reducers/LoaderTrigger";
import TablePagination from "@mui/material/TablePagination";
import CircularProgress from "@mui/material/CircularProgress";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#4CB5F5", //theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

//FUNCTION
const OutstandingTable = () => {
  //#region code
  const [outstandingCustomerData, setOutstandingCustomerData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { company_id, user_id } = useSelector((state) => state.GlobalVariables);
  const dispatch = useDispatch();

  const handleLedger = async () => {
    try {
      setLoading(true); // Start loader
      dispatch(triggerLoader());
      const dataToSend = {
        customer_id: user_id,
        company_id: company_id,
      };
      const res = await configServ.getCustomerLedger(dataToSend);
      if (res.status === 200) {
        setOutstandingCustomerData(res.data);
      } else {
        setOutstandingCustomerData(null);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false); // Start loader
      dispatch(triggerLoader());
    }
  };
  useEffect(() => {
    handleLedger();
  }, []);

  const LinkTableCell = styled(StyledTableCell)({
    cursor: "pointer",
    textDecoration: "none",
    "&:hover": {
      color: "darkblue",
      textDecoration: "underline",
    },
  });
  // State for pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Paginate the data with a fallback to an empty array
  const paginatedData = (outstandingCustomerData ?? []).slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  //#endregion

  return (
    <>
      <Box sx={{ textAlign: "center", marginBottom: 2 }}>
        <Typography variant="h5" fontWeight="bold">
          Outstanding Report
        </Typography>
      </Box>
      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        {/* Table view for medium and larger screens */}
        <Box sx={{ display: { xs: "none", md: "block" }, overflowX: "auto" }}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            {/* Table Header - Always visible */}
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">S.No</StyledTableCell>
                <StyledTableCell align="left">Number</StyledTableCell>
                <StyledTableCell align="left">Date</StyledTableCell>
                <StyledTableCell align="left">Type</StyledTableCell>
                <StyledTableCell align="left">Amount</StyledTableCell>
              </TableRow>
            </TableHead>

            {/* Table Body */}
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell
                    colSpan={5}
                    sx={{ textAlign: "center", padding: 4 }}
                  >
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : paginatedData && paginatedData.length > 0 ? (
                paginatedData.map((data, index) => (
                  <TableRow key={index}>
                    <StyledTableCell align="left">{page * rowsPerPage + index + 1}</StyledTableCell>
                    <StyledTableCell align="left">
                      {data.entity_number}
                    </StyledTableCell>
                    <StyledTableCell align="left">{data.date}</StyledTableCell>
                    <StyledTableCell align="left">{data.type}</StyledTableCell>
                    <StyledTableCell align="left">
                      {data.amount}
                    </StyledTableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={5}
                    sx={{
                      textAlign: "center",
                      color: "red",
                      fontSize: "16px",
                    }}
                  >
                    No Data Found.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>

        {/* Card view for small screens */}
        <Box sx={{ display: { xs: "block", md: "none" }, padding: 2 }}>
          {loading ? (
            // Loader displayed when data is loading
            <Box
              sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}
            >
              <CircularProgress />
            </Box>
          ) : paginatedData && paginatedData.length > 0 ? (
            // Render data in card format if available
            paginatedData.map((data, index) => (
              <Paper
                key={index}
                elevation={3}
                sx={{
                  marginBottom: 2,
                  padding: 2,
                  borderRadius: 2,
                  boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                }}
              >
                <Typography variant="h6" gutterBottom>
                  S.No: {index + 1}
                </Typography>
                <Typography variant="body2">
                  <b>Number:</b> {data.entity_number}
                </Typography>
                <Typography variant="body2">
                  <b>Date:</b> {data.date}
                </Typography>
                <Typography variant="body2">
                  <b>Type:</b> {data.type}
                </Typography>
                <Typography variant="body2">
                  <b>Amount:</b> {data.amount}
                </Typography>
              </Paper>
            ))
          ) : (
            // No data available message
            <Typography
              variant="h6"
              align="center"
              sx={{ color: "red", marginTop: 2 }}
            >
              No Data Found.
            </Typography>
          )}
        </Box>
        <TablePagination
            component="div"
            count={outstandingCustomerData?.length || 0}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 15]}
          />
      </TableContainer>
    </>
  );
};

export default memo(OutstandingTable);
