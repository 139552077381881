import React, { useState, useEffect, memo } from 'react';
import {
    TextField,
    IconButton,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Grid,
    Stack,
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Calculation from '../../utility/Calculations';
import CheckAndReturn from '../../utility/CheckAndReturn';

const initialRow = { customer_id: '', inv_id: '', invoice_number: '', total_amount: '', invoice_amount: '', transaction_date: Calculation.GetCurrentDate(), transaction_id: '', amount: '' };

//FUNCTION
const AddCustomerVoucherData = ({ invoices, customer_id, voucherDetails, setError, payment_mode, invoice_no = null }) => {
    //#region code
    const [rows, setRows] = useState([{ ...initialRow }]);

    useEffect(() => {
        if (invoice_no) {
            const selectedInvoice = invoices.find(invoice => invoice.invoice_number === invoice_no);
            const initialRowWithInvoice = {
                ...initialRow,
                invoice_number: invoice_no,
                inv_id: selectedInvoice ? selectedInvoice.id : '',
                total_amount: selectedInvoice ? selectedInvoice.total_amount : '',
                invoice_amount: selectedInvoice ? selectedInvoice.remaining_amount : '',
                amount: selectedInvoice ? selectedInvoice.remaining_amount : '',
            };
            setRows([initialRowWithInvoice]);
        }
    }, [invoice_no, invoices]);

    useEffect(() => {
        validateRows();
    }, [rows]);

    useEffect(() => {
        voucherDetails(rows);
    }, [rows, voucherDetails]);

    const handleAddRow = () => {
        if (invoice_no) {
            const selectedInvoice = invoices.find(invoice => invoice.invoice_number === invoice_no);
            const newRow = {
                ...initialRow,
                invoice_number: invoice_no,
                inv_id: selectedInvoice ? selectedInvoice.id : '',
                total_amount: selectedInvoice ? selectedInvoice.total_amount : '',
                invoice_amount: selectedInvoice ? selectedInvoice.remaining_amount : '',
                amount: selectedInvoice ? selectedInvoice.remaining_amount : ''
            };
            setRows([...rows, newRow]);
        }
        else {
            setRows([...rows, { ...initialRow }]);
        }
    };

    const handleDeleteRow = (index) => {
        const newRows = rows.filter((_, i) => i !== index);
        setRows(newRows);
    };

    const handleChange = (index, field, value) => {
        const newRows = [...rows];
        newRows[index][field] = value;
        newRows[index].customer_id = customer_id ?? '';

        if (field === 'invoice_number') {
            const selectedInvoice = invoices.find(invoice => invoice.invoice_number === value);
            newRows[index].inv_id = selectedInvoice ? selectedInvoice.id : '';
            newRows[index].total_amount = selectedInvoice ? CheckAndReturn.roundToInteger(selectedInvoice.total_amount ?? 0) : '';
            newRows[index].invoice_amount = selectedInvoice ? CheckAndReturn.roundToInteger(selectedInvoice.remaining_amount ?? 0) : '';
        }
        else if (field === 'amount') {
            const invoiceAmount = parseFloat(newRows[index].invoice_amount);
            const enteredAmount = parseFloat(value);
            if (enteredAmount > invoiceAmount) {
                newRows[index].amount = invoiceAmount;
                alert('Amount cannot exceed the invoice amount.');
            } 
            else {
                newRows[index].amount = enteredAmount;
            }
        }
        setRows(newRows);
    };

    const validateRows = () => {
        const hasError = rows.some(row => !row.amount);
        setError(hasError);
    };
    //#endregion

    return (
        <Grid container spacing={2} padding={2}>
            {rows.map((row, index) => (
                <Grid container item spacing={2} key={index}>
                    <Grid item xs={12} sm={6} md={2}>
                        <FormControl fullWidth size="small">
                            <InputLabel>Inv No</InputLabel>
                            <Select
                                size="small"
                                sx={{ background: 'white' }}
                                label="Inv No"
                                value={row.invoice_number}
                                onChange={(e) => handleChange(index, 'invoice_number', e.target.value)}
                            >
                                <MenuItem key={-1} value={''}>--select--</MenuItem>
                                {invoices.map((invoice, i) => (
                                    <MenuItem key={invoice.invoice_number ?? i} value={invoice.invoice_number ?? ''}>
                                        {invoice.invoice_number ?? ''}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <TextField
                            size="small"
                            sx={{ background: 'white' }}
                            label={row.invoice_number ? `Total=₹${row.total_amount??0}` : "Inv Amount"}
                            value={row.invoice_amount?`Remains=₹${row.invoice_amount}`:''}
                            onChange={(e) => handleChange(index, 'invoice_amount', e.target.value)}
                            disabled
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <TextField
                            label="Transaction Date"
                            type="date"
                            fullWidth
                            size='small'
                            name='transaction_date'
                            value={row.transaction_date}
                            onChange={(e) => handleChange(index, 'transaction_date', e.target.value)}
                            sx={{ backgroundColor: 'white' }}
                            InputLabelProps={{ shrink: true, }}
                            error={!row.transaction_date}
                        />
                    </Grid>
                    {payment_mode === 1 &&
                        <Grid item xs={12} sm={6} md={2}>
                            <TextField
                                size="small"
                                sx={{ background: 'white' }}
                                label={'Trans. Id'}
                                value={row.transaction_id}
                                onChange={(e) => handleChange(index, 'transaction_id', e.target.value)}
                                fullWidth
                            />
                        </Grid>
                    }
                    <Grid item xs={12} sm={6} md={2}>
                        <TextField
                            size="small"
                            type='number'
                            sx={{ background: 'white' }}
                            label={'Amount'}
                            value={row.amount}
                            onChange={(e) => handleChange(index, 'amount', e.target.value)}
                            required
                            fullWidth
                            error={!row.amount}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <Stack direction={'row'}>
                            <IconButton onClick={handleAddRow} disabled={index !== rows.length - 1}>
                                <AddBoxIcon color='primary' />
                            </IconButton>
                            <IconButton onClick={() => handleDeleteRow(index)} disabled={index === 0}>
                                <Delete color='error' />
                            </IconButton>
                        </Stack>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
};

export default memo(AddCustomerVoucherData);
