import React, { memo, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import configServ from '../../services/config';
import { useSelector } from 'react-redux';
import CheckAndReturn from '../../utility/CheckAndReturn';
import { CircularProgress } from '@mui/material';
import { Edit } from '@mui/icons-material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#4CB5F5',//theme.palette.common.black,
        color: theme.palette.common.white,
        paddingTop:1,
        marginTop:1,
        paddingBottom:1,
        marginBottom:1,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        paddingTop:0,
        marginTop:0,
        paddingBottom:0,
        marginBottom:0,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const FollowUpTable = ({ data, isChanged, handleEdit }) => {
    const { company_id } = useSelector((state) => state.GlobalVariables);
    const [followupData, setFollowupData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (data) {
            fetchFollowUp();
        }
    }, [isChanged]);

    const fetchFollowUp = async () => {
        try {
            setLoading(true);
            const dataToSend = {
                entity_id: data.id,
                entity_type: data.entity_type,
                company_id: company_id
            }
            const res = await configServ.getFollowUp(dataToSend);
            if (res.status === 200) {
                setFollowupData(res.data);
            }
            else {
                setFollowupData([]);
            }
        }
        catch (e) {
            console.log(e);
        }
        finally{
            setLoading(false);
        }
    }

    return (
        <>
            <TableContainer component={Paper}>
                <Box sx={{ overflowX: 'auto' }}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="left">S.No</StyledTableCell>
                                <StyledTableCell align="left">Remarks</StyledTableCell>
                                <StyledTableCell align="left">Follow Up</StyledTableCell>
                                <StyledTableCell align="left">Created At</StyledTableCell>
                                <StyledTableCell align="left">Status</StyledTableCell>
                                <StyledTableCell align="left">Action</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        {
                            loading?
                            <>
                            <TableRow>
                                <TableCell colSpan={12} sx={{textAlign:'center'}}>
                                    <Box>
                                        <CircularProgress/>
                                    </Box>
                                </TableCell>
                            </TableRow>
                            </>
                            :
                            <>
                            <TableBody>
                                {followupData.length > 0 ? (
                                    followupData.map((row, index) => (
                                        <StyledTableRow key={row.item_id}>
                                            <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                {row.remarks}
                                            </StyledTableCell>
                                            <StyledTableCell align="left">{CheckAndReturn.transformToDate3(row.follow_up_date)}</StyledTableCell>
                                            <StyledTableCell align="left">{CheckAndReturn.transformToDate3(row.created_at)}</StyledTableCell>
                                            <StyledTableCell align="left">{row.status === 1 ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />}</StyledTableCell>
                                            <StyledTableCell align="left">
                                                <Edit color='primary' onClick={()=>handleEdit(row)} />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={12} sx={{ textAlign: 'center', color: 'red', fontSize: '16px' }}>
                                            <div>
                                                <p><b>No Records Found.</b></p>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            </>
                        }
                    </Table>
                </Box>
            </TableContainer>
        </>
    );
};

export default memo(FollowUpTable);
