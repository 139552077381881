import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    cartValue:[],
    cartDiscountAmount: '',
    cartTaxAmount: '',
    cartMRP: '',
    cartNetAmount:'',
    billtoaddress:'',
    shiptoaddress:''
}

export const CartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addItem: (state, action) => {
      state.cartValue.push( {
        'id':action.payload.id,
        'qty':1,
        'company_id':action.payload.company_id,
        'itemname':action.payload.itemname,
        'hsn_code':action.payload.hsn_code,
        'Taxrate':action.payload.Taxrate,
        'discount':action.payload.discount,
        'rate':action.payload.rate,
        'item_code':action.payload.item_code,
        'item_size':action.payload?.item_size || null,
        'size_id':action.payload?.size_id || null,
        'unit':action.payload?.unit,
        'issizeAdded':action.payload?.issizeAdded,
      });
    },
    removeItem: (state, action) => {
        return ({...state,
            cartValue:state.cartValue.filter(item => item.id !== action.payload)})
      },
    qtyChange: (state, action) => {
        const index = state.cartValue.findIndex((item) => {
            return item.id === action.payload.id
        })
            state.cartValue[index].qty = action.payload.value
    },
    assignCartMRP: (state, action) => {
        state.cartMRP = action.payload
    },
    assignCartDiscountAmount: (state, action) => {
        state.cartDiscountAmount = action.payload
    },
    assignCartTaxAmount: (state, action) => {
        state.cartTaxAmount = action.payload
    },
    assignCartNetAmount: (state, action) => {
        state.cartNetAmount = action.payload
    },
    assignBillToAddress: (state, action) => {
        state.billtoaddress = action.payload
    },
    assignShipToAddress: (state, action) => {
        state.shiptoaddress = action.payload
    },
    clear: (state) => ({
        ...state,
        cartValue:[],
        cartDiscountAmount: '',
        cartTaxAmount: '',
        cartMRP: '',
        cartNetAmount:''
    }),
  },
})

// Action creators are generated for each case reducer function
export const { addItem, removeItem, qtyChange, assignCartMRP, assignCartDiscountAmount, assignCartTaxAmount, assignCartNetAmount, assignBillToAddress, assignShipToAddress, clear } = CartSlice.actions

export default CartSlice.reducer