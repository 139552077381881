import { Box, Divider, Stack, TextField, Typography, FormControlLabel, Checkbox, Button, Grid } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { assignBillToAddress, assignShipToAddress } from "../../../redux/reducers/Cart";
import { useEffect } from "react";
import CheckAndReturn from "../../../utility/CheckAndReturn";

const CustAddress = ({ handleNext }) => {
    //#region code
    const dispatch = useDispatch();
    const { address } = useSelector((state) => state.GlobalVariables);
    const { billtoaddress, shiptoaddress } = useSelector((state) => state.cart);

    const [validationErrors, setvalidationErrors] = useState({});

    const billTo = () => {
        dispatch(assignBillToAddress(address))
        dispatch(assignShipToAddress(''))
    }

    const handlevalidation = (name) => {
        if (validationErrors[name]) {
            setvalidationErrors((prev) => ({
                ...prev,
                [name]: ''
            }));
        }
    }

    const shipToSameAsBillTo = (e) => {
        const { checked } = e.target;
        if (checked) {
            handlevalidation('shiptoaddress');
            dispatch(assignShipToAddress(billtoaddress))
        } else {
            dispatch(assignShipToAddress(''))
        }
    }

    const handleBillTo = (e) => {
        const { name, value } = e.target;
        handlevalidation(name);
        dispatch(assignBillToAddress(value))
    }
    const handleShipTo = (e) => {
        const { name, value } = e.target;
        handlevalidation(name);
        dispatch(assignShipToAddress(value))
    }

    useEffect(() => {
        billTo()
    }, []);

    const validate = async () => {
        try {
            const errors = {};
            if (CheckAndReturn.checkError(billtoaddress)) {
                errors.billtoaddress = 'required';
            }
            if (CheckAndReturn.checkError(shiptoaddress)) {
                errors.shiptoaddress = 'required';
            }
            setvalidationErrors(errors);

            return Object.values(errors).every(error => error === "");
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleNextStep = async () => {
        try {
            const isValid = await validate();
            if (isValid === false) {
                return false;
            }
            handleNext();
        }
        catch (e) {
            console.log(e);
        }
    }

    //#endregion

    return (
        <>
            <Grid container xs={12} alignItems={'center'} justifyContent={'center'} spacing={1}>
                <Grid container xs={12} md={6} alignItems={'center'} spacing={1} sx={{background:'#fffff5'}} p={3} border='1px solid #33333355'>
                    <Grid container item xs={12} justifyContent={'center'}>
                        <Typography variant="h6" fontWeight={'bold'}>Verify Delivery Address</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="center">
                            <Grid container item xs={12} justifyContent={'flex-start'}>
                                <Typography variant="body" fontWeight='bold'>Bill to address</Typography>
                            </Grid>
                            <TextField
                                fullWidth
                                placeholder="Address"
                                size="small"
                                name="billtoaddress"
                                value={billtoaddress}
                                onChange={handleBillTo}
                                sx={{ background: 'white' }}
                                error={!!validationErrors.billtoaddress}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="center">
                            <Grid container item xs={12} justifyContent={'flex-start'}>
                                <Typography variant="body" fontWeight='bold'>Ship to address<FormControlLabel control={<Checkbox sx={{ml:1}} onChange={shipToSameAsBillTo} size="small" />} label="Same as Bill to address" /></Typography>
                            </Grid>
                            <TextField
                                fullWidth
                                placeholder="Address"
                                size="small"
                                name="shiptoaddress"
                                value={shiptoaddress}
                                onChange={handleShipTo}
                                sx={{ background: 'white' }}
                                error={!!validationErrors.shiptoaddress}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="space-between" alignItems="center" m={2}>
                            <Button
                                variant="contained"
                                color="success"
                                onClick={handleNextStep}
                            >
                                Confirm Address
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default CustAddress;
