import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  InputLabel,
  Typography,
} from "@mui/material";
import React, { memo } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import uploadImg from "../Assets/images/upload.jpeg";
import PopupDialogTitle from "../Dialog/PopupDialogTitle";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

const UploadListImage = ({
  open,
  handleClose,
  index,
  handleFileUpload,
  handleRemoveImage,
  image,
  fileType,
}) => {
  //#region code

  //#endregion

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="upload-list-image-title"
        aria-describedby="upload-list-image-description"
        fullWidth
        maxWidth={"sm"}
        PaperProps={{
          sx: {
            width: "auto",
            height: "auto",
          },
        }}
      >
        <PopupDialogTitle handleClose={handleClose} show={false} />
        <br />
        <DialogContent>
          <Box borderRadius={5} p={4} sx={{ backgroundColor: "#f7f4e2" }}>
            <Grid container spacing={2} alignItems="center" key={index}>
              <Grid
                container
                item
                xs={12}
                md={12}
                alignItems={"center"}
                justifyContent={"center"}
              >
                {image && fileType ? (
                  <>
                    {fileType === "jpeg" ||
                    fileType === "png" ||
                    fileType === "webp" ||
                    fileType === "jpg" ||
                    fileType === "image" ? (
                      <img
                        src={image}
                        alt={`Uploaded ${index}`}
                        style={{ width: 200, height: 200, marginBottom: 16 }}
                      />
                    ) : (
                      <Typography>
                        <a
                          href={URL.createObjectURL(
                            new Blob([atob(image.split(",")[1])], {
                              type: "application/pdf",
                            })
                          )}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <PictureAsPdfIcon />
                        </a>
                      </Typography>
                    )}
                  </>
                ) : (
                  <Typography>No file uploaded yet.</Typography>
                )}
              </Grid>

              <Grid
                container
                item
                xs={12}
                md={12}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                // flexDirection={"column"} // Ensures buttons and message are stacked
              >
                <InputLabel
                  htmlFor={`upload-button-${index}`}
                  sx={{ cursor: "pointer" }}
                >
                  <input
                    id={`upload-button-${index}`}
                    type="file"
                    accept="image/*,application/pdf" // Allow both images and PDFs
                    style={{ display: "none" }}
                    onChange={(e) => handleFileUpload(e, index)}
                  />
                  <Button
                    variant="contained"
                    component="span"
                    startIcon={<CloudUploadIcon />}
                    sx={{ mr: 2 }}
                  >
                    Upload
                  </Button>
                </InputLabel>

                <Button
                  variant="contained"
                  color="error"
                  component="span"
                  startIcon={<DeleteIcon />}
                  onClick={() => handleRemoveImage(index)}
                  sx={{ mr: 2 }}
                >
                  Remove
                </Button>

                <Typography
                  sx={{
                    mt: 1,
                    color: "red",
                    fontWeight: "bold",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    position: "relative",
                    display: "inline-block",
                    width: "100%",
                    "@keyframes marquee": {
                      "0%": { transform: "translateX(100%)" },
                      "100%": { transform: "translateX(-100%)" },
                    },
                  }}
                >
                  <Box
                    component="span"
                    sx={{
                      display: "inline-block",
                      animation: "marquee 20s linear infinite", // Slower movement (20 seconds)
                    }}
                  >
                    Image size should be ≤ 200 KB and PDF size ≤ 1 MB. Supported
                    formats: JPEG, PNG, WEBP, JPG, PDF.
                  </Box>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
};

export default memo(UploadListImage);
