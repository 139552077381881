import AlertDialog from "../../Components/AlertDialog/AlertDialog";
import React, { useState, useEffect, memo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Box,
  Tooltip,
  Grid,
  Typography,
  NativeSelect,
  InputLabel,
  TextField,
  InputAdornment,
  FormControl,
  Stack,
  CircularProgress,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import configServ from "../../services/config";
import { useSelector } from "react-redux";
import useDeviceDetect from "../../Components/Detect/useDeviceDetect ";
import IconButton from '@mui/material/IconButton';
import { clear } from "../../redux/reducers/Cart";
import LoaderNew from "../../Components/Loader/LoaderNew";


//FUNCTION
const LocationTable = ({ handleEditData, isChanged, pagination = true }) => {
  //#region code
  const { company_id } = useSelector((state) => state.GlobalVariables);
  const rowsPerPageOptions = [20, 50, 100, 200];
  const [locationDataList, setLocationDataList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
  const [searchBy, setSearchBy] = useState("location_name");
  const [count, setCount] = useState(0);
  const { isMobile } = useDeviceDetect();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    fetchLocationList();
  }, [isChanged]);

  const handleSearchBy = (event) => {
    try {
      setSearchBy(event.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (event) => {
    try {
      const search = event.target.value;
      let data = [];
      if (search === "") {
        data = locationDataList;
      } else if (searchBy === "location_name") {
        data = locationDataList.filter((x) =>
          x.location_name.toLowerCase().includes(search.toLowerCase())
        );
      } else if (searchBy === "city") {
        data = locationDataList.filter((x) =>
          x.city.toLowerCase().includes(search.toLowerCase())
        );
      } else if (searchBy === "state") {
        data = locationDataList.filter((x) =>
          x.state.toLowerCase().includes(search.toLowerCase())
        );
      }
      setLocationList(data);
      setCount(data.length);
    } catch (error) {
      console.log(error);
    }
  };

  const headers = [
    "LOCATION",
    "ADDRESS",
    "CITY",
    "STATE",
    "STATUS",
    "PRIMARY",
    "ACTION",
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

  const paginatedData = pagination
    ? locationList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : locationList;

  const headerCellStyle = {
    fontSize: 14, // Adjust the font size as needed
    fontWeight: "bold",
    backgroundColor: "#4CB5F5", // Set the header background color to blue
    color: "white",
  };

  const cellStyle = {
    py: 0,
    my: 0,
  };

  const evenRowStyle = {
    background: "#f5f5f5",
  };

  const oddRowStyle = {
    background: "white",
  };

  const rowStyle = {
    "&:hover": {
      background: "#f1f9fe",
    },
  };

  const handleOpenDialog = (content) => {
    setDialogContent(content);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    clear();
    setDialogOpen(false);
    setDialogContent("");
  };

  const fetchLocationList = async () => {
    try {
      const res = await configServ.getLocation({
        company_id: company_id,
      });
      setLocationDataList(res);
      setLocationList(res);
      setCount(res.length);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (params) => {
    const data = locationDataList.filter((x) => x.id === params);
    handleEditData(data);
  };
  //#endregion

  return (
    <>
      <AlertDialog
        open={dialogOpen}
        setOpen={handleCloseDialog}
        title="Alert"
        message={dialogContent}
        buttonTitle="Ok"
      />

      <Grid
        container
        alignItems="flex-end"
        justifyContent="flex-start"
        sx={{ pt: 1 }}
        spacing={1}
        direction={"row"}
      >
        <Grid item xs={12} md={6} ml={0} justifyContent={"flex-start"}>
          <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
            Location List: {count}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box sx={{ minWidth: 120, marginLeft: "0px" }}>
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Search By
              </InputLabel>
              <NativeSelect
                defaultValue={"contact_name"}
                onChange={handleSearchBy}
                inputProps={{
                  name: "searchBy",
                  id: "uncontrolled-native",
                }}
              >
                <option value={"location_name"}>Location Name</option>
                <option value={"city"}>City</option>
                <option value={"state"}>State</option>
              </NativeSelect>
            </FormControl>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          mr={{ xs: 0, md: 0 }}
          justifyContent={"flex-end"}
        >
          <TextField
            id="outlined-basic"
            size="small"
            fullWidth
            placeholder="search"
            variant="outlined"
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ fill: "grey" }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      {isMobile ? (
        <>
          {" "}
          <Grid container spacing={2} mt={1}>
            {loading ? (
              <Grid item xs={12} textAlign="center">
                <LoaderNew />
              </Grid>
            ) : paginatedData.length > 0 ? (
              paginatedData.map((row, rowIndex) => (
                <Grid item xs={12} key={rowIndex}>
                  <Paper elevation={3} sx={{ p: 2 }}>
                    <Stack spacing={1}>
                      <Box sx={{ fontWeight: "bold" }}>
                        <strong>S.No.:</strong> {page * rowsPerPage + rowIndex + 1}.
                      </Box>
                      <Box>
                        <strong>Location:</strong> {row.location_name}
                      </Box>
                      <Box>
                        <strong>Address:</strong> {row.address}
                      </Box>
                      <Box>
                        <strong>City:</strong> {row.city}
                      </Box>
                      <Box>
                        <strong>State:</strong> {row.state}
                      </Box>
                      <Box>
                        <strong>Status:</strong>{" "}
                        {row.status === 1 ? (
                          <CheckCircleIcon color="success" />
                        ) : (
                          <CancelIcon color="error" />
                        )}
                      </Box>
                      <Box>
                        <strong>Primary:</strong>{" "}
                        {row.isPrimary === 1 ? (
                          <CheckCircleIcon color="success" />
                        ) : (
                          <CancelIcon color="error" />
                        )}
                      </Box>
                      <Box>
                        <Tooltip title="Edit" arrow>
                          <IconButton
                            onClick={() => {
                              handleEdit(row.id);
                              scrollToTop();
                            }}
                          >
                            <EditIcon color="primary" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Stack>
                  </Paper>
                </Grid>
              ))
            ) : (
              <Grid item xs={12} textAlign="center">
                <Box sx={{ color: "red", fontWeight: 600 }}>No data found.</Box>
              </Grid>
            )}
            {pagination && (
              <Grid item xs={12}>
                <TablePagination
                  rowsPerPageOptions={rowsPerPageOptions}
                  component="div"
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Grid>
            )}
          </Grid>
        </>
      ) : (
        <>
          <TableContainer component={Paper} sx={{ py: 1 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={headerCellStyle}>S.No.</TableCell>
                  {headers.map((header, index) => (
                    <TableCell key={index} style={headerCellStyle}>
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={12} sx={{ textAlign: "center" }}>
                      <div>
                        <LoaderNew />
                      </div>
                    </TableCell>
                  </TableRow>
                ) : (
                  paginatedData.map((row, rowIndex) => (
                    <TableRow
                      key={rowIndex}
                      sx={{
                        ...rowStyle,
                        ...(rowIndex % 2 === 0 ? evenRowStyle : oddRowStyle),
                      }}
                    >
                      <TableCell sx={cellStyle}>
                        {page * rowsPerPage + rowIndex + 1}
                      </TableCell>
                      <TableCell sx={cellStyle}>{row.location_name}</TableCell>
                      <TableCell sx={cellStyle}>{row.address}</TableCell>
                      <TableCell sx={cellStyle}>{row.city}</TableCell>
                      <TableCell sx={cellStyle}>{row.state}</TableCell>
                      <TableCell sx={cellStyle}>
                        {row.status === 1 ? (
                          <CheckCircleIcon color="success" />
                        ) : (
                          <CancelIcon color="error" />
                        )}
                      </TableCell>
                      <TableCell sx={cellStyle}>
                        {row.isPrimary === 1 ? (
                          <CheckCircleIcon color="success" />
                        ) : (
                          <CancelIcon color="error" />
                        )}
                      </TableCell>
                      <TableCell sx={cellStyle}>
                        <Box
                          sx={{
                            borderRadius: 10,
                            width: "fit-content",
                            transition: "100ms",
                            cursor: "pointer",
                            padding: 0.5,
                            ":hover": { backgroundColor: "#99999955" },
                          }}
                          onClick={() => {
                            handleEdit(row.id);
                            scrollToTop();
                          }}
                        >
                          <Tooltip title="Edit" arrow>
                            <EditIcon color="primary" />
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                )}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 1 * emptyRows }}>
                    <TableCell colSpan={headers.length + 2} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {pagination && (
              <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </TableContainer>
        </>
      )}
    </>
  );
};

export default memo(LocationTable);
