import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import "./NotificationBell.css"; // For custom styling
import { useDispatch, useSelector } from "react-redux";
import { rSetShowFab } from "../../../redux/reducers/GlobalVariables";
import wrongimage from "../../Assets/images/wrongimg.jpg"

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 5,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function NotificationBell() {
  const [notifications, setNotifications] = useState(0);
  const [isShaking, setIsShaking] = useState(false);
  const [bellColor, setBellColor] = useState("inherit");
  const [open, setOpen] = useState(false); // State to control dialog open/close
  const dispatch = useDispatch();

  const demoNotifications = [
    { id: 1, message: "New order placed", time: "2 minutes ago" },
    { id: 2, message: "New order placed", time: "2 minutes ago" },
    { id: 3, message: "New order placed", time: "2 minutes ago" },
    { id: 4, message: "New order placed", time: "2 minutes ago" },
    { id: 5, message: "New order placed", time: "2 minutes ago" },
    { id: 6, message: "New order placed", time: "2 minutes ago" },
    { id: 7, message: "New order placed", time: "2 minutes ago" },
    { id: 8, message: "New order placed", time: "2 minutes ago" },
    { id: 9, message: "New order placed", time: "2 minutes ago" },
  ];

  // Simulate receiving a new notification
  useEffect(() => {
    const interval = setInterval(() => {
      if (notifications < 3) {
        setNotifications((prev) => prev + 1);
        setBellColor("error"); // Change the color when notification arrives
        setIsShaking(true); // Start shake animation
      }
    }, 5000); // Every 5 seconds a new notification arrives
    return () => clearInterval(interval);
  }, [notifications]);

  // Handle bell click
  const handleBellClick = () => {
    setIsShaking(false); // Stop shake animation
    dispatch(rSetShowFab(false));
    setBellColor("inherit"); // Reset bell color
    setOpen(true); // Open the dialog
  };

  // Handle closing the dialog
  const handleClose = () => {
    dispatch(rSetShowFab(true));
    setOpen(false); // Close dialog
  };

  return (
    <>
      <IconButton
        color={bellColor}
        onClick={handleBellClick}
        className={isShaking ? "shake" : ""}
      >
        <StyledBadge badgeContent={notifications} color="error">
          <NotificationsIcon />
        </StyledBadge>
      </IconButton>

      {/* Dialog Box for Notifications */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={handleClose}
        fullWidth
        maxWidth="xs"
        PaperProps={{
          sx: {
            position: "fixed",
            right: 0,
            top: "60px", // Adjusted top position to be higher
            m: 0,
            width: "100%",
            maxWidth: "360px",
          },
        }}
      >
        <DialogTitle>
          Notifications
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <img
              onClick={handleClose}
              src={wrongimage}
              alt=""
              style={{ height: "24px", width: "24px" }} // Adjust height and width as needed
            />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {demoNotifications.map((notification, index) => (
            <div
              key={notification.id}
              className={`notification-item ${
                index % 2 === 0 ? "even" : "odd"
              }`}
            >
              <p className="message">{notification.message}</p>
              <p className="time">{notification.time}</p>
              <button className="view-btn">View</button> {/* Add view button */}
            </div>
          ))}
        </DialogContent>
      </Dialog>
    </>
  );
}
