import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import IconButton from "@mui/material/IconButton";
import { useSelector } from "react-redux";
import configServ from "../../../services/config";
import Typography from "@mui/material/Typography";
import { Box, Card, CardContent } from "@mui/material";
import LoaderNew from "../../Loader/LoaderNew";
import { DisabledByDefault } from "@mui/icons-material";
import CheckAndReturn from "../../../utility/CheckAndReturn";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SalesDispatchDialog = ({ open, handleClose, order_no }) => {
  const [loading, setLoading] = React.useState(true);
  const { company_id } = useSelector((state) => state.GlobalVariables);
  const [dispatchDetails, SetDispatchDetails] = React.useState([]);

  const fetchDispatchDetails = async () => {
    try {
      setLoading(true);

      const dataToSend = {
        company_id: company_id,
        order_no: order_no,
      };

      const res = await configServ.getDispatchByOrderNo(dataToSend);
      if (res.status === 200) {
        SetDispatchDetails(res.data);
      } else {
        SetDispatchDetails([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    if (order_no) {
      fetchDispatchDetails();
    }
  }, [order_no]);

  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Typography
              variant="h6"
              color={'error'}
              fontWeight={'bold'}
            >
              DISPATCH DETAILS
            </Typography>
            <IconButton onClick={handleClose} sx={{ fontSize: '36px' }}>
              <DisabledByDefault fontSize="36px" color="error" />
            </IconButton>
          </Box>
          <Box
            sx={{
              maxHeight: "400px",
              overflowY: "auto",
            }}
          >
            {loading ? (
              <Typography>
                <LoaderNew />
              </Typography>
            ) : dispatchDetails.length > 0 ? (
              dispatchDetails.map((detail, index) => (
                <Card
                  key={index}
                  sx={{
                    margin: "10px 0",
                    boxShadow: index % 2 === 0 ? 3 : 1, // Shadow for odd/even
                    backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#fff",
                  }}
                >
                  <CardContent>
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      m={1}
                    >
                      <Typography variant="body1" fontWeight={700}>
                        Invoice No:
                      </Typography>
                      <Typography variant="body1">
                        {detail.invoice_number || ""}
                      </Typography>
                    </Box>

                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      m={1}
                    >
                      <Typography variant="body1" fontWeight={700}>
                        Customer Name:
                      </Typography>
                      <Typography variant="body1">
                        {detail.fname || ""}
                      </Typography>
                    </Box>

                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      m={1}
                    >
                      <Typography variant="body1" fontWeight={700}>
                        Address:
                      </Typography>
                      <Typography variant="body1">
                        {detail.address || ""}
                      </Typography>
                    </Box>

                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      m={1}
                    >
                      <Typography variant="body1" fontWeight={700}>
                        Quantity:
                      </Typography>
                      <Typography variant="body1">
                        {detail.qnty || ""}
                      </Typography>
                    </Box>

                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      m={1}
                    >
                      <Typography variant="body1" fontWeight={700}>
                        Amount:
                      </Typography>
                      <Typography variant="body1">
                        ₹{detail.amount || ""}
                      </Typography>
                    </Box>

                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      m={1}
                    >
                      <Typography variant="body1" fontWeight={700}>
                        Transport Mode:
                      </Typography>
                      <Typography variant="body1">
                        {detail.TransportMode || ""}
                      </Typography>
                    </Box>

                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      m={1}
                    >
                      <Typography variant="body1" fontWeight={700}>
                        Transport Name:
                      </Typography>
                      <Typography variant="body1">
                        {detail.transporter_name || ""}
                      </Typography>
                    </Box>

                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      m={1}
                    >
                      <Typography variant="body1" fontWeight={700}>
                        Transportation Mobile No:
                      </Typography>
                      <Typography variant="body1">
                        {detail.TransporterMobileNo || ""}
                      </Typography>
                    </Box>

                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      m={1}
                    >
                      <Typography variant="body1" fontWeight={700}>
                        Vehicle No:
                      </Typography>
                      <Typography variant="body1">
                        {detail.vehicle_num || ""}
                      </Typography>
                    </Box>

                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      m={1}
                    >
                      <Typography variant="body1" fontWeight={700}>
                        Date:
                      </Typography>
                      <Typography variant="body1">
                        {detail.Date ? CheckAndReturn.transformToDate3(detail.Date) : ""}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              ))
            ) : (
              <Typography>No Dispatch Details Found</Typography>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default SalesDispatchDialog;
