import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Stack, Paper, Divider, Button, Box, Tab, Grid } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { motion } from "framer-motion";

import AddItemImage from "./AddItemImage";
import AddItemSize from "./AddItemSize";
import ItemSizeList from "./ItemSizeList";
import ItemImageList from "./ItemImageList";
import LineItemList from "./LineItemList";
import AddLineItem from "./AddLineItem";

//FUNCTION
const ItemAdvance = () => {
  //#region code
  const location = useLocation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState(location.state);
  const [openAddImage, setOpenAddImage] = useState(false);
  const [openAddSize, setOpenAddSize] = useState(false);
  const [openGenerateLineItems, setOpenGenerateLineItems] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [tabOpen, setTabOpen] = useState("image");

  const assignItemData = () => {
    const data = location.state;
    setFormData(data);
  };

  const handleCloseImage = () => {
    setOpenAddImage(false);
  };

  const handleOpenImage = () => {
    setOpenAddImage(true);
  };

  const handleCloseSize = () => {
    setOpenAddSize(false);
  };

  const handleOpenSize = () => {
    setOpenAddSize(true);
  };

  const handleOpenLineItems = () => {
    setOpenGenerateLineItems(true);
  };

  const handleCloseLineItems = () => {
    setOpenGenerateLineItems(false);
  };

  const handleTabSwitch = (e, value) => {
    setTabOpen(value);
  };

  const handleLineItemCreated = () => {
    setIsChanged(!isChanged);
  };
  //#endregion

  return (
    <>
      <AddItemImage
        itemData={formData}
        handleClose={handleCloseImage}
        open={openAddImage}
        isEdited={isEdited}
        setIsEdited={setIsEdited}
      />
      <AddItemSize
        itemData={formData}
        handleClose={handleCloseSize}
        open={openAddSize}
        isEdited={isEdited}
        setIsEdited={setIsEdited}
      />
      <AddLineItem
        itemData={formData}
        handleClose={handleCloseLineItems}
        open={openGenerateLineItems}
        isChanged={handleLineItemCreated}
      />

      <Grid container width="100%" alignItems="center" justifyContent="center">
        <Grid item md={0.5} sm={0.5} xs={0.5}>
          <Button
            variant="contained"
            startIcon={<ArrowBackIcon />}
            size="small"
            onClick={() => {
              navigate(-1);
            }}
            sx={{ color: "white" }}
          >
            Back
          </Button>
        </Grid>
        <Grid item md={11.5} sm={11.5} xs={11.5}>
          <motion.h4
            style={{
              textAlign: "center",
            }}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.1 }}
          >
            Advance Details
          </motion.h4>
        </Grid>
      </Grid>

      <Divider
        sx={{
          backgroundColor: "#33333366",
          my: "2px",
          width: "100%",
        }}
      />

      {/* <Stack
                        direction='row'
                        justifyContent='space-around'
                        alignItems='center'
                        spacing={1}
                    > */}
      <Grid
        container
        alignItems={"center"}
        justifyContent={"space-around"}
        mb={1}
        spacing={1}
      >
        <Grid container justifyContent={"flex-end"} item xs={6} md={6}>
          <Button
            onClick={handleOpenImage}
            color="secondary"
            // sx={{
            //     minWidth: '8rem',
            //     aspectRatio: 2 / 1
            // }}
            variant="contained"
          >
            <b>Add Image</b>
          </Button>
        </Grid>
        <Grid container justifyContent={"flex-start"} item xs={6} md={6}>
          <Button
            onClick={handleOpenSize}
            color="secondary"
            // sx={{
            //     minWidth: '8rem',
            //     aspectRatio: 2 / 1
            // }}
            variant="contained"
          >
            <b>Add Size</b>
          </Button>
        </Grid>
        
        {/* <Grid item xs={4} md={4}>
                                <Button
                                    onClick={handleOpenLineItems}
                                    color="secondary"
                                    // sx={{
                                    //     minWidth: '8rem',
                                    //     aspectRatio: 2 / 1
                                    // }}
                                    variant="contained"
                                >
                                    <b>Generate Line Items</b>
                                </Button>
                            </Grid> */}
      </Grid>
      {/* </Stack> */}

      <Divider
        sx={{
          backgroundColor: "#33333366",
          mt: "2px",
          width: "100%",
        }}
      />

      <TabContext value={tabOpen}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleTabSwitch}
            value={tabOpen}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="Image" value={"image"} />
            <Tab label="Size" value={"size"} />
            {/* <Tab label="Line Items" value={'line_items'} /> */}
          </TabList>
        </Box>
        <TabPanel value={"image"}>
          <ItemImageList
            itemData={formData}
            isEdited={isEdited}
            setIsEdited={setIsEdited}
          />
        </TabPanel>
        <TabPanel value={"size"}>
          <ItemSizeList
            itemData={formData}
            isEdited={isEdited}
            setIsEdited={setIsEdited}
          />
        </TabPanel>
        <TabPanel value={"line_items"}>
          <LineItemList itemData={formData} isChangedItem={isChanged} />
        </TabPanel>
      </TabContext>
    </>
  );
};
export default ItemAdvance;
