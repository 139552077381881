import React, { memo, useState } from 'react'
import { Container, Row, Col, Form } from 'react-bootstrap'
import { Paper, Stack, Button, Tooltip, TextField, Autocomplete, Grid, Typography, FormGroup, FormControlLabel, Checkbox } from '@mui/material'
import { motion } from 'framer-motion'
import configServ from '../../../services/config'
import { useEffect } from 'react'
import SaveIcon from '@mui/icons-material/Save';
import UpdateIcon from '@mui/icons-material/Update';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import AlertDialog from "../../AlertDialog/AlertDialog";
import { useDispatch, useSelector } from 'react-redux'
import { triggerLoader } from '../../../redux/reducers/LoaderTrigger'

//FUNCTION
const GroupCard = ({ isChanged, setIsChanged, editData }) => {
    //#region code
    const dispatch = useDispatch();
    const { company_id } = useSelector((state) => state.GlobalVariables);
    const [formData, setFormData] = useState({
        status: true,
        isPrimary: false
    })
    const [isEdit, setIsEdit] = useState(false);
    //const [category, setCategory] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});
    const [dialogOpen, setDialogOpen] = useState(false);
    const [title, setTitle] = useState('Alert');
    const [dialogContent, setDialogContent] = useState('');
    const [buttonType, setButtonType] = useState(null);

    const handleOpenDialog = (heading, content, type) => {
        setTitle(heading);
        setDialogContent(content);
        setButtonType(type);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setTitle('Alert');
        setDialogContent('');
        setButtonType(null);
    };

    // useEffect(() => {
    //     fetchCategory();
    // }, []);

    // const fetchCategory = async () => {
    //     try {
    //         const dataToSend = {
    //             "company_id": company_id,
    //             "search_by": null,
    //             "search": null,
    //             "per_page": null,
    //             "page": null
    //         }
    //         const res = await configServ.getcategory(dataToSend);
    //         if (res.status === 200) {
    //             setCategory(res.data)
    //         }
    //     } catch (err) {
    //         console.log(err)
    //     }
    // }

    const handleChange = (e) => {
        const { name, value } = e.target

        //Update form data
        setFormData((state) => ({
            ...state,
            [name]: value
        }));

        // Reset the validation error when the user starts typing
        if (validationErrors[name]) {
            setValidationErrors((prevValidationError) => ({
                ...prevValidationError,
                [name]: '',
            }));
        }
    }

    const handleChangeCheckbox = (e) => {
        const { name, checked } = e.target;
        setFormData((state) => ({
            ...state,
            [name]: checked
        }))
    }

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();

            dispatch(triggerLoader());

            const error = handleFormValidation();
            if (error === false) {
                return false;
            }
            setValidationErrors({});

            const dataToSend = {
                ...formData,
                company_id: company_id
            }

            if (!isEdit) {
                const res = await configServ.addGroup(dataToSend);
                if (res.status === 200) {
                    setIsChanged(!isChanged);
                    clear();
                    handleOpenDialog('Success', res.message, 'success');
                }
                else if (res.status === 400) {
                    handleOpenDialog('Warning', res.message || "Failed to create group.", 'warning');
                }
                else {
                    handleOpenDialog('Error', res.message || "Failed to add group.", 'error');
                }
            }
            else {
                const res = await configServ.editGroup(dataToSend);
                if (res.status === 200) {
                    setIsChanged(!isChanged);
                    clear();
                    handleOpenDialog('Success', res.message, 'success');
                }
                else {
                    handleOpenDialog('Error', res.message || "Failed to update group.", 'error');
                }
            }
        }
        catch (err) {
            console.log(err);
            handleOpenDialog('Error', "An unexpected error has occurred.", 'error');
        }
        finally {
            dispatch(triggerLoader());
        }
    }

    const handleFormValidation = () => {
        const errors = {}
        if (!formData.group_name) {
            errors.group_name = "required";
        }
        setValidationErrors(errors);
        return Object.values(errors).every((error) => error === "");
    }

    useEffect(() => {
        if (Object.keys(editData).length !== 0) {
            setFormData(editData)
            setIsEdit(true)
            setFormData((prev) => ({
                ...prev,
                status: true
            }));
        }
    }, [editData])

    const clear = () => {
        setFormData({
            status: true,
            isPrimary: false
        })
        setIsEdit(false)
    }
    //#endregion

    return (
        <>
            <AlertDialog
                open={dialogOpen}
                setOpen={handleCloseDialog}
                title={title}
                message={dialogContent}
                buttonTitle="Ok"
                buttonType={buttonType}
            />

            <motion.div style={{ width: "100%", backgroundColor: "#e3f3fc", padding: 10, borderRadius: '10px', border: 'none' }}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.1, delay: 0.1 }}
            >
                <Grid container alignItems="center" justifyContent="flex-start" spacing={1}>
                    <Grid item xs={12} md={12} justifyContent={'flex-start'}>
                        <Typography sx={{ fontSize: '16px', fontWeight: 'bolder' }}>Group Detail</Typography>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <TextField
                            id="group_name"
                            name="group_name"
                            label="Group Name*"
                            variant="outlined"
                            size="small"
                            fullWidth
                            sx={{ backgroundColor: 'white' }}
                            onChange={handleChange}
                            error={validationErrors.group_name}
                            value={formData.group_name || ''}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            sx={{ backgroundColor: 'white' }}
                            size="small"
                            label="Description"
                            name="description"
                            onChange={handleChange}
                            value={formData.description || ''}
                            variant="outlined"
                        />
                    </Grid>
                    {/* <Grid item xs={12} md={3}>
                        <Autocomplete
                            fullWidth
                            sx={{ backgroundColor: 'white' }}
                            size="small"
                            options={category}
                            getOptionLabel={(item) => item.categoryname}
                            renderInput={(params) => <TextField {...params} label="Category" />}
                            value={category.find((item) => item.categoryid === formData.category_id) || null}
                            onChange={(event, newValue) => {
                                handleChange({ target: { name: 'category_id', value: newValue?.categoryid || '' } });
                            }}
                        />
                    </Grid> */}
                    <Grid item xs={12} md={5}>
                        <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                            <FormControlLabel
                                name='status'
                                control={<Checkbox checked={Boolean(formData.status) || false} />}
                                onChange={handleChangeCheckbox}
                                label="Status"
                            //style={{ marginRight: '20px' }}
                            />
                            <FormControlLabel
                                name='isPrimary'
                                control={<Checkbox checked={Boolean(formData.isPrimary) || false} />}
                                onChange={handleChangeCheckbox}
                                label="Is Primary"
                            />
                        </FormGroup>
                    </Grid>
                </Grid>

                <Grid container spacing={2} justifyContent="space-between" px={2}>
                    <Grid
                        item
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={2}
                    >
                        <Grid item>
                            <Button
                                onClick={clear}
                                variant="contained"
                                color='secondary'
                                startIcon={<RotateLeftIcon />}
                            >
                                Reset
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={handleSubmit}
                                autoFocus
                                variant="contained"
                                color={isEdit ? 'success' : 'primary'}
                                startIcon={isEdit ? <UpdateIcon /> : <SaveIcon />}
                            >
                                {isEdit ? "Update" : "Save"}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </motion.div>
        </>
    )
}

export default memo(GroupCard);