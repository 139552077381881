import React from 'react'
import "./Home.css";
import Login from '../loginPage/Login';
import OuterHeader from '../../Components/header/Header/OuterHeader';
import { Box, Container } from '@mui/material';
import OuterHeader1 from '../../Components/header/Header/OuterHeader1';
import { useSelector } from 'react-redux';
import bk from '../../Components/Assets/images/wbk.png';

const Home = () => {
    const lightMode = useSelector(state=>state.GlobalVariables.lightMode);

    return (
        <>
        <Box
            sx={{
                //position:'absolute',
                //top:0,
                width:'100vw',
                height:'100vh',
                backgroundImage: `url(${bk})`,
                background: lightMode ? '#ffffff' : '#090e34',//'linear-gradient(to bottom, #F8F6F4, #D2E9E9)',
                opacity:1,
                zIndex:-2  
            }}
        >
            <OuterHeader1 />
            <Container
                    component="main"
                    maxWidth="sm"
                    sx={{
                        // border: '1px solid red',
                        // padding: 3,
                        mt: {
                            xs: 10,
                            sm: 10
                        }

                    }}
                >
                    <br/>
                    <Login />
                </Container>
        </Box>
            
         </>
    )
}

export default Home;