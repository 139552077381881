import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import Avatar from "@mui/material/Avatar"; // Added Avatar import
import CompanyIcon from "@mui/icons-material/Business";
import { useSelector } from "react-redux";
import configServ from "../../../services/config";
import SiteLogoInitial from "../../Assets/images/SiteIcon.svg";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Link, useNavigate } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import Logout from "../../Logout/Logout";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#e3f2fd", // Light blue background for the whole search box
  "&:hover": {
    backgroundColor: alpha("#e3f2fd", 0.8), // Darker blue on hover
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
  border: `1px solid ${alpha(theme.palette.common.black, 0.25)}`, // Add border
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%", // Ensures full width of the input area
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export default function CustHeaderReBuild({ toggleSidebar }) {
  const navigate = useNavigate();
  const { company_id, companyName, user_name } = useSelector(
    (state) => state.GlobalVariables
  );
  const cartValue = useSelector((state) => state.cart.cartValue);
  const [companyLogo, setCompanyLogo] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [isLogutDialogOpen, setIsLogutDialogOpen] = React.useState(false);
  const open = useSelector((state) => state.sideExpand.open);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleLogout = () => {
    setIsLogutDialogOpen(!isLogutDialogOpen);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleCart = () => {
    //   setCartCount(cartCount + 1);
    navigate("/cart");
  };
  const assignCompanyLogo = async () => {
    try {
      const result = await configServ.company_logo({ company_id: company_id });
      // console.log(result.image)
      setCompanyLogo(result.image);
    } catch (err) {
      console.log(err);
    }
  };
  React.useEffect(() => {
    assignCompanyLogo();
  }, []);

  const menuId = "primary-search-account-menu";

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{ mt: 6 }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        px={2}
      >
        <IconButton>
          <AccountBoxIcon sx={{ fontSize: "32px" }} />
        </IconButton>
        <Typography
          sx={{ fontWeight: "bold", marginLeft: "1rem" }}
          color={"#1e61b4"}
        >
          {user_name}
        </Typography>
      </Box>
      <Link style={{ textDecoration: "none" }} to="/customer-profile">
        <MenuItem onClick={handleMenuClose} sx={{ p: 0 }}>
          <Button sx={{ color: "black" }} startIcon={<SwitchAccountIcon />}>
            My Profile
          </Button>
        </MenuItem>
      </Link>
      <MenuItem
        onClick={() => {
          handleMenuClose();
          handleLogout();
        }}
        sx={{ padding: 0 }}
      >
        <Button sx={{ color: "black" }} startIcon={<LogoutIcon />}>
          Log Out
        </Button>
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" color="inherit" onClick={handleCart}>
          <Badge
            badgeContent={cartValue.length}
            color="error"
            sx={{
              "& .MuiBadge-dot": {
                display: cartValue.length > 0 ? "block" : "none",
              },
            }}
          >
            <ShoppingCartIcon />
          </Badge>
        </IconButton>
        <p>Cart</p>
      </MenuItem>

      {/* <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>

      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem> */}
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <Avatar src="/path-to-avatar.jpg" /> {/* Avatar Component */}
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <Logout open={isLogutDialogOpen} setOpen={setIsLogutDialogOpen} />
      <Box
        sx={{
          width: "100%",
          height: 60,
          ...(open && {
            marginLeft: { xs: 0, md: 25 },
            paddingRight: { xs: 0, md: 3 },
            width: { xs: "100%", md: "88.55%" },
          }),
          transition: "200ms",
          zIndex: 1100,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          //paddingRight: 5,
          boxShadow: 5,
          background: "linear-gradient(to bottom,  #ffffff, #f1f1f1)",
          position: "sticky",
          top: 0,
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <AppBar
            position="static"
            sx={{ backgroundColor: "white", color: "black" }}
          >
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="open drawer"
                sx={{ mr: 2 }}
                onClick={toggleSidebar} // Trigger the sidebar toggle
              >
                <MenuIcon />
              </IconButton>
              <Grid container alignItems={"center"}>
                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      display: { xs: "flex", sm: "flex" },
                      alignItems: "center",
                    }}
                  >
                    {companyLogo === "" ? (
                      <span style={{ fontSize: "12px" }}>{companyName}</span>
                    ) : (
                      <img
                        src={
                          `data:image/png;base64,${companyLogo}` ||
                          SiteLogoInitial
                        }
                        alt="logoInitial"
                        height="60"
                        title={companyName}
                      />
                    )}
                  </Box>
                </Grid>
                {/* <Grid item xs={12} md={6} mb={{ xs: 1, md: 0 }}>
                  <Search>
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder="Search…"
                      inputProps={{ "aria-label": "search" }}
                    />
                  </Search>
                </Grid> */}
              </Grid>
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                <IconButton size="large" color="inherit" onClick={handleCart}>
                  <Badge
                    badgeContent={cartValue.length}
                    color="error"
                    sx={{
                      "& .MuiBadge-dot": {
                        display: cartValue.length > 0 ? "block" : "none",
                      },
                    }}
                  >
                    <ShoppingCartIcon />
                  </Badge>
                </IconButton>

                {/* <IconButton
                  size="large"
                  aria-label="show 4 new mails"
                  color="inherit"
                >
                  <Badge badgeContent={4} color="error">
                    <MailIcon />
                  </Badge>
                </IconButton>
                <IconButton
                  size="large"
                  aria-label="show 17 new notifications"
                  color="inherit"
                >
                  <Badge badgeContent={17} color="error">
                    <NotificationsIcon />
                  </Badge>
                </IconButton> */}
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <Avatar src="/path-to-avatar.jpg" /> {/* Avatar Component */}
                </IconButton>
              </Box>
              <Box sx={{ display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>
          {renderMobileMenu}
          {renderMenu}
        </Box>
      </Box>
    </>
  );
}
