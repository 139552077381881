import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import configServ from "../../services/config";
import { format } from "date-fns";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Box,
  Stack,
  Tooltip,
  IconButton,
  TextField,
  Select,
  MenuItem,
  InputAdornment,
  Typography,
  Card,
  Grid,
  TableSortLabel,
  CardContent,
  FormControl,
  InputLabel,
} from "@mui/material";
import LoaderNew from "../../Components/Loader/LoaderNew";
import LoaderBackdrop from "../../Components/Loader/LoaderBackdrop";

const UserHistoryTable = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [moreFilter, setMoreFilter] = useState("");
  const [userHistory, setUserHistory] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState("false");
  const [openBackdrop, setOpenBackdrop] = useState("false");
  const rowsPerPageOptions = [5, 20, 50, 100, 200];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
  const admin = "Admin";
  const staff = "Staff";
  const superRole = "Super"; // Avoid using "super" as it is a reserved keyword
  const customer = "Customer";

  const handleSearch = () => {
    featchUserHistory(searchTerm);
  };
  const handleReset = () => {
    setMoreFilter("");
    setSearchTerm("");
    featchUserHistory();
  };

  const handleChange = (event) => {
    setMoreFilter(event.target.value);
  };

  const headerCellStyle = {
    fontSize: 14, // Adjust the font size as needed
    fontWeight: "bold",
    backgroundColor: "#4CB5F5",
    color: "white",
    //px: 0,
    py: 1,
    justifyContent: "center",
  };

  const evenRowStyle = {
    background: "#f5f5f5",
  };

  const oddRowStyle = {
    background: "white",
  };

  const rowStyle = {
    "&:hover": {
      background: "#f1f9fe",
    },
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

  const headers = [
    { label: "CUSTOMER", id: "" },
    { label: "Role", id: "" },
    { label: "Location", id: "" },
    { label: "Login Time", id: "" },
    { label: "Company Name", id: "" },
  ];

  const featchUserHistory = async () => {
    try {
      setLoading(true);
      //   scrollToTop();

      const dataToSend = {
        page: page, // API expects 1-based page numbers
        pageSize: rowsPerPage, // Number of records per page
        filterBy: "cust_name", // Static filter, change if needed
        searchTerm: searchTerm,
        moreFilter: moreFilter,
      };

      const res = await configServ.getUserLogHistory(dataToSend);
      if (res.status === 200) {
        setUserHistory(res.data);
        setCount(res.total);
      } else {
        setUserHistory([]);
        setCount(0);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    featchUserHistory();
  }, [page, moreFilter]);

  useEffect(() => {
    if (page === 0) {
      featchUserHistory();
    } else {
      setPage(0);
    }
  }, [rowsPerPage, moreFilter]);

  return (
    <>
    {/* <LoaderBackdrop open={openBackdrop}/> */}
      <Grid alignItems="center" marginTop="3px">
        <Typography
          sx={{ fontSize: "1.5rem", fontWeight: "bold" }} // Customize size and weight here
        >
          User Log History
        </Typography>
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="flex-start"
        spacing={2}
        padding={2}
      >
        <Grid item xs={12} sm={6} md={2}>
          <Typography
            sx={{ fontSize: "1rem", fontWeight: "bold" }} // Customize size and weight here
          >
            Log History : {count}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <Select
            value="Customer_Name" // Match this with the MenuItem's value
            sx={{ width: "100%", height: 50 }}
            disabled // Static selection for "Customer Name"
          >
            <MenuItem value="Customer_Name">Customer Name</MenuItem>
          </Select>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <TextField
            variant="outlined"
            placeholder="Search by Customer Name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleSearch}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                height: 50, // Apply height to the input box
              },
            }}
          />
        </Grid>

        <Grid item xs={8} sm={6} md={1.4}>
          <FormControl sx={{ m: 1, minWidth: 130 }}>
            <InputLabel>More Filter</InputLabel>
            <Select
              value={moreFilter}
              label="More Filter"
              onChange={handleChange}
              size="small"
              sx={{ height: "47px" }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={admin}>Admin</MenuItem>
              <MenuItem value={superRole}>Super</MenuItem>{" "}
              {/* Use the corrected constant */}
              <MenuItem value={staff}>Staff</MenuItem>
              <MenuItem value={customer}>Customer</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid
          item
          xs={1}
          sm={1}
          md={1}
          display="flex"
          justifyContent="flex-end"
        >
          <IconButton onClick={handleReset} sx={{ color: "green" }}>
            <RefreshIcon />
          </IconButton>
        </Grid>
      </Grid>

      {/* Desktop table view */}

      <TableContainer
        component={Paper}
        elevation={0}
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh", // Set full height
          display: { xs: "none", sm: "none", lg: "block" },
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={headerCellStyle}>S.No.</TableCell>
              {headers.map((header) => (
                <TableCell key={header.id} sx={headerCellStyle}>
                  {header.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell
                  colSpan={headers.length + 1}
                  sx={{ textAlign: "center" }}
                >
                  <div>
                    <LoaderNew />
                  </div>
                </TableCell>
              </TableRow>
            ) : userHistory.length > 0 ? (
              userHistory.map((row, rowIndex) => (
                <TableRow
                  key={rowIndex}
                  sx={{
                    ...rowStyle,
                    ...(rowIndex % 2 === 0 ? evenRowStyle : oddRowStyle),
                  }}
                >
                  <TableCell>{page * rowsPerPage + rowIndex + 1}</TableCell>
                  <TableCell>{row.Customer_Name}</TableCell>
                  <TableCell>{row.Role}</TableCell>
                  <TableCell>{row.Location || "Data not found"}</TableCell>
                  <TableCell>
                    {row.Login_Time
                      ? format(new Date(row.Login_Time), "dd MMM yyyy, hh:mm a")
                      : "N/A"}
                  </TableCell>
                  <TableCell>{row.Company_Name}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={headers.length + 1}>
                  <Box textAlign={"center"}>
                    <Typography variant="body1" color={"red"}>
                      No data found.
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )}
            {emptyRows > 0 && (
              <TableRow style={{ height: 1 * emptyRows }}>
                <TableCell colSpan={headers.length + 1} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Mobile View (Card Layout) */}
      <Box
        sx={{
          display: { xs: "block", sm: "block", md: "none" }, // Only for mobile screens
        }}
      >
        {loading ? (
          <Box textAlign={"center"}>
            <LoaderNew />
          </Box>
        ) : userHistory.length > 0 ? (
          userHistory.map((row, rowIndex) => (
            <Card key={rowIndex} sx={{ marginBottom: 2 }}>
              <CardContent>
                <Typography variant="h6">
                  S.No. {page * rowsPerPage + rowIndex + 1}
                </Typography>
                <Typography variant="body1">
                  Customer Name: {row.Customer_Name}
                </Typography>
                <Typography variant="body1">Role: {row.Role}</Typography>
                <Typography variant="body1">
                  Location: {row.Location || "Data not found"}
                </Typography>
                <Typography variant="body1">
                  Login Time:{" "}
                  {row.Login_Time
                    ? format(new Date(row.Login_Time), "dd MMM yyyy, hh:mm a")
                    : "N/A"}
                </Typography>
                <Typography variant="body1">
                  Company Name: {row.Company_Name}
                </Typography>
              </CardContent>
            </Card>
          ))
        ) : (
          <Box textAlign={"center"}>
            <Typography variant="body1" color={"red"}>
              No data found.
            </Typography>
          </Box>
        )}
      </Box>

      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default UserHistoryTable;
