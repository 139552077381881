import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import CheckAndReturn from '../../utility/CheckAndReturn';
import { useSelector } from 'react-redux';
import configServ from '../../services/config';

const Sessions = ({ id, sessions, openSession, closeSession, refreshSession }) => {
  const { user_id, company_id } = useSelector((state) => state.GlobalVariables);
  const [formData, setFormData] = useState({});
  const [validationErrors, setValidationErrors] = useState({});

  const headers = ['S.No.', 'Start', 'End', 'Session', 'Code', 'Is Active'];

  const headerCellStyle = {
    fontSize: 14,
    fontWeight: 'bold',
    backgroundColor: '#4CB5F5',
    color: 'white',
  };

  const cellStyle = {
    py: 0,
    my: 0,
  }

  const evenRowStyle = {
    background: '#f5f5f5',
  };

  const oddRowStyle = {
    background: 'white',
  };

  const rowStyle = {
    '&:hover': {
      background: '#f1f9fe',
    },
  };

  useEffect(() => {
    if (formData.start_date && formData.end_date) {
      const startYear = new Date(formData.start_date).getFullYear();
      const endYear = new Date(formData.end_date).getFullYear();

      const session = `${startYear.toString().slice(-2)}-${endYear.toString().slice(-2)}`;
      const session_code = `${startYear.toString().slice(-2)}${endYear.toString().slice(-2)}`;
      setFormData((prevFormData) => ({
        ...prevFormData,
        'session': session,
        'session_code': session_code
      }));
    }
  }, [formData.start_date, formData.end_date]);

  const changeHandler = async (e) => {
    try {
      const { name, value } = e.target;

      setFormData((state) => ({
        ...state,
        [name]: value
      }));

      // Reset the validation error when the user starts typing
      if (validationErrors[name]) {
        setValidationErrors((prevValidationError) => ({
          ...prevValidationError,
          [name]: '',
        }));
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  const validateForm = async () => {
    try {
      let errors = {};
      if (!formData.start_date) {
        errors.start_date = "required";
      }
      if (!formData.end_date) {
        errors.end_date = "required";
      }
      if (!formData.session) {
        errors.session = "required";
      }
      if (!formData.session_code) {
        errors.session_code = "required";
      }
      setValidationErrors(errors);
      return Object.values(errors).every((error) => error === "");
    }
    catch (e) {
      console.log(e);
    }
  }

  const handleSubmit = async () => {
    try {
      const isValid = await validateForm();
      if (isValid === false) {
        return false;
      }
      const dataToSend = {
        start_date: formData.start_date,
        end_date: formData.end_date,
        session: formData.session,
        session_code: formData.session_code,
        company_id: id,
        user_id: user_id
      }
      const res = await configServ.createSession(dataToSend);
      if (res.status === 200) {
        handleReset();
        refreshSession();
        alert(res.message);
      }
      else {
        alert(res.message);
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  const handleReset = () => {
    setFormData({});
    setValidationErrors({});
  }

  return (
    <>
      <Dialog
        open={openSession}
        onClose={closeSession}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle id="alert-dialog-title">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box flex={1} textAlign="center">
              <Typography variant="h5" color="#1e61b4" fontSize={{ xs: '20px', md: '22px' }} fontWeight={700}>
                Sessions
              </Typography>
            </Box>
            <Box position="absolute" right={10}>
              <IconButton onClick={closeSession}>
                <DisabledByDefaultIcon color="error" />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Grid container alignItems={'center'} spacing={1}>
            <Grid item xs={12} md={12} justifyContent={'flex-start'}>
              <Typography sx={{ fontSize: '16px', fontWeight: 'bolder' }}>Create Session</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth size="small"
                type="date" name="start_date"
                label='Start Date' InputLabelProps={{ shrink: true }}
                sx={{ background: 'white' }}
                onChange={changeHandler} value={formData.start_date || ''}
                error={!!validationErrors.start_date}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth size="small"
                type="date" name="end_date"
                label='End Date' InputLabelProps={{ shrink: true }}
                sx={{ background: 'white' }}
                onChange={changeHandler} value={formData.end_date || ''}
                error={!!validationErrors.end_date}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                aria-readonly
                fullWidth size="small"
                type="text" name="session"
                label='Session' sx={{ background: 'white' }}
                value={formData.session || ''} InputLabelProps={{ shrink: true }}
                error={!!validationErrors.session}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Button type='submit' color='primary' variant="contained" onClick={handleSubmit} startIcon={<SaveIcon />}>
                Save
              </Button>
            </Grid>
          </Grid>

          <TableContainer component={Paper} sx={{ py: 2 }}>
            <Typography sx={{ fontSize: '16px', fontWeight: 'bolder' }}>Sessions: {sessions.length}</Typography>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {
                    headers.map(item => (
                      <TableCell key={item} sx={headerCellStyle}>
                        {item}
                      </TableCell>
                    ))
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  sessions.length > 0 ?
                    sessions.map((item, rowIndex) => (
                      <TableRow key={rowIndex}
                        sx={{
                          ...rowStyle,
                          ...(rowIndex % 2 === 0 ? evenRowStyle : oddRowStyle),
                        }}
                      >
                        <TableCell sx={{ cellStyle }}>
                          {rowIndex + 1}
                        </TableCell>
                        <TableCell sx={{ cellStyle }}>
                          {CheckAndReturn.transformToDate3(item.start_date)}
                        </TableCell>
                        <TableCell sx={{ cellStyle }}>
                          {CheckAndReturn.transformToDate3(item.end_date)}
                        </TableCell>
                        <TableCell sx={{ cellStyle }}>
                          {item.session}
                        </TableCell>
                        <TableCell sx={{ cellStyle }}>
                          {item.session_code}
                        </TableCell>
                        <TableCell sx={{ cellStyle }}>
                          {item.is_active === 1 ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />}
                        </TableCell>
                      </TableRow>
                    ))
                    :
                    <TableRow>
                      <TableCell colSpan={12} sx={{ textAlign: 'center' }}>
                        <Box>
                          No Session Found.
                        </Box>
                      </TableCell>
                    </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default Sessions
