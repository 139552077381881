import React, { useState, useEffect } from 'react';
import {
    Stack,
    Typography,
    Box,
    CircularProgress,
    IconButton,
    Tooltip,
    Fade,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Grid,
    Card,
    CardHeader,
    CardMedia,
    CardContent,
    CardActions,
    Divider
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { addItem, removeItem, qtyChange } from '../../../redux/reducers/Cart';
import configServ from '../../../services/config';
import { Autocomplete } from '@mui/material';
import LoaderNew from '../../../Components/Loader/LoaderNew';
import ReactReadMoreReadLess from "react-read-more-read-less";

//icon
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import CheckAndReturn from '../../../utility/CheckAndReturn';

export const CartItem = ({ data }) => {
    //#region code
    const { currency } = useSelector((state) => state.GlobalVariables);
    const { company_type } = useSelector((state) => state.admin);
    const [productImage, setProductImage] = useState([]);
    const [imageLoader, setImageLoader] = useState(false);
    const [item, setItem] = useState([]);
    const dispatch = useDispatch()
    const options = Array.from({ length: 1000 }, (_, index) => index + 1);

    const fetchItem = async () => {
        try {
            const result = await configServ.getitemdetail({ "id": data.id })
            setItem(result)
        } catch (err) {
            console.log(err)
        }
    }

    const fetchImage = async () => {
        setImageLoader(true)
        try {
            const result = await configServ.get_item_img({ "product_id": data.id })
            if (result.data.length !== 0) {
                result.data.map((item) => {
                    setProductImage((state) => ([
                        ...state,
                        `data:image/png;base64,${item.image}`
                    ]))
                })
                setImageLoader(false)
            } else {
                setProductImage(['https://dummyimage.com/400x400/7e8eff/ffffff&text=Product+Image'])
                setImageLoader(false)
            }
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchItem();
        fetchImage();
    }, [])

    const handleQuantityChange = (e, value) => {
        const dataToSend = {
            id: data.id,
            value: value
        };
        dispatch(qtyChange(dataToSend))
    }
    //#endregion

    return (
        <>
            {/* <Stack direction='row' spacing={2} alignItems="center" sx={{ background: 'white', border: 'solid 1px #33333355', borderRadius: '2px', height: { xs: '120px', md: '145px' }, width: { xs: '100%', md: '100%' }, px: '10px' }}>
                <Stack sx={{ width: { xs: '6rem', md: '6rem' } }}>
                    <Box
                        sx={{
                            width: '100%',
                            aspectRatio: 1 / 1,
                            position: 'relative'
                        }}
                    >
                        <Box
                            component="img"
                            sx={{
                                width: '100%',
                                aspectRatio: 1 / 1,
                                borderRadius: '7px',
                            }}
                            alt="Product"
                            src={productImage[0]} />
                        {imageLoader && (<Box
                            sx={{
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                top: 0,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#33333377',
                                borderRadius: '7px'
                            }}
                        >
                            <LoaderNew />
                        </Box>)}
                    </Box>
                </Stack>
                <Stack width='80%'>
                    <Typography
                        variant='h5'
                        sx={{
                            fontSize: 'medium',
                            fontWeight: 'bold',
                            color: '#333333dd'
                        }}
                    >
                        {`${item[0]?.item_name} `}{data?.item_size !== undefined ? `(${data?.item_size})` : ''}
                    </Typography>
                    <Typography
                        variant='body'
                        sx={{
                            fontSize: 'small',
                            color: '#333333dd'
                        }}
                    >
                        {item[0]?.description}
                    </Typography>
                    <Stack direction='row' justifyContent='space-between'>
                        <Stack direction='row' spacing={1}>
                            <Typography variant='body'>{currency}</Typography>
                            <Typography variant='body'>{data.qty * data.rate}</Typography>
                        </Stack>
                        <Stack direction='row' spacing={1} justifyContent='flex-end'>
                            <FormControl
                                fullWidth
                                size='small'
                                variant='standard'
                                sx={{
                                    minWidth: 80,
                                }}
                            >
                                <Autocomplete
                                    options={options}
                                    value={data.qty}
                                    getOptionLabel={(option) => String(option)}
                                    renderInput={(params) => (
                                        <TextField {...params} label={company_type !== 'service' ? `QTY (${data.unit})` : `Validity (${data.unit})`} variant="standard" />
                                    )}
                                    onChange={handleQuantityChange} />
                            </FormControl>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack alignItems='flex-start' sx={{ height: '100%' }}>
                    <Tooltip title='Remove Item' placement='top' arrow TransitionComponent={Fade} TransitionProps={{ timeout: 300 }}>
                        <IconButton onClick={() => { dispatch(removeItem(data.id)); }}>
                            <CloseIcon fontSize='medium' color='black' />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </Stack> */}

            <Grid container alignItems={'center'} spacing={1} p={1}>
                <Card sx={{ width: '100%', background: '#fffff5', border: '1px solid #33333355' }}>
                    <Grid container item xs={12} display={'flex'} justifyContent={'flex-end'}>
                        <Tooltip title='Remove Item' placement='top' arrow TransitionComponent={Fade} TransitionProps={{ timeout: 300 }}>
                            <IconButton onClick={() => { dispatch(removeItem(data.id)); }} color='error'>
                                <DisabledByDefaultIcon fontSize='large' />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                        direction={{ xs: 'column', md: 'row' }} // Column on xs, Row on md and up
                    >
                        <Grid item xs={12} md={4}>
                            <Box position="relative" height="80" width="100%">
                                {imageLoader ? (
                                    <Box display="flex" justifyContent="center" alignItems="center" height="80">
                                        <LoaderNew />
                                    </Box>
                                ) : (
                                    <CardMedia
                                        sx={{ objectFit: 'contain' }}
                                        component="img"
                                        alt="product"
                                        height="80"
                                        image={productImage[0]}
                                    />
                                )}
                            </Box>
                        </Grid>
                        <Grid container item alignItems={'flex-end'} justifyContent={'center'} xs={12} md={8}>
                            <Typography gutterBottom variant="h6" fontWeight={'bold'}>
                                {CheckAndReturn.checkTextNullAndUndefined(item[0]?.item_name)}
                                {(data?.item_size !== null && data?.item_size !== undefined) ? `(${data?.item_size})` : ''}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider sx={{ backgroundColor: '#33333377', width: '100%', m: 0 }} />
                    <CardContent>
                        {
                            item[0]?.description &&
                            <Grid item xs={12}>
                                <ReactReadMoreReadLess
                                    charLimit={50}
                                    readMoreText={"...Read more ▼"}
                                    readLessText={"Read less ▲"}
                                >
                                    {item[0]?.description}
                                </ReactReadMoreReadLess>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <Box display="flex" flexDirection={{ md: 'row' }} justifyContent="space-between" alignItems="center">
                                <Typography variant='h6' m={0} p={0}>{currency} <b>{data.qty * data.rate}</b></Typography>
                                <FormControl
                                    fullWidth
                                    size='small'
                                    variant='standard'
                                    sx={{
                                        maxWidth: 100,
                                    }}
                                >
                                    <Autocomplete
                                        options={options}
                                        value={data.qty}
                                        getOptionLabel={(option) => String(option)}
                                        renderInput={(params) => (
                                            <TextField {...params} label={company_type !== 'service' ? `QTY (${data.unit})` : `Validity (${data.unit})`} variant="standard" />
                                        )}
                                        onChange={handleQuantityChange} />
                                </FormControl>
                            </Box>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </>
    )
}
