import React, { memo, useRef, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Box,
  Tooltip,
  CircularProgress,
  Grid,
  FormControl,
  NativeSelect,
  InputLabel,
  Typography,
  Stack,
  TableSortLabel,
  Button,
  IconButton,
  CardContent,
  Card,
  CardActions,
  Select,
  MenuItem,
  Menu,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";

import { useReactToPrint } from "react-to-print";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import PrintIcon from "@mui/icons-material/Print";
import DownloadIcon from "@mui/icons-material/Download";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SavingsIcon from "@mui/icons-material/Savings";
import EditIcon from "@mui/icons-material/Edit";
import RefreshIcon from "@mui/icons-material/Refresh";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { useEffect } from "react";
import { styled } from "@mui/system";
import configServ from "../../../services/config";
import { useDispatch, useSelector } from "react-redux";
import CustomerVoucherPopup from "../../Voucher/CustomerVoucherPopup";
import SearchBox from "../../Search/SearchBox";
import CheckAndReturn from "../../../utility/CheckAndReturn";
import { triggerLoader } from "../../../redux/reducers/LoaderTrigger";
import OutstandingPopup from "../TransactionCards/OutstandingReport/OutstandingPopup";
import { useLocation, useNavigate } from "react-router-dom";
import useDebounce from "../../hooks/useDebounce";
import FollowUpPopup from "../../followUp/FollowUpPopup";
import useDeviceDetect from "../../Detect/useDeviceDetect ";
import bell_notification from "../../Assets/images/bell_notification.gif";
import DownloadCustomersTablePdf from "../../Download/DownloadCustomersTablePdf";
import DownloadCustomersTableExcel from "../../Download/DownloadCustomersTableExcel";

import * as XLSX from "xlsx";
// import jsPDF from "jspdf";
// import html2canvas from "html2canvas";
import Swal from "sweetalert2";
import LoaderNew from "../../Loader/LoaderNew";
import Constants from "../../../utility/Constants";

//FUNCTION
const CustomersTable = ({ handleEdit, isChanged }) => {
  //#region code
  const dispatch = useDispatch();
  const {
    company_id,
    companyName,
    companyAddress,
    companyState,
    companyCountry,
  } = useSelector((state) => state.GlobalVariables);
  const { isMobile } = useDeviceDetect();
  const rowsPerPageOptions = [20, 50, 100, 200];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
  const [customerList, setCustomerList] = useState([]);
  const [filterBy, setFilterBy] = useState("Standard");
  const [searchBy, setSearchBy] = useState("fname");
  const [search, setSearch] = useState(null);
  const debounceSearch = useDebounce(search, 1000);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [openVoucher, setOpenVoucher] = useState(false);
  const [customer_id, setCustomer_id] = useState(null);
  const [outstandingCustomerData, setOutstandingCustomerData] = useState(null);
  const [openOutstandingPopup, setOpenOutstandingPopup] = useState(false);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("fname");
  const [voucherChanged, setVoucherChanged] = useState(false);
  const [followup, setFollowup] = useState(null);
  const [isPrint, setIsPrint] = useState(false);
  const [isDownload, setIsDownload] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false); // Controls PDF generation state
  const [companyLogo, setCompanyLogo] = useState("");
  const isMenuOpen = Boolean(anchorEl);
  const [filteredReport, setFilteredReport] = useState(false);

  const location = useLocation();
  const [redirectCustomerName, setRedirectCustomerName] = useState(
    location.state
  );

  useEffect(() => {
    if (redirectCustomerName) {
      setSearchBy("fname");
      setSearch(redirectCustomerName);
      fetchCustomerList(redirectCustomerName);
    } else {
      setSearch("");
    }
  }, [redirectCustomerName]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (!redirectCustomerName && !followup) {
      fetchCustomerList();
    }
  }, [isChanged, voucherChanged, page, rowsPerPage, order, orderBy, followup, filterBy]);

  useEffect(() => {
    if (!redirectCustomerName) {
      if (page === 0) {
        fetchCustomerList();
      } else {
        setPage(0);
      }
    }
  }, [debounceSearch]);

  const fetchCustomerList = async (isRedirect = null) => {
    try {
      setLoading(true);
      const dataToSend = {
        company_id: company_id,
        search_by: searchBy,
        search: isRedirect === null ? debounceSearch : isRedirect,
        per_page: rowsPerPage,
        page: page,
        sort_by: orderBy,
        order,
        filterBy,
      };
      const res = await configServ.getCustomerList(dataToSend);
      if (res.status === 200) {
        setCustomerList(res.data);
        setCount(res.total);
      } else {
        setCustomerList([]);
        setCount(0);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadExcel = () => {
    console.log("handleDownloadExcel triggered");
    downloadExcel();
  };

  const fetchDownloadReport = async () => {
    try {
      // Prepare the data to send
      const dataToSend = {
        company_id: company_id,
        // If filteredReport is true, include the filters; otherwise, don't include them at all
        ...(filteredReport && { search_by: searchBy }),
        ...(filteredReport && { search: debounceSearch }),
        ...(filteredReport && { per_page: rowsPerPage }),
        ...(filteredReport && { page: page }),
        ...(filteredReport && { filterBy: filterBy }),
        sort_by: orderBy, // Optional: Keep sorting even when filters are false
        order, // Sorting order
      };

      // Call the API
      const res = await configServ.getCustomerList(dataToSend);
      if (res.status === 200) {
        const result = res.data;
        return result;
      } else {
        return null;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const downloadExcel = async () => {
    try {
      const result = await fetchDownloadReport();
      // console.log(result)
      if (result) {
        await DownloadCustomersTableExcel(result, companyName); // Ensure to pass customer data
      }
    } catch (e) {
      console.log(e);
    }
  };
  const downloadPdf = async () => {
    try {
      const result = await fetchDownloadReport();
      // console.log(result)
      if (result) {
        DownloadCustomersTablePdf(companyName, result, companyLogo);
        
      }
    } catch (e) {
      console.log(e);
    }
  };

  const headers = [
    { id: "fname", label: "CUSTOMER" },
    { id: "customer_code", label: "USERNAME" },
    { id: "mobile", label: "MOBILE" },
    { id: "openingamount", label: "OPENING" },
    { id: "due_amount", label: "OUTSTANDING" },
    ...(!isGeneratingPDF ? [{ id: "status", label: "STATUS" }] : []), // Correctly formatted object
  ];

  const assignCompanyLogo = async () => {
    try {
      const result = await configServ.company_logo({ company_id: company_id });
      if (result && result.image) {
        // Assuming the result.image is already a base64 string
        setCompanyLogo(`data:image/png;base64,${result.image}`);
      } else {
        setCompanyLogo(null); // Set as null if no logo
      }
    } catch (err) {
      console.log("Error fetching company logo:", err);
      setCompanyLogo(null); // Handle error by clearing logo
    }
  };

  useEffect(() => {
    assignCompanyLogo();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const StyledCard = styled(Card)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#f9f9ff",
    //boxShadow: theme.shadows[1],
  }));

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  

  // Download as PDF
  const downloadPDF = () => {
    downloadPdf()
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, count);

  const headerCellStyle = {
    fontSize: 14, // Adjust the font size as needed
    fontWeight: "bold",
    backgroundColor: "#4CB5F5",
    color: "white",
    px: 0,
    textAlign: "center",
  };

  const cellStyle = {
    p: 0,
    my: 0,
    textAlign: "center",
  };

  const evenRowStyle = {
    background: "#f5f5f5",
  };

  const oddRowStyle = {
    background: "white",
  };

  const followUpRowStyle = {
    background: "#f7da00",
  };

  const rowStyle = {
    "&:hover": {
      background: "#f1f9fe",
    },
  };

  const handleSearchBy = (event) => {
    try {
      setSearchBy(event.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFilterBy = (event) => {
    try {
      setFilterBy(event.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const handleVoucherOpen = (params) => {
    setCustomer_id(params);
    setOpenVoucher(true);
  };

  const handleVoucherClose = () => {
    setOpenVoucher(false);
    setVoucherChanged(!voucherChanged);
  };

  const handleLedger = async (params) => {
    try {
      dispatch(triggerLoader());
      const dataToSend = {
        customer_id: params.id,
        company_id: company_id,
      };
      const res = await configServ.getCustomerLedger(dataToSend);
      if (res.status === 200) {
        setOpenOutstandingPopup(true);
        const data = {
          name: params.fname,
          address: params.address,
          customer: params,
          ledger: res.data,
        };
        setOutstandingCustomerData(data);
      } else {
        setOpenOutstandingPopup(true);
        setOutstandingCustomerData(null);
      }
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(triggerLoader());
    }
  };

  const handleOutstandingPopup = () => {
    try {
      if (openOutstandingPopup === true) {
        setOutstandingCustomerData(null);
      }
      setOpenOutstandingPopup(!openOutstandingPopup);
    } catch (e) {
      console.log(e);
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleFollowUp = async (params) => {
    try {
      const data = {
        id: params.id,
        name: params.fname,
        entity_type: "customer",
      };
      setFollowup(data);
    } catch (e) {
      console.log(e);
    }
  };

  const customerRef = useRef();
  const tableRef = useRef();

  const handlePrintData = () => {
    setIsPrint(true);
  };

  useEffect(() => {
    if (isPrint === true) {
      handlePrint();
      setIsPrint(false);
    }
  }, [isPrint]);

  const handlePrint = useReactToPrint({
    content: () => customerRef.current,
    documentTitle: "Customers",
  });

  const handleBellNotification = async (params) => {
    try {
      await handleFollowUp(params);
      const dataToSend = {
        follow_up_id: params.follow_up_id,
      };
      const res = await configServ.stopFollowUpNotification(dataToSend);
      if (res.status === 200) {
      }
    } catch (e) {
      console.log(e);
    }
  };

  //#endregion

  return (
    <>
      <Backdrop
        sx={(theme) => ({
          color: "#fff",
          zIndex: 1200, // Set high z-index to block all interactions
          position: "fixed", // Ensure it covers the whole screen
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        })}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* Follow Up */}
      <FollowUpPopup data={followup} closeFollowup={() => setFollowup(null)} />

      {/* Customer Ledger */}
      <OutstandingPopup
        openOutstandingPopup={openOutstandingPopup}
        handleOutstandingPopup={handleOutstandingPopup}
        outstandingCustomerData={outstandingCustomerData}
      />

      {/* Customer Voucher Pop up */}
      <CustomerVoucherPopup
        openVoucher={openVoucher}
        handleVoucherClose={handleVoucherClose}
        customer_id={customer_id}
      />

      <Grid
        container
        alignItems="flex-end"
        justifyContent="flex-start"
        style={{ pt: 1 }}
        spacing={1}
        direction={"row"}
      >
        <Grid container item xs={12} md={2} justifyContent={"flex-start"}>
          <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
            Customer List: {count}
          </Typography>
        </Grid>
        <Grid container item xs={12} md={1}
          justifyContent={{ xs: "center", md: "flex-start" }}
        >
          <Stack direction={"row"} spacing={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleMenuClick}
              startIcon={<DownloadIcon sx={{ ml: 2 }} />}
            ></Button>
            <Menu
              anchorEl={anchorEl}
              open={isMenuOpen}
              onClose={handleMenuClose}
            >
              <MenuItem>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filteredReport}
                      onClick={() => setFilteredReport(!filteredReport)}
                    />
                  }
                  label="With Filter"
                />
              </MenuItem>

              <MenuItem
                onClick={() => {
                  handleDownloadExcel();
                  handleMenuClose();
                }}
              >
                Excel Download
              </MenuItem>
              <MenuItem
                onClick={() => {
                  downloadPDF();
                  handleMenuClose();
                }}
              >
                PDF Download
              </MenuItem>
            </Menu>
            <Button
              variant="contained"
              onClick={handlePrintData}
              startIcon={<PrintIcon sx={{ ml: 2 }} />}
              title="Print"
              sx={{
                backgroundColor: "#349af1",
                color: "white",
                "&:hover": {
                  backgroundColor: "#0868F8",
                  color: "white",
                },
                display: { xs: "flex", sm: "flex", lg: "none" }, // Show on mobile and tablet, hide on desktop
              }}
            />
          </Stack>
        </Grid>
        {redirectCustomerName ? (
          <>
            <Grid container item xs={12} md={6} justifyContent={"flex-end"}>
              <Button
                variant="contained"
                title="Referesh"
                color="success"
                startIcon={<RefreshIcon sx={{ ml: 1, color: "white" }} />}
                onClick={() => {
                  setRedirectCustomerName(null);
                }}
              />
            </Grid>
          </>
        ) : (
          <>
          <Grid item xs={12} md={2}>
              <Box sx={{ minWidth: 120, marginLeft: "0px" }}>
                <FormControl fullWidth>
                  <InputLabel variant="standard" htmlFor="uncontrolled-native">
                    Customer Type
                  </InputLabel>
                  <NativeSelect
                    value={filterBy}
                    onChange={handleFilterBy}
                    inputProps={{
                      name: "filterBy",
                      id: "uncontrolled-native",
                    }}
                  >
                    {
                    Constants.customerTypes.map((item, index) => (
                      <option key={index} value={item.value}>{item.label} </option>
                    ))
                  }
                  </NativeSelect>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ minWidth: 120, marginLeft: "0px" }}>
                <FormControl fullWidth>
                  <InputLabel variant="standard" htmlFor="uncontrolled-native">
                    Search By
                  </InputLabel>
                  <NativeSelect
                    value={searchBy}
                    onChange={handleSearchBy}
                    inputProps={{
                      name: "searchBy",
                      id: "uncontrolled-native",
                    }}
                  >
                    <option value={"fname"}>Customer Name</option>
                    <option value={"customer_code"}>Username</option>
                    <option value={"address"}>Address</option>
                    <option value={"email"}>Email</option>
                    <option value={"mobile"}>Mobile</option>
                  </NativeSelect>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} justifyContent={"flex-end"}>
              <SearchBox search={search} setSearch={setSearch} />
            </Grid>
          </>
        )}
      </Grid>

      {isMobile ? (
        <>
          <Grid container alignItems={"center"} spacing={1} mt={1}>
            <Grid container item xs={6} md={6} justifyContent={"center"}>
              <FormControl fullWidth size="small">
                <InputLabel>Sort By</InputLabel>
                <Select
                  fullWidth
                  size="small"
                  name="order_by"
                  label="Sort By"
                  value={orderBy}
                  onChange={(e) => setOrderBy(e.target.value)}
                  sx={{ background: "white" }}
                >
                  {headers.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid container item xs={6} md={6} justifyContent={"center"}>
              <FormControl fullWidth size="small">
                <InputLabel>Order</InputLabel>
                <Select
                  fullWidth
                  size="small"
                  name="order"
                  label="Order"
                  value={order}
                  onChange={(e) => setOrder(e.target.value)}
                  sx={{ background: "white" }}
                >
                  <MenuItem key={1} value={"asc"}>
                    {"Ascending"}
                  </MenuItem>
                  <MenuItem key={2} value={"desc"}>
                    {"Descending"}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Box mt={1} ref={customerRef}>
            {loading ? (
              <Box sx={{ textAlign: "center", mt: 4 }}>
                <LoaderNew />
              </Box>
            ) : (
              <>
                {(isPrint || isDownload) && (
                  <>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: 600,
                        textAlign: "center",
                      }}
                    >
                      {companyName}
                    </Typography>
                    <Typography sx={{ textAlign: "center" }}>
                      {companyAddress}, {companyState}, {companyCountry}
                    </Typography>
                  </>
                )}

                {customerList.map((row, rowIndex) => (
                  <StyledCard key={rowIndex}>
                    <CardContent>
                      <Stack direction={"row"}>
                        {row.follow_up_date && (
                          <img
                            src={bell_notification}
                            alt=""
                            width={40}
                            onClick={() => handleBellNotification(row)}
                            style={{ cursor: "pointer" }}
                          />
                        )}
                        <Typography variant="h6" gutterBottom>
                          {page * rowsPerPage + rowIndex + 1}. {row.fname}
                        </Typography>
                      </Stack>
                      <Typography variant="body2" color="textSecondary">
                        {row.address ?? ""}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Customer Code:</strong> {row.customer_code}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Mobile:</strong> {row.mobile}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Opening Amount:</strong> ₹
                        {row.openingamount ?? 0}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Dues Amount:</strong> ₹
                        {CheckAndReturn.makePositive(
                          CheckAndReturn.roundToInteger(row.due_amount ?? 0)
                        )}
                        {(row.due_amount ?? 0) > 0 ? (
                          <span style={{ marginLeft: 4, color: "red" }}>
                            Dr
                          </span>
                        ) : (
                          <span style={{ marginLeft: 4, color: "green" }}>
                            Cr
                          </span>
                        )}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Status:</strong>{" "}
                        {row.status === 1 ? (
                          <CheckCircleIcon color="success" />
                        ) : (
                          <CancelIcon color="error" />
                        )}
                      </Typography>
                    </CardContent>
                    {isPrint === false && isDownload === false && (
                      <CardActions>
                        <Grid container spacing={1}>
                          <Grid item>
                            <Tooltip title="Ledger" arrow>
                              <IconButton onClick={() => handleLedger(row)}>
                                <VisibilityIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <Grid item>
                            <Tooltip title="Edit" arrow>
                              <IconButton
                                onClick={() => {
                                  handleEdit(row);
                                  scrollToTop();
                                }}
                              >
                                <EditIcon color="primary" />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <Grid item>
                            <Tooltip title="Pay" arrow>
                              <IconButton
                                onClick={() => handleVoucherOpen(row.id)}
                              >
                                <SavingsIcon color="success" />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <Grid item>
                            <Tooltip title="Follow Up" arrow>
                              <IconButton onClick={() => handleFollowUp(row)}>
                                <BookmarkIcon color="warning" />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </CardActions>
                    )}
                  </StyledCard>
                ))}
              </>
            )}
          </Box>
          <Box mt={2}>
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </>
      ) : (
        <>
          <TableContainer
            component={Paper}
            elevation={0}
            sx={{
              display: "flex",
              flexDirection: "column",
              minHeight: "100vh", // Ensures minimum full height
              // maxHeight: "auto",  // This is implied by default
            }}
            ref={tableRef}
          >
            {(isPrint || isDownload) && (
              <>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  {companyName}
                </Typography>
                <Typography sx={{ textAlign: "center" }}>
                  {companyAddress}, {companyState}, {companyCountry}
                </Typography>
              </>
            )}
            <Box sx={{ flexGrow: 1, overflow: "auto", mt: 2 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell sx={headerCellStyle}>S.No.</TableCell>
                    {headers.map((column) => (
                      <TableCell
                        key={column.id}
                        sortDirection={orderBy === column.id ? order : false}
                        sx={headerCellStyle}
                      >
                        <TableSortLabel
                          active={orderBy === column.id}
                          direction={orderBy === column.id ? order : "asc"}
                          onClick={() => handleRequestSort(column.id)}
                        >
                          {column.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    {!isGeneratingPDF &&
                      isPrint === false &&
                      isDownload === false && (
                        <TableCell sx={headerCellStyle}>Action</TableCell>
                      )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading === false ? (
                    customerList.length > 0 ? (
                      customerList.map((row, rowIndex) => (
                        <TableRow
                          key={rowIndex}
                          sx={{
                            ...rowStyle,
                            ...(rowIndex % 2 === 0
                              ? evenRowStyle
                              : oddRowStyle),
                          }}
                        >
                          <TableCell sx={cellStyle}>
                            <Stack
                              direction={"row"}
                              alignItems={"center"}
                              mx={1}
                            >
                              {page * rowsPerPage + rowIndex + 1}
                              {row.follow_up_date && (
                                <img
                                  src={bell_notification}
                                  alt=""
                                  width={30}
                                  onClick={() => handleBellNotification(row)}
                                  style={{ cursor: "pointer" }}
                                />
                              )}
                            </Stack>
                          </TableCell>
                          <TableCell sx={{ ...cellStyle, textAlign: "left" }}>
                            {row.fname} -{" "}
                            <span style={{ color: "gray" }}>
                              {row.address ?? ""}
                            </span>
                          </TableCell>
                          <TableCell sx={cellStyle}>
                            {row.customer_code}
                          </TableCell>
                          <TableCell sx={cellStyle}>{row.mobile}</TableCell>
                          <TableCell sx={cellStyle}>
                            ₹{row.openingamount ?? 0}
                          </TableCell>
                          <TableCell sx={cellStyle}>
                            ₹
                            {CheckAndReturn.makePositive(
                              CheckAndReturn.roundToInteger(row.due_amount ?? 0)
                            )}
                            {(row.due_amount ?? 0) > 0 ? (
                              <span style={{ marginLeft: 4, color: "red" }}>
                                Dr
                              </span>
                            ) : (
                              <span style={{ marginLeft: 4, color: "green" }}>
                                Cr
                              </span>
                            )}
                          </TableCell>
                          {!isGeneratingPDF && (
                            <TableCell sx={cellStyle}>
                              {row.status === 1 ? (
                                <CheckCircleIcon color="success" />
                              ) : (
                                <CancelIcon color="error" />
                              )}
                            </TableCell>
                          )}
                          {!isGeneratingPDF && (
                            <>
                              {isPrint === false && isDownload === false && (
                                <TableCell sx={cellStyle}>
                                  <Stack direction={"row"} spacing={1}>
                                    <Tooltip title="Ledger" arrow>
                                      <VisibilityIcon
                                        onClick={() => handleLedger(row)}
                                        sx={{ cursor: "pointer" }}
                                      />
                                    </Tooltip>
                                    <Tooltip title="Edit" arrow>
                                      <EditIcon
                                        color="primary"
                                        onClick={() => {
                                          handleEdit(row);
                                          scrollToTop();
                                        }}
                                        sx={{ cursor: "pointer" }}
                                      />
                                    </Tooltip>
                                    <Tooltip title="Pay" arrow>
                                      <SavingsIcon
                                        color="success"
                                        onClick={() =>
                                          handleVoucherOpen(row.id)
                                        }
                                        sx={{ cursor: "pointer" }}
                                      />
                                    </Tooltip>
                                    <Tooltip title="Follow Up" arrow>
                                      <BookmarkIcon
                                        onClick={() => handleFollowUp(row)}
                                        color={"warning"}
                                      />
                                    </Tooltip>
                                  </Stack>
                                </TableCell>
                              )}
                            </>
                          )}
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={12}>
                          <Box textAlign={"center"}>
                            <Typography variant="body1" color={"red"}>
                              No data found.
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )
                  ) : (
                    <TableRow>
                      <TableCell colSpan={12} sx={{ textAlign: "center" }}>
                        <Box>
                          <LoaderNew />
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </TableContainer>

          <TableContainer>
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </>
      )}
    </>
  );
};

export default memo(CustomersTable);
