import React, { useState, useEffect, memo } from 'react';
import { TextField, Button, Switch, FormControlLabel, MenuItem, Grid, Container, Typography, Box, CircularProgress } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import configServ from '../../services/config';
import { useSelector } from 'react-redux';

const ConfigurationForm = () => {
    //#region code
    const { company_id, user_id, is_admin } = useSelector((state) => state.GlobalVariables);

    const initialConfig = {
        customer_notifications_enabled: false,
        invoice_print_preference: 'Select',
        invoice_arrear_enabled: true,
        invoice_itemcode_enabled: true,
    }
    const [configData, setConfigData] = useState(initialConfig);
    const [isLoading, setIsLoading] = useState(true);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    // Fetch the existing configuration if available
    const fetchConfig = async () => {
        try {
            setIsLoading(true);
            if (Boolean(is_admin) === true) {
                const dataToSend = {
                    company_id: company_id
                }
                const res = await configServ.getConfiguration(dataToSend);
                if (res.status === 200) {
                    const result = res.data;
                    setConfigData({
                        customer_notifications_enabled: result.customer_notifications_enabled,
                        invoice_print_preference: result.invoice_print_preference,
                        invoice_arrear_enabled: result.invoice_arrear_enabled,
                        invoice_itemcode_enabled: result.invoice_itemcode_enabled,
                    });
                    setSuccessMessage('');
                    setErrorMessage('');
                }
                else {
                    setSuccessMessage('');
                    setErrorMessage(res.message);
                }
            }
        }
        catch (error) {
            console.error(error);
            setErrorMessage('Failed to fetch configuration');
        }
        finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchConfig();
    }, []);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setConfigData({
            ...configData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            const dataToSend = {
                company_id: company_id,
                user_id: user_id,
                ...configData,
            }
            const res = await configServ.createOrUpdateConfiguration(dataToSend);
            if (res.status === 200) {
                const result = res.data;
                setConfigData({
                    customer_notifications_enabled: result.customer_notifications_enabled,
                    invoice_print_preference: result.invoice_print_preference,
                    invoice_arrear_enabled: result.invoice_arrear_enabled,
                    invoice_itemcode_enabled: result.invoice_itemcode_enabled,
                });
                setSuccessMessage(res.message);
                setErrorMessage('');
            }
            else {
                setSuccessMessage('');
                setErrorMessage(res.message);
            }
        }
        catch (error) {
            console.log(error);
            setSuccessMessage('');
            setErrorMessage('Failed to create or update configuration');
        }
        finally {
            setIsLoading(false);
        }
    };

    const handleReset = () => {
        try {
            setConfigData(initialConfig);
            setSuccessMessage('');
            setErrorMessage('');
        }
        catch (error) {
            console.log(error);
        }
    }

    //#endregion

    return (
        <Container maxWidth="lg">
            <form onSubmit={handleSubmit}>
                <Box sx={{ width: '100%', backgroundColor: '#e3f3fc', padding: 5, marginTop: 5, border: 'none' }}>
                    <Grid container spacing={1}>
                        <Grid container xs={12} alignItems={'center'} justifyContent={'flex-start'} fontWeight={700}>
                            <Typography variant='h6' color={'#1e61b4'} fontWeight={600}>
                                Customer
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControlLabel
                                disabled={true}
                                control={
                                    <Switch
                                        checked={configData.customer_notifications_enabled}
                                        onChange={handleChange}
                                        name="customer_notifications_enabled"
                                    />
                                }
                                label="Enable Customer Email Notifications"
                            />
                        </Grid>

                        <Grid container xs={12} alignItems={'center'} justifyContent={'flex-start'} fontWeight={700}>
                            <Typography variant='h6' color={'#1e61b4'} fontWeight={600}>
                                Invoice
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={configData.invoice_arrear_enabled}
                                        onChange={handleChange}
                                        name="invoice_arrear_enabled"
                                    />
                                }
                                label="Enable Invoice Arrear"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={configData.invoice_itemcode_enabled}
                                        onChange={handleChange}
                                        name="invoice_itemcode_enabled"
                                    />
                                }
                                label="Show Item Code on print"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                disabled={true}
                                select
                                size='small'
                                fullWidth
                                label="Invoice Print Preference"
                                name="invoice_print_preference"
                                value={configData.invoice_print_preference}
                                onChange={handleChange}
                                variant="outlined"
                                sx={{
                                    background: 'white'
                                }}
                            >
                                <MenuItem value="Select">Select on print</MenuItem>
                                <MenuItem value="PDF">PDF</MenuItem>
                                <MenuItem value="Paper">Paper</MenuItem>
                            </TextField>
                        </Grid>

                        <Grid container item xs={12} justifyContent={'center'}>
                            <Button type="submit" variant="contained" color="primary" disabled={isLoading} startIcon={isLoading ? <CircularProgress size={'small'} /> : <SaveIcon />}>
                                {isLoading ? 'Loading...' : 'Save'}
                            </Button>
                        </Grid>

                        {errorMessage && (
                            <Grid container item xs={12} justifyContent={'center'} fontWeight={500} m={2}>
                                <Typography color="error">{errorMessage}</Typography>
                            </Grid>
                        )}

                        {successMessage && (
                            <Grid container item xs={12} justifyContent={'center'} fontWeight={500} m={2}>
                                <Typography color="success.main">{successMessage}</Typography>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </form>
        </Container>
    );
};

export default memo(ConfigurationForm);
