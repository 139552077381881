import React,{useState} from 'react'

import { BsPlusSquareFill, BsFillCalendar3EventFill, BsFillFileFill, BsXCircle } from "react-icons/bs";

import { Form, Container, Row, Col, FloatingLabel,Button } from 'react-bootstrap';
import { Stack, Paper, Divider, Typography, RadioGroup, Radio } from "@mui/material";
import MyFormControlLabel from '@mui/material/FormControlLabel';
import NoteCard from '../../NoteCards/NoteCard';
import AddPurchaseOrderItems from '../PurchaseOrder/AddPurchaseOrderItems';

function CreateReceiptsCard({title}) {

    const [notes, setNotes] = useState([])

    const addNote = ()=>{
        setNotes((state)=>([
            ...state,
            {}
        ]))
    }
    const deleteNote = (id)=>{
        let data = [...notes]
        data.splice(id,1)
        setNotes(data)
    }


  return (
    <>
    


        <div style={{ width: "100%", backgroundColor: "#DBDFEA99", padding: 20, borderRadius:'10px', border:'none' }}>
       
                <Row>
                    <Col sm={4}>
                        <Form.Label>Customer/Debitor*</Form.Label>
                        <Form.Control type="" placeholder='Enter Customer Name' />
                    </Col>
                    <Col sm={4}>
                        <Form.Label>Total Amounts*</Form.Label>
                        <Form.Control type="" />
                    </Col>
                    <Col sm={4}>
                        <Form.Label>Date*</Form.Label>
                        <Form.Control type="date"  />
                    </Col>

                </Row>
                <br/>
                <Row>
                    <Col sm={4}>
                        <Form.Label>Remarks*</Form.Label>
                        <FloatingLabel controlId="floatingTextarea2" label="">
                <Form.Control
                  as="textarea"
                  placeholder="Leave a comment here"
                  style={{ height: '65px' }}
                />
              </FloatingLabel>
                    </Col>
                    <Col sm={4}>
                        <Form.Label>Invoice Number</Form.Label>
                        <Form.Control type="" placeholder='' />
                    </Col>
                    <Col sm={4}>
                        <Form.Label>Pay Options*</Form.Label>
                        <Form.Select type=""placeholder=''>
                                <option>Select</option>
                                <option>By Case</option>
                                <option>By Bank</option>
                                
                            </Form.Select>
                    </Col>

                </Row>
                <br/>
                <Row>
                    <Col sm={1}>
                        <Button variant="primary">save</Button>
                    </Col>
                </Row>

        </div>
        <br/>
    </>
  )
}

export default CreateReceiptsCard