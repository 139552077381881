import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { CartItem } from "./CartItem";
import { assignCartNetAmount, assignCartDiscountAmount, assignCartMRP, assignCartTaxAmount } from "../../../redux/reducers/Cart";
import Calculation from "../../../utility/Calculations";

const CartDetails = ({ handleNext }) => {
    //#region code
    const { currency } = useSelector((state) => state.GlobalVariables);
    const dispatch = useDispatch()
    const { cartValue, cartMRP, cartDiscountAmount, cartTaxAmount, cartNetAmount } = useSelector((state) => state.cart)

    const totalAmount = () => {
        let totalMRP = 0, totalDiscount = 0, totalTaxAmount = 0, netAmount = 0;
        cartValue.map((item => {
            totalMRP += item.qty * item.rate;
            totalDiscount += item.qty * Calculation.discountAmount(item.rate, item.discount);
            totalTaxAmount += item.qty * Calculation.taxAmount(item.rate, item.discount, item.Taxrate);
            netAmount += item.qty * Calculation.netAmount(item.rate, item.discount, item.Taxrate);
        }));
        dispatch(assignCartMRP(totalMRP));
        dispatch(assignCartDiscountAmount(totalDiscount));
        dispatch(assignCartTaxAmount(totalTaxAmount));
        dispatch(assignCartNetAmount(netAmount));
    }
    useEffect(() => {
        totalAmount()
    }, [cartValue]);
    //#endregion

    return (
        <>
                <Grid container spacing={2} px={3}>
                    {/* First Grid (Cart Items) */}
                    <Grid item xs={12} md={6}>
                        {cartValue.map((item) => {
                            return (
                                <CartItem key={item.id} data={item} />
                            );
                        })}
                    </Grid>

                    {/* Second Grid (Price Details) */}
                    <Grid item xs={12} md={6} mt={2}
                        alignItems="center"
                        border={'solid 1px #33333355'}
                        borderRadius={'2px'}
                        p={3}
                        sx={{
                            background: '#fffff5',
                            height: 'auto', // Allow content-based height
                            alignSelf: 'flex-start', // Keep it aligned to the top
                        }}
                    >
                        <Grid item xs={12}>
                            <Typography variant='h6' color={'#898989'}>
                                <b>Price Details</b> ( <span>{cartValue.length} item(s)</span> )
                            </Typography>
                        </Grid>
                        <Divider sx={{ backgroundColor: '#33333377', width: '100%', my: 1 }} />
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant='h6' color={'#898989'}>MRP</Typography>
                                <Typography variant='h6' fontWeight={'bold'}>₹{cartMRP}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant='h6' color={'#898989'}>Discount</Typography>
                                <Typography variant='h6' fontWeight={'bold'}>-₹{cartDiscountAmount}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant='h6' color={'#898989'}>GST</Typography>
                                <Typography variant='h6' fontWeight={'bold'}>+₹{cartTaxAmount}</Typography>
                            </Box>
                        </Grid>
                        <Divider sx={{ backgroundColor: '#33333377', width: '100%', my: 1 }} />
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant='h6'>Total Amount</Typography>
                                <Typography variant='h6' fontWeight={'bold'}>{currency} ₹{cartNetAmount}</Typography>
                            </Box>
                        </Grid>
                        <Divider sx={{ backgroundColor: '#33333377', width: '100%', my: 1 }} />
                        <Grid container alignItems={'center'} justifyContent={'center'} my={1}>
                            <Grid item xs={12} md={4}>
                                <Button fullWidth size="small" variant="contained" color="success" onClick={handleNext}>
                                    Place Order
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
        </>
    )
}
export default CartDetails;