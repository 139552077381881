import React from 'react';
import { DataGrid } from '@mui/x-data-grid';

const DataGridTable = ({rows,columns, width='90%'}) => {

  // In your component's stylesheet or inline styles
  const alternateRowClass = {
    backgroundColor: 'lightgrey', // Define the background color for alternate rows
  };

  // Function to conditionally apply the alternating row class
  const getRowClassName = (params) => {
    return params.indexRelativeToCurrentPage % 2 === 1 ? alternateRowClass : '';
  };

  return (
    <div style={{ 
      height: '50vh', 
      width: width 
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        rowClassName={getRowClassName} // Apply the row class
        pageSizeOptions={[5, 10, 50]}        
      />
    </div>
  )
}

export default DataGridTable
