import React, { useState, useEffect } from "react";
import MasterEnteryCard from '../Card/MaterEntryCards/MasterEntryCard'
// import TableCard from "../Card/MaterEntryCards/TableCard";
import GroupTable from "../Card/MaterEntryCards/GroupTable";
import GroupCard from "../Card/MaterEntryCards/GroupCard";

import { Stack, Paper, Divider, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import CardHeadMenu from "../Card/CardHeadMenu/CardHeadMenu";

const Group = () => {
    //#region code
    const { company_id } = useSelector((state) => state.GlobalVariables);
    const [isChanged, setIsChanged] = useState(false)
    const [editData, setEditData] = useState({})
    const [isEdit, setIsEdit] = useState(false);
    const [isCreate, setIsCreate] = useState(false);

    const handleEdit = (data) => {
        setEditData(data);
        setIsCreate(true);
        setIsEdit(true);
    }

    const handlePopup = () => {
        setIsCreate(!isCreate);
    }
    //#endregion

    return (
        <>
            <CardHeadMenu
                prefix={"Na"}
                suffix={"Na"}
                title={"GROUP"}
                handlePopup={handlePopup}
                isEdit={isEdit}
                create={isCreate}
            />

            {
                isCreate &&
                <GroupCard isChanged={isChanged} setIsChanged={setIsChanged} editData={editData} />
            }

            <Divider />

            <GroupTable handleEdit={handleEdit} isChanged={isChanged} />
        </>
    )
}
export default Group;