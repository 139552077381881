import React, { memo } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InvoiceItemTable from './InvoiceItemTable';
import CloseIcon from '@mui/icons-material/Close';
import useDeviceDetect from '../../Detect/useDeviceDetect ';
import InvoiceItemCard from './InvoiceItemCard';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';

//FUNCTION
const InvoiceItemsPopUp = memo(({ openInvoiceItems, handleInvoiceItemsClose, invoiceItems }) => {
    const { isMobile } = useDeviceDetect();

    return (
        <>
            <Dialog
                open={openInvoiceItems}
                onClose={handleInvoiceItemsClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={false}
                PaperProps={{
                    sx: {
                        width: '80%',
                        height: '80%',
                    },
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box flex={1} textAlign="center">
                            <Typography variant="h5" color="#1e61b4" fontWeight={700}>
                                Invoice Items
                            </Typography>
                        </Box>
                        <Box position="absolute" right={16}>
                            <IconButton onClick={handleInvoiceItemsClose}>
                                <DisabledByDefaultIcon color="error" />
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    {
                        isMobile === true ?
                            <Box component={Paper} sx={{ padding: '16px', backgroundColor: '#fff', overflowY: 'auto', maxHeight: '80vh' }}>
                                {invoiceItems.length > 0 ? (
                                    <InvoiceItemCard invoiceItems={invoiceItems} />
                                ) : (
                                    <Typography variant="h6" align="center" color="red">
                                        No items found.
                                    </Typography>
                                )}
                            </Box>
                            :
                            <InvoiceItemTable invoiceItems={invoiceItems} />
                    }
                </DialogContent>
            </Dialog>
        </>
    )
});

export default memo(InvoiceItemsPopUp);
