import React, { useEffect, useState } from "react";
import {
  Card,
  Typography,
  Button,
  Stack,
  Box,
  CircularProgress,
  Tooltip,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import http from "../../../services/http";
import { useDispatch, useSelector } from "react-redux";
import { addItem, removeItem } from "../../../redux/reducers/Cart";
import { useNavigate } from "react-router-dom";

//icons
import MoreIcon from "@mui/icons-material/More";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import configServ from "../../../services/config";
import AlertDialog from "../../../Components/AlertDialog/AlertDialog";
import LoaderNew from "../../../Components/Loader/LoaderNew";
import readmore from "../../../Components/Assets/images/read-more.png";
import ProductDetailsDialogs from "./ProductDetailsDialogs";
import { ChromeReaderMode } from "@mui/icons-material";

const ProductCard = ({ data, view }) => {
  //#region code
  const [dataDialog,setDataDialog] = useState(null)
  const { currency } = useSelector((state) => state.GlobalVariables);
  const { company_type } = useSelector((state) => state.admin);
  const [productImage, setProductImage] = useState([]);
  const [imageLoader, setImageLoader] = useState(false);
  const dispatch = useDispatch();
  const cartValue = useSelector((state) => state.cart.cartValue);
  const [isInCart, setIsInCart] = useState(false);
  const navigate = useNavigate();
  const companyType = company_type;
  const [itemSize, setItemSize] = useState([]);
  const [formData, setFormData] = useState({});
  const [image, setImage] = useState(
    `https://dummyimage.com/400x400/7e8eff/ffffff&text=Product+Image`
  );
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [open, setOpen] = useState(false);
  
  const handleOpenDialog = (content) => {
    setDialogContent(content);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDialogContent("");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(name, ' ', value)
    setFormData((state) => ({
      ...state,
      [name]: value,
    }));
    if (name === "size_id") {
      const selectedSize = itemSize.filter((item) => value === item.id);
      // console.log(selectedSize[0])
      setFormData((state) => ({
        ...state,
        item_size: selectedSize[0].size,
      }));
    }
  };

  const fetchItemSize = async () => {
    try {
      const result = await configServ.getItemSize({ item_id: data.id });
      const filtered = result.filter((item) => {
        return item.size !== "Primary";
      });
      // console.log(filtered)
      setItemSize(filtered);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchImage = async () => {
    setImageLoader(true);
    try {
      const result = await configServ.get_item_img({ product_id: data.id });
      if (result.data.length !== 0) {
        result.data.map((item) => {
          // setProductImage([...productImage,`data:image/png;base64,${item.image}`])
          setProductImage((state) => [
            ...state,
            `data:image/png;base64,${item.image}`,
          ]);
        });
        setImageLoader(false);
      } else {
        setProductImage([
          "https://dummyimage.com/400x400/7e8eff/ffffff&text=Product+Image",
        ]);
        setImageLoader(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const manageCart = () => {
    const dataToSend = {
      ...formData,
      id: data.id,
      company_id: data.company_id,
      itemname: data.item_name,
      hsn_code: data.hsncode,
      Taxrate: data.tax_rate,
      discount: data.discount,
      rate: data.rate_unit,
      item_code: data.item_code,
      unit: data.unit,
      issizeAdded: Boolean(data.issizeAdded),
    };
    if (!isInCart) {
      if (Boolean(data.issizeAdded)) {
        if (formData.item_size) {
          dispatch(addItem(dataToSend));
        } else {
          handleOpenDialog("Please select a size for the item");
        }
      } else {
        dispatch(addItem(dataToSend));
      }
    } else {
      dispatch(removeItem(data.id));
    }
  };

  const isItemInCart = () => {
    const inCart = cartValue.find((item) => {
      return item.id === data.id;
    });
    if (inCart !== undefined) {
      setIsInCart(true);
    } else {
      setIsInCart(false);
    }
  };

  useEffect(() => {
    isItemInCart();
  }, [cartValue, data.id]);

  useEffect(() => {
    // fetchImage()
    // isItemInCart()
    if (data?.id) {
      // fetchItemSize();
    }
  }, [data.id]);

  const handleClickOpen = (param) => {
    setDataDialog(param);
    setOpen(true); // Open dialog
  };
  
  const handleClose = () => {
    setOpen(false); 
    setDataDialog(null);
  };

  //#endregion

  return (
    <>
      <AlertDialog
        open={dialogOpen}
        setOpen={handleCloseDialog}
        title="Alert"
        message={dialogContent}
        buttonTitle="Ok"
      />
      <ProductDetailsDialogs open={open} handleClose={handleClose} dataDialog={dataDialog}/>
      <Card
        // variant='outlined'
        sx={{
          // aspectRatio:16/6,
          ...(view !== "list" && { height: "10rem" }),
          padding: "15px",
        }}
        elevation={isInCart ? 10 : 1}
      >
        {/* <Button onClick={abc}>abc</Button> */}
        <Stack
          spacing={1}
          direction={view === "list" ? "row" : "column"}
          justifyContent="space-between"
          sx={{ height: `${view === "list" ? "4rem" : "100%"}` }}
        >
          <Stack
            spacing={view === "list" ? 2 : 0}
            direction={view === "list" ? "row-reverse" : "row"}
            justifyContent={view === "list" ? "flex-end" : "space-between"}
            width={view === "list" ? "20rem" : "100%"}
          >
            <Stack
              justifyContent="flex-start"
              alignItems="flex-start"
              // justifySelf='flex-start'
              sx={{
                width: "75%",
              }}
            >
              <Box
                sx={{
                  height: "20%",
                  width: "100%",
                }}
                mb={2}
              >
                <Typography
                  variant="body"
                  component="div"
                  sx={{
                    textAlign: "left",
                    opacity: 0.7,
                    fontWeight: "bold",
                    fontSize: {
                      xs: `${view === "list" ? "0.6rem" : "0.75rem"}`,
                      sm: `${view === "list" ? "0.65rem" : "0.8rem"}`,
                      md: `${view === "list" ? "0.7rem" : "1rem"}`,
                    },
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {data.item_name}
                </Typography>
              </Box>
              <Box
                sx={{
                  height: "20%",
                  width: "100%",
                }}
              >
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    fontSize: {
                      xs: `${view === "list" ? "0.6rem" : "0.75rem"}`,
                      sm: `${view === "list" ? "0.65rem" : "0.78rem"}`,
                      md: `${view === "list" ? "0.68rem" : "0.9rem"}`,
                    },
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {data.description}
                </Typography>
              </Box>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  fontSize: {
                    xs: `${view === "list" ? "0.6rem" : "0.75rem"}`,
                    sm: `${view === "list" ? "0.65rem" : "0.78rem"}`,
                    md: `${view === "list" ? "0.68rem" : "0.9rem"}`,
                  },
                  mt: 1,
                }}
              >
                <span style={{ fontWeight: "bold" }}>
                  {currency} ₹{data.rate_unit}{" "}
                  {companyType === "service" ? data.SubscriptionStatus : ""}
                </span>
              </Typography>
            </Stack>
            <Box
              sx={{
                width: "20%",
                ...(view !== "list" && { aspectRatio: 1 / 1 }),
                aspectRatio: 1 / 1,
                // border:'1px solid',
                position: "relative",
              }}
            >
              <Box
                component="img"
                sx={{
                  width: `${view === "list" ? "4rem" : "100%"}`,
                  aspectRatio: 1 / 1,
                  borderRadius: "7px",
                }}
                alt="Product"
                // src={productImage !== undefined?`data:image/png;base64,${productImage}`:'https://dummyimage.com/400x400/7e8eff/ffffff&text=Product+Image'}
                // src={productImage[0]}
                src={
                  data.image.length !== 0
                    ? `data:image/png;base64,${data.image[0]?.image}`
                    : image
                }
              />
              {imageLoader && (
                <Box
                  sx={{
                    width: `${view === "list" ? "4rem" : "100%"}`,
                    // height: `${view === 'list' ? '4rem' : '100%'}`,
                    aspectRatio: 1 / 1,
                    position: "absolute",
                    top: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#33333377",
                    borderRadius: "7px",
                  }}
                >
                  <LoaderNew />
                </Box>
              )}
            </Box>
          </Stack>
          {view === "grid" && (
            <Stack
              direction="row"
              spacing={2}
              sx={{ fontSize: { xs: "0.75rem", sm: "0.8", md: "0.9rem" } }}
            >
              {Boolean(data.issizeAdded) && (
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    width: "20%",
                    height: "1.7rem",
                    fontSize: {
                      xs: "0.35rem",
                      sm: "0.45rem",
                      md: "0.65rem",
                    },
                  }}
                >
                  <InputLabel
                    sx={{
                      fontSize: { xs: "0.35rem", sm: "0.45rem", md: "0.65rem" },
                    }}
                  >
                    Size
                  </InputLabel>
                  <Select
                    label="Size"
                    name="size_id"
                    sx={{
                      height: "100%",
                    }}
                    onChange={handleChange}
                    value={formData.size_id || ""}
                  >
                    {data.size.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.size}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
              <Button
                fullWidth
                onClick={() => {
                  if (isInCart) {
                    navigate("/cart");
                  } else {
                    handleClickOpen(data);
                  }
                }}
                variant="outlined"
                size="small"
                color="secondary"
              >
                {isInCart ? "Go to Cart" : "Learn More"}
              </Button>

              <Button
                fullWidth
                onClick={manageCart}
                size="small"
                variant="outlined"
                color={isInCart ? "error" : "primary"}
              >
                {isInCart ? "Remove" : "Add to Cart"}
              </Button>
            </Stack>
          )}
          {view === "list" && (
            <Stack
              direction="row"
              spacing={2}
              sx={{
                fontSize: {
                  xs: "0.75rem",
                  sm: "0.8",
                  md: "0.9rem",
                },
              }}
              alignItems="center"
              justifyContent="space-evenly"
            >
              {Boolean(data.issizeAdded) && (
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    width: "40%",
                    height: "1.7rem",
                    fontSize: {
                      xs: "0.35rem",
                      sm: "0.45rem",
                      md: "0.65rem",
                    },
                  }}
                >
                  <InputLabel
                    sx={{
                      fontSize: { xs: "0.35rem", sm: "0.45rem", md: "0.65rem" },
                    }}
                  >
                    Size
                  </InputLabel>
                  <Select
                    label="Size"
                    name="size_id"
                    sx={{
                      height: "100%",
                    }}
                    onChange={handleChange}
                    value={formData.size_id || ""}
                  >
                    {data.size.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.size}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
              <Tooltip
                title="Learn More"
                arrow
                onClick={() => {
                  handleClickOpen(data);
                }}
              >
                <IconButton>
                  <ChromeReaderMode color="success" />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={isInCart ? "Remove" : "Add to Cart"}
                arrow
                onClick={manageCart}
              >
                <IconButton>
                  {isInCart ? (
                    <RemoveCircleIcon sx={{ color: "#333" }} />
                  ) : (
                    <AddShoppingCartIcon color="primary" />
                  )}
                </IconButton>
              </Tooltip>
            </Stack>
          )}
        </Stack>
      </Card>
    </>
  );
};

export default ProductCard;
