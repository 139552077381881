import CheckAndReturn from "./CheckAndReturn";

function GetCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

function _GetCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${day}_${month}_${year}`;
}

const getCurrentDatePlusDays = (daysToAdd) => {
    const date = new Date();
    let addDays = Number(daysToAdd);
    if (!daysToAdd) {
        addDays = 30;
    }
    date.setDate(date.getDate() + addDays);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
};

function addDays(dateString, days) {
    const date = new Date(dateString);
    date.setDate(date.getDate() + days);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
}

function formatDate(dateString) {
    const options = { weekday: 'short', day: '2-digit', month: 'short', year: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
}

function GetTaxAmount(taxRate, rate, quantity) {
    const tx_rt = parseFloat(taxRate !== '' && taxRate !== null && taxRate !== undefined ? taxRate : 0);
    const rt = parseFloat(rate !== '' && rate !== null && rate !== undefined ? rate : 0);
    const qty = parseFloat(quantity !== '' && quantity !== null && quantity !== undefined ? quantity : 0);
    const totalTax = (rt * tx_rt) / 100
    const taxAmount = (totalTax * qty).toFixed(2);
    return taxAmount;
}

function GetAmount(rate, quantity) {
    const rt = parseFloat(rate !== '' && rate !== null && rate !== undefined ? rate : 0);
    const qty = parseFloat(quantity !== '' && quantity !== null && quantity !== undefined ? quantity : 0);
    const amount = (rt * qty).toFixed(2);
    return amount;
}

function GetTotalAmount(taxRate, rate, quantity) {
    const taxAmount = parseFloat(GetTaxAmount(taxRate, rate, quantity));
    const rt = parseFloat(rate !== '' && rate !== null && rate !== undefined ? rate : 0);
    const qty = parseFloat(quantity !== '' && quantity !== null && quantity !== undefined ? quantity : 0);
    const totalAmount = ((rt * qty) + taxAmount).toFixed(2);
    return totalAmount;
}

function GetTotalDiscount(amount, discount) {
    const totalDiscount = (amount * discount) / 100;
    return totalDiscount;
}

function GetDiscountTaxAmount(taxRate, rate, quantity, discount) {
    const taxAmount = parseFloat(GetTaxAmount(taxRate, rate, quantity));
    const dsct = parseFloat(discount !== '' && discount !== null && discount !== undefined ? discount : 0);
    const totatlDiscount = GetTotalDiscount(taxAmount, dsct);
    const discountAmount = (taxAmount - totatlDiscount).toFixed(2);
    return discountAmount;
}

function GetDiscountTotalAmount(taxRate, rate, quantity, discount) {
    const amount = GetTotalAmount(taxRate, rate, quantity);
    const dsct = parseFloat(discount !== '' && discount !== null && discount !== undefined ? discount : 0);
    const toatlDiscount = GetTotalDiscount(amount, dsct);
    const discountAmount = (amount - toatlDiscount).toFixed(2);
    return discountAmount;
}

function GetOrderItemAmount(quantity, rate) {
    const qty = parseFloat(quantity !== '' && quantity !== null && quantity !== undefined ? quantity : 0);
    const rt = parseFloat(rate !== '' && rate !== null && rate !== undefined ? rate : 0);
    const saleOrderAmount = (qty * rt).toFixed(2);
    return saleOrderAmount;
}

function GetPurchaseBillItemAmount(quantity, rate, tax_rate) {
    const qty = parseFloat(quantity !== '' && quantity !== null && quantity !== undefined ? quantity : 0);
    const rt = parseFloat(rate !== '' && rate !== null && rate !== undefined ? rate : 0);
    const tx = parseFloat(tax_rate !== '' && tax_rate !== null && tax_rate !== undefined ? tax_rate : 0);
    const amountWithoutTax = qty * rt;
    const taxAmount = (amountWithoutTax / 100) * tx;
    const totalAmount = (amountWithoutTax + taxAmount).toFixed(2);
    return totalAmount;
}

function GetCurrentDateString() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${day}_${month}_${year}`;
}

function discountAmount(rate, discount, calculate = true) {
    const rateAmount = (rate === null || rate === undefined || rate === '')? 0:rate;
    const discountPercent = (discount === null || discount === undefined || discount === '')? 0:discount;
    const discountAmount = rateAmount * (discountPercent/100);
    if(calculate === true){
        return CheckAndReturn.roundToInteger(discountAmount);
    }
    else{
        return discountAmount;
    }
}

function taxableValue(rate, discount, calculate = true){
    const discount_amount = discountAmount(rate,discount, false);
    const rateAmount = (rate === null || rate === undefined || rate === '')? 0:rate;
    const taxable_value = rateAmount - discount_amount;
    if(calculate === true){
        return CheckAndReturn.roundToInteger(taxable_value);
    }
    else{
        return taxable_value;
    }
}

function taxAmount(rate, discount, tax, calculate = true){
    const taxable_value = taxableValue(rate,discount, false);    
    const taxPercent = (tax === null || tax === undefined || tax === '')? 0:tax;
    const tax_amount = taxable_value * (taxPercent/100);
    if(calculate === true){
        return CheckAndReturn.roundToInteger(tax_amount);
    }
    else{
        return tax_amount;
    }
}

function grossAmount(rate, discount, calculate = true){    
    const taxable_value = taxableValue(rate,discount, false); 
    const gross_amount = taxable_value;
    if(calculate === true){
        return CheckAndReturn.roundToInteger(gross_amount);
    }
    else{
        return gross_amount;
    }
}

function netAmount(rate,discount,tax, calculate = true){
    const gross_amount = grossAmount(rate, discount, false);
    const tax_amount = taxAmount(rate, discount, tax, false);
    const net_amount = gross_amount + tax_amount;
    if(calculate === true){
        return CheckAndReturn.roundToInteger(net_amount);
    }
    else{
        return net_amount;
    }
}

const Calculation = {
    GetCurrentDate,
    _GetCurrentDate,
    getCurrentDatePlusDays,
    addDays,
    formatDate,
    GetTaxAmount,
    GetAmount,
    GetTotalAmount,
    GetTotalDiscount,
    GetDiscountTaxAmount,
    GetDiscountTotalAmount,
    GetOrderItemAmount,
    GetPurchaseBillItemAmount,
    GetCurrentDateString,
    discountAmount,
    taxableValue,
    taxAmount,
    grossAmount,
    netAmount,
}

export default Calculation;