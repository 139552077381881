import React, { useState, useEffect, memo } from "react";
import {
  Button,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  Typography,
  Tooltip,
  InputAdornment,
} from "@mui/material";
import { motion } from "framer-motion";
import configServ from "../../../../services/config";
import Calculation from "../../../../utility/Calculations";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import CardHeadMenu from "../../CardHeadMenu/CardHeadMenu";
import CheckAndReturn from "../../../../utility/CheckAndReturn";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import SaveIcon from "@mui/icons-material/Save";
import UpdateIcon from "@mui/icons-material/Update";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch, useSelector } from "react-redux";
import AddPurchaseBillItems from "./AddPurchaseBillItems";
import { Link } from "react-router-dom";
import AlertDialog from "../../../AlertDialog/AlertDialog";
import { triggerLoader } from "../../../../redux/reducers/LoaderTrigger";
import { rSetShowFab } from "../../../../redux/reducers/GlobalVariables";

//FUNCTION
function PurchaseBillCard({ editData, setEditData, isChanged, setIsChanged }) {
  //#region code
  const dispatch = useDispatch();
  const { user_id, company_id, is_super } = useSelector(
    (state) => state.GlobalVariables
  );

  const [suppliers, setSuppliers] = useState([]);
  const [items, setItems] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [title, setTitle] = useState("Alert");
  const [dialogContent, setDialogContent] = useState("");
  const [buttonType, setButtonType] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [purchaseOrderNo, setPurchaseOrderNo] = useState(null);
  const [purchaseBillNo, setPurchaseBillNo] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [purchaseOrderNumbers, setPurchaseOrderNumbers] = useState([]);
  const [newPurchaseOrderNo, setNewPurchaseOrderNo] = useState(false);
  const itemListInitial = [
    {
      id: 1,
      itemFullname: "",
      item_id: "",
      purchaseoder_id: "",
      hsn_code: "",
      tax_rate: "",
      quantity: "",
      rate: "",
      total_amount: "",
    },
  ];
  const [itemList, setItemList] = useState(itemListInitial);
  const itemListErrorInitial = [
    {
      itemFullname: "",
      quantity: "",
      rate: "",
    },
  ];
  const [itemListError, setItemListError] = useState(itemListErrorInitial);
  // const purchaseBillInitial = {
  //     user_id: user_id,
  //     is_super: is_super,
  //     company_id: company_id,
  //     supplier_id: null,
  //     address: '',
  //     purchase_bill_no: '',
  //     billing_date: '',
  //     bill_due_date: '',
  //     reference: '',
  //     payment_mode: '',
  //     billing_note: '',
  //     total_amount: '',
  //     item: [
  //         {
  //             item_id: '',
  //             tax_rate: '',
  //             quantity: '',
  //             rate: '',
  //             total_amount: ''
  //         }
  //     ],
  // };
  const purchaseBillInitial = {
    status: true,
  };
  const [purchaseBill, setPurchaseBill] = useState(purchaseBillInitial);
  // const purchaseOrderErrorInitial = {
  //     partyname: '',
  //     address: '',
  //     ShipToAddress: '',
  //     purchase_bill_no: '',
  //     billing_date: '',
  //     reference: '',
  // }
  const [purchaseBillError, setPurchaseBillError] = useState({});

  const handleOpenDialog = (heading, content, type) => {
    setTitle(heading);
    setDialogContent(content);
    setButtonType(type);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setTitle('Alert');
    setDialogContent('');
    setButtonType(null);
  };

  useEffect(() => {
    if (Object.keys(editData).length !== 0) {
      setPurchaseBill(editData);

      const updateItemList = CheckAndReturn.UpdateItemList(editData.items);
      setItemList(updateItemList);
      setIsEdit(true);
      setIsCreate(true);
    }
  }, [editData]);

  useEffect(() => {
    getPurchaseOrderNumbers();
    fetchSupplierList();
    fetchItemList();
  }, []);


  const getPurchaseOrderNumbers = async () => {
    try {
      const res = await configServ.purchaseOrderNumbers({
        company_id: company_id,
      });
      if (res.status === 200) {
        setPurchaseOrderNumbers(res.data);
      } else {
        setPurchaseOrderNumbers([]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    calculateTotalAmount();
  }, [itemList]);

  const fetchSupplierList = async () => {
    try {
      const dataToSend = {
        company_id: company_id,
      };
      const res = await configServ.getSupplier(dataToSend);
      if (res.status === 200) {
        const result = res.data.filter((x) => x.status === 1);
        setSuppliers(result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchItemList = async () => {
    try {
      const dataToSend = {
        company_id: company_id,
        search_by: null,
        search: null,
        per_page: null,
        page: null,
      };
      const res = await configServ.getItemList(dataToSend);
      if (res.status === 200) {
        if (res.data.length > 0) {
          const result = res.data.filter((x) => x.status === 1);
          setItems(result);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Handle supplier change
  const handleSupplierAutocompleteChange = (event, newValue) => {
    try {
      if (newValue) {
        const selectedSupplier = suppliers.find(
          (item) => item.fname.toLowerCase() === newValue.toLowerCase()
        );

        if (selectedSupplier) {
          handleSupplierInputChange({
            target: { name: "partyname", value: selectedSupplier.fname },
          });
          handleSupplierInputChange({
            target: { name: "supplier_id", value: selectedSupplier.id },
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Handle customer input
  const handleSupplierInputChange = async (event) => {
    try {
      const SupplierName = event.target.value;
      const selectedSupplier = suppliers.find(
        (item) => item.fname.toLowerCase() === SupplierName.toLowerCase()
      );

      if (selectedSupplier !== null && selectedSupplier !== undefined) {
        setPurchaseBill((prev) => ({
          ...prev, // Copy existing fields
          custId: CheckAndReturn.checkUndefinedReturnNull(selectedSupplier.id), // Update the specific field with the new value
          partyname: selectedSupplier.fname,
          supplier_id: selectedSupplier.id,
          address: selectedSupplier.address,
          ShipToAddress: selectedSupplier.address,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Handle Purchase Bill Customer
  const handlePurchaseBill = (event) => {
    try {
      const { name, value } = event.target;
      setPurchaseBill((prev) => ({
        ...prev, //copy existing field values
        [name]: value, //update field value
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeCheckbox = (e) => {
    const { name, checked } = e.target;
    setPurchaseBill((state) => ({
      ...state,
      [name]: checked,
    }));
  };

  // const handleApprovalChange = (event) => {
  //     const isApproved = event.target.checked;
  //     if (isApproved === true) {
  //         setPurchaseBill((prev) => ({
  //             ...prev,
  //             approval_is: 'on'
  //         }));
  //     }
  //     else {
  //         setPurchaseBill((prev) => ({
  //             ...prev,
  //             approval_is: ''
  //         }));
  //     }
  //     setApproval(isApproved);
  // };

  const calculateTotalAmount = () => {
    let totalAmount = 0;
    itemList.forEach((item) => {
      // Parse the total_amount string to a float value
      const total_amount = parseFloat(item.total_amount);
      // Check if the total_amount is a valid number
      if (!isNaN(total_amount)) {
        // Add the total_amount to the total
        totalAmount += total_amount;
      }
    });
    setTotalAmount(totalAmount.toFixed(2));
  };

  // Itemlist code
  // Handle the Autocomplete's onChange event
  const handleAutocompleteChange = (event, newValue, id) => {
    try {
      if (newValue) {
        const selectedItem = items.find(
          (item) => item.item_name.toLowerCase() === newValue.toLowerCase()
        );

        if (selectedItem) {
          handleInputChange(
            {
              target: { name: "itemFullname", value: selectedItem.item_name },
            },
            id
          );
          handleInputChange(
            {
              target: { name: "item_id", value: selectedItem.id },
            },
            id
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Handle Input Changes
  const handleInputChange = (event, id) => {
    try {
      const { name, value } = event.target;
      let updatedItem;
      if (value !== null && value !== undefined) {
        if (value.length > 0) {
          if (name === "itemFullname") {
            // Find the selected item from the options
            const selectedItem = items.find(
              (item) => item.item_name.toLowerCase() === value.toLowerCase()
            );
            // If a valid item is selected, update the item properties accordingly
            if (selectedItem) {
              updatedItem = {
                ...itemList.find((item) => item.id === id),
                item_id: selectedItem.id.toString(),
                itemFullname: selectedItem.item_name,
                itemcode: selectedItem.item_code,
                packagingunit: selectedItem.packagingunit,
                hsn_code: selectedItem.hsncode,
                quantity: "",
                unit: selectedItem.unit,
                rate: selectedItem.rate_unit,
                tax_rate: selectedItem.tax_rate,
                total_amount: "0.00",
              };
            } else {
              // If the selected item is not found, reset the properties
              updatedItem = {
                ...itemList.find((item) => item.id === id),
                item_id: "",
                itemFullname: value,
                itemcode: "",
                packagingunit: "",
                hsn_code: "",
                discount: "",
                rate: "",
                tax_rate: "",
              };
            }
          } else {
            // For other fields, just update the value
            updatedItem = {
              ...itemList.find((item) => item.id === id),
              [name]: value,
            };
          }
          // Update the itemList state with the updated item
          const updatedItemList = itemList.map((item) =>
            item.id === id ? updatedItem : item
          );
          setItemList(updatedItemList);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Function to add a new item to the list
  const handleAddItem = (id) => {
    const newItem = {
      id: Date.now(), // Unique ID based on timestamp
      name: "",
      quantity: "",
    };

    // Find the index of the item after which the new item should be added
    const index = itemList.findIndex((item) => item.id === id);

    const updatedItemList = [
      ...itemList.slice(0, index + 1), // Add items before the current index
      newItem, // Add the new item
      ...itemList.slice(index + 1), // Add remaining items
    ];

    setItemList(updatedItemList);
  };

  const handleDeleteItem = (id) => {
    const updatedItemList = itemList.filter((item) => item.id !== id);
    setItemList(updatedItemList);
  };

  // Handle Quantity Change
  const handleQuantityChange = (event, id) => {
    try {
      const { name } = event.target;
      var value = event.target.value;
      let updatedItem;
      if (value !== null && value !== undefined) {
        // Find the selected item from the options
        if (value === "") {
          value = "0";
        }
        const selectedItem = itemList.find((item) => item.id === id);
        if (value.length > 0) {
          if (name === "quantity") {
            // If a valid item is selected, update the item properties accordingly
            if (selectedItem) {
              const totalAmount = Calculation.GetPurchaseBillItemAmount(
                value,
                selectedItem.rate,
                selectedItem.tax_rate
              );
              updatedItem = {
                ...itemList.find((item) => item.id === id),
                quantity: value === "0" ? "" : value,
                total_amount: totalAmount.toString(),
              };
            }
            // else {
            //     // If the selected item is not found, reset the properties
            //     updatedItem = {
            //         ...itemList.find((item) => item.id === id),
            //         quantity: '',
            //         total_amount: '0.00'
            //     };
            // }
          } else if (name === "rate") {
            // If a valid item is selected, update the item properties accordingly
            if (selectedItem) {
              const totalAmount = Calculation.GetPurchaseBillItemAmount(
                selectedItem.quantity,
                value,
                selectedItem.tax_rate
              );
              updatedItem = {
                ...itemList.find((item) => item.id === id),
                rate: value === "0" ? "" : value,
                total_amount: totalAmount.toString(),
              };
            }
            // else {
            //     // If the selected item is not found, reset the properties
            //     updatedItem = {
            //         ...itemList.find((item) => item.id === id),
            //         rate: '',
            //         total_amount: '0.00'
            //     };
            // }
          } else if (name === "tax_rate") {
            // If a valid item is selected, update the item properties accordingly
            if (selectedItem) {
              const totalAmount = Calculation.GetPurchaseBillItemAmount(
                selectedItem.quantity,
                selectedItem.rate,
                value
              );
              updatedItem = {
                ...itemList.find((item) => item.id === id),
                tax_rate: value === "0" ? "" : value,
                total_amount: totalAmount.toString(),
              };
            }
            // else {
            //     // If the selected item is not found, reset the properties
            //     updatedItem = {
            //         ...itemList.find((item) => item.id === id),
            //         tax_rate: '',
            //         //total_amount: '0.00'
            //     };
            // }
          } else {
            // For other fields, just update the value
            updatedItem = {
              ...itemList.find((item) => item.id === id),
              [name]: value,
            };
          }
          // Update the itemList state with the updated item
          const updatedItemList = itemList.map((item) =>
            item.id === id ? updatedItem : item
          );
          setItemList(updatedItemList);
        } else {
          if (name === "quantity") {
            updatedItem = {
              ...itemList.find((item) => item.id === id),
              quantity: "",
              total_amount: "0.00",
            };
          } else if (name === "rate") {
            updatedItem = {
              ...itemList.find((item) => item.id === id),
              rate: "",
              total_amount: "0.00",
            };
          } else if (name === "tax_rate") {
            updatedItem = {
              ...itemList.find((item) => item.id === id),
              tax_rate: "",
              //total_amount: '0.00'
            };
          }

          const updatedItemList = itemList.map((item) =>
            item.id === id ? updatedItem : item
          );
          setItemList(updatedItemList);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Reset
  const handleReset = () => {
    setPurchaseOrderNo(null);
    setPurchaseBill(purchaseBillInitial);
    setPurchaseBillError({});
    setItemList(itemListInitial);
    setItemListError(itemListErrorInitial);
    setIsEdit(false);
    setEditData({});
    setNewPurchaseOrderNo(false);
  };

  // Save
  const handleSubmit = async () => {
    try {
      dispatch(triggerLoader());
      const purchaseOrderErrors = validatePurchaseOrder();

      const itemListErrors = await validateItemList();
      if (
        Object.keys(purchaseOrderErrors).length > 0 ||
        Object.keys(itemListErrors).length > 0
      ) {
        return false;
      }

      // const supplierRes = await configServ.getStateBySupplier({ "supplier_id": purchaseBill.supplier_id })
      // const company = await configServ.company_details({ company_id: company_id });
      // let tax_type = "CGST+SGST";
      // if (Boolean(supplierRes.state.toLowerCase() === company.State.toLowerCase())) {
      //     tax_type = "CGST+SGST";
      // }
      // else {
      //     tax_type = "IGST";
      // }
      //purchaseBill.tax_type = tax_type;
      purchaseBill.total_amount = totalAmount;
      purchaseBill.company_id = company_id;
      purchaseBill.user_id = user_id;
      purchaseBill.items = await handlePurchaseOrderItemList();
      if (!isEdit) {
        const res = await configServ.createPurchaseBill(purchaseBill);
        if (res.status === 200) {
          handleReset();
          setIsChanged(!isChanged);
          handleOpenDialog(
            "Success",
            res.message || "Purchase Bill created successfully.",
            "success"
          );
        } else {
          handleOpenDialog(
            "Error",
            res.message || "Failed to create purchase bill.",
            "error"
          );
        }
      } else {
        const res = await configServ.updatePurchaseBill(purchaseBill);
        if (res.status === 200) {
          handleReset();
          setIsChanged(!isChanged);
          handleOpenDialog(
            "Success",
            res.message || "Purchase Bill updated successfully.",
            "success"
          );
        } else {
          handleOpenDialog(
            "Error",
            res.message || "Failed to update purchase bill.",
            "error"
          );
        }
      }
    } catch (error) {
      console.log(error);
      handleOpenDialog("Error", "An unexpected error has occurred.", "error");
    } finally {
      dispatch(triggerLoader());
    }
  };

  const handlePurchaseOrderItemList = async () => {
    try {
      const purchaseOrderItems = itemList.map((item) => ({
        //itemFullname: item.itemFullname,
        item_id: item.item_id,
        item_code: item.itemcode,
        tax_rate: item.tax_rate || "",
        quantity: item.quantity,
        rate: item.rate,
        total_amount: item.total_amount,
      }));
      return purchaseOrderItems;
    } catch (error) {
      console.log(error);
    }
  };

  // Validate the Purchase Bill fields
  const validatePurchaseOrder = () => {
    const errors = {};

    // Check if the required fields are empty
    if (newPurchaseOrderNo && !purchaseBill.purchase_order_no) {
      errors.purchase_order_no = "Purchase Order No is required.";
    }
    if (!purchaseBill.supplier_id) {
      errors.supplier_id = "Supplier is required.";
    }
    if (!purchaseBill.billing_date) {
      errors.billing_date = "Delivery Date is required.";
    }
    if (!purchaseBillNo && !isEdit) {
      errors.purchase_bill_no = "Purchase Bill No is required.";
    }

    setPurchaseBillError(errors);

    return errors;
  };

  // Validate ItemList
  const validateItemList = async () => {
    const errors = itemList.map((item) => ({
      item_id: !item.item_id ? "Item Name is required." : "",
      quantity: !item.quantity ? "Quantity is required." : "",
      rate: !item.rate ? "Rate is required." : "",
      // Add additional validations for other item fields as needed...
    }));

    setItemListError(errors);
    const hasErrors = errors.some(
      (error) => error.itemFullname || error.quantity || error.rate
    );
    return hasErrors ? errors : {};
  };

  const handleCreatePopup = () => {
    try {
      //setValidationErrors({})
      dispatch(rSetShowFab(isCreate));
      setIsCreate(!isCreate);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOrderNoChange = async (params) => {
    try {
      setPurchaseBill((prev) => ({
        ...prev,
        purchase_order_no: params,
      }));
      if (params === null) {
        handleReset();
      } else {
        const dataToSend = {
          company_id: company_id,
          purchase_order_no: params,
        };
        const res = await configServ.purchaseOrderByOrderNo(dataToSend);
        if (res.status === 200) {
          const data = res.data;
          const purchaseData = {
            supplier_id: data.supplier_id,
            reference: data.salesperson,
            total_amount: data.total_amount,
            billing_date: data.delivery_date,
            purchase_order_id: data.id,
            purchase_order_no: data.purchase_order_no,
            status: true,
          };
          const items = data.item.map((a) => ({
            id: a.id,
            item_id: a.itemid,
            quantity: a.qty,
            rate: a.rate,
            tax_rate: a.Taxrate === null ? "" : a.Taxrate,
            total_amount: a.amount,
          }));
          setPurchaseBill(purchaseData);
          setItemList(items);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const checkPurchaseBillNo = async () => {
    try {
      const dataToSend = {
        purchase_bill_no: purchaseBill.purchase_bill_no,
      };
      const res = await configServ.checkPurchaseBillNo(dataToSend);
      if (res.status === 200) {
        setPurchaseBillNo(true);
      } else {
        setPurchaseBillNo(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (purchaseBill.purchase_bill_no) {
      checkPurchaseBillNo();
    }
  }, [purchaseBill.purchase_bill_no]);
  //#endregion

  return (
    <>
      <AlertDialog
        open={dialogOpen}
        setOpen={handleCloseDialog}
        title={title}
        message={dialogContent}
        buttonTitle="Ok"
        buttonType={buttonType}
      />

      <CardHeadMenu
        prefix={"Na"}
        suffix={"Na"}
        title={"PURCHASE BILL"}
        handlePopup={handleCreatePopup}
        isEdit={isEdit}
        create={isCreate}
      />

      {isCreate && (
        <>
          <motion.div
            style={{
              width: "100%",
              backgroundColor: "#e3f3fc",
              marginTop: 20,
              padding: 20,
              borderRadius: "10px",
              border: "none",
            }}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.1, delay: 0.1 }}
          >
            <Grid
              container
              alignItems="center"
              justifyContent="flex-start"
              sx={{ padding: 0 }}
              spacing={1}
            >
              <Grid container alignItems={"flex-end"} mx={1}>
                <Grid item xs={12} md={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={newPurchaseOrderNo}
                        onClick={(e) =>
                          setNewPurchaseOrderNo(!newPurchaseOrderNo)
                        }
                      />
                    }
                    label="Enter New Order No"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Link to="/account-master-entry/supplier">
                    <Tooltip title={"Add Supplier"}>
                      <PersonAddIcon
                        sx={{
                          cursor: "pointer",
                          color: "white",
                          backgroundColor: "grey",
                          margin: "5px",
                        }}
                        justifyContent="flex-end"
                      />
                    </Tooltip>
                  </Link>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                {newPurchaseOrderNo === true ? (
                  <TextField
                    fullWidth
                    size="small"
                    label="Purchase Order No"
                    sx={{ backgroundColor: "white" }}
                    name="purchase_order_no"
                    value={purchaseBill.purchase_order_no || ""}
                    onChange={handlePurchaseBill}
                    error={!!purchaseBillError.purchase_order_no}
                  />
                ) : (
                  <Autocomplete
                    size="small"
                    fullWidth
                    disablePortal
                    id="combo-box-demo"
                    value={purchaseBill.purchase_order_no || ""}
                    options={purchaseOrderNumbers.map(
                      (option) => option.purchase_order_no
                    )}
                    onChange={(event, newValue) =>
                      handleOrderNoChange(newValue)
                    }
                    sx={{ backgroundColor: "white" }}
                    renderInput={(params) => (
                      <TextField {...params} label="Order No" />
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Autocomplete
                  id="grouped-demo"
                  size="small"
                  sx={{ backgroundColor: "white" }}
                  value={
                    purchaseBill.supplier_id
                      ? suppliers.find(
                        (supplier) => supplier.id === purchaseBill.supplier_id
                      )?.fname
                      : ""
                  }
                  options={suppliers.map((option) => option.fname)}
                  onChange={handleSupplierAutocompleteChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Supplier Name"
                      fullWidth
                      onChange={handleSupplierInputChange}
                      error={!!purchaseBillError.supplier_id}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="Address"
                  fullWidth
                  size="small"
                  name="address"
                  value={
                    purchaseBill.supplier_id
                      ? suppliers.find(
                        (supplier) => supplier.id === purchaseBill.supplier_id
                      )?.address
                      : ""
                  }
                  onChange={handlePurchaseBill}
                  sx={{ backgroundColor: "white" }}
                  error={!!purchaseBillError.address}
                />
              </Grid>
              {/* <Grid item xs={12} sm={3} md={3}>
                    <FormControl fullWidth size='small' >
                        <InputLabel id="demo-simple-select-label">Ship To Address</InputLabel>
                        <Select
                            sx={{ backgroundColor: 'white' }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Ship To Address"
                            name='ShipToAddress'
                            value={purchaseBill.ShipToAddress}
                            onChange={handlePurchaseBill}
                            error={!!purchaseBillError.ShipToAddress}
                        >
                            <MenuItem value={purchaseBill.ShipToAddress}>{purchaseBill.ShipToAddress}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid> */}
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  disabled={isEdit}
                  fullWidth
                  size="small"
                  label="Purchase Bill No"
                  sx={{ backgroundColor: "white" }}
                  name="purchase_bill_no"
                  value={purchaseBill.purchase_bill_no || ""}
                  onChange={handlePurchaseBill}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {purchaseBillNo || isEdit ? (
                          <CheckCircleIcon color="success" />
                        ) : (
                          <CancelIcon color="error" />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  error={!!purchaseBillError.purchase_bill_no}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  label="Bill Date"
                  type="date"
                  fullWidth
                  size="small"
                  name="billing_date"
                  value={purchaseBill.billing_date || ""}
                  onChange={handlePurchaseBill}
                  sx={{ backgroundColor: "white" }}
                  InputLabelProps={{ shrink: true }}
                  error={!!purchaseBillError.billing_date}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  label="Bill Due Date"
                  type="date"
                  fullWidth
                  size="small"
                  name="bill_due_date"
                  value={purchaseBill.bill_due_date || ""}
                  onChange={handlePurchaseBill}
                  sx={{ backgroundColor: "white" }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  label="Supplier/ref Name"
                  fullWidth
                  size="small"
                  name="reference"
                  value={purchaseBill.reference || ""}
                  onChange={handlePurchaseBill}
                  sx={{ backgroundColor: "white" }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  label="Payment Mode"
                  fullWidth
                  size="small"
                  name="payment_mode"
                  value={purchaseBill.payment_mode || ""}
                  onChange={handlePurchaseBill}
                  sx={{ backgroundColor: "white" }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  label="Billing Note"
                  fullWidth
                  size="small"
                  name="billing_note"
                  multiline
                  rows={2}
                  value={purchaseBill.billing_note || ""}
                  onChange={handlePurchaseBill}
                  sx={{ backgroundColor: "white" }}
                />
              </Grid>

              <Grid
                container
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Grid item xs={12} sm={6} md={6}>
                  <FormControlLabel
                    control={<Checkbox />}
                    name="status"
                    checked={Boolean(purchaseBill.status)}
                    onChange={handleChangeCheckbox}
                    label="Status"
                    sx={{ fontWeight: "bold" }}
                  />
                </Grid>
              </Grid>
            </Grid>
          <AddPurchaseBillItems
            itemList={itemList}
            handleAutocompleteChange={handleAutocompleteChange}
            handleInputChange={handleInputChange}
            handleAddItem={handleAddItem}
            handleDeleteItem={handleDeleteItem}
            items={items}
            handleQuantityChange={handleQuantityChange}
            errors={itemListError}
          />
                <Grid item xs={12} sm={6} md={6} textAlign="right">
                  <Button
                    onClick={handleReset}
                    variant="contained"
                    color="secondary"
                    startIcon={<RotateLeftIcon />}
                    sx={{ m: 1 }}
                  >
                    Reset
                  </Button>
                  <Button
                    onClick={handleSubmit}
                    autoFocus
                    variant="contained"
                    color={isEdit ? "success" : "primary"}
                    startIcon={isEdit ? <UpdateIcon /> : <SaveIcon />}
                    sx={{ m: 1 }}
                  >
                    {isEdit ? "Update" : "Save"}
                  </Button>
                </Grid>
          </motion.div>

        </>
      )}
    </>
  );
}

export default memo(PurchaseBillCard);
