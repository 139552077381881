import React, { useState, useEffect } from "react";
// import Header from "../../../Components/header/Header/Header";
import Footer from "../../../Components/Footer/Footer";
import CustomerSideBar from "../../../Components/sidebar/CustomerSideBar";
// import Sidebar from "../../../Components/sidebar/SideBar";
// import AccountMasterCard from "../../Components/Card/AccountMasterCards/AccountMasterCard";
// import AccountMasterTable from "../../Components/Card/AccountMasterCards/AccountMasterTable";
// import InvoiceTable from "../../../pages/Invoice/InvoiceTable";
import CustInvoiceTable from "../../Component/CustInvoice/CustInvoiceTable";
// import InvoiceCard from "../../../Components/Card/InvoiceCards/InvoiceCard";
import CustHeader from "../../../Components/header/Header/CustHeader";

import { Box, Stack, Paper, Divider, Typography, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import http from "../../../services/http";
import { SessionVerification } from "../../../Components/SessionManagement/SessionManagement";


const CustInvoice = () => {
  return (
    <>
      <motion.h4
        style={{ textAlign: "center" }}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.1 }}
      >
        Invoices List
      </motion.h4>
      <Stack
        direction="column"
        justifyContent="flex-start"
        width="100%"
        // my={2}
      >
        <Divider />
      </Stack>
    
        <CustInvoiceTable  />
     
    </>
  );
};
export default CustInvoice;
