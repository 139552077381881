import React, { useState, useEffect } from "react";
import { Box, Stack, Tab, Tabs, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import configServ from "../../services/config";
import ChangePassword from "../ChangePassword/ChangePassword";
import MyAccount from "../MyAccount/MyAccount";
import CustomerPassword from "../customer forgot password/CustomerPassword";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

//FUNCTION
function Profile() {
  //#region code
  const { user_id, is_super, is_admin } = useSelector(
    (state) => state.GlobalVariables
  );
  const open = useSelector((state) => state.sideExpand.open);
  const [formData, setFormData] = useState({});
  const [tabValue, setTabValue] = useState(0);
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm")); // Small devices

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFormData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const submitHandler = (e) => {};

  const fetchUser = async () => {
    const id = {
      user_id: user_id,
    };
    try {
      const result = await configServ.getAdminDetails(id);
      setFormData(result);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  //#endregion

  return (
    <>
      {/* Tabs in column layout for all screen sizes */}
      {/* Tabs with dynamic orientation */}
      <Grid
        container
        spacing={2}
        direction={isSmallDevice ? "column" : "row"} // Vertical on small devices, horizontal on large devices
        justifyContent="center"
      >
        <Grid item xs={12} sm="auto">
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            textColor="primary"
            indicatorColor="primary"
            centered
            orientation={isSmallDevice ? "vertical" : "horizontal"} // Vertical on small devices, horizontal on large
            sx={{ marginBottom: 2 }}
          >
            <Tab label="My Account" />
            <Tab label="Change Password" />
            { (is_admin === 1 || is_super === 1) && <Tab label="Customer Password Change" /> }
          </Tabs>
        </Grid>
      </Grid>

      <TabPanel value={tabValue} index={0}>
        <Stack
          direction={{
            md: "column",
            sm: "column-reverse",
            xs: "column-reverse",
          }}
          // justifyContent="center"
          // alignItems='center'
          // // alignItems={{ md: 'flex-start', sm: 'center', xs: 'center' }}
          // sx={{
          //   padding: 2,
          // }}
          // // spacing={2}
        >
          <MyAccount />
        </Stack>
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <Stack
          direction={{
            md: "column",
            sm: "column-reverse",
            xs: "column-reverse",
          }}
          // justifyContent="center"
          // alignItems='center'
          // // alignItems={{ md: 'flex-start', sm: 'center', xs: 'center' }}
          // sx={{
          //   padding: 2,
          // }}
          // // spacing={2}
        >
          <ChangePassword />
          {/* Change Password content */}
        </Stack>
      </TabPanel>

      <TabPanel value={tabValue} index={2}>
        <Stack
          direction={{
            md: "column",
            sm: "column",
            xs: "column",
          }}
          // justifyContent="center"
          // alignItems='center'
          // // alignItems={{ md: 'flex-start', sm: 'center', xs: 'center' }}
          // sx={{
          //   padding: 2,
          // }}
          // // spacing={2}
        >
          <CustomerPassword />
        </Stack>
      </TabPanel>
    </>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export default Profile;
