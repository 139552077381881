import React, { useEffect, useState } from "react";

import DashboardCard from "../../../Components/Card/DashboardCard";
// import Sidebar from '../../Components/sidebar/SideBar'
// import Header from '../../../Components/header/Header/Header'
import Footer from "../../../Components/Footer/Footer";
import http from "../../../services/http";
import CustomerSideBar from "../../../Components/sidebar/CustomerSideBar";

import { useSelector } from "react-redux";

import { Box, Button, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";
import { useJwt } from "react-jwt";
import { SessionVerification } from "../../../Components/SessionManagement/SessionManagement";
import CustHeader from "../../../Components/header/Header/CustHeader";
import RecentlyAddedItem from "../../Component/RecentlyAdded/RecentlyAddedItem";
import LatestTransaction from "../../Component/RecentlyAdded/LatestTransaction";
import configServ from "../../../services/config";

// icon
import DescriptionIcon from "@mui/icons-material/Description";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import CustHeaderReBuild from "../../../Components/header/Header/CustHeaderReBuild";
import CustomerSiseBarReBuild from "../../../Components/sidebar/CustomerSideBarReBuild";

const CustDashboard = () => {
  //#region code
  const { id, user_id, company_id } = useSelector((state) => state.GlobalVariables);
  const open = useSelector((state) => state.sideExpand.open);
  // const [userType, setUserType] = useState('customer')
  const [summary, setSummary] = useState({});
  console.log(user_id)

  useEffect(() => {
    // const getUser = async () => {
    //   const res = await configServ.profiledetail({ user_id: user_id });
    //   if (res.success) {

    //   } 
    //   else {
    //     console.error("Error while fetchiing user data");
    //   }
    // };

    const getDashboardSummary = async () => {
      try {
        const res = await configServ.dashboardsummary({ user_id: id, company_id: company_id,user_idd:user_id });
        setSummary({
          orders: res.orders,
          pending_orders: res.pending_orders,
          invoiceCount: res.InvoiceCount,
          paidCount: res.PaidCount,
          unpaidCount: res.UnpaidCount,
          PendingAmount: res.PendingAmount,
          outstanding_amount:res.outstanding_amount,
        });
      } catch (err) {
        console.log(err);
      }
    };
    //getUser();
    getDashboardSummary();
  }, []);

  const cardData = [
    {
      title: "ORDERS",
      number: summary.orders ?? <CircularProgress />,
      icon: <DescriptionIcon sx={{ color: "#fb6340" }} />,
      link: '/Purchase-Order',
    },
    {
      title: "PENDING ORDERS",
      number: summary.pending_orders ?? <CircularProgress />,
      icon: <DescriptionIcon sx={{ color: "#fb6340" }} />,
      link: '/Purchase-Order',
    },
    {
      title: "INVOICES",
      number: summary.invoiceCount ?? <CircularProgress />,
      icon: <DescriptionIcon sx={{ color: "#fb6340" }} />,
      link: '/customer-invoice',
    },
    {
      title: "PAID",
      number: summary.paidCount ?? <CircularProgress />,
      icon: <AttachMoneyIcon sx={{ color: "#fb6340" }} />,
    },
    {
      title: "UNPAID",
      number: summary.unpaidCount ?? <CircularProgress />,
      amount: parseFloat(summary.PendingAmount || 0).toFixed(2),
      icon: <MoneyOffIcon sx={{ color: "#fb6340" }} />,
    },
    {
      title: "Outstanding Amount",
      number: summary.outstanding_amount ?? <CircularProgress />,
      icon: <MoneyOffIcon sx={{ color: "#fb6340" }} />,
    },
  ];
  //#endregion

  return (
    <>
      <Grid
        container
        alignItems={"center"}
        justifyContent={"flex-start"}
        spacing={2}
        p={2}
        my={1}
        sx={{
          backgroundColor: "#6270e4",
          width: "100%",
        }}
      >
        {cardData.map((item, index) => (
          <DashboardCard
            key={index}
            data={item}

          />
        ))}
      </Grid>

      <Grid
        container
        alignItems={"center"}
        justifyContent={"center"}
        spacing={2}
        p={2}
        my={1}
        sx={{
          width: "100%",
        }}
      >
        <RecentlyAddedItem />
        {/* <LatestTransaction /> */}
      </Grid>
    </>
  );
};

export default CustDashboard;
