import React, { useState } from "react";
import { Container } from "@mui/material";
import VoucherCard from "../../../Components/Voucher/VoucherCard";
import VoucherTable from "../../../Components/Table/Voucher/VoucherTable";

const Voucher = () => {
    //#region code
    const [editData, setEditData] = useState({})
    const [isChanged, setIsChanged] = useState(false)

    const handleEdit = (data) => {
        setEditData(data[0]);
    }

    //#endregion
    return (
        <>
            <Container>
                <VoucherCard editData={editData} setEditData={setEditData} isChanged={isChanged} setIsChanged={setIsChanged} />
                <VoucherTable handleEditData={handleEdit} isChanged={isChanged} />
            </Container>
        </>
    )
}
export default Voucher;