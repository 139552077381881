import React from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

const DownloadSalesSummaryPdf = (salesOrders, totalAmount, companyName, logo,setOpenBackdrop) => {
    const headers = ['S.No.', 'CUSTOMERS', 'MOBILE NO.', 'TOTAL INVOICES', 'TOTAL AMOUNT'];

    // Format data with S.No and formatted currency
    const data = salesOrders.map((order, index) => [
        (index + 1).toString(), // S.No. column
        order.name,
        order.mobile,
        order.total_invoices.toString(),
        `Rs. ${(Number(order.total_amount) || 0).toFixed(2)}` // Format amount using formatCurrency
    ]);

    const doc = new jsPDF();

    // Show backdrop when starting PDF generation
    setOpenBackdrop(true);

    // Add report title (e.g., "Sales Summary")
    doc.setFontSize(18);
    doc.text('Sales Summary Report', 105, 10, { align: 'center' }); // Centered title at the top

    // Add company logo (position: x=14, y=10)
    if (logo) {
        doc.addImage(logo, 'PNG', 5, 5, 10, 10); // Adjust width and height as per your logo size
    }

    // Add company name (position: below the title and logo)
    doc.setFontSize(15);
    doc.text(companyName, 17, 12); // Adjust x, y for better positioning

    // Add a line break for spacing before the table
    doc.setFontSize(12); // Reset font size after company name

    // Add the table with headers and data (position: below the logo and company name)
    doc.autoTable({
        startY: 20, // Adjust the starting Y position to leave space for logo, company name, and title
        head: [headers],
        body: data
    });

    // Add total amount at the end
    const finalY = doc.autoTable.previous.finalY;
    doc.setFontSize(12);
    doc.text(`Total Amount: Rs. ${Number(totalAmount).toFixed(2)}`, 14, finalY + 10);

    // Save the PDF
    const date = new Date().toLocaleDateString();
    doc.save(`SalesSummary_${date}.pdf`);

      // Hide backdrop after PDF generation
      setOpenBackdrop(false);
};

export default DownloadSalesSummaryPdf;
