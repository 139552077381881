import React, { memo, useEffect, useState } from "react";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import SaveIcon from "@mui/icons-material/Save";
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormControlLabel,
  Checkbox,
  Typography,
  Grid,
  InputAdornment,
} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useDispatch, useSelector } from "react-redux";
import { triggerLoader } from "../../redux/reducers/LoaderTrigger";
import configServ from "../../services/config";
import AlertDialog from "../AlertDialog/AlertDialog";
import CardHeadMenu from "../Card/CardHeadMenu/CardHeadMenu";
import { rSetShowFab } from "../../redux/reducers/GlobalVariables";
import CheckAndReturn from "../../utility/CheckAndReturn";

//FUNCTION
function SupplierCard({ isChanged, setIsChanged, editData, setEditData }) {
  //#region code
  const dispatch = useDispatch();
  const { user_id, company_id, companyCountry, companyState } = useSelector(
    (state) => state.GlobalVariables
  );
  const initialFormData = {
    country: companyCountry,
    state: "",
    openingamount: 0,
    status: true,
  };
  const [formData, setFormData] = useState(initialFormData);
  const [validationErrors, setValidationErrors] = useState({});
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [isDone, setIsDone] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [title, setTitle] = useState("Alert");
  const [dialogContent, setDialogContent] = useState("");
  const [buttonType, setButtonType] = useState(null);
  const [isSupplierName, setIsSupplierName] = useState(false);

  const handleOpenDialog = (heading, content, type) => {
    setTitle(heading);
    setDialogContent(content);
    setButtonType(type);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    dispatch(rSetShowFab(true));

    setDialogOpen(false);
    setTitle("Alert");
    setDialogContent("");
    setButtonType(null);
  };

  const handlePopup = () => {
    dispatch(rSetShowFab(isCreate));
    setIsCreate(!isCreate);
    clear();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    setFormData((state) => ({
      ...state,
      [name]: value,
    }));

    if(validationErrors[name]){
      setValidationErrors((prev)=>({
        ...prev,
        [name]: ''
      }))
    }
  };

  const handleChangeCheckbox = (e) => {
    const { name, checked } = e.target;
    setFormData((state) => ({
      ...state,
      [name]: checked,
    }));
  };

  const assignContactName = () => {
    if (!isDone) {
      setFormData((state) => ({
        ...state,
        contact_name: formData.fname || "",
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    dispatch(triggerLoader());
    try {
      const error = await Validate();
      if (error === false) {
        return false;
      }
      const dataToSend = {
        ...formData,
        company_id: company_id,
        admin_id: user_id,
      };
      if (formData.is_credit_days === false) {
        delete dataToSend.credit_period;
        delete dataToSend.credit_limit;
      }
      if (!isEdit) {
        const res = await configServ.addSupplier(dataToSend);
        if (res.status === 200) {
          setIsChanged(!isChanged);
          clear();
          handleOpenDialog(
            "Success",
            res.message || "Supplier created successfully.",
            "success"
          );
        } else if (res.status === 400) {
          handleOpenDialog(
            "Warning",
            res.message || "failed to create supplier.",
            "warning"
          );
        } else {
          handleOpenDialog(
            "Error",
            res.message || "failed to create supplier.",
            "error"
          );
        }
      } else {
        const res = await configServ.editSupplier(dataToSend);
        if (res.status === 200) {
          setIsChanged(!isChanged);
          clear();
          handleOpenDialog(
            "Success",
            res.message || "Supplier updated successfully.",
            "success"
          );
        } else {
          handleOpenDialog(
            "Error",
            res.message || "failed to updtae supplier.",
            "error"
          );
        }
      }
    } catch (e) {
      console.log(e);
      handleOpenDialog("Error", "An unexpected error has occurred.", "error");
    } finally {
      dispatch(triggerLoader());
    }
  };

  const Validate = async () => {
    try {
      let errors = {};
      if (!formData.fname) {
        errors.fname = "required";
      }
      else if (!isSupplierName && !isEdit) {
        errors.fname = "required";
      }
      if (!formData.contact_name) {
        errors.contact_name = "required";
      }
      if (!formData.country) {
        errors.country = "required";
      }
      if (!formData.state) {
        errors.state = "required";
      }
      if (!formData.phone) {
        errors.phone = "required";
      }
      if (!formData.address) {
        errors.address = "required";
      }

      setValidationErrors(errors);
      return Object.values(errors).every((error) => error === "");
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchCounties();
  }, []);

  const fetchCounties = async () => {
    try {
      const res = await configServ.getCountry();
      if (res.status === 200) {
        setCountryList(res.data);
      } else {
        setCountryList([]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (formData.country && countryList.length > 0) {
      fetchStates();
    } else {
      fetchCounties();
    }
  }, [formData.country, countryList]);

  const fetchStates = async () => {
    try {
      const countryId = countryList.find(
        (x) => x.country === formData.country
      ).PK;
      const res = await configServ.getState({ country: countryId });
      if (res.status === 200) {
        setStateList(res.data);
      } else {
        setStateList([]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (editData !== null) {
      setFormData(editData);
      setIsEdit(true);
      setIsCreate(true);
    }
  }, [editData]);

  const checkSupplierName = async () => {
    try {
      const dataToSend = {
        company_id: company_id,
        fname: formData.fname,
      }
      const res = await configServ.checkSupplierName(dataToSend);
      if (res.status === 200) {
        setIsSupplierName(true);
      }
      else {
        setIsSupplierName(false);
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (formData.fname) {
      checkSupplierName();
    }
    else {
      setIsSupplierName(false)
    }
  }, [formData.fname]);
  
  const clear = () => {
    setFormData(initialFormData);
    setValidationErrors({});
    setEditData(null);
    setIsDone(false);
    setIsEdit(false);
  };

  //#endregion

  return (
    <>
      <AlertDialog
        open={dialogOpen}
        setOpen={handleCloseDialog}
        title={title}
        message={dialogContent}
        buttonTitle="Ok"
        buttonType={buttonType}
      />
      <CardHeadMenu
        prefix={"Na"}
        suffix={"Na"}
        title={"SUPPLIER"}
        handlePopup={handlePopup}
        isEdit={isEdit}
        create={isCreate}
      />

      {isCreate && (
        <Grid container>
          <Grid
            container
            alignItems={"center"}
            spacing={1}
            bgcolor={"#e3f3fc"}
            my={1}
            px={2}
          >
            <Grid item xs={12} md={12} justifyContent={"flex-start"} pt={1}>
              <Typography sx={{ fontSize: "16px", fontWeight: "bolder" }}>
                Supplier Detail
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                size="small"
                label={
                  <span>
                    Supplier Name<span style={{ color: "red" }}>*</span>
                  </span>
                }
                name="fname"
                onChange={handleChange}
                value={CheckAndReturn.checkTextNullAndUndefined(formData.fname)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {isSupplierName || isEdit ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />}
                    </InputAdornment>
                  )
                }}
                error={!!validationErrors.fname}
                sx={{ backgroundColor: "white" }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                size="small"
                label={
                  <span>
                    Contact Name<span style={{ color: "red" }}>*</span>
                  </span>
                }
                name="contact_name"
                onChange={handleChange}
                value={formData.contact_name || ""}
                error={!!validationErrors.contact_name}
                sx={{ backgroundColor: "white" }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth size="small">
                <InputLabel error={!!validationErrors.country}>
                  Country<span style={{ color: "red" }}>*</span>
                </InputLabel>
                <Select
                  required
                  name="country"
                  label="country"
                  value={formData.country || ""}
                  onChange={handleChange}
                  error={!!validationErrors.country}
                  sx={{ backgroundColor: "white" }}
                >
                  {countryList.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.country}>
                        {item.country}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth size="small">
                <InputLabel error={!!validationErrors.state}>
                  State<span style={{ color: "red" }}>*</span>
                </InputLabel>
                <Select
                  required
                  name="state"
                  label="state"
                  value={formData.state || ""}
                  onChange={handleChange}
                  error={!!validationErrors.state}
                  sx={{ backgroundColor: "white" }}
                >
                  {stateList.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.State}>
                        {item.State}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                size="small"
                label="Email"
                name="email"
                onChange={handleChange}
                value={formData.email || ""}
                b
                sx={{ backgroundColor: "white" }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                size="small"
                label={
                  <span>
                    Mobile Number<span style={{ color: "red" }}>*</span>
                  </span>
                }
                name="phone"
                type="number"
                onChange={handleChange}
                value={formData.phone || ""}
                error={!!validationErrors.phone}
                sx={{ backgroundColor: "white" }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                size="small"
                label={
                  <span>
                    Address<span style={{ color: "red" }}>*</span>
                  </span>
                }
                name="address"
                onChange={handleChange}
                value={formData.address || ""}
                error={!!validationErrors.address}
                sx={{ backgroundColor: "white" }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                type="number"
                size="small"
                label="Opening Amount"
                name="openingamount"
                onChange={handleChange}
                value={formData.openingamount || ""}
                sx={{ backgroundColor: "white" }}
              />
            </Grid>

            <Grid item xs={12} md={12} justifyContent={"flex-start"} mt={1}>
              <Typography sx={{ fontSize: "16px", fontWeight: "bolder" }}>
                Tax Detail
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                size="small"
                label="PAN/NO"
                name="pan_no"
                onChange={handleChange}
                value={formData.pan_no || ""}
                sx={{ backgroundColor: "white" }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                size="small"
                label="GSTIN/UIN"
                name="gst_uin"
                onChange={handleChange}
                value={formData.gst_uin || ""}
                sx={{ backgroundColor: "white" }}
              />
            </Grid>
            <Grid
              container
              xs={12}
              md={8}
              border={"solid lightgrey 1px"}
              spacing={1}
              m={1}
              bgcolor={"#f4e7b7"}
            >
              <Grid item xs={12} md={12}>
                <FormControlLabel
                  control={<Checkbox />}
                  name="is_credit_days"
                  checked={Boolean(formData.is_credit_days)}
                  onChange={handleChangeCheckbox}
                  label="Check credit days"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Credit Period"
                  size="small"
                  name="credit_period"
                  InputProps={{
                    endAdornment: "d",
                  }}
                  disabled={
                    Boolean(formData.is_credit_days) === true ? false : true
                  }
                  onChange={handleChange}
                  value={formData.credit_period || ""}
                  sx={{ backgroundColor: "white", mb: 1 }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Credit Limit"
                  size="small"
                  name="credit_limit"
                  disabled={
                    Boolean(formData.is_credit_days) === true ? false : true
                  }
                  onChange={handleChange}
                  value={formData.credit_limit || ""}
                  sx={{ backgroundColor: "white", mb: 1 }}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} md={2} justifyContent={"flex-end"}>
              <FormControlLabel
                control={<Checkbox />}
                name="status"
                checked={Boolean(formData.status)}
                onChange={handleChangeCheckbox}
                label="Status"
              />
            </Grid>
            <Grid
              container
              item
              justifyContent={"flex-end"}
              bgcolor={"#e3f3fc"}
              spacing={1}
              mb={2}
            >
              <Button
                onClick={clear}
                variant="contained"
                color="secondary"
                startIcon={<RotateLeftIcon />}
                sx={{ mx: 1 }}
              >
                Reset
              </Button>
              <Button
                variant="contained"
                onClick={handleSubmit}
                startIcon={<SaveIcon />}
              >
                {isEdit ? "Update" : "Save"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default memo(SupplierCard);
