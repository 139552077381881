import React from 'react'

import { BsPlusSquareFill,} from "react-icons/bs";
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';

import { Form, Row, Col } from 'react-bootstrap';

import { Button, IconButton, Stack } from "@mui/material";

function NoteCard({addNote, deleteNote, id, noDel,notes}) {
  return (
    <>
                <div style={{ width: "100%", backgroundColor: "#DBDFEA", padding: 20, fontSize:'0.8rem', borderRadius:'10px', border:'none', marginTop:10 }}>
                <Row >
                    <Col sm={2}>
                        <Form.Label>Item Name(Code)</Form.Label>
                        <Form.Control type="" placeholder='Item Name' />
                    </Col>
                    <Col sm={1}>
                        <Form.Label >Item Code</Form.Label>
                        <Form.Control type="" placeholder='Item Code' />
                    </Col>
                    <Col sm={1}>
                        <Form.Label>Pkg Unit</Form.Label>
                        <Form.Control type="" />
                    </Col>
                    <Col sm={1}>
                        <Form.Label>HSN</Form.Label>
                        <Form.Control type="" placeholder='#CodeS' />
                    </Col>
                    <Col sm={1}>
                        <Form.Label>Quantity</Form.Label>
                        <Form.Control type="" placeholder='Qty' />
                    </Col>
                    <Col sm={1}>
                        <Form.Label>Rate</Form.Label>
                        <Form.Control type="" placeholder='Rate' />
                    </Col>
                    <Col sm={1}>
                        <Form.Label>Discount</Form.Label>
                        <Form.Control type="" placeholder='0' />
                    </Col>
                    <Col sm={2}>
                        <Form.Label>Tax Amount</Form.Label>
                        <Form.Control type="" placeholder='Taxable Amount' />
                    </Col>
                    <Col sm={1}>
                        <Form.Label>Amount</Form.Label>
                        <Form.Control type="" placeholder='Amount' />
                    </Col>
                    <Col sm={1}>
                        <Stack direction='row' justifyContent='center' alignItems='center' sx={{width:'100%', height:'100%', marginTop:1.5}}>
                       
                            <IconButton onClick={addNote}>
                                <AddBoxIcon fontSize='medium' sx={{color:'green'}} />
                            </IconButton>
                             
                            <IconButton hidden={noDel?true:false} onClick={()=>{deleteNote(id)}}>
                                <DeleteIcon fontSize='medium' sx={{color:'#d43529'}}/>
                            </IconButton>
                              
                        </Stack>
                    </Col>
                </Row>
                </div>
    </>
  )
}

export default NoteCard