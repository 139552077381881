import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, Paper, TextField, Typography } from '@mui/material';
import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SaveIcon from '@mui/icons-material/Save';
import UpdateIcon from '@mui/icons-material/Update';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import configServ from '../../services/config';
import AlertDialog from '../AlertDialog/AlertDialog';
import useDeviceDetect from '../Detect/useDeviceDetect ';
import FollowUpTable from './FollowUpTable';
import FollowUpCard from './FollowUpCard';

//FUNCTION
const FollowUp = ({ data }) => {
    //#region code
    const { isMobile } = useDeviceDetect();
    const { user_id, company_id } = useSelector((state) => state.GlobalVariables);
    const initialFormData = {
        status: true
    }
    const [formData, setFormData] = useState(initialFormData);
    const [validationErrors, setValidationErrors] = useState({});
    const [isChanged, setIschanged] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [title, setTitle] = useState('Alert');
    const [dialogContent, setDialogContent] = useState('');
    const [buttonType, setButtonType] = useState(null);

    const handleOpenDialog = (heading, content, type) => {
        setTitle(heading);
        setDialogContent(content);
        setButtonType(type);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setTitle('Alert');
        setDialogContent('');
        setButtonType(null);
    };

    useEffect(() => {
        return () => {
            handleReset();
        }
    }, []);

    const handleChange = (event) => {
        try {
            const { name, value, type, checked } = event.target;
            setFormData((prev) => ({
                ...prev,
                [name]: type === 'checkbox' ? checked : value,
            }));

            if (validationErrors[name]) {
                setValidationErrors((prev) => ({
                    ...prev,
                    [name]: ''
                }));
            }
        }
        catch (e) {
            console.log(e);
        }
    };

    const validateForm = async () => {
        try {
            const errors = {};
            if (!formData.remarks) {
                errors.remarks = "required";
            }
            if (!formData.follow_up_date) {
                errors.follow_up_date = "required";
            }
            setValidationErrors(errors);
            return Object.values(errors).every(error => error === "");
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            const isValid = await validateForm();
            if (isValid === false) {
                return false;
            }
            const dataToSend = {
                id: formData.id ? formData.id : null,
                entity_id: data.id,
                entity_type: data.entity_type,
                company_id: company_id,
                user_id: user_id,
                remarks: formData.remarks,
                follow_up_date: formData.follow_up_date,
                status: formData.status
            }
            if (!isEdit) {
                const res = await configServ.createFollowUp(dataToSend);
                if (res.status === 200) {
                    setIschanged(!isChanged);
                    handleReset();
                    handleOpenDialog('Success', res.message || 'Follow up created successfully.', 'success');
                }
                else {
                    handleOpenDialog('Error', res.message || 'Failed to create follow up.', 'error');
                }
            }
            else {
                const res = await configServ.updateFollowUp(dataToSend);
                if (res.status === 200) {
                    setIschanged(!isChanged);
                    handleReset();
                    handleOpenDialog('Success', res.message || 'Follow up updated successfully.', 'success');
                }
                else {
                    handleOpenDialog('Error', res.message || 'Failed to update follow up.', 'error');
                }
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleEdit = (params) => {
        try {
            setFormData(params);
            setIsEdit(true);
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleReset = () => {
        setFormData(initialFormData);
        setValidationErrors({});
        setIsEdit(false);
    }
    //#endregion

    return (
        <>
            <AlertDialog
                open={dialogOpen}
                setOpen={handleCloseDialog}
                title={title}
                message={dialogContent}
                buttonTitle="Ok"
                buttonType={buttonType}
            />

            <form onSubmit={handleSubmit}>
                <Grid container alignItems={'flex-end'} spacing={1} my={1}>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            size='small'
                            type='text'
                            label='Remarks'
                            name='remarks'
                            multiline
                            rows={2}
                            value={formData.remarks || ''}
                            onChange={handleChange}
                            sx={{ background: 'white' }}
                            error={!!validationErrors.remarks}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            size='small'
                            type='date'
                            label='Follow Up'
                            name='follow_up_date'
                            value={formData.follow_up_date || ''}
                            onChange={handleChange}
                            sx={{ background: 'white' }}
                            InputLabelProps={{ shrink: true }}
                            error={!!validationErrors.follow_up_date}
                        />
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="status"
                                        checked={formData.status || false}
                                        onChange={handleChange}
                                    />
                                }
                                label="Status"
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Button variant="contained" type='submit' color={isEdit ? 'success' : 'primary'} startIcon={<SaveIcon />} sx={{ pl: 3, mr: 1 }} title={isEdit ? 'Update' : 'Save'} />
                        <Button variant="contained" type='reset' color={'secondary'} startIcon={<RotateLeftIcon />} sx={{ pl: 3 }} title={'Reset'} onClick={handleReset} />
                    </Grid>
                </Grid>
            </form>

            {
                isMobile === true ?
                    <Box component={Paper} sx={{ padding: '16px', backgroundColor: '#fff', overflowY: 'auto', maxHeight: '80vh' }}>
                        <FollowUpCard data={data} isChanged={isChanged} />
                    </Box>
                    :
                    <FollowUpTable data={data} isChanged={isChanged} handleEdit={handleEdit} />
            }
        </>
    );
};

export default memo(FollowUp);
