import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Stack,
  Box,
  Button,
  Grid,
} from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import configServ from "../../../services/config";
import apple13 from "../../../Components/Assets/images/apple13.jpg"
import LoaderNew from "../../../Components/Loader/LoaderNew";

const RecentlyAddedItem = () => {
  //#region code
  const { company_id, currency } = useSelector(
    (state) => state.GlobalVariables
  );
  const navigate = useNavigate();
  const { company_type } = useSelector((state) => state.admin);

  const [onHover, setOnHover] = useState(true);
  const [catalog, setCatalog] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [image, setImage] = useState(
    (apple13)
  );

  const fetchCatalog = async () => {
    try {
      setIsLoading(true);
      const res = await configServ.getCatalogCustomer({
        company_id: company_id,
      });
      if (res.success === true) {
        setCatalog(res.data);
      } else {
        setCatalog([]);
      }
    } catch (err) {
      console.log(err);
    }
    finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCatalog();
  }, []);

  const showHover = () => {
    setOnHover(false);
  };

  const hideHover = () => {
    setOnHover(true);
  };

  //#endregion

  return (
    <>
      <Box
        sx={{
          // backgroundColor: "#eeeeeeaa",
          padding: "20px",
          width: { xs: "100%", md: "100%" },
        }}
      >
        {/* <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ background: "black", mb: "10px" }}
        >
          <Typography variant="h6" color="white">
            <b>
              <i>Catalog</i>
            </b>
          </Typography>
        </Stack> */}
        {
          isLoading ?
            <>
              <LoaderNew />
            </>
            :
            <Carousel
              autoPlay
              infiniteLoop
              transitionTime={500}
              showThumbs={false}
              sx={{
                paddingTop: "20px",
                width: "70vw",
                height: "170px",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                flexDirection: "column",
              }}
              NextIcon={<NavigateNextOutlinedIcon />}
              PrevIcon={<ArrowBackIosNewOutlinedIcon />}
            >
              {catalog.map((catalogItem) =>
                catalogItem.catalog_items.map((item) => (
                  <Grid xs={12} key={item.id}>
                    <Card>
                      <Box
                        onMouseEnter={showHover}
                        onMouseLeave={hideHover}
                        sx={{ position: "relative" }}
                      >
                        <CardMedia
                          component="img"
                          alt={item.item.image[0]?.alt || "Product Image"}
                          height="300"
                          image={
                            item.item.image.length !== 0
                              ? `data:image/png;base64,${item.item.image[0]?.image}`
                              : image
                          }
                          sx={{
                            objectFit: "contain", // Ensure the image fits within the given space
                            width: "100%", // Ensure it takes the full width of the container
                            height: "autp", // Set the height explicitly
                          }}
                        />

                        <Stack
                          spacing={1}
                          sx={{
                            background: "#33333388",
                            position: "absolute",
                            bottom: 0,
                            width: "100%",
                            height: "60%",
                            color: "white",
                            opacity: `${onHover ? 0 : 1}`,
                            padding: 3,
                            transition: "opacity 0.15s ease-in-out",
                          }}
                        >
                          <Typography variant="h5" component="div">
                            {item.item.item_name}
                          </Typography>
                          <Typography
                            variant="body2"
                            component="div"
                            sx={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {item.item.description || "No description available"}
                          </Typography>
                          <Typography
                            variant="body2"
                            component="div"
                            sx={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            <span style={{ fontWeight: "bold" }}>
                              {currency} {item.item.rate_unit}{" "}
                              {company_type === "service"
                                ? item.item.SubscriptionStatus
                                : ""}
                            </span>
                          </Typography>
                        </Stack>
                      </Box>
                      <CardContent>
                        {/* <Stack
                      direction="row"
                      spacing={2}
                      sx={{
                        fontSize: { xs: "0.75rem", sm: "0.8", md: "0.9rem" },
                      }}
                    > */}
                        <Grid container item xs={12} justifyContent="center">
                          <Grid item xs={12} md={5} my={3} mx={1} display="flex" justifyContent="center">
                            <Button
                              fullWidth
                              onClick={() => {
                                navigate("/product-details", {
                                  state: { data: item.item, image: item.item.image },
                                });
                              }}
                              variant="outlined"
                              size="small"
                              color="secondary"
                              sx={{ maxWidth: 300 }}  // Optional: To limit the max width of the button
                            >
                              Learn More
                            </Button>
                          </Grid>
                          <Grid item xs={12} md={5} my={3} mx={1} display="flex" justifyContent="center">
                            <Button
                              fullWidth
                              onClick={() => {
                                navigate("/product");
                              }}
                              variant="outlined"
                              size="small"
                              color="primary"
                              sx={{ maxWidth: 300 }}  // Optional: To limit the max width of the button
                            >
                              View All Products
                            </Button>
                          </Grid>
                        </Grid>


                        {/* </Stack> */}
                        <br />
                      </CardContent>
                    </Card>
                  </Grid>
                ))
              )}
            </Carousel>
        }
        <br />
      </Box>
    </>
  );
};

export default RecentlyAddedItem;
