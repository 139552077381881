import React, { useEffect, useState } from 'react';
import { TextField, FormControl, InputLabel, MenuItem, Select, Button, IconButton, Typography, Tooltip, Divider, Grid, Box, OutlinedInput } from '@mui/material';
import AlertDialog from '../../AlertDialog/AlertDialog';
import RoleTable from './RoleTable';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import SaveIcon from '@mui/icons-material/Save';
import UpdateIcon from '@mui/icons-material/Update';
import AddBoxIcon from '@mui/icons-material/AddBox';
import configServ from '../../../services/config';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion'
import Permissions from '../../AccessMaster/permissions/Permissions';
import { triggerLoader } from '../../../redux/reducers/LoaderTrigger';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const CreateRole = () => {
    //#region code
    const dispatch = useDispatch();
    const { user_id, is_super } = useSelector((state) => state.GlobalVariables);
    const [validationErrors, setValidationErrors] = useState({});
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [permissionList, setPermissionList] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [permissionOpen, setPermissionOpen] = useState(false);
    const [formData, setFormData] = useState({
        "name": '',
    });

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const fetchPermissions = async () => {
        try {
            const res = await configServ.getPermissions({
                "admin_id": user_id
            });
            if (res.status === 200) {
                setPermissionList(res.data);
            }
            else {
                setPermissionList([]);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        fetchPermissions();
    }, []);

    const handleOpenDialog = (content) => {
        setDialogContent(content);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setDialogContent('');
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((state) => ({
            ...state,
            [name]: value,
        }));
    };

    const handlePermissionChange = (event) => {
        const {
            target: { value },
        } = event;
        setPermissions(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleSubmit = async () => {
        try{
            dispatch(triggerLoader());
            const dataToSend = {
                "name": formData.name,
                "admin_id": user_id,
                "is_super": is_super,
                "permissions": permissions
            }
            const error = await handleFormValidation();
            if (error === false) {
                return;
            }
            if (!isEdit) {
                const res = await configServ.createRole(dataToSend);
                if (res.status === 200) {
                    setDialogOpen(true);
                    setDialogContent(res.message);
                    setIsChanged(!isChanged);
                }
                else {
                    setDialogContent(res.message || "Failed to create Role.");
                }
            }
            else {
                const res = await configServ.updateRole({
                    "name": formData.name,
                    "role_id": formData.id,
                    "admin_id": user_id,
                    "is_super": is_super,
                    "permissions": permissions
                });
                if (res.status === 200) {
                    setDialogOpen(true);
                    setDialogContent(res.message);
                    setIsChanged(!isChanged);
                }
                else {
                    setDialogContent(res.message || "An error occurred.");
                }
            }
            clear();
        }
        catch(e){
            console.log(e);
        }
        finally{
            dispatch(triggerLoader());
        }
    };

    const handleFormValidation = async() => {
        const errors = {};
        if (!formData.name) {
            errors.name = 'required';
        }
        if (permissions.length === 0) {
            errors.permissions = 'required';
        }
        // Add more validation rules as needed

        setValidationErrors(errors);
        return Object.values(errors).every((error) => error === '');
    };

    const handleEdit = (param) => {
        try {
            scrollToTop();
            setFormData(param);
            setPermissions(param.permissions.map(item => item.id));
            setIsEdit(true);
        }
        catch (e) {
            console.log(e);
        }
    }

    const handlePermission = () => {
        setPermissionOpen(true);
    }

    const handleClosePermission = () => {
      setPermissionOpen(false);
      fetchPermissions();
    }

    const clear = () => {
        setFormData({ "name": '' });
        setPermissions([]);
        setIsEdit(false);
        setValidationErrors({});
        setPermissionOpen(false);
        scrollToTop();
    };
    //#endregion

    return (
        <>
            <Permissions open={permissionOpen} handleClose={handleClosePermission} />
            <AlertDialog open={dialogOpen} setOpen={handleCloseDialog} title="Alert" message={dialogContent} buttonTitle="Ok" />
            <motion.div style={{ width: "100%", backgroundColor: "#e3f3fc", padding: 20, borderRadius: '10px', border: 'none' }}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.1, delay: 0.1 }}
            >
                <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                    <Grid item xs={12} sm={5} md={4}>
                        <TextField
                            fullWidth
                            size="small"
                            sx={{ backgroundColor: 'white' }}
                            label="Role Name*"
                            name="name"
                            onChange={handleChange}
                            value={formData.name}
                            error={!!validationErrors.name}
                        />
                    </Grid>
                    <Grid item xs={12} sm={5} md={6}>
                        <FormControl size='small' sx={{ width: { xs: '100%', sm: '100%', md: '100%' } }}>
                            <InputLabel id="demo-multiple-name-label" error={!!validationErrors.permissions}>Permissions</InputLabel>
                            <Select
                                size="small"
                                sx={{ backgroundColor: 'white' }}
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                multiple
                                value={permissions}
                                onChange={handlePermissionChange}
                                input={<OutlinedInput label="Permissions" />}
                                MenuProps={MenuProps}
                                error={!!validationErrors.permissions}
                            >
                                {permissionList.map((item) => (
                                    <MenuItem
                                        key={item.id}
                                        value={item.id}
                                    >
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {/* {
                        (is_super === 1) &&
                        <>
                            <Grid container item xs={12} sm={2} md={2} alignItems={'center'} justifyContent={'flex-start'}>
                                <IconButton>
                                    <AddBoxIcon color='primary' onClick={handlePermission} fontSize='92px' />
                                </IconButton>
                            </Grid>
                        </>
                    } */}
                </Grid>

                <Grid container spacing={2} justifyContent="space-between" px={2}>
                    <Grid
                        item
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={2}
                    >
                        <Grid item>
                            <Button
                                onClick={clear}
                                variant="contained"
                                color='secondary'
                                startIcon={<RotateLeftIcon />}
                            >
                                Reset
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={handleSubmit}
                                autoFocus
                                variant="contained"
                                color={isEdit ? 'success' : 'primary'}
                                startIcon={isEdit ? <UpdateIcon /> : <SaveIcon />}
                            >
                                {isEdit ? "Update" : "Save"}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </motion.div>

            <Divider
                sx={{
                    my: '2rem',
                    backgroundColor: '#33333377'
                }}
            />

            <RoleTable handleEdit={handleEdit} isChanged={isChanged} />
        </>
    );
};

export default CreateRole;
