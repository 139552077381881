import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Menu,
  IconButton,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  Paper,
  TableHead,
  TableBody,
  CircularProgress,
  TablePagination,
  Card,
  CardContent,
  Box,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Backdrop from "@mui/material/Backdrop";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import configServ from "../../../services/config";
import { useDispatch, useSelector } from "react-redux";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Swal from "sweetalert2";
import useDeviceDetect from "../../Detect/useDeviceDetect ";
import CardHeadMenu from "../../Card/CardHeadMenu/CardHeadMenu";
import LoaderNew from "../../Loader/LoaderNew";

const SalesReport = () => {
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [searchBy, setSearchBy] = useState("partyname");
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [salesOrders, setSalesOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const { company_id, companyName, user_id, is_admin } = useSelector(
    (state) => state.GlobalVariables
  );
  const [isApproved, setIsApproved] = useState(false);
  const [count, setCount] = useState(0);
  const isMenuOpen = Boolean(anchorEl);
  const tableRef = useRef(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false); // Controls PDF generation state
  const [totalAmount, setTotalAmount] = useState(0); // To store the total amount
  const [companyLogo, setCompanyLogo] = useState("");
  const { isMobile } = useDeviceDetect();
  const [totalFilteredAmount, setTotalFilteredAmount] = useState(0);

  // const handleSearchByChange = (event) => {
  //   setSearchBy(event.target.value);
  // };
  const handleSearchByChange = (event) => {
    setSearchBy(event.target.value);
  };

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // page pagination started
  const handleChangePage = (event, newPage) => {
    setPage(newPage); // Update the page number
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10)); // Update rows per page
    setPage(0); // Reset to the first page when rows per page change
  };

  // page pagination ended

  const formatCurrency = (amount) => `₹${parseFloat(amount)}`;
  // Download as Excel
  const downloadExcel = () => {
    // Use filteredSalesOrders to only download filtered data
    const filteredData = filteredSalesOrders.map((order) => ({
      CUSTOMER_NAME: order.partyname,
      SALES_PERSON: order.salesperson,
      ORDER_NO: order.order_no,
      ORDER_DATE: new Date(order.order_date).toLocaleDateString(),
      TOTAL_AMOUNT: formatCurrency(order.total_amount),
    }));

    const worksheet = XLSX.utils.json_to_sheet([]);
    const workbook = XLSX.utils.book_new();

    // Add title, company name, and a placeholder for the logo
    XLSX.utils.sheet_add_aoa(
      worksheet,
      [
        ["Sales Order Report"], // Title
        [`Company Name: ${companyName}`], // Company Name
      ],
      { origin: "A1" }
    );

    // Add filtered data to the sheet starting from row 4
    XLSX.utils.sheet_add_json(worksheet, filteredData, { origin: "A4" });

    // Set column width for better readability
    const wscols = [
      { wch: 30 }, // CUSTOMER_NAME
      { wch: 20 }, // SALES_PERSON
      { wch: 15 }, // ORDER_NO
      { wch: 15 }, // ORDER_DATE
      { wch: 15 }, // TOTAL_AMOUNT
    ];
    worksheet["!cols"] = wscols;

    // Append worksheet and download the Excel file
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sales Data");
    XLSX.writeFile(workbook, "sales_data.xlsx");

    // Success popup after Excel download
    setTimeout(() => {
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Your Excel file has been downloaded",
        showConfirmButton: false,
        timer: 1500,
      });
    }, 1000); // Delay for the popup
  };

  // Download as PDF
  const downloadPDF = () => {
    setOpenBackdrop(true); // Show Backdrop when PDF generation starts
    setIsGeneratingPDF(true); // Start PDF generation, hide pagination

    // Temporarily force the table to be visible
    const tableContainer = tableRef.current;
    tableContainer.style.display = "block"; // Show table on mobile

    setTimeout(() => {
      const input = tableContainer;
      if (!input) {
        console.error("Table element not found");
        return;
      }

      html2canvas(input, { scale: 4, useCORS: true, dpi: 500 }).then(
        (canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF("p", "mm", "a4");
          const imgWidth = 190; // Width of the PDF page
          const pageHeight = pdf.internal.pageSize.height;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          let heightLeft = imgHeight;
          let position = 15; // Adjusted position to leave space for the logo and name

          // Add Company Logo and Name to the PDF
          if (companyLogo) {
            pdf.addImage(companyLogo, "PNG", 5, 5, 10, 10); // Adjust position/size
          } else {
            console.log("Company logo is not available");
          }

          pdf.setFontSize(12);
          pdf.text(companyName, 15, 12); // Adjust x, y position for the company name

          // Add title to PDF
          pdf.setFontSize(16);
          pdf.text("Sales Order Report", 80, 10); // Adjust x, y position as needed

          // Add the table data after the logo and name
          pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;

          while (heightLeft > 0) {
            position = heightLeft - imgHeight;
            pdf.addPage();
            pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
          }

          pdf.save("sales_data.pdf");

          setTimeout(() => {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Your PDF file has been downloaded",
              showConfirmButton: false,
              timer: 1500,
            });
          }, 1000); // Delay of 1 second

          setIsGeneratingPDF(false); // Show pagination again after PDF is generated
          setOpenBackdrop(false); // Hide Backdrop after PDF generation completes

          // // Hide the table again after the PDF is generated
          if (window.innerWidth < 960) {
            tableContainer.style.display = "none"; // Hide on mobile and tablet
          } else {
            tableContainer.style.display = "block"; // Show on desktop
          }
        }
      );
    }, 500); // Wait 500ms for state to update and pagination to hide
  };

  const headers = [
    "CUSTOMER'S",
    "EXECUTIVE",
    "ORDER NO.",
    "ORDER DATE",
    "STATUS",
    "TOTAL AMOUNT",
  ];

  const headerCellStyle = {
    fontSize: 14, // Adjust the font size as needed
    fontWeight: "bold",
    backgroundColor: "#4CB5F5",
    color: "white",
  };

  const evenRowStyle = {
    background: "#f5f5f5",
  };

  const oddRowStyle = {
    background: "white",
  };

  const rowStyle = {
    "&:hover": {
      background: "#f1f9fe",
    },
  };
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 300); // Adjust debounce time as needed

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  const assignCompanyLogo = async () => {
    try {
      const result = await configServ.company_logo({ company_id: company_id });
      if (result && result.image) {
        // Assuming the result.image is already a base64 string
        setCompanyLogo(`data:image/png;base64,${result.image}`);
      } else {
        setCompanyLogo(null); // Set as null if no logo
      }
    } catch (err) {
      console.log("Error fetching company logo:", err);
      setCompanyLogo(null); // Handle error by clearing logo
    }
  };

  useEffect(() => {
    assignCompanyLogo();
  }, []);
  const filteredSalesOrders = salesOrders.filter((order) => {
    if (!searchQuery) return true;
    switch (searchBy) {
      case "partyname":
        return order.partyname
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
      case "Order_Number":
        return order.order_no.toLowerCase().includes(searchQuery.toLowerCase());
      case "sales_Executive":
        return order.salesperson
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
      case "order_Date":
        return new Date(order.order_date)
          .toLocaleDateString()
          .includes(searchQuery);
      default:
        return true;
    }
  });
  // Step 2: Use useEffect to recalculate the total when filteredSalesOrders changes
  useEffect(() => {
    const total = filteredSalesOrders.reduce((acc, order) => {
      const amount =
        typeof order.total_amount === "string"
          ? parseFloat(order.total_amount.replace(/[^0-9.-]+/g, ""))
          : order.total_amount;

      return acc + (isNaN(amount) ? 0 : amount);
    }, 0);

    setTotalFilteredAmount(total); // Update the total amount state
  }, [filteredSalesOrders]); // Runs whenever the filteredSalesOrders array changes

  // Step 3: Apply pagination to the filtered data
  const paginatedSalesOrders = filteredSalesOrders.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  useEffect(() => {
    const fetchSalesOrderList = async () => {
      try {
        // Set loading state
        setLoading(true);
        const dataToSend = {
          company_id: company_id,
          user_id: user_id,
          isApproved: isApproved,
          is_admin: is_admin,
        };

        // Call the API to fetch sales order list
        const res = await configServ.getSalesOrderReport(dataToSend);
        if (res.status === 200) {
          const data = res.data.map((item) => ({
            id: item.id,
            partyname: item.partyname,
            is_admin: is_admin,
            salesperson:
              item.is_admin && item.admin_user && item.admin_user.name
                ? item.admin_user.name
                : item.user && item.user.name
                ? item.user.name
                : "Unknown Salesperson", // Fallback if both are null
            order_no: item.order_no,
            order_date: item.order_date,
            order_type: item.order_type,
            order_status: item.order_status,
            total_amount: item.total_amount,
          }));

          setSalesOrders(data);
          setCount(data.length);
        } else {
          setSalesOrders([]);
          setCount(0);
        }
      } catch (err) {
        // Handle error
        console.log(err);
      } finally {
        // Reset loading state
        setLoading(false);
      }
    };

    // Fetch the data when dependencies change
    fetchSalesOrderList();
  }, [company_id, isApproved, page, rowsPerPage]);

  return (
    <>
      <CardHeadMenu
        prefix={"Na"}
        suffix={"Na"}
        title={"Sales Order Report"}
        showCreate={false}
      />
      <Backdrop
        sx={(theme) => ({
          color: "#fff",
          zIndex: 1200, // Set high z-index to block all interactions
          position: "fixed", // Ensure it covers the whole screen
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        })}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* Row with Sales Order Report, Download button, and Search section */}
      <Grid
        container
        xs={12}
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        {/* Left Side: Sales Order Report and Download Button */}
        <Grid item xs={12} md={3} alignItems="center">
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            Sales Order Report: {count}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={3}
          alignItems="center"
          justifyContent={"flex-start"}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleMenuClick}
            startIcon={<DownloadIcon sx={{ ml: 2 }} />}
          ></Button>
          <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={handleMenuClose}>
            <MenuItem
              onClick={() => {
                downloadExcel();
                handleMenuClose();
              }}
            >
              Excel Download
            </MenuItem>
            <MenuItem
              onClick={() => {
                downloadPDF();
                handleMenuClose();
              }}
            >
              PDF Download
            </MenuItem>
          </Menu>
        </Grid>

        {/* Right Side: Search by Dropdown and Search Box */}
        <Grid item xs={12} md={3} justifyContent="flex-end">
          <FormControl fullWidth>
            <InputLabel id="search-by-label">Search by</InputLabel>
            <Select
              size="small"
              fullWidth
              labelId="search-by-label"
              value={searchBy}
              onChange={handleSearchByChange}
              label="Search by"
            >
              <MenuItem value="partyname">Customer Name</MenuItem>
              <MenuItem value="Order_Number">Order No.</MenuItem>
              <MenuItem value="sales_Executive">Sales Executive</MenuItem>
              {/* <MenuItem value="order_Date">Order Date</MenuItem> */}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3} alignItems={"center"}>
          <TextField
            size="small"
            variant="outlined"
            placeholder="Search..."
            fullWidth
            value={searchQuery}
            onChange={handleSearchQueryChange} // Bind to searchQuery
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        mt={3}
        sx={{ display: { xs: "flex", lg: "none" } }}
      >
        {loading ? (
          <CircularProgress sx={{ mt: 3, ml: 20 }} />
        ) : filteredSalesOrders.length === 0 ? (
          <Typography align="center" sx={{ width: "100%" }} mt={3} color="red">
            Data Not Found
          </Typography>
        ) : (
          filteredSalesOrders.map((row, index) => (
            <Grid item xs={12} key={row.order_no}>
              <Card sx={{ padding: 2, width: "100%" }}>
                <Typography variant="h6">
                  Order {index + 1 + page * rowsPerPage}
                </Typography>
                <Typography variant="body2">
                  <strong>Party Name:</strong> {row.partyname}{row.order_type === "online"? `(${row.order_type})`: ''}
                </Typography>
                <Typography variant="body2">
                  <strong>Salesperson:</strong> {row.salesperson}
                </Typography>
                <Typography variant="body2">
                  <strong>Order No:</strong> {row.order_no}
                </Typography>
                <Typography variant="body2">
                  <strong>Order Date:</strong>{" "}
                  {new Date(row.order_date).toLocaleDateString()}
                </Typography>
                <Typography variant="body2">
                  <strong>Order Status:</strong> {row.order_status}
                </Typography>
                <Typography variant="body2">
                  <strong>Total Amount:</strong>{" "}
                  {formatCurrency(row.total_amount)}
                </Typography>
              </Card>
            </Grid>
          ))
        )}

        {/* Display total amount for mobile */}
        {!loading && filteredSalesOrders.length > 0 && (
          <Grid item xs={12}>
            <Card sx={{ padding: 2, fontWeight: "bold", width: "100%" }}>
              Total Amount: {formatCurrency(totalFilteredAmount)}
            </Card>
          </Grid>
        )}
        {!isGeneratingPDF && (
          <TablePagination
            rowsPerPageOptions={[20, 50, 100, 200, 500]} // Adjust based on your preference
            component="div"
            count={filteredSalesOrders.length} // Total number of filtered orders
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Grid>

      <TableContainer
        component={Paper}
        sx={{
          display: { xs: "none", sm: "none", lg: "flex" }, // Correct display logic
          flexDirection: "column",
          height: "100vh", // Set full height
        }}
        ref={tableRef}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={headerCellStyle}>S.No.</TableCell>
              {headers.map((header, index) => (
                <TableCell key={index} sx={headerCellStyle}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={6} sx={{ textAlign: "center" }}>
                  <LoaderNew />
                </TableCell>
              </TableRow>
            ) : filteredSalesOrders.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6} sx={{ textAlign: "center" }}>
                  Data Not Found
                </TableCell>
              </TableRow>
            ) : (
              paginatedSalesOrders.map((row, index) => (
                <TableRow
                  key={row.order_no}
                  sx={{
                    ...rowStyle,
                    ...(index % 2 === 0 ? evenRowStyle : oddRowStyle),
                  }}
                >
                  <TableCell>{index + 1 + page * rowsPerPage}</TableCell>{" "}
                  <TableCell>{row.partyname}{row.order_type === "online"? `(${row.order_type})`: ''}</TableCell>
                  <TableCell>{row.salesperson}</TableCell>
                  <TableCell>{row.order_no}</TableCell>
                  <TableCell>
                    {new Date(row.order_date).toLocaleDateString()}
                  </TableCell>
                  <TableCell>{row.order_status}</TableCell>
                  <TableCell>{formatCurrency(row.total_amount)}</TableCell>
                </TableRow>
              ))
            )}

            {/* Row to show total amount */}
            {!loading && filteredSalesOrders.length > 0 && (
              <TableRow>
                <TableCell
                  colSpan={6}
                  sx={{ fontWeight: "bold", textAlign: "right" }}
                >
                  Total Amount:
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>
                  {formatCurrency(totalFilteredAmount)}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        {/* Conditionally hide pagination during PDF download */}
        {!isGeneratingPDF && (
          <TablePagination
            rowsPerPageOptions={[20, 50, 100, 200, 500]} // Adjust based on your preference
            component="div"
            count={filteredSalesOrders.length} // Total number of filtered orders
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </TableContainer>
    </>
  );
};

export default SalesReport;
