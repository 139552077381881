//#region Vouchers
const VoucherTypes = [
    {
        label: 'Payment',
        value: 'payment',
    },
    {
        label: 'Receipt',
        value: 'receipt',
    },
];

const JournalVoucherTypes = [
    {
        label: 'Credit Note',
        value: 'creditnote',
    },
    {
        label: 'Debit Note',
        value: 'debitnote',
    },
    {
        label: 'Journal',
        value: 'journal',
    },
];

const PaymentModes = [
    {
        label: 'Bank',
        value: 1,
    },
    {
        label: 'Cash',
        value: 2,
    }
];

const EntityTypes = [
    {
        label: 'Account',
        value: 'account',
    },
    {
        label: 'Customer',
        value: 'customer',
    },
    {
        label: 'Supplier',
        value: 'supplier',
    }
];

const customerTypes = [
    {
        label: 'Standard',
        value: 'Standard',
    },
    {
        label: 'Privileged',
        value: 'Privileged',
    },
    {
        label: 'Staff',
        value: 'Staff',
    },
    {
        label: 'Discount',
        value: 'Discount',
    },
    {
        label: 'Direct Cash',
        value: 'Direct Cash',
    }
];

const isCredit = [
    {
        label: 'Debit',
        value: false,
    },
    {
        label: 'Credit',
        value: true,
    },
];

const filterOutstandingAmount = [
    {
        label: '> 0',
        value: 1,
    },
    {
        label: '> 500',
        value: 500,
    },
    {
        label: '> 2000',
        value: 2000,
    },
    {
        label: '> 5000',
        value: 5000,
    },
    {
        label: '> 10000',
        value: 10000,
    },
];

//#endregion

const Constants = {
    VoucherTypes,
    JournalVoucherTypes,
    PaymentModes,
    EntityTypes,
    customerTypes,
    isCredit,
    filterOutstandingAmount,
}

export default Constants;