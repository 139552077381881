import React from 'react';
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';

const DownloadCustomersTableExcel = (customerData, companyName) => {
  // console.log("customerData=",customerData)
  const makePositive = (amount) => Math.abs(amount);
  const roundToInteger = (amount) => Math.round(amount);

  // 1. Map the customer data to the desired format
  const filteredData = customerData.map((order) => ({
    CUSTOMER: order.fname,
    USERNAME: order.customer_code,
    MOBILE_NO: order.mobile,
    OPENING: order.openingamount,
    OUTSTANDING: `${makePositive(roundToInteger(order.due_amount ?? 0))} ${(order.due_amount ?? 0) > 0 ? 'Dr' : 'Cr'}`,
  }));

  // 2. Create a new worksheet and workbook
  const worksheet = XLSX.utils.json_to_sheet([]);
  const workbook = XLSX.utils.book_new();

  // 3. Add title, company name, and a placeholder for the logo
  XLSX.utils.sheet_add_aoa(
    worksheet,
    [
      ["Customer List"], // Title
      [`Company Name: ${companyName}`], // Company Name
      [], // Empty row for spacing
    ],
    { origin: "A1" }
  );

  // 4. Add filtered data starting from row 4
  XLSX.utils.sheet_add_json(worksheet, filteredData, { origin: "A4" });

  // 5. Set column width for better readability
  const wscols = [
    { wch: 30 }, // CUSTOMER
    { wch: 20 }, // USERNAME
    { wch: 20 }, // MOBILE_NO
    { wch: 20 }, // OPENING
    { wch: 20 }, // OUTSTANDING
  ];
  worksheet['!cols'] = wscols;

  // 6. Append the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Customers Data');

  // 7. Format the file name with the current date
  const currentDate = new Date().toLocaleDateString('en-GB').replace(/\//g, '-'); // Format: DD-MM-YYYY

  // 8. Write the Excel file and trigger download
  XLSX.writeFile(workbook, `Customers_${currentDate}.xlsx`);

  // 9. Success popup after Excel download
  Swal.fire({
    position: 'top-end',
    icon: 'success',
    title: 'Your Excel file has been downloaded',
    showConfirmButton: false,
    timer: 1500,
  });
};

export default DownloadCustomersTableExcel;

