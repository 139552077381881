import React, { useState } from "react";
import { Stack, Paper, Divider } from "@mui/material";
import BatchTable from "../Card/MaterEntryCards/BatchTable";
import BatchCard from "../Card/MaterEntryCards/BatchCard";

//FUNCTION
const Batch = () => {
    //#region code
    const [isChangedData, setIsChangedData] = useState(false);
    const [editData, setEditData] = useState({});

    //#endregion

    return (
        <>
            <Stack
                direction='column'
                justifyContent='center'
                alignItems='center'
            >
                <Paper
                    elevation={0}
                    sx={{
                        width: '100%',
                        // mt: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 3,
                        // pb:5
                    }}
                >
                    <BatchCard isChanged={isChangedData} setIsChanged={setIsChangedData} />
                </Paper>
                <Divider />

                <BatchTable isChangedData={isChangedData} />

            </Stack>

        </>
    )
}
export default Batch;