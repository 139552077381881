
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    userData:{},
    isUserAdmin:false
}

export const userSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    dataAssign: (state, action) => {
        state.userData = action.payload
    },
    userType: (state, action) => {
        state.isUserAdmin = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { dataAssign, userType } = userSlice.actions

export default userSlice.reducer