import React, { memo, useEffect } from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, FormLabel, IconButton, Radio, RadioGroup, Stack, Tab, Tabs, TextField, Typography } from '@mui/material';
import configServ from '../../services/config';
import { useDispatch } from 'react-redux';
import { triggerLoader } from '../../redux/reducers/LoaderTrigger';
import AlertDialog from "../../Components/AlertDialog/AlertDialog";
import EmailIcon from '@mui/icons-material/Email';
import PrintIcon from '@mui/icons-material/Print';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import InvoiceTabPanel from '../Tab/InvoiceTabPanel';
import CheckAndReturn from '../../utility/CheckAndReturn';
import LoaderNew from '../Loader/LoaderNew';

const InvoiceOrientation = ({ open, setOpen, data }) => {
    //#region 
    const dispatch = useDispatch()
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState('');
    const [page, setPage] = useState(2);
    const [landscape, setLandscape] = useState(true);
    const [value, setValue] = useState(0);
    const [isEmail, setIsEmail] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isDirectCash, setIsDirectCash] = useState(false);
    const [customerName, setCustomerName] = useState(null);

    const fetchCustomerEmail = async () => {
        try {
            setLoading(true);
            const dataToSend = {
                "customer_id": data.customer_id
            }
            const res = await configServ.getCustomerEmailByID(dataToSend);
            if (res.status === 200) {
                setIsEmail(true);
            }
            else {
                setIsEmail(false);
            }
        }
        catch (e) {
            console.log(e);
        }
        finally {
            setLoading(false);
        }
    }

    const checkCustomerType = async () => {
        try {
            let is_direct_cash = false;
            const res = await configServ.getCustomerById({
                customer_id: data.customer_id
            });
            if (res.status === 200) {
                const result = res.data;
                if (result.type === 'Direct Cash') {
                    is_direct_cash = true;
                }
            }
            data.is_direct_cash = is_direct_cash;
            setIsDirectCash(is_direct_cash);
        }
        catch (e) {
            console.log(e);
        }
    }


    useEffect(() => {
        if (data) {
            const checkEmailAndType = async () => {
                await fetchCustomerEmail();
                await checkCustomerType();
            }
            checkEmailAndType();
        }
    }, [data]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleOpenDialog = (content) => {
        setDialogContent(content);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setDialogContent('');
    };

    const handleOrientationChange = (event) => {
        const { name, checked } = event.target;
        setLandscape(name === "landscape" ? checked : !checked);
    };

    const printInvoice = async () => {
        try {
            dispatch(triggerLoader());
            const dataToSend = {
                ...data,
                "email": false,
                "landscape": landscape,
                "page": page,
                "customer_name": customerName
            }
            const result = await configServ.printInvoice(dataToSend);
            const blobFile = new Blob([result], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blobFile);
            window.open(url);
        }
        catch (e) {
            handleOpenDialog('Error while printing invoice');
            console.log(e);
        }
        finally {
            dispatch(triggerLoader());
        }
    };

    const emailInvoice = async () => {
        try {
            dispatch(triggerLoader());
            const dataToSend = {
                ...data,
                "email": true,
                "landscape": landscape,
                "page": page
            }
            const res = await configServ.printInvoice(dataToSend);
            if (res.status === 200) {
                handleOpenDialog(res.message);
            }
            else if (res.status === 404) {
                handleOpenDialog(res.message);
            }
            else {
                handleOpenDialog('Error while mailing/printing invoice');
            }
        }
        catch (e) {
            handleOpenDialog('Error while printing invoice');
            console.log(e);
        }
        finally {
            dispatch(triggerLoader());
        }
    };

    const handleClose = () => {
        setDialogOpen(false);
        setDialogContent('');
        setPage(2);
        setLandscape(true);
        setValue(0);
        setLoading(true);
        setIsEmail(false);
        setOpen(false);
        setIsDirectCash(false);
        setCustomerName(null);
    };

    const handlePage = (event) => {
        const data = parseInt(event.target.value);
        setPage(data);
    }

    const handleSendEmail = () => {
        emailInvoice();
    };
    //#endregion

    return (
        <div>
            <AlertDialog
                open={dialogOpen}
                setOpen={handleCloseDialog}
                title="Alert"
                message={dialogContent}
                buttonTitle="Ok"
            />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth='xs'
            >
                <DialogTitle id="alert-dialog-title">
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 0 }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs">
                            <Tab label="EMAIL" id="tab-0" aria-controls="tabpanel-0" sx={{ fontWeight: 600 }} />
                            <Tab label="PRINT" id="tab-1" aria-controls="tabpanel-1" sx={{ fontWeight: 600 }} />
                        </Tabs>
                    </Box>
                    <Box position="absolute" right={16} top={6}>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                        >
                            <DisabledByDefaultIcon sx={{ fontSize: 32 }} color="error" />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <InvoiceTabPanel value={value} index={0}>
                    <Box textAlign="center">
                        <Typography variant="h6" color="textSecondary">
                            Save paper, send the invoice via email!
                        </Typography>
                        <br />
                        {
                            loading ?
                                <LoaderNew />
                                :
                                <Button
                                    disabled={!isEmail}
                                    variant="contained"
                                    color="primary"
                                    startIcon={<EmailIcon />}
                                    onClick={handleSendEmail}
                                >
                                    Send Email
                                </Button>
                        }
                        {
                            (loading === false && isEmail === false) &&
                            <Typography component={'p'} variant="body" color="error" p={2}>
                                No email address found. Please update customer email to continue.
                            </Typography>
                        }
                    </Box>
                </InvoiceTabPanel>
                <InvoiceTabPanel value={value} index={1}>
                    <Stack direction={'column'}>
                        <DialogContent>
                            {
                                isDirectCash === true &&
                                <Box mb={1}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        name='customer_name'
                                        label='Customer Name'
                                        sx={{ background: 'white' }}
                                        value={CheckAndReturn.checkTextNullAndUndefined(customerName)}
                                        onChange={(e) => setCustomerName(e.target.value)}
                                    />
                                </Box>
                            }
                            <Box mb={1}>
                                <FormControl>
                                    <FormLabel id="demo-row-radio-buttons-group-label" >No. of pages</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        value={page}
                                        onChange={handlePage}
                                    >
                                        <Stack direction={'row'}>
                                            <FormControlLabel value={1} control={<Radio />} label="1" />
                                            <FormControlLabel value={2} control={<Radio />} label="2" />
                                            <FormControlLabel value={3} control={<Radio />} label="3" />
                                            <FormControlLabel value={4} control={<Radio />} label="4" />
                                        </Stack>
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                            <Box>
                                <FormControl component="fieldset">
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={landscape}
                                                    onChange={handleOrientationChange}
                                                    name="landscape"
                                                />
                                            }
                                            label="Landscape"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={!landscape}
                                                    onChange={handleOrientationChange}
                                                    name="portrait"
                                                />
                                            }
                                            label="Portrait"
                                        />
                                    </FormGroup>
                                </FormControl>

                            </Box>
                        </DialogContent>
                        <DialogActions sx={{ justifyContent: 'center' }}>
                            <Button variant='contained' startIcon={<PrintIcon />} onClick={() => { handleClose(); printInvoice() }}>
                                Print
                            </Button>
                        </DialogActions>
                    </Stack>
                </InvoiceTabPanel>
            </Dialog>
        </div>
    );
}

export default memo(InvoiceOrientation);