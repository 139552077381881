import React, { memo, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Box } from '@mui/material';
import Paper from '@mui/material/Paper';
import useDeviceDetect from '../../../Detect/useDeviceDetect ';
import OutstandingCustomerTable from './OutstandingCustomerTable';
import OutstandingCustomerCard from './OutstandingCustomerCard';
import PopupDialogTitle from '../../../Dialog/PopupDialogTitle';
import Calculation from '../../../../utility/Calculations';
import { useDispatch } from 'react-redux';
import { triggerLoader } from '../../../../redux/reducers/LoaderTrigger';

//FUNCTION
const OutstandingPopup = memo(({ openOutstandingPopup, handleOutstandingPopup, outstandingCustomerData }) => {
    const { isMobile } = useDeviceDetect();
    const dispatch = useDispatch();
    const outstandingRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => outstandingRef.current,
        documentTitle: 'Outstanding Ledger',
    });

    const handleDownload = () => {
        try {
            dispatch(triggerLoader());
            const input = outstandingRef.current;

            html2canvas(input, { scale: 1 }) // Adjust the scale to reduce resolution (e.g., 1 for lower resolution)
                .then(canvas => {
                    const imgData = canvas.toDataURL('image/jpeg', 0.75); // Use 'image/jpeg' and reduce quality (0.75 is 75% quality)
                    const pdf = new jsPDF();
                    const imgProps = pdf.getImageProperties(imgData);
                    const pdfWidth = pdf.internal.pageSize.getWidth();
                    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

                    pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight); // Use 'JPEG' instead of 'PNG'
                    const name = outstandingCustomerData?.name ?? 'OutstandingLedger';
                    const date = Calculation.GetCurrentDateString();
                    const pdfName = name + '_' + date + '.pdf';
                    setTimeout(() => {
                        pdf.save(pdfName);
                        dispatch(triggerLoader());
                    }, 5000);
                })
                .catch(error => {
                    console.error('Error generating PDF: ', error);
                    dispatch(triggerLoader()); // Stop loader if there's an error
                });
        }
        catch (e) {
            console.log(e);
            dispatch(triggerLoader()); // Stop loader if there's an error in try block
        }
    };

    return (
        <>
            <Dialog
                open={openOutstandingPopup}
                onClose={handleOutstandingPopup}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={false}
                PaperProps={{
                    sx: {
                        width: '90%',
                        height: '80%',
                    },
                }}
            >
                <PopupDialogTitle handleDownload={handleDownload} handlePrint={handlePrint} handleClose={handleOutstandingPopup}/>
                <DialogContent>
                    {
                        isMobile ?
                            <Box component={Paper} sx={{ padding: '16px', backgroundColor: '#fff', overflowY: 'auto', maxHeight: '100vh' }} ref={outstandingRef}>
                                <OutstandingCustomerCard outstandingCustomerData={outstandingCustomerData} />
                            </Box>
                            :
                            <Box component={Paper} sx={{ padding: '16px', backgroundColor: '#fff', overflowY: 'auto', maxHeight: '80vh' }} ref={outstandingRef}>
                            <OutstandingCustomerTable outstandingCustomerData={outstandingCustomerData} />
                            </Box>
                    }
                </DialogContent>
            </Dialog>
        </>
    )
});

export default memo(OutstandingPopup);
