import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import SiteLogo from "../../Assets/images/DovetailLogo.svg";
import LogoInitial from "../../Assets/images/SiteLogoInitial.png";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Stack,
  Button,
  Tooltip,
  IconButton,
  Drawer,
  useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { userType } from "../../../redux/reducers/userData";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import { changeLightMode } from "../../../redux/reducers/GlobalVariables";
import MenuIcon from "@mui/icons-material/Menu";

const agent_32 = "http://demo.d-crm.in/doc/dcrmagent_32.zip";
const agent_64 = "http://demo.d-crm.in/doc/dcrmagent_64.zip";
//const agent_32 = "https://mt.d-crm.in/doc/dcrmagent_32.zip";
//const agent_64 = "https://mt.d-crm.in/doc/dcrmagent_64.zip";

function OuterHeader1({ companyLogo }) {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:600px)");
  const lightMode = useSelector((state) => state.GlobalVariables.lightMode);
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };

  return (
    <Box sx={{ display: "flex", mb: 10 }}>
      <CssBaseline />
      <AppBar
        component="nav"
        elevation={0}
        sx={{ backgroundColor: "transparent", p: "10px" }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Box sx={{ width: "12%" }}>
            <Box
              component={"img"}
              src={
                SiteLogo
              }
              alt="Dovetail"
              sx={{
                filter: lightMode ? "invert(0)" : "invert(1)",
                width: { xs: "10rem" }, // Conditional width
                display: { xs: "none", sm: "block" }, // Conditional display
                height: "auto", // Example for height

              }}
            />

            <Box
              component={"img"}
              src={
                LogoInitial
              }
              sx={{
                filter: lightMode ? "invert(0)" : "invert(1)",
                width: { xs: "4rem" },
                display: { xs: "block", sm: "none" },
              }}
              alt="Dovetail"
            />
          </Box>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 2, sm: 4 }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ display: { xs: "inline", sm: "none" } }}
              onClick={handleMenuToggle}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="top"
              open={isMenuOpen}
              onClose={handleMenuClose}
              sx={{ "& .MuiPaper-root": { backgroundColor: "transparent" } }}
            >
              <Box p={2}>
                <Stack direction="column" spacing={2}>
                  <NavLink to="/" onClick={handleMenuClose}>
                    <Button
                      variant="text"
                      sx={{ color: lightMode ? "#333" : "#ffffff" }}
                    >
                      Home
                    </Button>
                  </NavLink>
                  {/* <NavLink to="/Registration-Form" onClick={handleMenuClose}>
                    <Button variant="text" sx={{ color: lightMode ? '#333' : '#ffffff' }}>
                      Register
                    </Button>
                  </NavLink> */}
                  <NavLink to="/admin-login" onClick={handleMenuClose}>
                    <Button
                      variant="text"
                      sx={{ color: lightMode ? "#333" : "#ffffff" }}
                    >
                      Admin Login
                    </Button>
                  </NavLink>
                  {/*<a href={agent_32} download>
                    <Button
                      variant="text"
                      sx={{ color: lightMode ? "#333" : "#ffffff" }}
                    >
                      Agent-32
                    </Button>
                  </a>
                  <a href={agent_64} download>
                    <Button
                      variant="text"
                      sx={{ color: lightMode ? "#333" : "#ffffff" }}
                    >
                      Agent-64
                    </Button>
                  </a>*/}
                  <Button
                    variant="text"
                    sx={{ color: lightMode ? "#333" : "#ffffff" }}
                    onClick={() => {
                      dispatch(changeLightMode());
                      handleMenuClose();
                    }}
                  >
                    {lightMode ? (
                      <Tooltip title="Dark Mode">
                        <DarkModeOutlinedIcon />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Light Mode">
                        <LightModeOutlinedIcon />
                      </Tooltip>
                    )}
                  </Button>
                </Stack>
              </Box>
            </Drawer>
            {!isMobile && (
              <>
                <NavLink to="/">
                  <Button
                    variant="text"
                    sx={{
                      color: lightMode ? "#333" : "#ffffff",
                      "&:hover": {
                        color: "#959cb1",
                      },
                    }}
                  >
                    Home
                  </Button>
                </NavLink>
                <NavLink to="/admin-login">
                  <Button
                    variant="text"
                    sx={{
                      color: lightMode ? "#333" : "#ffffff",
                      "&:hover": {
                        color: "#959cb1",
                      },
                    }}
                    onClick={() => {
                      navigate("/admin-login");
                    }}
                  >
                    Admin Login
                  </Button>
                </NavLink>
                {/*<a href={agent_32} download>
                  <Button
                    variant="text"
                    sx={{ color: lightMode ? "#333" : "#ffffff" }}
                  >
                    Agent-32
                  </Button>
                </a>
                <a href={agent_64} download>
                  <Button
                    variant="text"
                    sx={{ color: lightMode ? "#333" : "#ffffff" }}
                  >
                    Agent-64
                  </Button>
                </a>*/}
                {/* <NavLink to="/Registration-Form">
                  <Button
                    variant="text"
                    sx={{
                      color: lightMode ? '#333' : '#ffffff',
                      '&:hover': {
                        color: '#959cb1',
                      },
                    }}
                    onClick={() => {
                      navigate('/Registration-Form');
                    }}
                  >
                    Register
                  </Button>
                </NavLink> */}
                <Button
                  variant="text"
                  sx={{
                    color: lightMode ? "#333" : "#ffffff",
                    "&:hover": {
                      color: "#959cb1",
                    },
                  }}
                  onClick={() => {
                    dispatch(changeLightMode());
                  }}
                >
                  {lightMode ? (
                    <Tooltip title="Dark Mode">
                      <DarkModeOutlinedIcon />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Light Mode">
                      <LightModeOutlinedIcon />
                    </Tooltip>
                  )}
                </Button>
              </>
            )}
          </Stack>
        </Toolbar>
      </AppBar>
      <Box component="nav"></Box>
    </Box>
  );
}

export default OuterHeader1;
