import React from "react";
import { Outlet } from "react-router-dom";

//FUNCTION
const AdminReport = () => {
    return (
        <>
            <Outlet />
        </>
    )
}

export default AdminReport;