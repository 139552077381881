import React from "react";
import {
TableContainer,
Paper,
TablePagination,
Table,
TableBody,
TableCell,
TableHead,
TableRow,
Typography
} from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion'

const CustSalesSubsTable = ({ rows }) => {

  const [page, setPage] = React.useState(0);
  const rowsPerPageOptions = [20, 50, 100, 200];
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageOptions[1]);

  const columns = [
    {
      id: 'id',
      label: 'SR. NO',
      minWidth: 170
    },
    // {
    //   id: 'customer',
    //   label: 'CUSTOMER',
    //   minWidth: 170,
    //   align: 'right',
    //   format: (value) => value.toLocaleString('en-US'),
    // },
    {
      id: 'product',
      label: 'PRODUCT',
      minWidth: 170,
      align: 'right',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'biling-cycle',
      label: 'BILING CYCLE',
      minWidth: 170,
      align: 'right',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'kit-type',
      label: 'KIT TYPE',
      minWidth: 170,
      align: 'right',
      format: (value) => value.toFixed(2),
    },
    {
      id: 'reg_from',
      label: 'REGISTRATION FROM',
      minWidth: 170,
      align: 'right',
      format: (value) => value.toFixed(2),
    },
    {
      id: 'valid_to',
      label: 'VALID TO',
      minWidth: 170,
      align: 'right',
      format: (value) => value.toFixed(2),
    },
    {
      id: 'amount',
      label: 'AMOUNT',
      minWidth: 170,
      align: 'right',
      format: (value) => value.toFixed(2),
    },
    {
      id: 'status',
      label: 'STATUS',
      minWidth: 170,
      align: 'right',
      format: (value) => value.toFixed(2),
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //   useEffect(()=>{
  //     if(rows !== undefined){
  //         console.log(rows[0]?.status)
  //     }
  //   },[])

  return (
    <>
      <Paper sx={{ width: '100%' }}>
        <TableContainer sx={{ maxHeight: 440 }}
          component={motion.div}
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 440 }}
          transition={{ duration: 0.3, delay: 0.2 }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    key={column.id}>
                    <b>{column.label}</b>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <>
                      <TableRow key={row.id} hover role="checkbox" tabIndex={-1} >
                        <TableCell >{++index}</TableCell>
                        {/* <TableCell >{row.customer_code || 'N/A'}</TableCell> */}
                        <TableCell >{row.product || 'N/A'}</TableCell>
                        <TableCell >{row.billing_type || 'N/A'}</TableCell>
                        <TableCell >{row.kit_type || 'N/A'}</TableCell>
                        <TableCell >{row.subs_valid_from || 'N/A'}</TableCell>
                        <TableCell >{row.subs_valid_to || 'N/A'}</TableCell>
                        <TableCell >{row.price || 'N/A'}</TableCell>
                        <TableCell >{Boolean(row.status) === true ? 'Subscribed' : 'Expired' || 'N/A'}</TableCell>
                      </TableRow>
                    </>);
                })}
              {rows.length === 0 && (<TableRow>
                <TableCell align='center' colSpan={9}><Typography variant='h6'>No Records to show </Typography></TableCell>
              </TableRow>)}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  )
};
export default CustSalesSubsTable;