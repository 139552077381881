import React, { useState } from 'react';
import DispatchCard from '../../Components/Card/Dispatch/DispatchCard';
import DispatchTable from '../../Components/Table/Dispatch/DispatchTable';

const Dispatch = () => {
    //#region code
    const [editData, setEditData] = useState(null);
    const [isChanged, setIsChanged] = useState(false);

    const handleEdit = (params) => {
        setEditData(params);
    }
    //#endregion

    return (
        <>
            <DispatchCard editData={editData} setEditData={setEditData} isChanged={isChanged} setIsChanged={setIsChanged} />
            <DispatchTable handleEditData={handleEdit} isChanged={isChanged} />
        </>
    )
}

export default Dispatch
