import React, { memo, useState } from "react";
import SupplierCard from "../../Components/supplier/SupplierCard";
import SupplierTable from "../../Components/supplier/SupplierTable";

//FUNCTION
const Supplier = () => {
    //#region code
    const [isChanged, setIsChanged] = useState(false);
    const [editData, setEditData] = useState(null);

    const handleEdit = (data) => {
        setEditData(data);
    }
    //#endregion

    return (
        <>
            <SupplierCard isChanged={isChanged} setIsChanged={setIsChanged} editData={editData} setEditData={setEditData} />

            <SupplierTable isChanged={isChanged} handleEdit={handleEdit} />
        </>
    )
}
export default memo(Supplier);