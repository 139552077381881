import React, { memo, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { Grid, Stack, Typography } from '@mui/material';
import CheckAndReturn from '../../../utility/CheckAndReturn';
import { useNavigate } from 'react-router-dom';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#4CB5F5',//theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const InvoiceItemTable = ({ invoiceItems }) => {
  const navigate = useNavigate();
  const [invoiceAmount, setInvoiceAmount] = useState(0);

  useEffect(() => {
    let totalInvoiceAmount = 0;

    invoiceItems.forEach(row => {
      totalInvoiceAmount += Number(row.amount ?? 0);
    });

    setInvoiceAmount(totalInvoiceAmount);

    return () => {
      setInvoiceAmount(0);
    }
  }, [invoiceItems]);

  const handleItemClick = (params) => {
    try {
      const data = params;
      navigate('/master-entry/item', { state: data });
    }
    catch (e) {
      console.log(e);
    }
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Box sx={{ overflowX: 'auto' }}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">S.No</StyledTableCell>
                <StyledTableCell align="left">Name</StyledTableCell>
                <StyledTableCell align="left">Code</StyledTableCell>
                <StyledTableCell align="left">Quantity</StyledTableCell>
                <StyledTableCell align="left">Rate</StyledTableCell>
                <StyledTableCell align="left">Discount(%)</StyledTableCell>
                <StyledTableCell align="left">Tax(%)</StyledTableCell>
                <StyledTableCell align="left">Amount</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoiceItems.length > 0 ? (
                invoiceItems.map((row, index) => (
                  <StyledTableRow key={row.item_id}>
                    <StyledTableCell align="left">{index + 1}</StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      <span className='linkStyle' onClick={() => handleItemClick(row.itemname)}>{row.itemname}</span>
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.itemcode}</StyledTableCell>
                    <StyledTableCell align="left">{row.qty}</StyledTableCell>
                    <StyledTableCell align="left">₹{row.rate}</StyledTableCell>
                    <StyledTableCell align="left">{row.discount}</StyledTableCell>
                    <StyledTableCell align="left">{row.tax_rate}</StyledTableCell>
                    <StyledTableCell align="left">₹{row.amount}</StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={12} sx={{ textAlign: 'center', color: 'red', fontSize: '16px' }}>
                    <div>
                      <p><b>No Item Found.</b></p>
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </TableContainer>

      <Grid container spacing={1} mt={2}>
        <Grid container item xs={11} md={11} justifyContent={'flex-end'}>
          <Stack direction={'row'}>
            <Typography variant='body1' sx={{ fontSize: '16px', fontWeight: 600 }}>
              Invoice Amount:
            </Typography>
            <Typography variant='body1' sx={{ fontSize: '16px', fontWeight: 600, color: 'green' }}>
              ₹{CheckAndReturn.roundToInteger(invoiceAmount ?? 0)}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default memo(InvoiceItemTable);
