import React,{useState} from 'react'

import { BsPlusSquareFill, BsFillCalendar3EventFill, BsFillFileFill, BsXCircle } from "react-icons/bs";

import { Form, Container, Row, Col } from 'react-bootstrap';
import {  RadioGroup, Radio, IconButton, Tooltip, Fade  } from "@mui/material";
import MyFormControlLabel from '@mui/material/FormControlLabel';
import NoteCard from "../../../Components/Card/NoteCards/NoteCard";

import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

function NoteCardMain({title}) {

    const [notes, setNotes] = useState([])

    const addNote = ()=>{
        setNotes((state)=>([
            ...state,
            {}
        ]))
    }
    const deleteNote = (id)=>{
        let data = [...notes]
        data.splice(id,1)
        setNotes(data)
    }


  return (
    <>
    
        <Row style={{ padding: 10 }}>

        <div className="d-flex justify-content-end" >
        <Col sm={1} style={{ marginRight: 10 }}>
                <Tooltip title='Reset' placement='top' arrow TransitionComponent={Fade} TransitionProps={{ timeout: 300 }}>
                    <IconButton>
                        <RestartAltIcon fontSize='large' color='primary'/>
                    </IconButton>
                </Tooltip>
            </Col>
            <Col sm={1}>
                <Tooltip title='Save' placement='top' arrow TransitionComponent={Fade} TransitionProps={{ timeout: 300 }}>
                    <IconButton>
                        <SaveIcon fontSize='large' color='success'/>
                    </IconButton>
                </Tooltip>
            </Col>
            <Col sm={1}>
                <Tooltip title='Close' placement='top' arrow TransitionComponent={Fade} TransitionProps={{ timeout: 300 }}>
                    <IconButton>
                        <CancelIcon fontSize='large' color='error'/>
                    </IconButton>
                </Tooltip>
            </Col>
        </div>
        </Row>

        <div style={{ width: "100%", backgroundColor: "#DBDFEA99", padding: 20, borderRadius:'10px', border:'none' }}>
        <Row>
            <Col sm={3}>
                <Form.Label>Customer Name</Form.Label>
                <Form.Control type="" />
            </Col>
            <Col sm={3}>
                <Form.Label>Bill To Address</Form.Label>
                <Form.Control type="" />
            </Col>
            <Col sm={3}>
                <Form.Label>Original Invoice-No.</Form.Label>
                <Form.Control type="" />
            </Col>
            <Col sm={3}>
                <Form.Label>Date</Form.Label>
                <Form.Control type="date" />
            </Col>

        </Row>
        <br />
        <Row >
            <Col sm={3}>
                <Form.Label>Reason For {title} Note</Form.Label>
                <Form.Control type="" />
            </Col>
            <Col sm={3}>
                <Form.Label>Tax (in) </Form.Label>
                <RadioGroup name="use-radio-group" defaultValue="first" row>
                    <MyFormControlLabel value="first" label="CGST + SGST" control={<Radio size="small" />} inline />
                    <MyFormControlLabel inline value="second" label="IGST" control={<Radio size="small" />} />
                </RadioGroup>
            </Col>
            <Col sm={3}>
                <Form.Label>Tax Amount(₹ 0.00)</Form.Label><br />
                <Form.Label>Net Amount(₹ 0)</Form.Label>
            </Col>

        </Row>
        </div>
        <br />
                    <NoteCard noDel={true} addNote={addNote} deleteNote={deleteNote}/>
                    {
                        notes.map((item, index)=>{
                            return(
                                <NoteCard key={index} id={index} addNote={addNote} deleteNote={deleteNote}/>
                            )
                        })
                    }
    
    </>
  )
}

export default NoteCardMain