import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Container, Grid, Button, Stack } from "@mui/material";

import SiteLogo from "../../Components/Assets/images/DovetailLogo.svg";

import OuterHeader1 from "../../Components/header/Header/OuterHeader1";
import LoginIcon from "@mui/icons-material/Login";
import { useSelector } from "react-redux";
import bk from "../../Components/Assets/images/wbk.png";
import configServ from "../../services/config";
import Skeleton from "@mui/material/Skeleton";
import SupportButton from "./SupportButton";

// import Inquiry from "../../Components/Inquiry/Inquiry";

const Welcome = () => {
  const lightMode = useSelector((state) => state.GlobalVariables.lightMode);
  const inquiryRef = useRef(null);
  const navigate = useNavigate();
  const [companyLogo, setCompanyLogo] = useState(null);
  const [companyName, SetCompanyName] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  
  // const emailRef = useRef(null);
  // const commentRef = useRef(null);

  // const checkedLoggedIn = ()=>{
  //     if(cookie.get('dcrm_token') !== undefined ){
  //         if(cookie.get('userType') === 'customer' ){
  //             navigate('/customer-dashboard')
  //         }else{
  //             navigate('/Dashboard')
  //         }
  //     }
  // }
  const fetchPrimaryCompany = async () => {
    try {
      const res = await configServ.getPrimaryCompany();
      if (res.status === 200) {
        setCompanyLogo(res.data.CompanyLogo);
        SetCompanyName(res.data.CompanyName);
      } else {
        console.log(`Unexpected status code: ${res.status}`);
      }
    } catch (error) {
      console.log("Error fetching primary company", error);
    } finally {
      setIsLoading(false); // Stop loading after API call
    }
  };

  useEffect(() => {
    fetchPrimaryCompany();
  }, []);

  const lines = [
    "Dovetail-crm is a technology-driven manufacturing company delivering Billing, Payment Delivery Solutions for Small Medium Businesses.",
    "This software is the web version of Accounting Software.",
    "It provide flexibility to the user to use web application for invoicing and web application but data will be synced and will provide flexibility to the user.",
  ];

  return (
    <>
      <Box
        sx={{
          // position: 'absolute',
          // top: 0,
          width: "100vw",
          height: "100vh",
          backgroundImage: `url(${bk})`,
          backgroundColor: lightMode ? "#ffffff" : "#090e34",
          opacity: 1,
          zIndex: -1,
        }}
      >
        <OuterHeader1 companyLogo={companyLogo} />
        <Container
          component="main"
          maxWidth="md"
          sx={{
            // border: '1px solid red',
            // padding: 3,
            mt: {
              xs: 10,
              sm: 10,
            },
          }}
        >
          <Stack justifyContent={"center"} alignItems={"center"} spacing={3}>
            {isLoading ? (
              <Stack spacing={1}>
                <Skeleton variant="circular" width={100} height={100} />
              </Stack>
            ) : (
              <Box
                component={"img"}
                src={
                  companyLogo
                    ? `data:image/png;base64,${companyLogo}`
                    : SiteLogo // Fallback to SiteLogo if API fails
                }
                mb={{ xs: 5, sm: 5 }}
                sx={{
                  height: companyLogo ? "150px" : "auto",
                  width: companyLogo ? "150px" : "50%",
                  filter: lightMode ? "invert(0)" : "invert(1)",

                  borderRadius: companyLogo ? "50%" : "none", // Makes the logo circular
                }}
              />
            )}
          </Stack>
          <Stack width={"100%"}>
            <Box
              sx={{
                width: "100%",
                textAlign: "center",
                px: "20px",
              }}
            >
              {isLoading ? (
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  sx={{ height: "50vh" }}
                >
                  <Box sx={{ width: 600 }}>
                    <Skeleton />
                    <Skeleton animation="wave" />
                    <Skeleton animation={false} />
                  </Box>
                </Grid>
              ) : companyName ? (
                <Typography
                  variant="h4"
                  component="h2"
                  sx={{
                    fontWeight: 700,
                    filter: lightMode ? "invert(0)" : "invert(1)",
                    letterSpacing: { xs: 1, sm: 1, md: 2 },
                    width: "100%",
                    textAlign: "center",
                    fontSize: {
                      xs: "1.5rem",
                      sm: "1.5rem",
                      md: "2.5rem",
                      lg: "2.5rem",
                    },
                  }}
                >
                  {companyName}
                </Typography>
              ) : (
                lines.map((line, index) => (
                  <Typography
                    key={index}
                    variant="body1"
                    sx={{
                      filter: lightMode ? "invert(0)" : "invert(1)",
                      fontSize: { xs: "14px", md: "16px" },
                      fontWeight: 600,
                      lineHeight: "24px",
                    }}
                  >
                    {line}
                  </Typography>
                ))
              )}
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                size="large"
                sx={{
                  maxWidth: "10rem",
                  margin: "20px",
                }}
                onClick={() => {
                  navigate("/login");
                }}
              >
                <LoginIcon sx={{ margin: "10px" }} />
                Login
              </Button>
            </Box>
          </Stack>
          {/* support button */}
          <Stack
            justifyContent="center"
            alignItems="center"
            spacing={3}
            direction="row"
          >
            <SupportButton /> {/* Use the SupportButton component here */}
          </Stack>
        </Container>
      </Box>
    </>
  );
};
export default Welcome;
