import React, { useEffect, useState, useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";

import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import configServ from "../../../services/config";
import { useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { useReactToPrint } from "react-to-print";
import wrongimage from "../../../Components/Assets/images/wrongimg.jpg";
import {
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  Table,
  TableHead,
  Grid,
  TablePagination,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  TableBody,
  Tooltip,
  Collapse,
  Menu,
  Card,
  CardContent,
  DialogTitle,
  Stack,
  Box,
  CircularProgress,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import Swal from "sweetalert2";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import LoaderNew from "../../Loader/LoaderNew";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SalesSummaryDialog = ({
  open,
  handleClose,
  userId,
  isAdmin,
  orderDetails,
  setOrderDetails,
  orderItem,
  setOrderItem,
  expandedRow,
  setExpandedRow,
  name,
  companyLogo,
}) => {
  
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  // const [orderDetails, setOrderDetails] = useState(null);
  const { company_id, companyName } = useSelector(
    (state) => state.GlobalVariables
  );
  const [searchTerm, setSearchTerm] = useState(""); // Search term for filtering
  const [searchType, setSearchType] = useState("partyname"); // Dropdown for search type
  const [page, setPage] = useState(0); // Pagination page
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [orderItemLoading, setOrderItemLoading] = useState(false);
  // const [expandedRow, setExpandedRow] = useState(null); // State to track expanded row
  // const [orderItem, setOrderItem] = useState([]);
  const componentRef = useRef(); // Create a ref for printing
  const tableRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current, // Specify the content to print
  });
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false); // Controls PDF generation state

  const downloadPDF = () => {
    setIsGeneratingPDF(true); // Start PDF generation, hide pagination

    // Temporarily force the table to be visible
    const tableContainer = tableRef.current;

    setTimeout(() => {
      const input = tableContainer;
      if (!input) {
        console.error("Table element not found");
        return;
      }

      html2canvas(input, { scale: 4, useCORS: true, dpi: 500 }).then(
        (canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF("p", "mm", "a4");
          const imgWidth = 190; // Width of the PDF page
          const pageHeight = pdf.internal.pageSize.height;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          let heightLeft = imgHeight;
          let position = 15; // Adjusted position to leave space for the logo and name

          // Add Company Logo and Name to the PDF
          if (companyLogo) {
            pdf.addImage(companyLogo, "PNG", 5, 5, 10, 10); // Adjust position/size
          } else {
            console.log("Company logo is not available");
          }

          pdf.setFontSize(12);
          pdf.text(companyName, 15, 12); // Adjust x, y position for the company name

          // Add title to PDF
          pdf.setFontSize(16);
          pdf.text("Sales Summary Report", 80, 10); // Adjust x, y position as needed

          // Add the table data after the logo and name
          pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;

          while (heightLeft > 0) {
            position = heightLeft - imgHeight;
            pdf.addPage();
            pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
          }

          // Get current date in the format 'DD-MM-YYYY'
          const today = new Date();
          const day = String(today.getDate()).padStart(2, "0");
          const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is 0-based
          const year = today.getFullYear();

          const formattedDate = `${day}-${month}-${year}`;

          // Save the PDF with the current date in the file name
          pdf.save(`sales_summary_${formattedDate}.pdf`);

          setTimeout(() => {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Your PDF file has been downloaded",
              showConfirmButton: false,
              timer: 1500,
            });
          }, 1000); // Delay of 1 second

          setIsGeneratingPDF(false); // Show pagination again after PDF is generated
        }
      );
    }, 500); // Wait 500ms for state to update and pagination to hide
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const headers = [
    "CUSTOMER",
    "ORDER NO.",
    "DATE.",
    "STATUS",
    "AMOUNT",
    ...(!isGeneratingPDF ? ["ACTION"] : []),
  ];
  const itemHeaders = [
    // "ITEM ID",
    "NAME",
    // "CODE",
    // "HSN",
    "QUANTITY",
    "RATE",
    "TAX RATE",
    "DISCOUNT",
    "TAX AMOUNT",
    "AMOUNT",
  ];
  const handleExpandClick = (rowId) => {
    if (expandedRow !== rowId) {
      setExpandedRow(rowId);
    } else {
      setExpandedRow(null); // Collapse row if clicked again
    }
  };

  const headerCellStyle = {
    fontSize: 14, // Adjust the font size as needed
    fontWeight: "bold",
    backgroundColor: "#4CB5F5",
    color: "white",
  };
  const headerCellStyle1 = {
    fontSize: 12, // Adjust the font size as needed
    fontWeight: "bold",
    backgroundColor: "#666600",
    color: "white",
  };

  const evenRowStyle = {
    background: "#f5f5f5",
  };

  const oddRowStyle = {
    background: "white",
  };

  const rowStyle = {
    "&:hover": {
      background: "#f1f9fe",
    },
  };
  // Filtered order details based on search term and type

  const handleDialogClose = () => {
    // Clear the dialog's state before closing
    setOrderDetails(null);
    setSearchTerm(""); // Clear the search input
    setSearchType("partyname"); // Reset to default search type
    setPage(0); // Reset pagination
    setRowsPerPage(50); // Reset rows per page to default

    handleClose(); // Call parent to close dialog
  };

  // Handle change in pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const fetchOrdersSummaryitem = async (rowId) => {
      if (!rowId) return; // Don't fetch if no row is expanded
      try {
        setOrderItemLoading(true);
        const dataToSend = {
          company_id: company_id,
          order_id: rowId, // Use rowId (unique ID) from the table
        };
        const res = await configServ.getOrdersSummaryItems(dataToSend);
        if (res.status === 200) {
          const data = res.data.map((item) => ({
            id: item.id || "No data found",
            itemid: item.itemid || "No data found",
            itemname: item.itemname || "No data found",
            hsn_code: item.hsn_code || "No data found",
            qty: item.qty || "No data found",
            rate: item.rate || "No data found",
            Taxrate: item.Taxrate || "No data found",
            item_code: item.item_code || "No data found",

            tax_amount: item.tax_amount || "No data found",
            discount: item.discount || "No data found",
            amount: item.amount || "No data found",
          }));
          setOrderItem(data);
        }
        //setData(res); // Store the fetched data
      } catch (error) {
        console.log(error);
      } finally {
        setOrderItemLoading(false); // Reset loading state
      }
    };

    fetchOrdersSummaryitem(expandedRow); // Fetch data when a row is expanded
  }, [expandedRow, company_id]);

  useEffect(() => {
    const fetchOrdersSummaryDetails = async () => {
      try {
        
        setLoading(true);
        const dataToSend = {
          company_id: company_id, // Using userId here
          created_by: userId,
          is_admin: isAdmin, // Using isAdmin here
        };
        const res = await configServ.getOrdersSummaryDetails(dataToSend);
        if (res.status === 200) {
          const data = res.data.map((item) => ({
            id: item.id || "No data found",
            partyname: item.partyname || "No data found",
            order_no: item.order_no || "No data found",
            order_date: item.order_date || "No data found",
            order_type: item.order_type || "No data found",
            order_status: item.order_status || "No data found",
            total_amount: item.total_amount || "No data found",
          }));
          setOrderDetails(data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false); // Reset loading state
      }
    };

    if (userId && company_id !== null) {
      fetchOrdersSummaryDetails(); // Fetch only if userId and isAdmin are available
    }
  }, [userId, isAdmin, company_id]);
  // Filtered order details based on search term and type
  const filteredOrderDetails =
    orderDetails && Array.isArray(orderDetails)
      ? orderDetails.filter((order) =>
        order[searchType]?.toLowerCase().includes(searchTerm.toLowerCase())
      )
      : [];

  return (
    <>
      <style>
        {`
    @media print {
      .hide-on-print {
        display: none !important; /* Ensure pagination is hidden during print */
      }
    }
  `}
      </style>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            width: "80vw", // Set a fixed width (80% of the viewport width)
            maxWidth: "80vw", // Ensure the width doesn't exceed this value
            height: "80vh", // Set a fixed height (80% of the viewport height)
            maxHeight: "80vh", // Ensure the height doesn't exceed this value
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box flex={1} textAlign="center">
              <Typography variant="h5" color="#1e61b4" fontWeight={700}>
                Order Summary by {name}
              </Typography>
            </Box>
            <Box position="absolute" right={16}>
              <IconButton onClick={handleClose}>
                <DisabledByDefaultIcon color="error" />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>

        <Grid container sx={{ px: 2 }}>
          {/* Search Filter */}
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{ mx: 2 }}
          >
            {/* Left Side: Download/Print Buttons */}
            <Grid item xs={12} sm={6} md={3}>
              <Grid container spacing={1}>
                {/* Button for Print - Visible on mobile only */}
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{ display: { xs: "block", lg: "none" } }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handlePrint} // Call handlePrint on click
                    startIcon={<LocalPrintshopIcon sx={{ ml: 2 }} />}
                    fullWidth
                  >
                    Print
                  </Button>
                </Grid>

                {/* Button for Download - Visible on desktop only */}
                <Grid
                  item
                  md={3}
                  xs={12}
                  sm={6}
                  sx={{ display: { xs: "none", lg: "block" } }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleMenuClick}
                    startIcon={<DownloadIcon sx={{ ml: 2 }} />}
                    fullWidth
                    sx={{
                      padding: "6px 16px", // Adjust padding to make it smaller
                      fontSize: "0.8rem", // Adjust font size
                      minWidth: "auto", // Removes the default min width setting
                    }}
                  ></Button>
                  <Menu
                    anchorEl={anchorEl}
                    open={isMenuOpen}
                    onClose={handleMenuClose}
                  >
                    <MenuItem
                      onClick={() => {
                        downloadPDF();
                        handleMenuClose();
                      }}
                    >
                      PDF Download
                    </MenuItem>
                  </Menu>
                </Grid>
              </Grid>
            </Grid>

            {/* Right Side: Search Options */}
            <Grid
              item
              xs={12}
              sm={6}
              md={9}
              container
              justifyContent="flex-end" // Aligns search fields to the right
            >
              <Grid container spacing={2} justifyContent="flex-end">
                {/* Search By Dropdown */}
                <Grid item xs={12} sm={4} md={3}>
                  <FormControl fullWidth>
                    <InputLabel>Search By</InputLabel>
                    <Select
                      fullWidth
                      size="small"
                      value={searchType}
                      label="Search By"
                      onChange={(e) => setSearchType(e.target.value)}
                    >
                      <MenuItem value="partyname">Customer</MenuItem>
                      <MenuItem value="order_no">Order No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {/* Search Text Field */}
                <Grid item xs={12} sm={8} md={3}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Search"
                    variant="outlined"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Table */}
          <Grid item xs={12}>
            <TableContainer
              component={Paper}
              sx={{ pb: 1, display: { xs: "none", sm: "none", lg: "block" } }}
              ref={tableRef}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell sx={headerCellStyle}>S.No.</TableCell>
                    {headers.map((header, index) => (
                      <TableCell key={index} sx={headerCellStyle}>
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!loading && filteredOrderDetails.length > 0 ? (
                    filteredOrderDetails
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((order, index) => (
                        <React.Fragment key={order.id}>
                          <TableRow
                            sx={{
                              ...rowStyle,
                              ...(index % 2 === 0 ? evenRowStyle : oddRowStyle),
                            }}
                          >
                            <TableCell>
                              {page * rowsPerPage + index + 1}
                            </TableCell>
                            <TableCell>{order.partyname}{order.order_type === "online"? `(${order.order_type})`: ''}</TableCell>
                            <TableCell>{order.order_no}</TableCell>
                            <TableCell>{order.order_date}</TableCell>
                            <TableCell>{order.order_status}</TableCell>
                            <TableCell>{order.total_amount}</TableCell>
                            <TableRow key={order.id} hover>
                              {!isGeneratingPDF && (
                                <TableCell>
                                  <Tooltip
                                    title={
                                      expandedRow === order.id
                                        ? "Close"
                                        : "More"
                                    }
                                    placement="top"
                                  >
                                    <IconButton
                                      onClick={() =>
                                        handleExpandClick(order.id)
                                      }
                                    >
                                      {expandedRow === order.id ? (
                                        <CloseIcon sx={{ color: "red" }} />
                                      ) : (
                                        <AddIcon sx={{ color: "green" }} />
                                      )}
                                    </IconButton>
                                  </Tooltip>
                                </TableCell>
                              )}
                            </TableRow>
                          </TableRow>
                          {/* Collapse for sub-table */}
                          <TableRow>
                            <TableCell
                              style={{
                                paddingBottom: 0,
                                paddingTop: 0,
                              }}
                              colSpan={headers.length + 1}
                            >
                              <Collapse
                                in={expandedRow === order.id}
                                timeout="auto"
                                unmountOnExit
                              >
                                <div
                                  style={{ width: "100%", paddingLeft: "0" }}
                                >
                                  {orderItemLoading ? ( // sub-table ke liye loading check
                                    <Grid container item xs={12} m={2} alignItems={'center'} justifyContent={'center'}>
                                      <LoaderNew />
                                    </Grid>
                                  ) : (
                                    <Table
                                      size="small"
                                      style={{ width: "100%" }}
                                    >
                                      <TableHead>
                                        <TableRow>
                                          <TableCell sx={headerCellStyle1}>
                                            S.No.
                                          </TableCell>
                                          {itemHeaders.map((header, index) => (
                                            <TableCell
                                              key={index}
                                              sx={headerCellStyle1}
                                            >
                                              {header}
                                            </TableCell>
                                          ))}
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {orderItem && orderItem.length > 0 ? (
                                          orderItem.map((item, index) => (
                                            <TableRow key={item.id}>
                                              <TableCell>{index + 1}</TableCell>
                                              {/* <TableCell>
                                                {item.itemid}
                                              </TableCell> */}
                                              <TableCell>
                                                {item.itemname}
                                              </TableCell>
                                              {/* <TableCell>
                                                {item.item_code}
                                              </TableCell>
                                              <TableCell>
                                                {item.hsn_code}
                                              </TableCell> */}
                                              <TableCell>{item.qty}</TableCell>
                                              <TableCell>{item.rate}</TableCell>
                                              <TableCell>
                                                {item.Taxrate}
                                              </TableCell>
                                              <TableCell>
                                                {item.discount}
                                              </TableCell>
                                              <TableCell>
                                                {item.tax_amount}
                                              </TableCell>
                                              <TableCell>
                                                {item.amount}
                                              </TableCell>
                                            </TableRow>
                                          ))
                                        ) : (
                                          <TableRow>
                                            <TableCell
                                              colSpan={itemHeaders.length}
                                            >
                                              No data available
                                            </TableCell>
                                          </TableRow>
                                        )}
                                      </TableBody>
                                    </Table>
                                  )}
                                </div>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={headers.length + 1} align="center">
                        {loading ? <LoaderNew /> : "No orders found"}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>

              {/* Pagination */}
              {!isGeneratingPDF && (
                <TablePagination
                  component="div"
                  count={filteredOrderDetails.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  rowsPerPageOptions={[50, 100]} // Start from 50, allow 100
                  labelRowsPerPage="Rows per page"
                />
              )}
            </TableContainer>
          </Grid>

          {/* mobile view */}
          <Grid
            item
            xs={12}
            sx={{ display: { xs: "block", lg: "none" } }}
            ref={componentRef}
          >
            {/* Use Grid to display cards in mobile view */}
            {loading ? (
              <div style={{ textAlign: "center" }}>Loading...</div>
            ) : filteredOrderDetails.length === 0 ? (
              <div style={{ textAlign: "center" }}>No orders found</div>
            ) : (
              <Grid container spacing={2}>
                {filteredOrderDetails
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((order, index) => (
                    <Grid item xs={12} key={order.id}>
                      <Card>
                        <CardContent>
                          <Typography variant="h6" gutterBottom>
                            {index + 1 + page * rowsPerPage}. {order.partyname}
                          </Typography>

                          <Typography variant="body1">
                            <strong>Order No:</strong> {order.order_no}
                          </Typography>
                          <Typography variant="body1">
                            <strong>Order Date:</strong> {order.order_date}
                          </Typography>
                          <Typography variant="body1">
                            <strong>Order Status:</strong> {order.order_status}
                          </Typography>
                          <Typography variant="body1">
                            <strong>Total Amount:</strong> {order.total_amount}
                          </Typography>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Tooltip
                              title={
                                expandedRow === order.id ? "Close" : "More"
                              }
                              placement="top"
                            >
                              <IconButton
                                onClick={() => handleExpandClick(order.id)}
                              >
                                {expandedRow === order.id ? (
                                  <CloseIcon sx={{ color: "red" }} />
                                ) : (
                                  <AddIcon sx={{ color: "green" }} />
                                )}
                              </IconButton>
                            </Tooltip>
                          </div>

                          {/* Collapse for sub-table */}
                          <Collapse
                            in={expandedRow === order.id}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Divider sx={{ my: 2 }} />
                            {orderItemLoading ? (
                              <Grid container item xs={12} m={2} alignItems={'center'} justifyContent={'center'}>
                                <LoaderNew />
                              </Grid>
                            ) : orderItem && orderItem.length > 0 ? (
                              orderItem.map((item, index) => (
                                <Card key={item.id} sx={{ mb: 2 }}>
                                  <CardContent>
                                    <Typography variant="body2">
                                      <strong>S.No.:</strong> {index + 1}
                                    </Typography>
                                    {/* <Typography variant="body2">
                                      <strong>Item ID:</strong> {item.itemid}
                                    </Typography> */}
                                    <Typography variant="body2">
                                      <strong>Item Name:</strong>{" "}
                                      {item.itemname}
                                    </Typography>
                                    {/* <Typography variant="body2">
                                      <strong>Item Code:</strong>{" "}
                                      {item.item_code}
                                    </Typography>
                                    <Typography variant="body2">
                                      <strong>HSN Code:</strong> {item.hsn_code}
                                    </Typography> */}
                                    <Typography variant="body2">
                                      <strong>Quantity:</strong> {item.qty}
                                    </Typography>
                                    <Typography variant="body2">
                                      <strong>Rate:</strong> {item.rate}
                                    </Typography>
                                    <Typography variant="body2">
                                      <strong>Tax Rate:</strong> {item.Taxrate}
                                    </Typography>
                                    <Typography variant="body2">
                                      <strong>Discount:</strong> {item.discount}
                                    </Typography>
                                    <Typography variant="body2">
                                      <strong>Tax Amount:</strong>{" "}
                                      {item.tax_amount}
                                    </Typography>
                                    <Typography variant="body2">
                                      <strong>Amount:</strong> {item.amount}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              ))
                            ) : (
                              <Typography>No sub-items available</Typography>
                            )}
                          </Collapse>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
              </Grid>
            )}

            {/* Pagination */}
            <TablePagination
              className="hide-on-print" // Add this class
              component="div"
              count={filteredOrderDetails.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[50, 100]} // Start from 50, allow 100
              labelRowsPerPage="Rows per page"
            />
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default SalesSummaryDialog;
