import React from "react";
import AddBoxIcon from "@mui/icons-material/AddBox";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Link,
  IconButton,
  Grid,
  Tooltip,
  Autocomplete,
  Box,
  InputAdornment,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { motion } from "framer-motion";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import FetchItemSizeById from "../../../utility/FetchItemSizeById";
import CheckAndReturn from "../../../utility/CheckAndReturn";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

//FUNCTION
const AddInvoiceItems = ({
  itemList,
  handleAutocompleteChange,
  handleInputChange,
  handleAddItem,
  handleDeleteItem,
  items,
  handleQuantityChange,
  handleDiscountChange,
  handleRemarksChange,
  errors,
}) => {
  const navigate = useNavigate();
  const { company_type } = useSelector((state) => state.admin);

  return (
    <>
      <Box>
        <motion.div
          style={{
            width: "100%",
            backgroundColor: "#eeeef0",
            paddingTop: 20,
            paddingBottom: 20,
            borderRadius: "10px",
            border: "none",
            margin: "10px 0px 10px 10px",
          }}
          initial={{ opacity: 0, y: 15 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.1, delay: 0.1 }}
        >
          <Grid container spacing={1}>
            <Link to="/master-entry/item">
              <Tooltip title={"Add item"}>
                <AddShoppingCartIcon
                  sx={{
                    cursor: "pointer",
                    color: "white",
                    backgroundColor: "grey",
                    margin: "5px 5px 5px 10px",
                  }}
                  justifyContent="flex-end"
                  onClick={() => navigate("/master-entry/item")}
                />
              </Tooltip>
            </Link>
          </Grid>
          {itemList.map((item, index) => (
            <Box key={item.id} mx={1}>
              <Grid
                container
                //spacing={{ xs: 1, sm: 1, md: 1, lg: 1 }}
                alignItems={"center"}
                //columns={{ xs: 4, sm: 8, md: 12 }}
                key={item.id}
                sx={{ paddingBottom: "10px" }}
                spacing={1}
              >
                <Grid item xs={12} sm={6} md={3}
                  columnSpacing={0}
                  spacing={0}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={
                      item.itemname ? `${item.itemname}(${item.itemcode})` : ""
                    }
                    options={items.map(
                      (option) => `${option.item_name}(${option.item_code})`
                    )}
                    onChange={(e, newValue) =>
                      handleAutocompleteChange(e, newValue, item.id)
                    } // Pass newValue instead of e.target.innerText
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ backgroundColor: "white" }}
                        size="small"
                        label="Item Name"
                        variant="outlined"
                        error={errors[`${index}`]?.itemFullname ? true : false}
                      />
                    )}
                  />
                </Grid>
                {/*----------for size-----------*/}
                {item.issizeAdded && item.issizeAdded !== null ? (
                  <Grid item xs={12} sm={3} md={1.5}>
                    <FetchItemSizeById
                      itemData={item}
                      itemList={itemList}
                      handleChange={handleQuantityChange}
                      index={index}
                      errors={errors}
                    />
                  </Grid>
                ) : (
                  ""
                )}
                <Grid item xs={12} sm={6} md={1}>
                  <TextField
                    sx={{ backgroundColor: "white" }}
                    size="small"
                    label={item.unit? item.unit : "QTY"}
                    variant="outlined"
                    type="number"
                    fullWidth
                    name="qty"
                    value={item.qty}
                    onChange={(e) => handleQuantityChange(e, item.id)}
                    error={
                      errors[`${index}`] !== undefined
                        ? !!errors[`${index}`].qty
                        : false
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={1.4}>
                  <TextField
                    sx={{ backgroundColor: "white" }}
                    size="small"
                    label="Rate(₹)"
                    variant="outlined"
                    type="number"
                    fullWidth
                    name="rate"
                    value={item.rate}
                    onChange={(e) => handleQuantityChange(e, item.id)}
                    defaultValue={0}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={1}>
                  <TextField
                    sx={{ backgroundColor: "white" }}
                    size="small"
                    label="Disc(%)"
                    variant="outlined"
                    type="number"
                    fullWidth
                    name="discount"
                    value={item.discount}
                    onChange={(e) => handleDiscountChange(e, item.id)}
                    defaultValue={0}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={1.2}>
                  <TextField
                    sx={{ backgroundColor: "white" }}
                    size="small"
                    label="Tax Amt(₹)"
                    variant="outlined"
                    type="number"
                    fullWidth
                    name="tax_amount"
                    value={CheckAndReturn.roundToInteger(item.tax_amount)}
                    InputLabelProps={{ shrink: item.tax_amount ? true : false }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <TextField
                    sx={{ backgroundColor: "white" }}
                    size="small"
                    label={
                      <span>
                        Gross=₹
                        {CheckAndReturn.roundToInteger(item.amount ?? 0) - CheckAndReturn.roundToInteger(item.tax_amount ?? 0)}
                        {/* {item.amountWithoutTax ? CheckAndReturn.roundToInteger(item.amountWithoutTax) : "0"} */}
                      </span>
                    }
                    variant="outlined"
                    type="number"
                    fullWidth
                    name="amount"
                    value={CheckAndReturn.roundToInteger(item.amount ?? 0)}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start">Net=₹</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={1.2}>
                  <TextField
                    label="Remarks"
                    name="remarks"
                    value={item.remarks || ""}
                    onChange={(e) => handleRemarksChange(e, item.id)}
                    variant="outlined"
                    fullWidth
                    size="small"
                    sx={{ backgroundColor: "white" }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={0.5}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Tooltip title={"Add item"}>
                    <IconButton onClick={() => handleAddItem(item.id)}>
                      <AddBoxIcon fontSize="medium" sx={{ color: "green" }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={"Delete item"}>
                    <IconButton
                      hidden={itemList.length <= 1}
                      onClick={() => handleDeleteItem(item)}
                    >
                      <DeleteIcon fontSize="medium" sx={{ color: "#d43529" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>

                {/* 
                  <Grid item xs={12} sm={3} md={1} >
                    <TextField
                        sx={{ backgroundColor: 'white' }}
                        size="small"
                        label="Pkg Unit"
                        variant="outlined"
                        type="text"
                        fullWidth
                        name="packagingunit"
                        value={item.packagingunit}
                        InputLabelProps={{ shrink: item.packagingunit ? true : false }}
                        InputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={1} >
                    <TextField
                        sx={{ backgroundColor: 'white' }}
                        size="small"
                        label={company_type === "product" ? "HSN" : "SAC"}
                        variant="outlined"
                        type="text"
                        fullWidth
                        name="hsn_code"
                        value={item.hsn_code}
                        InputLabelProps={{ shrink: item.hsn_code ? true : false }}
                        InputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={2}>
                      <TextField
                          sx={{ backgroundColor: 'white' }}
                          size="small"
                          label="Net Amt"
                          variant="outlined"
                          type="number"
                          fullWidth
                          name="amountWithoutTax"
                          value={item.amountWithoutTax}
                          InputLabelProps={{ shrink: item.amountWithoutTax ? true : false }}
                          InputProps={{ readOnly: true }} />
                  </Grid> 
                */}

              </Grid>
            </Box>
          ))}
        </motion.div>
      </Box>
    </>
  );
};

export default AddInvoiceItems;
