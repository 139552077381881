import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';

import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    // DialogContentText,
    DialogActions,
    IconButton,
    Typography,
    Stack,
    TextField
} from '@mui/material'
import configServ from '../../../services/config';
import Checkbox from '@mui/joy/Checkbox';
import AlertDialog from "../../AlertDialog/AlertDialog";
import { useSelector } from 'react-redux';

function AddItemSize({ itemData, handleClose, open, editData, isEdited, setIsEdited }) {
    //#region code
    const { user_id, company_id } = useSelector((state) => state.GlobalVariables);
    const [sizeData, setSizeData] = useState({})
    const [isEdit, setIsEdit] = useState(false)
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState('');

    const handleOpenDialog = (content) => {
        setDialogContent(content);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setDialogContent('');
    };
    const changeHandler = (e) => {
        const { name, value } = e.target
        setSizeData((state) => ({
            ...state,
            [name]: value
        }))
    }

    const checkHandler = (e) => {
        const { name, checked } = e.target
        setSizeData((state) => ({
            ...state,
            [name]: checked
        }))
    }


    const handleSubmit = async () => {
        const dataToSend = {
            ...sizeData,
            "company_id": company_id,
            "item_id": itemData.id
        }
        try {
            if (!isEdit) {
                const result = await configServ.addItemSize(dataToSend);
                if (result.success) {
                    handleOpenDialog('Size successfully added');
                    setIsEdited(!isEdited);
                }
            } 
            else {
                const result = await configServ.updateItemSize(dataToSend);
                if (result.success) {
                    handleOpenDialog('Size successfully updated');
                    setIsEdited(!isEdited);
                }
            }
        } 
        catch (err) {
            console.log(err);
        }
    }

    const assignForEdit = async () => {
        if (editData !== undefined) {
            setSizeData(editData);
            setIsEdit(true);
        }
    }

    useEffect(() => {
        assignForEdit();
    }, [editData]);
    //#endregion

    return (
        <>
            <AlertDialog
                open={dialogOpen}
                setOpen={handleCloseDialog}
                title="Alert"
                message={dialogContent}
                buttonTitle="Ok"
            />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='md'
                fullWidth={true}
            >
                <DialogTitle id="alert-dialog-title">
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='h5'color={'#1e61c5'}>{isEdit ? 'Update' : 'Create'} Item Size</Typography>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>

                    <Stack spacing={2}>
                        <Typography variant='body' color='text.secondary'>Choose Size for {itemData.item_name}:</Typography>
                        <Stack direction='row' spacing={4} alignItems='center'>
                            <TextField
                                name='size'
                                variant="outlined"
                                label='Size'
                                size='small'
                                onChange={changeHandler}
                                value={sizeData.size}
                            />
                            <TextField
                                name='rate'
                                variant="outlined"
                                label='Rate'
                                size='small'
                                onChange={changeHandler}
                                type='number'
                                value={sizeData.rate}
                            />
                            <TextField
                                name='qty'
                                variant="outlined"
                                label='Quantity'
                                size='small'
                                onChange={changeHandler}
                                type='number'
                                value={sizeData.qty}
                            />
                            <Checkbox
                                label="Status"
                                name='status'
                                onChange={checkHandler}
                                checked={Boolean(sizeData.status)}
                            />
                        </Stack>
                    </Stack>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { handleClose(); handleSubmit() }} autoFocus variant='contained'>
                        {isEdit ? 'Update' : 'Submit'}
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    )
}

export default AddItemSize