import React, { memo } from "react";
import AddBoxIcon from "@mui/icons-material/AddBox";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  TextField,
  IconButton,
  Grid,
  Autocomplete,
  Tooltip,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import AddIcon from "@mui/icons-material/Add";

//FUNCTION
function AddPurchaseOrderItems({
  itemList,
  handleAutocompleteChange,
  handleInputChange,
  handleAddItem,
  handleDeleteItem,
  items,
  handleQuantityChange,
  errors,
}) {
  return (
    <>
      <div>
        <motion.div
          style={{
            width: "100%",
            backgroundColor: "#f2f0fc",
            padding: 10,
            borderRadius: "10px",
            border: "none",
            margin: "5px 0px",
            display: "flex",
            justifyContent: "center",
          }}
          initial={{ opacity: 0, y: 15 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.1, delay: 0.1 }}
        >
          <div>
            <Grid container>
              <Link to="/master-entry/item">
                <Tooltip title={"Add item"}>
                  <AddIcon
                    sx={{
                      cursor: "pointer",
                      color: "white",
                      backgroundColor: "grey",
                      margin: "5px",
                    }}
                    justifyContent="flex-end"
                  />
                </Tooltip>
              </Link>
            </Grid>
            {itemList.map((item, index) => (
              <div key={item.id}>
                <Grid
                  container
                  alignItems={"center"}
                  spacing={1}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                  key={item.id}
                  sx={{ mb: 1 }}
                >
                  <Grid item xs={12} sm={3} md={3}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      value={
                        item.itemid
                          ? items.find(
                              (it) =>
                                it.id.toString() === item.itemid.toString()
                            )?.item_name
                          : ""
                      }
                      options={items.map((option) => option.item_name)}
                      onChange={(e, newValue) =>
                        handleAutocompleteChange(
                          { target: { value: newValue } },
                          item.id
                        )
                      } // Passing the selected value directly
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{
                            backgroundColor: "white",
                          }}
                          size="small"
                          label="Item Name"
                          variant="outlined"
                          name="item_id"
                          value={
                            item.itemid
                              ? items.find(
                                  (it) =>
                                    it.id.toString() === item.itemid.toString()
                                )?.item_name
                              : ""
                          }
                          onChange={(e) => handleInputChange(e, item.id)}
                          InputLabelProps={{ ...params.InputProps }}
                          error={
                            errors[`${index}`] !== undefined
                              ? !!errors[`${index}`].item_id
                              : false
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={2}>
                    <TextField
                      sx={{
                        backgroundColor: "white",
                      }}
                      size="small"
                      label={item.unit? item.unit : "Qty"}
                      variant="outlined"
                      type="number"
                      fullWidth
                      name="qty"
                      value={item.qty}
                      onChange={(e) => handleQuantityChange(e, item.id)}
                      error={
                        errors[`${index}`] !== undefined
                          ? !!errors[`${index}`].qty
                          : false
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={2} md={2}>
                    <TextField
                      sx={{
                        backgroundColor: "white",
                      }}
                      size="small"
                      label="Rate(₹)"
                      variant="outlined"
                      type="number"
                      fullWidth
                      name="rate"
                      value={item.rate}
                      onChange={(e) => handleQuantityChange(e, item.id)}
                      InputLabelProps={{ shrink: true }}
                      error={
                        errors[`${index}`] !== undefined
                          ? !!errors[`${index}`].rate
                          : false
                      }
                      //defaultValue={0}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2} md={1}>
                    <TextField
                      sx={{
                        backgroundColor: "white",
                      }}
                      size="small"
                      label="Tax(%)"
                      variant="outlined"
                      type="number"
                      fullWidth
                      name="Taxrate"
                      value={item.Taxrate}
                      onChange={(e) => handleQuantityChange(e, item.id)}
                      InputLabelProps={{ shrink: true }}
                      error={
                        errors[`${index}`] !== undefined
                          ? !!errors[`${index}`].Taxrate
                          : false
                      }
                      //defaultValue={0}
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={3} md={1} >
                                        <TextField
                                            sx={{ backgroundColor: 'white' }}
                                            size="small"
                                            label="Tax Amt"
                                            variant="outlined"
                                            type="number"
                                            fullWidth
                                            name="tax_amount"
                                            value={item.tax_amount}
                                            InputLabelProps={{ shrink: item.tax_amount ? true : false }}
                                            InputProps={{ readOnly: true }}
                                        />
                                    </Grid> */}
                  <Grid item xs={12} sm={2} md={2}>
                    <TextField
                      sx={{
                        backgroundColor: "white",
                      }}
                      size="small"
                      label="Amount(₹)"
                      variant="outlined"
                      type="number"
                      fullWidth
                      name="amount"
                      value={item.amount}
                      InputLabelProps={{ shrink: item.amount ? true : false }}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2} md={2}>
                    <Tooltip title={"Add item"}>
                      <IconButton onClick={() => handleAddItem(item.id)}>
                        <AddBoxIcon fontSize="medium" sx={{ color: "green" }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={"Delete item"}>
                      <IconButton
                        hidden={itemList.length > 1 ? false : true}
                        onClick={() => handleDeleteItem(item.id)}
                      >
                        <DeleteIcon
                          fontSize="medium"
                          sx={{ color: "#d43529" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </div>
            ))}
          </div>
        </motion.div>
      </div>
    </>
  );
}

export default memo(AddPurchaseOrderItems);
