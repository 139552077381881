import React, { memo, useEffect, useRef, useState } from 'react';
import { Box, Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, Menu, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Tooltip, Typography } from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";
import DownloadIcon from "@mui/icons-material/Download";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import PrintTwoToneIcon from "@mui/icons-material/PrintTwoTone";
import SavingsIcon from "@mui/icons-material/Savings";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import configServ from '../../../services/config';
import CheckAndReturn from '../../../utility/CheckAndReturn';
import LoaderNew from '../../Loader/LoaderNew';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

//FUNCTION
const AllLedgers = () => {
    //#region code    
    const { company_id, companyName, company_type, is_super, companyAddress, companyState, companyCountry } = useSelector((state) => state.GlobalVariables);

    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [count, setCount] = useState(0);
    const [searchBy, setSearchBy] = useState("sales_Executive");
    const [searchQuery, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
    const [companyLogo, setCompanyLogo] = useState("");
    const [allLedgersData, setAllLedgersData] = useState([]);
    const rowsPerPageOptions = [20, 50, 100, 200];
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("id");
    const [isPrint, setIsPrint] = useState(false);
    const [isDownload, setIsDownload] = useState(false);
    const [monthDate, setMonthDate] = useState(null);

    const headers = [
        { id: "type", label: "TRANSACTION" },
        { id: "fname", label: "NAME" },
        { id: "entity_type", label: "TYPE" },
        { id: "entity_number", label: "REFERENCE NO." },
        { id: "is_credit", label: "CREDIT/DEBIT" },
        { id: "month", label: "MONTH" },
        { id: "amount", label: "AMOUNT" },
    ];

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

    const headerCellStyle = {
        fontSize: 14, // Adjust the font size as needed
        fontWeight: "bold",
        backgroundColor: "#4CB5F5",
        color: "white",
    };

    const cellStyle = {
        py: 0,
        my: 0,
    };

    const evenRowStyle = {
        background: "#f5f5f5",
    };

    const oddRowStyle = {
        background: "white",
    };

    const rowStyle = {
        "&:hover": {
            background: "#f1f9fe",
        },
    };

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const assignCompanyLogo = async () => {
        try {
            const result = await configServ.company_logo({ company_id: company_id });
            if (result && result.image) {
                // Assuming the result.image is already a base64 string
                setCompanyLogo(`data:image/png;base64,${result.image}`);
            } else {
                setCompanyLogo(null); // Set as null if no logo
            }
        } catch (err) {
            console.log("Error fetching company logo:", err);
            setCompanyLogo(null); // Handle error by clearing logo
        }
    };

    useEffect(() => {
        assignCompanyLogo();
    }, []);

    const isMenuOpen = Boolean(anchorEl);
    const tableRef = useRef(null);

    const handleSearchQueryChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const formatCurrency = (amount) => `₹${parseFloat(amount)}`;

    // Download as Excel
    const downloadExcel = () => {
        // Use allLedgersData to only download filtered data
        const filteredData = allLedgersData.map((order) => ({
            EXECUTIVE: order.name,
            // EMAIL: order.email,
            // MOBILE_NO: order.mobile,
            TOTAL_ORDERS: order.total_orders,
            TOTAL_AMOUNT: formatCurrency(order.total_amount),
        }));

        const worksheet = XLSX.utils.json_to_sheet([]);
        const workbook = XLSX.utils.book_new();

        // Add title, company name, and a placeholder for the logo
        XLSX.utils.sheet_add_aoa(
            worksheet,
            [
                ["Sales Order Summary "], // Title
                [`Company Name: ${companyName}`], // Company Name
            ],
            { origin: "A1" }
        );

        // Add filtered data to the sheet starting from row 4
        XLSX.utils.sheet_add_json(worksheet, filteredData, { origin: "A4" });

        // Set column width for better readability
        const wscols = [
            { wch: 30 }, // EXECUTIVE
            { wch: 20 }, // TOTAL_ORDERS
            { wch: 15 }, // TOTAL_AMOUNT
        ];
        worksheet["!cols"] = wscols;

        // Append worksheet and download the Excel file
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sales Data");
        XLSX.writeFile(workbook, "sales_summary.xlsx");

        // Success popup after Excel download
        setTimeout(() => {
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Your Excel file has been downloaded",
                showConfirmButton: false,
                timer: 1500,
            });
        }, 1000); // Delay for the popup
    };

    // Download as PDF
    const downloadPDF = () => {
        setOpenBackdrop(true); // Show Backdrop when PDF generation starts
        setIsGeneratingPDF(true); // Start PDF generation, hide pagination

        // Temporarily force the table to be visible
        const tableContainer = tableRef.current;
        tableContainer.style.display = "block"; // Show table on mobile

        setTimeout(() => {
            const input = tableContainer;
            if (!input) {
                console.error("Table element not found");
                return;
            }

            html2canvas(input, { scale: 4, useCORS: true, dpi: 500 }).then(
                (canvas) => {
                    const imgData = canvas.toDataURL("image/png");
                    const pdf = new jsPDF("p", "mm", "a4");
                    const imgWidth = 190; // Width of the PDF page
                    const pageHeight = pdf.internal.pageSize.height;
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;
                    let heightLeft = imgHeight;
                    let position = 15; // Adjusted position to leave space for the logo and name

                    // Add Company Logo and Name to the PDF
                    if (companyLogo) {
                        pdf.addImage(companyLogo, "PNG", 5, 5, 10, 10); // Adjust position/size
                    } else {
                        console.log("Company logo is not available");
                    }

                    pdf.setFontSize(12);
                    pdf.text(companyName, 15, 12); // Adjust x, y position for the company name

                    // Add title to PDF
                    pdf.setFontSize(16);
                    pdf.text("Sales Summary Report", 80, 10); // Adjust x, y position as needed

                    // Add the table data after the logo and name
                    pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
                    heightLeft -= pageHeight;

                    while (heightLeft > 0) {
                        position = heightLeft - imgHeight;
                        pdf.addPage();
                        pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
                        heightLeft -= pageHeight;
                    }

                    pdf.save("sales_data.pdf");

                    setTimeout(() => {
                        Swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: "Your PDF file has been downloaded",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    }, 1000); // Delay of 1 second

                    setIsGeneratingPDF(false); // Show pagination again after PDF is generated
                    setOpenBackdrop(false); // Hide Backdrop after PDF generation completes

                    // // Hide the table again after the PDF is generated
                    if (window.innerWidth < 960) {
                        tableContainer.style.display = "none"; // Hide on mobile and tablet
                    } else {
                        tableContainer.style.display = "block"; // Show on desktop
                    }
                }
            );
        }, 500); // Wait 500ms for state to update and pagination to hide
    };

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      };

    const fetchAllLedgers = async () => {
        try {
            setLoading(true);
            scrollToTop();
            const dataToSend = {
                company_id: company_id,
                page: page,
                per_page: rowsPerPage,
                sort_by: orderBy,
                order: order,
                monthDate: monthDate
            }
            const res = await configServ.getAllLedgers(dataToSend);
            if (res.status === 200) {
                setCount(res.total);
                setAllLedgersData(res.data);
            }
            else {
                setCount(0);
                setAllLedgersData([]);
            }
        }
        catch (e) {
            console.log(e);
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchAllLedgers();
    }, [page]);

    useEffect(() => {
        if (page === 0) {
            fetchAllLedgers();
        }
        else {
            setPage(0);
        }
    }, [rowsPerPage, orderBy, order, monthDate]);

    const handleMonth = (params) => {
      try{
        const month = params.$M +1;
        const year = params.$y;
        const date = `${year}-${month}`;
        setMonthDate(date);
      }
      catch(e){
        console.log(e);
      }
    }
    

    //#endregion

    return (
        <>
            <Grid container xs={12} spacing={2} justifyContent="space-between" alignItems="center">
                <Grid item xs={12} md={3} alignItems="center">
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        All Ledger Report: {count}
                    </Typography>
                </Grid>

                <Grid item xs={12} md={9} justifyContent="flex-end">
                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker label={'month-year'} views={['month', 'year']}  onChange={handleMonth}/>
                        </DemoContainer>
                    </LocalizationProvider> */}
                </Grid>
            </Grid>

            <TableContainer
                component={Paper}
                elevation={0}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "100vh", // Set full height
                }}
            //ref={invoiceReff}
            >
                {(isPrint || isDownload) && (
                    <>
                        <Typography
                            sx={{
                                fontSize: "18px",
                                fontWeight: 600,
                                textAlign: "center",
                            }}
                        >
                            {companyName}
                        </Typography>
                        <Typography sx={{ textAlign: "center" }}>
                            {companyAddress}, {companyState}, {companyCountry}
                        </Typography>
                    </>
                )}
                <Box sx={{ flexGrow: 1, overflow: "auto", mt: 2 }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={headerCellStyle}>S.No.</TableCell>
                                {headers.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        sortDirection={orderBy === column.id ? order : false}
                                        sx={headerCellStyle}
                                    >
                                        <TableSortLabel
                                            active={orderBy === column.id}
                                            direction={orderBy === column.id ? order : "asc"}
                                            onClick={() => handleRequestSort(column.id)}
                                        >
                                            {column.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {loading === false ? (
                                allLedgersData.length > 0 ? (
                                    allLedgersData.map((row, rowIndex) => (
                                        <TableRow
                                            key={rowIndex}
                                            sx={{
                                                ...rowStyle,
                                                ...(rowIndex % 2 === 0
                                                    ? evenRowStyle
                                                    : oddRowStyle),
                                            }}
                                        >
                                            <TableCell sx={cellStyle}>
                                                <Stack direction={"row"} spacing={1}>
                                                    {!isGeneratingPDF &&
                                                        isPrint === false &&
                                                        isDownload === false && (
                                                            <VisibilityIcon
                                                                //onClick={() => handleInvoiceItems(row)}
                                                                sx={{ cursor: "pointer" }}
                                                                color="primary"
                                                            />
                                                        )}
                                                    <Typography variant="body1">
                                                        {page * rowsPerPage + rowIndex + 1}
                                                    </Typography>
                                                </Stack>
                                            </TableCell>
                                            <TableCell sx={cellStyle}>
                                                <span
                                                    className="linkStyle"
                                                //onClick={() => handleCustomerClick(row.partyname)}
                                                >
                                                    {row.type}
                                                </span>
                                            </TableCell>
                                            <TableCell sx={cellStyle}>
                                                {row.fname}
                                            </TableCell>
                                            <TableCell sx={cellStyle}>
                                                {row.entity_type}
                                            </TableCell>
                                            <TableCell sx={cellStyle}>
                                                {row.entity_number}
                                            </TableCell>
                                            <TableCell sx={cellStyle}>
                                                {Boolean(row.is_credit) === true ? 'CREDIT' : 'DEBIT'}
                                            </TableCell>
                                            <TableCell sx={cellStyle}>
                                                {row.month}
                                            </TableCell>
                                            <TableCell sx={cellStyle}>
                                                ₹{CheckAndReturn.roundToInteger(row.amount)}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={12}>
                                            <Box textAlign={"center"}>
                                                <Typography variant="body1" color={"red"}>
                                                    No data found.
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                )
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={12} sx={{ textAlign: "center" }}>
                                        <div>
                                            <LoaderNew />
                                        </div>
                                    </TableCell>
                                </TableRow>
                            )}

                            {emptyRows > 0 && (
                                <TableRow style={{ height: 1 * emptyRows }}>
                                    <TableCell colSpan={headers.length + 2} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Box>
            </TableContainer>
            <TableContainer>
                <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{
                        mt: "auto", // Ensure the pagination stays at the bottom
                    }}
                />
            </TableContainer>
        </>
    )
}

export default memo(AllLedgers);
