import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IconButton, Tooltip, Modal, Box, Typography } from "@mui/material";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import CloseIcon from "@mui/icons-material/Close"; // Import the Close Icon
import "./VoiceCommand.css"; // Ensure CSS is responsive
import sound from "../../Components/Assets/Audio/beep.mp3";

const VoiceCommand = () => {
  const navigate = useNavigate();
  const [listening, setListening] = useState(false); // State to toggle listening
  const [speaking, setSpeaking] = useState(false); // State to simulate speaking
  const [open, setOpen] = useState(false); // For controlling the popup

  useEffect(() => {
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    const recognition = new SpeechRecognition();

    recognition.continuous = true;
    recognition.interimResults = false;
    recognition.lang = "en-US";

    recognition.onresult = (event) => {
      setSpeaking(true); // User is speaking
      const transcript = event.results[event.results.length - 1][0].transcript
        .trim()
        .toLowerCase();

      // Redirect and close popup after recognizing commands
      if (transcript.includes("invoice")) {
        setOpen(false); // Close pop-up on navigation
        navigate("/invoice");
        setListening(false); // Stop listening
      } else if (transcript.includes("sales order")) {
        setOpen(false);
        navigate("/sales-order");
        setListening(false);
      } else if (transcript.includes("account master")) {
        setOpen(false);
        navigate("/account-master-entry/account-master");
        setListening(false);
      } else if (transcript.includes("customer")) {
        setOpen(false);
        navigate("/account-master-entry/customers");
        setListening(false);
      } else {
        console.log("Command not recognized");
      }

      // Stop wave after speaking
      setTimeout(() => setSpeaking(false), 1000); // Reduced time for faster response
    };

    if (listening) {
      recognition.start();
    } else {
      recognition.stop();
    }

    return () => {
      recognition.stop(); // Cleanup when the component unmounts
    };
  }, [listening, navigate]);

  // Toggle mic on and off
  const toggleMic = () => {
    if (!listening) {
      // Play sound when mic turns on
      const audio = new Audio(sound); // Path to your audio file
      audio.play();
    }
    setListening((prevListening) => !prevListening);
  };

  // Function to handle opening and closing of the popup
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setListening(false); // Stop mic when modal is closed
    setOpen(false);
  };

  return (
    <div>
      {/* Button to Open Voice Command Modal */}
      <Tooltip title="Open Voice Command">
        <IconButton onClick={handleOpen}>
          <MicIcon style={{ color: "green" }} />
        </IconButton>
      </Tooltip>

      {/* Modal Popup */}
      <Modal
        open={open}
        onClose={handleClose} // Close modal and stop mic when clicking outside
        aria-labelledby="voice-command-modal"
        aria-describedby="voice-command-description"
      >
        <Box className="voice-command-modal">
          <Typography id="voice-command-modal" variant="h6" component="h2">
            Voice Command
          </Typography>

          {/* Close (Cross) Icon in Top-Right */}
          <IconButton
            onClick={handleClose} // Close modal and stop mic
            style={{
              position: "absolute",
              top: 8,
              right: 8,
            }}
          >
            <CloseIcon />
          </IconButton>

          {/* Mic Toggle Button */}
          <Tooltip
            title={listening ? "Click to stop listening" : "Click to start listening"}
          >
            <IconButton
              onClick={toggleMic}
              color={listening ? "primary" : "default"}
            >
              {listening ? (
                <MicIcon style={{ color: "blue" }} /> // Mic on (blue)
              ) : (
                <MicOffIcon style={{ color: "red" }} /> // Mic off (red with cross)
              )}
            </IconButton>
          </Tooltip>

          {/* Animated Wave */}
          {listening && (
            <div className="wave-container">
              <div className={`wave ${speaking ? "wave-active" : ""}`} />
              <div className={`wave ${speaking ? "wave-active" : ""}`} />
              <div className={`wave ${speaking ? "wave-active" : ""}`} />
              <div className={`wave ${speaking ? "wave-active" : ""}`} />
              <div className={`wave ${speaking ? "wave-active" : ""}`} />
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default VoiceCommand;
