import React, { useState, useEffect } from 'react'
import Header from '../../Components/header/Header/Header'
import Footer from '../../Components/Footer/Footer'
import Sidebar from '../../Components/sidebar/SideBar'
import {
    Box,
    Paper,
    Typography,
    Card,
    Stack,
    Divider,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Tooltip,
    IconButton,
    FormControlLabel,
    Checkbox,
    Autocomplete
} from '@mui/material'
import { useSelector } from 'react-redux'

import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddIcon from '@mui/icons-material/Add';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';

import StateList from '../../Components/List/StateList'
import ProfilePicture from '../../Components/Assets/images/profilePicture.jpg'
import configServ from '../../services/config'
import { SessionVerificationAdmin } from '../../Components/SessionManagement/SessionManagementAdmin'
import CityTable from './CityTable'
import AlertDialog from "../../Components/AlertDialog/AlertDialog";

function CityMaster() {

    const open = useSelector((state) => state.sideExpand.open)
    const [formData, setFormData] = useState({})
    const [catalogItem, setCatalogItem] = useState([{
        id: 1,
        item_id: '',
    }])
    const [applicationList, setApplicationList] = useState([])
    const [isChanged, setIsChanged] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [countryList, setCountryList] = useState([])
    const [stateList, setStateList] = useState([])
    const [cityList, setCityList] = useState([])
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState('');
  
    const handleOpenDialog = (content) => {
      setDialogContent(content);
      setDialogOpen(true);
    };
  
    const handleCloseDialog = () => {
      setDialogOpen(false);
      setDialogContent('');
    };
    const cityType = [
        { label: 'Rural', name: 'city_type' },
        { label: 'Urban', name: 'city_type' },
        { label: 'Semi-Urban', name: 'city_type' },
    ]


    const changeHandler = (e) => {
        const { name, value } = e.target
        setFormData((state) => ({
            ...state,
            [name]: value
        }))
    }

    const fetchCountry = async () => {
        const res = await configServ.getCountry();
        if (res.status === 200) {
            setCountryToList(res.data);
        }
    }
    const setCountryToList = (data) => {
        if (data.length === 0) {
            return;
        }
        data.map((item) => {
            setCountryList((state) => ([
                ...state,
                {
                    ...item,
                    label: item.country_name,
                    name: 'CountryCode',
                }
            ]))
        })
    }

    const fetchState = async () => {
        const res = await configServ.getState({ "country_id": formData.CountryCode?.CountryCode });
        if (res.success) {
            setStateToList(res.data)
            // console.log(res.data)
        }
    }
    const setStateToList = (data) => {
        if (data.length === 0) {
            return;
        }
        data.map((item) => {
            setStateList((state) => ([
                ...state,
                {
                    ...item,
                    label: item.State,
                    name: 'StateCode',
                }
            ]))
        })
    }
    useEffect(() => {
        if (formData.CountryCode) {
            fetchState()
        }
    }, [formData.CountryCode])

    // const fetchCity = async () => {
    //     //below line is commented because there is no state code assosiated with city table yet. Uncomment it when state code field gets completed.
    //     // const res = await configServ.getCity({"StateCode":formData.StateCode?.StateCode});
    //     const res = await configServ.getCity();
    //     if (res.success) {
    //         setCityToList(res.data)
    //         // console.log(res.data)
    //     }
    // }
    // const setCityToList = (data) => {
    //     if (data.length === 0) {
    //         return;
    //     }
    //     data.map((item) => {
    //         setCityList((state) => ([
    //             ...state,
    //             {
    //                 ...item,
    //                 label: item.City,
    //                 name: 'City',
    //             }
    //         ]))
    //     })
    // }
    // useEffect(() => {
    //     if (formData.StateCode) {
    //         fetchCity()
    //     }
    // }, [formData.StateCode])


    useEffect(() => {
        fetchCountry()
    }, [])


    const handleAutoOnchange = (e, value) => {
        console.log(value)
        if (value) {
            const { name, label } = value
            if (name === 'CountryCode' ||
                name === 'StateCode' ||
                name === 'City'
            ) {
                setFormData((state) => ({
                    ...state,
                    [name]: value
                }))
                return
            }
            setFormData((state) => ({
                ...state,
                [name]: label
            }))
        }
    }


    const handleSubmit = async (e) => {
        // if (!catalogItem[0].item_name) {
        //     alert('please select item')
        //     return
        // }
        const dataToSend = {
            ...formData,
            CountryCode: formData.CountryCode?.CountryCode,
            StateCode: formData.StateCode?.StateCode,
        }
        console.log(dataToSend)
        try {
            if (!isEdit) {
                const result = await configServ.addCity(dataToSend)
                // console.log(result)
                if (result.success) {
                    handleOpenDialog(result.message)
                    clear()
                    setIsChanged(!isChanged)
                }
            } else {
                // const result = await configServ.editCatalog(dataToSend)
                // // console.log(result)
                // if (result.success) {
                //     alert(result.message)
                //     clear()
                //     setIsChanged(!isChanged)
                // }
            }
        } catch (err) {
            console.log(err)
        }
    }

    const clear = () => {
        setFormData({})
        setCatalogItem([{
            id: 1,
            item_id: '',
        }])
        setIsEdit(false)
    }

    const handleEditData = (data) => {
        // console.log(data[0])
        setIsEdit(true)
        setFormData(data[0])
        setCatalogItem(data[0].catalog_items)
    }

    return (
        <>
               <AlertDialog
        open={dialogOpen}
        setOpen={handleCloseDialog}
        title="Alert"
        message={dialogContent}
        buttonTitle="Ok"
      />
            <SessionVerificationAdmin />
            <Header />
            <Sidebar />
            <Box sx={{
                marginLeft: {
                    xs: 7,
                    sm: 8,
                },
                ...(open && { marginLeft: 30 }),
                transition: '200ms',
                overflowX: 'hidden',
                minHeight: '77vh'
            }}>

                <Stack
                    direction='column'
                    justifyContent='space-around'
                    alignItems='center'
                    sx={{
                        padding: 2,
                    }}
                    spacing={5}
                >
                    <Paper
                        elevation={0}
                        sx={{
                            width: {
                                md: '90%',
                                sm: '90%',
                                xs: '100%'
                            },
                            padding: 4,
                            border: '1px solid #55555522'
                        }}
                    >
                        {/* <Button onClick={abc}>abc</Button> */}
                        <Typography color='#333' variant='body' fontSize='large'>City</Typography>
                        <br />
                        <Divider
                            sx={{
                                background: '#55555533'
                            }}
                        />
                        <br />
                        <Stack
                            direction={'column'}
                            spacing={1}
                        >
                            <Stack
                                direction={{
                                    xs: 'column',
                                    md: 'row'
                                }}
                                spacing={2}
                            >
                                {/* <TextField
                                    fullWidth
                                    size='small'
                                    label='Catalog Name'
                                    name='catalog_name'
                                    sx={{
                                        width: {

                                            md: '50%',
                                            sm: '100%'
                                        }
                                    }}
                                    value={formData.catalog_name || ''}
                                    onChange={changeHandler}
                                /> */}
                                <Autocomplete
                                    fullWidth
                                    options={countryList}
                                    size="small"
                                    value={formData.CountryCode?.CountryName || ''}
                                    onChange={handleAutoOnchange}
                                    // sx={{ backgroundColor: 'white' }}
                                    sx={{
                                        width: {
                                            md: '50%',
                                            sm: '100%'
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Country" />}
                                />
                                <Autocomplete
                                    fullWidth
                                    options={stateList}
                                    size="small"
                                    value={formData.StateCode?.State || ''}
                                    onChange={handleAutoOnchange}
                                    // sx={{ backgroundColor: 'white' }}
                                    sx={{
                                        width: {
                                            md: '50%',
                                            sm: '100%'
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params} label="State" />}
                                />
                                {/* <Autocomplete
                                    fullWidth
                                    options={cityList}
                                    size="small"
                                    value={formData.City?.City || ''}
                                    onChange={handleAutoOnchange}
                                    // sx={{ backgroundColor: 'white' }}
                                    sx={{
                                        width: {
                                            md: '50%',
                                            sm: '100%'
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params} label="City" />}
                                /> */}
                                <TextField
                                    fullWidth
                                    size='small'
                                    label='City'
                                    name='City'
                                    sx={{
                                        width: {

                                            md: '50%',
                                            sm: '100%'
                                        }
                                    }}
                                    value={formData.City || ''}
                                    onChange={changeHandler}
                                />
                            </Stack>
                            <Stack
                                direction={{
                                    xs: 'column',
                                    md: 'row'
                                }}
                                spacing={2}
                            >
                                <Autocomplete
                                    fullWidth
                                    options={cityType}
                                    size="small"
                                    value={formData.city_type || ''}
                                    onChange={handleAutoOnchange}
                                    // sx={{ backgroundColor: 'white' }}
                                    sx={{
                                        width: {
                                            md: '50%',
                                            sm: '100%'
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params} label="City Type" />}
                                />
                                <TextField
                                    fullWidth
                                    size='small'
                                    label='Longitude'
                                    name='longitude'
                                    type='number'
                                    sx={{
                                        width: {

                                            md: '50%',
                                            sm: '100%'
                                        }
                                    }}
                                    value={formData.longitude || ''}
                                    onChange={changeHandler}
                                />
                                <TextField
                                    fullWidth
                                    size='small'
                                    label='Latitude'
                                    name='latitude'
                                    type='number'
                                    sx={{
                                        width: {

                                            md: '50%',
                                            sm: '100%'
                                        }
                                    }}
                                    value={formData.latitude || ''}
                                    onChange={changeHandler}
                                />
                            </Stack>
                            <Stack
                                direction={{
                                    xs: 'column',
                                    md: 'row'
                                }}
                                spacing={2}
                            >
                                <TextField
                                    fullWidth
                                    size='small'
                                    label='City Code'
                                    name='city_Code'
                                    sx={{
                                        width: {

                                            md: '50%',
                                            sm: '100%'
                                        }
                                    }}
                                    value={formData.city_Code || ''}
                                    onChange={changeHandler}
                                />
                            </Stack>
                        </Stack>
                        <Divider sx={{ my: 3, background: '#55555533' }} />
                        <Stack
                            direction='row'
                            justifyContent='flex-end'
                            alignItems='center'
                            padding={1}
                            mt={2}
                        >
                            <Stack
                                direction={{
                                    xs: 'column',
                                    sm: 'row'
                                }}
                                width='100%'
                                justifyContent={{
                                    xs: 'center',
                                    sm: 'flex-end'
                                }}
                            >
                                <Tooltip title='Reset'>
                                    <IconButton onClick={clear} >
                                        <RotateLeftIcon />
                                    </IconButton>
                                </Tooltip>
                                <Button
                                    variant='contained'
                                    sx={{
                                        width: {
                                            xs: '100%',
                                            sm: '200px'
                                        },
                                    }}
                                    onClick={handleSubmit}
                                >
                                    {isEdit ? 'Update' : 'Add City'}
                                </Button>
                            </Stack>
                        </Stack>
                    </Paper>
                    <Divider
                        variant='middle'
                        sx={{
                            width: '80%',
                        }}
                    />
                    <CityTable handleEditData={handleEditData} isChanged={isChanged} />
                </Stack>
            </Box>
            <Footer />

        </>
    )
}

export default CityMaster