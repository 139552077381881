import React, { useState, useEffect } from 'react';
import {
    Button,
    TextField,
    Grid,
    Typography,
    FormGroup,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import SaveIcon from '@mui/icons-material/Save';
import UpdateIcon from '@mui/icons-material/Update';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import configServ from '../../services/config';
import AlertDialog from '../AlertDialog/AlertDialog';
import CardHeadMenu from '../Card/CardHeadMenu/CardHeadMenu';

//FUNCTION
const AddCity = ({ isChanged, setIsChanged, editData, setEditData }) => {
    //#region code
    //const app = useSelector((state) => state.GlobalVariables.noRoomService);
    const { company_id, user_id } = useSelector((state) => state.GlobalVariables);
    const [formData, setFormData] = useState({
        status: true
    });
    const [isEdit, setIsEdit] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState('');
    const [isCreate, setIsCreate] = useState(false);
    const [countryList, setCountryList] = useState([]);
    const [country, setCountry] = useState('');
    const [stateList, setStateList] = useState([]);

    const fetchCountry = async () => {
        try {
            const res = await configServ.getCountry();
            if (res.status === 200) {
                setCountryList(res.data);
            }
            else {
                setCountryList([]);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        fetchCountry();
        return () =>{
            clear();
        }
    }, []);

    useEffect(()=>{
        if(formData.country){
            fetchState();
        }
    },[formData.country]);

    const fetchState = async () => {
        try {
            const res = await configServ.getState({ "country": formData.country });
            if (res.status === 200) {
                setStateList(res.data);
            }
            else{
                setStateList([]);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(()=>{
        fetchState();
    },[country]);

    // const handleCountryState = (params) => {
    //     try {
    //         const { name, value } = params.target;
    //         if(name === 'country_id'){
    //             setStateList([]);
    //             setCountry(value);
    //         }
    //         setFormData((prev)=>({
    //             ...prev,
    //             [name]: value
    //         }));
    //     }
    //     catch (e) {
    //         console.log(e);
    //     }
    // }

    const handleOpenDialog = (content) => {
        setDialogContent(content);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setDialogContent('');
    };

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData((state) => ({
            ...state,
            [name]: value
        }));

        if(validationErrors[name]){
            setValidationErrors((prev)=>({
                ...prev,
                [name]:''
            }));
        }
    }

    const handleChangeCheckbox = (e) => {
        const { name, checked } = e.target
        setFormData((state) => ({
            ...state,
            [name]: checked
        }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const error = handleFormValidation();
        if (error === false) {
            return false;
        }

        const dataToSend = {
            ...formData,
            user_id: user_id
        }
        try {
            if (!isEdit) {
                const res = await configServ.addCity(dataToSend);
                if (res.status === 200) {
                    setIsChanged(!isChanged)
                    handleOpenDialog(res.message);
                    clear();
                }
                else {
                    handleOpenDialog(res.message || "An error occurred.");
                }
            }
            else {
                const res = await configServ.updateCity(dataToSend);
                if (res.status === 200) {
                    setIsChanged(!isChanged)
                    handleOpenDialog(res.message);
                    clear();
                }
                else {
                    handleOpenDialog(res.message || "An error occurred.");
                }
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        if(editData !== null){
            setFormData(editData);
            setIsEdit(true);
            setIsCreate(true);
        }
    }, [editData])

    const clear = () => {
        setFormData({
            status: true
        });
        setValidationErrors({});
        setIsEdit(false);
        setEditData(null);
    }

    const handleFormValidation = () => {
        const errors = {}
        if (!formData.name) {
            errors.name = "required";
        }
        if (!formData.country) {
            errors.country = "required";
        }
        if (!formData.state) {
            errors.state = "required";
        }
        setValidationErrors(errors);
        return Object.values(errors).every((error) => error === "");
    }

    const handlePopup = () => {
        setIsCreate(!isCreate);
    }
    //#endregion

    return (
        <>
            <AlertDialog
                open={dialogOpen}
                setOpen={handleCloseDialog}
                title="Alert"
                message={dialogContent}
                buttonTitle="Ok"
            />

            <CardHeadMenu prefix={'Na'} suffix={'Na'} title={'CITY'} handlePopup={handlePopup} isEdit={isEdit} create={isCreate} />

            {isCreate &&
                <motion.div style={{ width: "100%", backgroundColor: "#e3f3fc", paddingTop: 10, paddingBottom: 10, paddingLeft: 20, paddingRight: 20, borderRadius: '10px', border: 'none' }}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.1, delay: 0.1 }}
                >
                    <form onSubmit={handleSubmit}>
                        <Grid container alignItems="flex-end" justifyContent="flex-start" spacing={1}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12} justifyContent={'flex-start'}>
                                    <Typography sx={{ fontSize: '16px', fontWeight: 'bolder' }}>City Detail</Typography>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        id="name"
                                        name="name"
                                        label="City*"
                                        variant="outlined"
                                        size='small'
                                        fullWidth
                                        sx={{ backgroundColor: 'white' }}
                                        value={formData.name || ''}
                                        onChange={handleChange}
                                        error={!!validationErrors.name}
                                    />
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <FormControl fullWidth>
                                        <InputLabel size='small' id="demo-simple-select-label" error={!!validationErrors.country}>Country</InputLabel>
                                        <Select
                                            size='small'
                                            labelId="demo-simple-select-label"
                                            variant="outlined"
                                            id="country"
                                            name='country'
                                            value={formData.country || ''}
                                            label="Country"
                                            sx={{ backgroundColor: 'white' }}
                                            onChange={handleChange}
                                            error={!!validationErrors.country}
                                        >
                                            {countryList.map((item) => (
                                                <MenuItem value={item.PK} key={item.PK}>{item.country}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <FormControl fullWidth>
                                        <InputLabel size='small' id="demo-simple-select-label" error={!!validationErrors.state}>State</InputLabel>
                                        <Select
                                            size='small'
                                            labelId="demo-simple-select-label"
                                            variant="outlined"
                                            id="state"
                                            name='state'
                                            value={formData.state || ''}
                                            sx={{ backgroundColor: 'white' }}
                                            label="State"
                                            onChange={handleChange}
                                            error={!!validationErrors.state}
                                        >
                                            {stateList.map((item) => (
                                                <MenuItem value={item.PK} key={item.PK}>{item.State}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                                        <FormControlLabel
                                            name='status'
                                            control={<Checkbox checked={Boolean(formData.status) || false} />}
                                            onChange={handleChangeCheckbox}
                                            label="Status"
                                            style={{ marginRight: '20px' }}
                                        />
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} justifyContent="space-between" px={2}>
                            <Grid
                                item
                                container
                                justifyContent="flex-end"
                                alignItems="center"
                                spacing={2}
                            >
                                <Grid item>
                                    <Button
                                        onClick={clear}
                                        variant="contained"
                                        color='secondary'
                                        startIcon={<RotateLeftIcon />}
                                    >
                                        Reset
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        type='submit'
                                        variant="contained"
                                        color={isEdit ? 'success' : 'primary'}
                                        startIcon={isEdit ? <UpdateIcon /> : <SaveIcon />}
                                    >
                                        {isEdit ? "Update" : "Save"}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </motion.div>
            }
        </>
    )
}

export default AddCity;


