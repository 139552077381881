import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import configServ from '../../services/config';
import OrderView from './OrderView';
import Calculation from '../../utility/Calculations';

import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    // DialogContentText,
    DialogActions,
    TextField,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    FormControlLabel,
    Checkbox,
    IconButton,
    Typography,
    Stack,
    Tooltip,
    ToggleButton,
    ToggleButtonGroup,
    Autocomplete
} from '@mui/material'
import { useSelector } from 'react-redux';

function OrderToInvoice({ handleClose, open, sendData, sendItems, invoiceInitial, itemListInitial }) {
    //#region code
    const { company_id } = useSelector((state) => state.GlobalVariables);
    const [formData, setFormData] = useState({})
    const [customerList, setCustomerList] = useState([])
    const [orderList, setOrderList] = useState([])
    const [salesorderList, setSalesorderList] = useState([])
    const [searched, setSearched] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [searchMode, setSearchMode] = useState('customer');
    const [order, setOrder] = useState({})
    const [selectedItems, setSelectedItems] = useState([]);

    const handleSearchMode = (event, newAlignment) => {
        setSearchMode(newAlignment);
    };

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData((state) => ({
            ...state,
            [name]: value
        }))
    }

    const handleChangeAutocomplete = (e, target) => {
        if (target) {
            const { name, value } = target
            setFormData((state) => ({
                ...state,
                [name]: value
            }))
        }
    }

    const handleChangeCheckbox = (e) => {
        const { name, checked } = e.target
        setFormData((state) => ({
            ...state,
            [name]: checked
        }))
    }

    const fetchCustomerList = async () => {
        try {
            const dataToSend = {
                "company_id": company_id,
                "search_by": null,
                "search": null,
                "per_page": null,
                "page": null
            }
            const res = await configServ.getCustomerList(dataToSend);
            if (res.status === 200) {
                setCustomerList(res.data)
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    const fetchOrder = async () => {
        const dataToSend = {
            "company_id": company_id,
            "customer_id": formData.customer_id
        }
        try {
            const result = await configServ.customer_order_list(dataToSend)
            // console.log(result.data)
            setOrderList(result.data)
        } catch (err) {
            console.log(err)
        }
    }

    const fetchSalesorder = async () => {
        const dataToSend = {
            "company_id": company_id,
        }
        try {
            const result = await configServ.salesOrderList(dataToSend)
            // console.log(result)
            setSalesorderList(result)
        } catch (err) {
            console.log(err)
        }
    }

    const fetchOrderDetails = async () => {
        const dataToSend = {
            "company_id": company_id,
            "order_no": formData.order_no
        }
        try {
            const result = await configServ.getOrderDetails(dataToSend)
            // console.log(result.data)
            setOrder(result.data)
        } catch (err) {
            console.log(err)
        }
    }


    useEffect(() => {
        if (formData.customer_id) {
            fetchOrder()
        }
    }, [formData.customer_id]);


    useEffect(() => {
        fetchCustomerList()
        fetchSalesorder()
    }, []);

    const search = async () => {
        if (formData.order_no) {
            fetchOrderDetails()
        }
    }

    const importOrder = () => {
        const dataToSend = {
            ...invoiceInitial,
            contact_name: order.partyname,
            buyeraddress: order.billtoaddress,
            ShipToAddress: order.shiptoaddress,
            sales_order: order.order_no,
            salesperson: order.salesperson,
            date: Calculation.GetCurrentDate(),
            due_date: Calculation.GetCurrentDate(),
            customer_id: order.customer_id,
        }
        sendData(dataToSend)
        setOrder({})
        const itemsToSend = selectedItems.map((item) => ({
            ...itemListInitial[0],
            ...item,
            itemcode: item.item_code,
            item_id: item.itemid,
            tax_rate: item.Taxrate
        }))
        sendItems(itemsToSend)
        setSelectedItems([])
    }

    const handleClick = async () => {
        if (!searched) {
            search()
            setSearched(true)
        } else {
            importOrder()
            clear()
            handleClose()
        }
    }

    const clear = () => {
        setFormData({})
        // setEditData({})
        // setIsDone(false)
        setIsEdit(false)
        setSearched(false)
    }
    //#endregion

    return (
        <>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='md'
                fullWidth={true}
                scroll='paper'
            >
                <DialogTitle id="alert-dialog-title">
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='h5'>Create invoice from order</Typography>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent dividers>
                    <Stack
                        direction='column'
                        justifyContent='space-between'
                        alignItems='center'
                        spacing={2}
                    >
                        <ToggleButtonGroup
                            color="primary"
                            value={searchMode}
                            exclusive
                            onChange={handleSearchMode}
                            aria-label="Platform"
                            size='small'
                        >
                            <ToggleButton value="customer">Customer Name</ToggleButton>
                            <ToggleButton value="order">Order ID</ToggleButton>
                        </ToggleButtonGroup>
                        <Autocomplete
                            sx={{
                                width: '60%'
                            }}
                            value={formData.customer_id}
                            id="customer"
                            size="small"
                            hidden={searchMode !== 'customer'}
                            options={customerList.map((option) => ({ value: option.id, label: option.contact_name, name: 'customer_id' }))}
                            onChange={handleChangeAutocomplete}
                            renderInput={(params) => <TextField {...params}
                                label="Customer Name"
                            />}
                        />
                        {(searchMode === 'customer' && formData.customer_id !== undefined) && (
                            <Autocomplete
                                sx={{
                                    width: '60%'
                                }}
                                value={formData.order_no}
                                id="order"
                                size="small"
                                // hidden={searchMode !== 'customer'}
                                options={orderList.map((option) => ({ value: option.order_no, label: option.order_no, name: 'order_no' }))}
                                onChange={handleChangeAutocomplete}
                                renderInput={(params) => <TextField {...params}
                                    label="Order Number"
                                />}
                            />
                        )}

                        <Autocomplete
                            hidden={searchMode !== 'order'}
                            sx={{
                                width: '60%'
                            }}
                            value={formData.order_no}
                            id="salesorder"
                            size="small"
                            // hidden={searchMode !== 'customer'}
                            options={salesorderList.map((option) => ({ value: option.order_no, label: option.order_no, name: 'order_no' }))}
                            onChange={handleChangeAutocomplete}
                            renderInput={(params) => <TextField {...params}
                                label="Order Number"
                            />}
                        />

                    </Stack>

                    {searched && (
                        <Stack>
                            <OrderView order={order} selectedItems={selectedItems} setSelectedItems={setSelectedItems} />
                        </Stack>
                    )}

                </DialogContent>
                <DialogActions>
                    <Tooltip title='Reset'>
                        <IconButton onClick={clear} >
                            <RotateLeftIcon />
                        </IconButton>
                    </Tooltip>
                    <Button
                        onClick={() => {
                            // handleClose();
                            handleClick()
                        }}
                        autoFocus
                        variant='contained'
                        disabled={formData.order_no ? false : true}
                    >
                        {searched ? 'Import' : 'Search'}
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    )
}

export default OrderToInvoice