import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  IconButton,
  Stack,
  Box,
  Menu,
  MenuItem,
  Button,
  Avatar,
  Divider,
  Chip,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Logout from "../../Logout/Logout";
import configServ from "../../../services/config";
import YesNoDialog from "../../AlertDialog/YesNoDialog";

import SiteLogoInitial from "../../Assets/images/SiteIcon.svg";
import SettingsIcon from "@mui/icons-material/Settings";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import LogoutIcon from "@mui/icons-material/Logout";
import { assignType } from "../../../redux/reducers/Admin";
import {
  rSetCompanyAddress,
  rSetCompanyCountry,
  rSetCompanyId,
  rSetCompanyName,
  rSetCompanyState,
} from "../../../redux/reducers/GlobalVariables";
import NotificationBell from "../../sidebar/NotificationBell/NotificationBell";
import VoiceCommand from "../../VoiceRecognization/VoiceCommand ";

function Header() {
  //#region code
  const { user_id, company_id, companyName, is_super, is_admin } = useSelector(
    (state) => state.GlobalVariables
  );
  const dispatch = useDispatch();
  const open = useSelector((state) => state.sideExpand.open);
  const [companyLogo, setCompanyLogo] = useState("");
  const [isLogutDialogOpen, setIsLogutDialogOpen] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [companySwitchPopup, setCompanySwitchPopup] = useState(false);
  const [companyData, setCompanyData] = useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);

  const navigate = useNavigate();

  function stringToColor(string) {
    // Return a default color if the input string is undefined or null
    if (!string) {
      return "#000000"; // You can choose any default color you prefer
    }

    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    const initials = name
      ? name.includes(" ")
        ? `${name.split(" ")[0][0].toUpperCase()}${name
            .split(" ")[1][0]
            .toUpperCase()}`
        : `${name[0].toUpperCase()}`
      : "W";

    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: initials,
    };
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setIsLogutDialogOpen(!isLogutDialogOpen);
  };

  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const assignCompanyLogo = async () => {
    try {
      const result = await configServ.company_logo({ company_id: company_id });
      if (result.image) {
        setCompanyLogo(`data:image/png;base64,${result.image}`);
      } else {
        setCompanyLogo("");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchCompanyList = async () => {
    try {
      const dataToSend = {
        user_id: user_id,
        is_admin: is_admin
      }
      const result = await configServ.getCompanyList(dataToSend);
      setCompanyList(result);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchCompanyList();
    assignCompanyLogo();
  }, []);

  // const switchCompany = async () => {
  //   dispatch(rSetCompanyId(companyData.id));
  //   const data = {
  //     id: companyData.id,
  //   };

  //   const res = await configServ.getCompanyById(data);
  //   if (res.status === 200) {
  //     dispatch(rSetCompanyName(res.data.CompanyName));
  //     dispatch(rSetCompanyAddress(res.data.Address));
  //     dispatch(rSetCompanyCountry(res.data.country));
  //     dispatch(rSetCompanyState(res.data.State));
  //   }
  //   //localStorage.setItem('company_type', companyData.CompanyType);
  //   dispatch(assignType(companyData.CompanyType));
  //   const dataToSend = {
  //     user_id: user_id,
  //     company_id: companyData.id,
  //   };
  //   try {
  //     const result = await configServ.setActiveCompany(dataToSend);
  //     console.log(result);
  //   } catch (err) {
  //     console.log(err);
  //   }
  //   navigate(0);
  // };

  // const handleCompanySwitchPopup = () => {
  //   setCompanySwitchPopup(!companySwitchPopup);
  // };

  // useEffect(() => {
  //   if (Boolean(is_super) === true || Boolean(is_admin) === true) {
  //     fetchCompanyList();
  //   }
  //   assignCompanyLogo();
  // }, []);

  const switchCompany = async () => {
    dispatch(rSetCompanyId(companyData.id));
    const data = {
      id: companyData.id,
    };

    const res = await configServ.getCompanyById(data);
    if (res.status === 200) {
      dispatch(rSetCompanyName(res.data.CompanyName));
      dispatch(rSetCompanyAddress(res.data.Address));
      dispatch(rSetCompanyCountry(res.data.country));
      dispatch(rSetCompanyState(res.data.State));
    }
    //localStorage.setItem('company_type', companyData.CompanyType);
    dispatch(assignType(companyData.CompanyType));
    const dataToSend = {
      user_id: user_id,
      company_id: companyData.id,
    };
    try {
      const result = await configServ.setActiveCompany(dataToSend);
    } catch (err) {
      console.log(err);
    }
    navigate(0);
  };

  const handleCompanySwitchPopup = () => {
    setCompanySwitchPopup(!companySwitchPopup);
  };
  //#endregion

  // return (
  //     <>
  //         <Logout open={isLogutDialogOpen} setOpen={setIsLogutDialogOpen} />
  //         <YesNoDialog
  //             open={companySwitchPopup}
  //             setOpen={setCompanySwitchPopup}
  //             title={'Company Switch'}
  //             message={'Do you want to switch company?'}
  //             handleYes={switchCompany}
  //         />
  //         <Box
  return (
    <>
      <Logout open={isLogutDialogOpen} setOpen={setIsLogutDialogOpen} />
      <YesNoDialog
        open={companySwitchPopup}
        setOpen={setCompanySwitchPopup}
        title={"Company Switch"}
        message={"Do you want to switch company?"}
        handleYes={switchCompany}
      />
      <Box
        sx={{
          width: "100%",
          height: 60,
          ...(open && { marginLeft: 25, paddingRight: 8, width: "88.55%" }),
          transition: "200ms",
          zIndex: 10,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          //paddingRight: 5,
          boxShadow: 5,
          background: "linear-gradient(to bottom,  #ffffff, #f1f1f1)",
          position: "sticky",
          top: 0,
        }}
      >
        <Stack
          maxWidth={1000}
          marginLeft={10}
          height="100%"
          justifyContent="center"
        >
          <IconButton
            maxWidth={1000}
            id="basic-button"
            aria-controls={open1 ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open1 ? "true" : undefined}
            // onClick={handleClick}
            onClick={(Boolean(is_super) === true || Boolean(is_admin) === true) ? handleClick : () => {}}
          >
            {companyLogo === "" ? (
              <Typography
                variant="button"
                sx={{
                  p: 1,
                  fontSize: { xs: "12px", md: "16px" },
                  fontWeight: 700,
                  color: "black",
                  ":hover": {
                    color: "grey",
                  },
                }}
              >
                {companyName}
              </Typography>
            ) : (
              <img
                src={companyLogo || SiteLogoInitial}
                alt="logoInitial"
                height="50"
                title={companyName}
              />
            )}
          </IconButton>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open1}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {companyList
              .sort((a, b) => a.CompanyName.localeCompare(b.CompanyName))
              .map((item) => (
                <MenuItem
                  key={item.id}
                  sx={{
                    padding: { xs: 1, md: 2 },
                    height: { xs: "2.5rem", md: "3.5rem" },
                    fontWeight: 600,
                  }}
                  onClick={(e) => {
                    handleClose();
                    setCompanyData(item);
                    handleCompanySwitchPopup();
                  }}
                >
                  {item.CompanyLogo === null || item.CompanyLogo === "" ? (
                    <span style={{ color: "#1e61b4" }}>{item.CompanyName}</span>
                  ) : (
                    <>
                      <img
                        style={{ padding: "5px" }}
                        src={`data:image/png;base64,${item.CompanyLogo}`}
                        alt={item.CompanyName}
                        width="50"
                      />
                      <span style={{ color: "#1e61b4" }}>
                        {item.CompanyName}
                      </span>
                    </>
                  )}
                </MenuItem>
              ))}
          </Menu>
        </Stack>
        {/* <profile/> */}

        <Stack
          direction="row"
          spacing={7} // Adds space between avatar and bell
          alignItems="center"
          marginRight={-4} // Adjust the right margin as needed
        >
          <VoiceCommand />
          {/* <NotificationBell /> */}

          <IconButton
            id="basic-button2"
            aria-controls={open2 ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open2 ? "true" : undefined}
            onClick={handleClick2}
            sx={{
              width: "5%",
              // px: 7,
              //backgroundColor: 'rgba(0, 0, 0, 0.08)',
              borderRadius: "20px",
              cursor: "pointer",
              ":hover": {
                //backgroundColor: 'rgba(0, 0, 0, 0.16)',
              },
            }}
          >
            <Avatar {...stringAvatar(companyName)} />
            {anchorEl2 === null ? (
              <ArrowDropDownIcon sx={{ marginRight: 1, fontSize: "30px" }} />
            ) : (
              <ArrowDropUpIcon
                sx={{ marginRight: 1, fontSize: "30px" }}
                color="primary"
              />
            )}
            {/* <SettingsIcon  */}
          </IconButton>
        </Stack>

        {/* profile dropdown */}
        <Menu
          id="basic-menu2"
          anchorEl={anchorEl2}
          open={open2}
          onClose={handleClose2}
          MenuListProps={{
            "aria-labelledby": "basic-button2",
          }}
        >
          <Link style={{ textDecoration: "none" }} to="/profile">
            <MenuItem onClick={handleClose2} sx={{ padding: 0 }}>
              <Button
                sx={{ color: "black", padding: 2 }}
                startIcon={<AccountBoxIcon />}
              >
                My Profile
              </Button>
            </MenuItem>
          </Link>
          <MenuItem
            onClick={() => {
              handleClose2();
              handleLogout();
            }}
            sx={{ padding: 0 }}
          >
            <Button
              sx={{ color: "black", padding: 2 }}
              startIcon={<LogoutIcon />}
            >
              Log Out
            </Button>
          </MenuItem>
        </Menu>
      </Box>
      {/* <Divider /> */}
    </>
  );
}

export default Header;
