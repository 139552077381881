import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';

import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    // DialogContentText,
    DialogActions,
    IconButton,
    Typography,
    Stack,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Tooltip
} from '@mui/material'
import configServ from '../../../../services/config';
import Checkbox from '@mui/joy/Checkbox';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import AlertDialog from "../../../../Components/AlertDialog/AlertDialog";
import { useSelector } from 'react-redux';

function Neighbourhood({ itemData, handleClose, open, editData, isEdited, setIsEdited }) {
    //#region code
    const { company_id } = useSelector((state) => state.GlobalVariables);
    const [formData, setFormData] = useState({})
    const [isEdit, setIsEdit] = useState(false)
    const [neighbourhoodType, setNeighbourhoodType] = useState([])
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState('');

    const handleOpenDialog = (content) => {
        setDialogContent(content);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setDialogContent('');
    };

    const changeHandler = (e) => {
        const { name, value } = e.target
        setFormData((state) => ({
            ...state,
            [name]: value
        }));
    }

    const checkHandler = (e) => {
        const { name, checked } = e.target
        setFormData((state) => ({
            ...state,
            [name]: checked
        }));
    }

    const fetchNeighbourhoodType = async () => {
        try {
            const result = await configServ.getNeighbourhoodType()
            // console.log(result)
            if (result) {
                setNeighbourhoodType(result)
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchNeighbourhoodType()
    }, []);

    const handleSubmit = async () => {
        const dataToSend = {
            ...formData,
            "company_id": company_id,
            "location_id": itemData.id
        }
        try {
            if (!isEdit) {
                const result = await configServ.addNeighbourhood(dataToSend);
                if (result.success) {
                    handleOpenDialog('Neighbourhood successfully added')
                    setIsEdited(!isEdited)
                    clear()
                }
            } else {
                const result = await configServ.editNeighbourhood(dataToSend);
                if (result.success) {
                    handleOpenDialog('Neighbourhood successfully updated')
                    setIsEdited(!isEdited)
                    clear()
                }
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    const assignForEdit = async () => {
        if (editData !== undefined) {
            setFormData(editData)
            setIsEdit(true)
            // alert('editData')
        }
    }

    useEffect(() => {
        assignForEdit()
        // console.log(itemData)
    }, [editData]);

    const clear = () => {
        setFormData({})
        setIsEdit(false)
    }
    //#endregion

    return (
        <>
            <AlertDialog
                open={dialogOpen}
                setOpen={handleCloseDialog}
                title="Alert"
                message={dialogContent}
                buttonTitle="Ok"
            />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='md'
                fullWidth={true}
            >
                <DialogTitle id="alert-dialog-title">
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='h5'>{isEdit ? 'Update' : 'Add'} neighbourhood</Typography>
                        {/* <Button onClick={abc}>abc</Button> */}
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>

                    <Stack spacing={2}>
                        <Typography variant='body' color='text.secondary'>Choose nieighbourhood for {itemData.location_name}:</Typography>
                        <Stack
                            direction={{
                                xs: 'column',
                                md: 'row'
                            }}
                            spacing={1}
                            alignItems='center'
                            justifyContent='space-between'
                        >
                            <TextField
                                name='place'
                                variant="outlined"
                                label='Name'
                                size='small'
                                onChange={changeHandler}
                                value={formData.place || ''}
                                fullWidth
                            />
                            <FormControl size='small' sx={{ minWidth: '20%' }} fullWidth>
                                <InputLabel>Nieighbourhood Type</InputLabel>
                                <Select name='type' label='Nieighbourhood Type' onChange={changeHandler} value={formData.type || ''}>
                                    {neighbourhoodType.map((item, index) => {
                                        return (
                                            <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                            <TextField
                                name='description'
                                variant="outlined"
                                label='Description'
                                size='small'
                                onChange={changeHandler}
                                value={formData.description || ''}
                                fullWidth
                            />
                        </Stack>
                        <Stack
                            direction={{
                                xs: 'column',
                                md: 'row'
                            }}
                            spacing={1}
                            alignItems='center'
                            justifyContent='space-between'
                        >
                            <TextField
                                name='distance'
                                variant="outlined"
                                label='Distance(KM)'
                                size='small'
                                onChange={changeHandler}
                                value={formData.distance || ''}
                                fullWidth
                            />
                            <TextField
                                name='latitude'
                                variant="outlined"
                                label='Latitude'
                                size='small'
                                onChange={changeHandler}
                                value={formData.latitude || ''}
                                fullWidth
                            />
                            <TextField
                                name='longitude'
                                variant="outlined"
                                label='Longitude'
                                size='small'
                                onChange={changeHandler}
                                value={formData.longitude || ''}
                                fullWidth
                            />
                        </Stack>
                    </Stack>

                </DialogContent>
                <DialogActions>
                    <Tooltip title='Reset'>
                        <IconButton onClick={clear} >
                            <RotateLeftIcon />
                        </IconButton>
                    </Tooltip>
                    <Button onClick={() => { handleClose(); handleSubmit() }} autoFocus variant='contained'>
                        {isEdit ? 'Update' : 'Submit'}
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    )
}

export default Neighbourhood