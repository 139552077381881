import React, { useState, useEffect, memo } from "react";
import {
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  FormControlLabel,
  Checkbox,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  Typography,
  Stack,
  RadioGroup,
  FormLabel,
  Radio,
  InputAdornment,
} from "@mui/material";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import configServ from "../../../services/config";
import Calculation from "../../../utility/Calculations";
import AddCardSales from "./AddCardSales";
import CardHeadMenu from "../CardHeadMenu/CardHeadMenu";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import JoyModal from "../../Dialog/JoyModal";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { useDispatch, useSelector } from "react-redux";
import SaveIcon from "@mui/icons-material/Save";
import UpdateIcon from "@mui/icons-material/Update";
import { triggerLoader } from "../../../redux/reducers/LoaderTrigger";
import AlertDialog from "../../AlertDialog/AlertDialog";
import CheckAndReturn from "../../../utility/CheckAndReturn";

function SalesOrderCard({
  isEdit,
  setIsEdit,
  editData,
  clear,
  setIsChanged,
  isChanged,
}) {
  //#region code
  const dispatch = useDispatch();
  const { user_id, company_id, is_super, is_admin } = useSelector(
    (state) => state.GlobalVariables
  );
  const [customers, setCustomers] = useState([]);
  const [items, setItems] = useState([]);
  const [autoGenerate, setAutoGenerate] = useState(true);
  const [approval, setApproval] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [title, setTitle] = useState("Alert");
  const [dialogContent, setDialogContent] = useState("");
  const [buttonType, setButtonType] = useState(null);
  const [totalTax, setTotalTax] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalGrossAmount, setTotalGrossAmount] = useState(0);
  const itemListInitial = [
    {
      id: 1,
      itemid: "",
      itemname: "",
      item_code: "",
      packagingunit: "",
      hsn_code: "",
      Taxrate: "",
      qty: "",
      unit: "",
      rate: "",
      tax_amount: "",
      discount: "",
      amount: "",
    },
  ];
  const [itemList, setItemList] = useState(itemListInitial);
  const itemListErrorInitial = [
    {
      itemname: "",
      qty: "",
    },
  ];
  const [itemListError, setItemListError] = useState(itemListErrorInitial);
  const saleOrderInitial = {
    user_id: user_id,
    is_admin: is_admin,
    company_id: company_id,
    auto_generate: true,
    _token: "",
    order_no: "",
    partyname: "",
    billtoaddress: "",
    shiptoaddress: "",
    order_type: "Primary",
    voucher_type: "order",
    order_no_sup: "",
    supplementry_orderno: "",
    validitydate: Calculation.getCurrentDatePlusDays(30),
    sales_order: "",
    salesperson: "",
    pay_terms: "",
    delivery_date: "",
    po_number: "",
    po_date: "",
    tax_type: "",
    tax_amount: totalTax,
    totalamount: totalGrossAmount,
    custId: "",
    remarks: "",
    IsApproved: is_admin ? false : true,
    with_gst: false,
    items: itemList,
  };
  const [saleOrder, setSaleOrder] = useState(saleOrderInitial);
  const saleOrderErrorInitial = {
    partyname: "",
    billtoaddress: "",
    shiptoaddress: "",
    validitydate: "",
    delivery_date: "",
    po_number: "",
  };
  const [saleOrderError, setSaleOrderError] = useState(saleOrderErrorInitial);
  const shipToAddressesInitial = [
    {
      id: "",
      address: "",
    },
  ];
  const [shipToAddresses, setShipToAddresses] = useState(
    shipToAddressesInitial
  );
  const [isOrderAvailable, setIsOrderAvailable] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleOpenDialog = (heading, content, type) => {
    setTitle(heading);
    setDialogContent(content);
    setButtonType(type);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setTitle("Alert");
    setDialogContent("");
    setButtonType(null);
  };

  useEffect(() => {
    if (Object.keys(editData).length !== 0) {
      setSaleOrder({
        ...editData,
        auto_generate: true,
      });

      const editItems = editData.items.map((item)=>({
        order_id: item.order_id,
        company_id: item.company_id,
        itemname: item.itemname,
        packagingunit: item.packagingunit,
        hsn_code: item.hsn_code,
        Taxrate: item.Taxrate,
        tax_amount: item.tax_amount,
        qty: item.qty,
        rate: item.rate,
        discount: item.discount,
        amount: item.amount,
        itemid: item.itemid,
		    item_code: item.item_code,
        unit: (item.product !== null && item.product !== undefined)? item.product.unit: '',
      }));
      const updatedItemList = CheckAndReturn.UpdateItemList(editItems);
      
      setItemList(updatedItemList);
      setIsEdit(true);
      setIsOrderAvailable(true);
      fetchShipToAddress(editData.customer_id);
      scrollToTop();
    }
  }, [editData]);

  useEffect(() => {
    fetchCustomerList();
    fetchItemList();
  }, []);

  useEffect(() => {
    calculateTotal();
  }, [itemList]);

  useEffect(() => {
    if (saleOrder.shiptoaddress && saleOrder.with_gst === true) {
      compareStateCodes();
    } else if (saleOrder.with_gst === false) {
      setSaleOrder((prev) => ({
        ...prev,
        tax_type: "",
      }));
    }
  }, [saleOrder.customer_id, saleOrder.with_gst]);

  useEffect(() => {
    if (saleOrder.order_no && saleOrder.auto_generate === false) {
      checkOrderNumber();
    }
  }, [saleOrder.order_no]);

  const checkOrderNumber = async () => {
    try {
      const dataToSend = {
        company_id: company_id,
        order_no: saleOrder.order_no,
      };
      const res = await configServ.checkOrderNumber(dataToSend);
      if (res.status === 200) {
        setIsOrderAvailable(true);
      } else {
        setIsOrderAvailable(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchCustomerList = async () => {
    try {
      const result = await configServ.get_customer({ company_id: company_id });
      setCustomers(result);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchItemList = async () => {
    try {
      const dataToSend = {
        company_id: company_id,
        search_by: null,
        search: null,
        per_page: null,
        page: null,
      };
      const res = await configServ.getItemList(dataToSend);
      if (res.status === 200) {
        if (res.data.length > 0) {
          const result = res.data;  //.filter((x) => x.status === 1);
          setItems(result);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  //Sale Order code
  // Handle customer change
  const handleCustomerAutocompleteChange = (newValue) => {
    try {
      if (newValue !== null && newValue !== undefined) {
        handleCustomerInputChange({
          target: { name: "partyname", value: newValue },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  // Handle customer input
  const handleCustomerInputChange = (event) => {
    try {
      const customerName = event.target.value;
      const selectedCustomer = customers.find(
        (item) => item.fname.toLowerCase() === customerName.toLowerCase()
      );

      if (selectedCustomer !== null && selectedCustomer !== undefined) {
        setSaleOrder((prev) => ({
          ...prev, // Copy existing fields
          customer_id: selectedCustomer.id, // Update the specific field with the new value
          partyname: selectedCustomer.fname,
          billtoaddress: selectedCustomer.address,
          shiptoaddress: selectedCustomer.address,
        }));
        fetchShipToAddress(selectedCustomer.id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Handle Sale Order Customer
  const handleSaleOrderCustomer = (event) => {
    try {
      const { name, value, type, checked } = event.target;

      if (type === "checkbox") {
        setSaleOrder((prev) => ({
          ...prev, //copy existing field values
          [name]: checked, //update field value
        }));
      } else {
        setSaleOrder((prev) => ({
          ...prev, //copy existing field values
          [name]: value, //update field value
        }));
      }

      // Reset the validation error when the user starts typing
      if (saleOrderError[name]) {
        setSaleOrderError((prevValidationError) => ({
          ...prevValidationError,
          [name]: "",
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  //Fetch Ship To Address
  const fetchShipToAddress = async (cust_id) => {
    try {
      const result = await configServ.getShipToAddress({ cust_id: cust_id });
      setShipToAddresses(result);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAutoGenerateChange = (event) => {
    const autoGenerate = event.target.checked;
    // if(autoGenerate === true){
    //   setSaleOrder((prev)=>({
    //     ...prev,
    //     order_no: ''
    //   }));
    // }
    // else{
    //   setSaleOrder((prev)=>({
    //     ...prev,
    //     order_no: ''
    //   }));
    // }
    setAutoGenerate(autoGenerate);
  };

  const handleApprovalChange = (event) => {
    const isApproved = event.target.checked;
    if (isApproved === true) {
      setSaleOrder((prev) => ({
        ...prev,
        approval_is: true,
      }));
    } else {
      setSaleOrder((prev) => ({
        ...prev,
        approval_is: false,
      }));
    }
    setApproval(isApproved);
  };

  const calculateTotal = () => {
    let total_Quantity = 0;
    let total_Tax = 0;
    let totalAmount = 0;
    itemList.forEach((item) => {
      // Parse the amount string to a float value
      const quantity = parseInt(item.qty);
      const tax = parseFloat(item.tax_amount);
      const amount = parseFloat(item.amount);
      // Check if the amount is a valid number
      if (!isNaN(amount)) {
        // Add the amount to the total
        total_Quantity += quantity;
        total_Tax += tax;
        totalAmount += amount;
      }
    });

    setTotalQuantity(total_Quantity);
    setTotalTax(CheckAndReturn.roundToInteger(total_Tax ?? 0));
    setTotalGrossAmount(totalAmount);
    saleOrder.tax_amount = CheckAndReturn.roundToInteger(total_Tax ?? 0);
    saleOrder.totalamount = CheckAndReturn.roundToInteger(totalAmount ?? 0);
  };

  const handleAutocompleteChange = (newValue, id) => {
    const value = newValue || ""; // Handle possible undefined or null values
    handleInputChange({ target: { name: "itemname", value } }, id);
  };

  const handleInputChange = (event, id) => {
    try {
      const { name, value } = event.target;

      // Find the current item by ID
      const currentItem = itemList.find((item) => item.id === id);
      if (!currentItem) return; // Early exit if the item is not found

      // Calculate updated values based on the input field
      const updatedItem = {
        ...currentItem,
        [name]: value, // Default update
      };

      // Update logic based on the specific field being changed
      if (
        name === "qty" ||
        name === "rate" ||
        name === "Taxrate" ||
        name === "discount"
      ) {
        const taxAmount = Calculation.GetDiscountTaxAmount(
          updatedItem.Taxrate || currentItem.Taxrate,
          updatedItem.rate || currentItem.rate,
          updatedItem.qty || currentItem.qty,
          updatedItem.discount || currentItem.discount
        );

        const totalAmount = Calculation.GetDiscountTotalAmount(
          updatedItem.Taxrate || currentItem.Taxrate,
          updatedItem.rate || currentItem.rate,
          updatedItem.qty || currentItem.qty,
          updatedItem.discount || currentItem.discount
        );

        updatedItem.tax_amount = taxAmount.toString();
        updatedItem.amount = CheckAndReturn.roundToInteger(
          totalAmount ?? 0
        ).toString();
      }

      // Specific handling for 'itemname' to update item details
      if (name === "itemname") {
        const selectedItem = items.find(
          (item) => item.item_name.toLowerCase() === value.toLowerCase()
        );

        if (selectedItem) {
          // Update the item details if found
          Object.assign(updatedItem, {
            itemid: selectedItem.id,
            itemname: selectedItem.item_name,
            item_code: selectedItem.item_code,
            packagingunit: selectedItem.packagingunit,
            hsn_code: selectedItem.hsncode,
            qty: "",
            unit: selectedItem.unit,
            rate: selectedItem.rate_unit,
            Taxrate: Boolean(saleOrder.with_gst) ? selectedItem.tax_rate : 0,
            discount: selectedItem.discount ?? 0,
            amount: "0",
          });
        } else {
          // If no item is found, reset relevant fields
          Object.assign(updatedItem, {
            itemid: "",
            item_code: "",
            packagingunit: "",
            hsn_code: "",
            qty: "",
            unit: "",
            rate: "",
            Taxrate: "0",
            discount: "0",
            amount: "0",
          });
        }
      }

      // Update item list with the modified item
      const updatedItemList = itemList.map((item) =>
        item.id === id ? updatedItem : item
      );
      setItemList(updatedItemList);
    } catch (error) {
      console.error(error);
    }
  };

  // Function to add a new item to the list right after the current item
  const handleAddItem = (currentItemId) => {
    try {
      setItemList((prevList) => {
        // Check if the list is empty
        if (prevList.length === 0) {
          // Return the list with the first item if it's empty
          return [{
            id: 1,
            name: "",
            qty: "",
          }];
        }

        // Find the index of the current item
        const currentIndex = prevList.findIndex((item) => item.id === currentItemId);

        // Handle case when currentItemId is not found
        if (currentIndex === -1) {
          console.error(`Item with id ${currentItemId} not found.`);
          return prevList; // Return the original list without changes
        }

        // Create new item
        const newItem = {
          id: Math.max(...prevList.map((item) => item.id)) + 1, // New ID
          name: "",
          qty: "",
        };

        // Insert new item right after the current item
        return [
          ...prevList.slice(0, currentIndex + 1), // Items before and including the current item
          newItem, // New item to be added
          ...prevList.slice(currentIndex + 1), // Items after the current item
        ];
      });
    }
    catch (error) {
      console.error("Error adding item:", error);
    }
  };

  // Function to delete an item from the list based on its ID
  const handleDeleteItem = (data) => {
    try {
      // Check if the data object is valid
      if (data !== null && data !== undefined) {
        const id = data.id;

        setItemList((prevList) => {
          // If there's more than one item, remove the selected one
          if (prevList.length > 1) {
            return prevList.filter((item) => item.id !== id);
          }

          // If only one item is left, reset to the initial state
          else if (prevList.length === 1) {
            return itemListInitial; // Assuming `itemListInitial` is your default list
          }

          return prevList; // Return the same list if no changes
        });
      }
      else {
        console.error("Invalid data object passed to handleDeleteItem.");
      }
    }
    catch (error) {
      console.error("Error deleting item:", error);
    }
  };


  // Reset
  const handleReset = () => {
    setSaleOrder(saleOrderInitial);
    setSaleOrderError(saleOrderErrorInitial);
    setItemList(itemListInitial);
    setItemListError(itemListErrorInitial);
    setIsOrderAvailable(false);
    clear();
    setIsEdit(false);
  };

  // Save
  const handleSubmit = async () => {
    try {
      dispatch(triggerLoader());

      const saleOrderErrors = await validateSaleOrder();
      const itemListErrors = await validateItemList();
      if (
        Object.keys(saleOrderErrors).length > 0 ||
        Object.keys(itemListErrors).length > 0 ||
        (isOrderAvailable === false && saleOrder.auto_generate === false)
      ) {
        // Handle the errors as needed (e.g., show error messages, prevent saving, etc.)
        return; // Return early if there are errors
      }

      saleOrder.items = await handleSaleOrderItemList();

      if (!isEdit) {
        const res = await configServ.createSaleOrder(saleOrder);
        if (res.success === true) {
          clear();
          handleReset();
          setIsChanged(!isChanged);
          handleOpenDialog(
            "Success",
            res.message || "Sales Order created successfully.",
            "success"
          );
        } else if (res.success === false) {
          handleOpenDialog(
            "Error",
            res.message || "Failed to create sales order.",
            "error"
          );
        } else {
          handleOpenDialog(
            "Error",
            res.message || "Failed to create sales order.",
            "error"
          );
        }
      } else {
        const res = await configServ.editSaleOrder(saleOrder);
        if (res.success === true) {
          clear();
          handleReset();
          setIsChanged(!isChanged);
          handleOpenDialog(
            "Success",
            res.message || "Sales Order updated successfully.",
            "success"
          );
        } else if (res.success === false) {
          handleOpenDialog(
            "Error",
            res.message || "Failed to update sales order.",
            "error"
          );
        } else {
          handleOpenDialog(
            "Error",
            res.message || "Failed to update sales order.",
            "error"
          );
        }
      }
    } catch (error) {
      console.log(error);
      handleOpenDialog("Error", "An unexpected error has occurred.", "error");
    } finally {
      dispatch(triggerLoader());
    }
  };

  const compareStateCodes = async () => {
    const dataToSend = {
      cust_id: saleOrder.customer_id,
      address: saleOrder.shiptoaddress,
    };
    const state = await configServ.getStateByCidAddress(dataToSend);
    const company = await configServ.company_details({
      company_id: company_id,
    });
    if (state.State && company.State) {
      if (Boolean(state.State.toLowerCase() === company.State.toLowerCase())) {
        setSaleOrder((state) => ({
          ...state,
          tax_type: "CGST+SGST",
        }));
      } else {
        setSaleOrder((state) => ({
          ...state,
          tax_type: "IGST",
        }));
      }
    }
  };

  const handleSaleOrderItemList = async () => {
    try {
      const saleOrderItems = itemList.map((item) => ({
        itemname: item.itemname,
        itemid: item.itemid,
        item_code: item.item_code,
        packagingunit: item.packagingunit,
        hsn_code: item.hsn_code,
        Taxrate: item.Taxrate,
        tax_amount: item.tax_amount,
        qty: item.qty,
        rate: item.rate,
        discount: item.discount,
        amount: item.amount,
      }));
      return saleOrderItems;
    } catch (error) {
      console.log(error);
    }
  };

  // Validate the Sale Order fields
  const validateSaleOrder = async () => {
    const errors = {};

    // Check if the required fields are empty
    if (!saleOrder.partyname) {
      errors.partyname = "Customer Name is required.";
    }
    if (!saleOrder.billtoaddress) {
      errors.billtoaddress = "Bill To Address is required.";
    }
    if (!saleOrder.shiptoaddress || saleOrder.shiptoaddress === "") {
      errors.shiptoaddress = "ShipTo Address is required.";
    }
    // if (!saleOrder.validitydate) {
    //     errors.validitydate = 'Validity Date is required.';
    // }
    // if (!saleOrder.delivery_date) {
    //     errors.delivery_date = 'PO Date is required.';
    // }
    // if (!saleOrder.po_number) {
    //     errors.po_number = 'PO no is required.';
    // }

    setSaleOrderError(errors);

    return errors;
  };

  // Validate ItemList
  const validateItemList = async () => {
    const errors = itemList.map((item) => ({
      itemname: !item.itemname ? "Item Name is required." : "",
      qty: !item.qty ? "Quantity is required." : "",
      // Add additional validations for other item fields as needed...
    }));

    setItemListError(errors);
    const hasErrors = errors.some((error) => error.itemname || error.qty);
    return hasErrors ? errors : {};
  };
  //#endregion

  return (
    <Box>
      <AlertDialog
        open={dialogOpen}
        setOpen={handleCloseDialog}
        title={title}
        message={dialogContent}
        buttonTitle="Ok"
        buttonType={buttonType}
      />

      <motion.div
        style={{
          width: "100%",
          backgroundColor: "#e3f3fc",
          padding: 20,
          borderRadius: "10px",
          border: "none",
          margin: "10px",
        }}
        initial={{ opacity: 0, y: 15 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.1, delay: 0.1 }}
      >
        {/* <Button onClick={abc}>abc</Button> */}
        <Grid
          container
          alignItems="flex-end"
          justifyContent="space-between"
          //sx={{ p: 10 }}
          spacing={1}
        >
          {/* Left side: PersonAddIcon */}
          <Grid item xs={6} md={6}></Grid>

          {/* Right side: Reset and Update buttons */}
          <Grid
            container
            item
            xs={6}
            md={6}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <Stack direction={"row"} spacing={1}>
              <IconButton
                onClick={handleReset}
                sx={{
                  borderRadius: 2,
                  px: 2, // Horizontal padding
                  py: 1, // Vertical padding
                  backgroundColor: "secondary.main", // Use theme secondary color
                  color: "white", // Icon color
                  "&:hover": {
                    backgroundColor: "secondary.dark", // Darker shade on hover
                  },
                  ml: 1, // Margin left to separate from other elements
                }}
                title="Reset"
              >
                <RotateLeftIcon sx={{ fontSize: 16 }} />
              </IconButton>

              <IconButton
                size="small"
                onClick={handleSubmit}
                sx={{
                  borderRadius: 2,
                  px: 2, // Horizontal padding
                  py: 1, // Vertical padding
                  backgroundColor: isEdit ? "success.main" : "primary.main", // Use theme primary color
                  color: "white", // Icon color
                  "&:hover": {
                    backgroundColor: isEdit ? "success.dark" : "primary.dark", // Darker shade on hover
                  },
                }}
                title={isEdit ? "Update" : "Save"}
              >
                {<SaveIcon sx={{ fontSize: 16 }} />}
              </IconButton>
            </Stack>
          </Grid>
        </Grid>

        {/* Next line of content starts here */}
        <Grid
          container
          alignItems="flex-end"
          justifyContent="flex-start"
          sx={{ px: 1 }}
          spacing={1}
        >
          <Grid item xs={12} sm={6} md={3}>
            <Stack direction={"column"}>
              <Link to="/account-master-entry/customers">
                <Tooltip title={"Add customer"}>
                  <PersonAddIcon
                    sx={{
                      cursor: "pointer",
                      color: "white",
                      backgroundColor: "grey",
                      margin: "2px",
                    }}
                    justifyContent="flex-end"
                  />
                </Tooltip>
              </Link>
              <Autocomplete
                id="grouped-demo"
                size="small"
                sx={{ backgroundColor: "white" }}
                value={customers.find(customer => customer.id === saleOrder.customer_id) || null}  // Find the selected customer by id
                options={customers}  // Pass the full customer objects as options
                getOptionLabel={(option) => option.fname && option.address ? `${option.fname} (${CheckAndReturn.checkTextNullAndUndefined(option.address)})` : `${option.fname}`}  // Ensure proper label display
                isOptionEqualToValue={(option, value) => option.id === value.id}  // Compare options by id
                onChange={(event, newValue) => {
                  if (newValue) {
                    handleCustomerAutocompleteChange(newValue.fname);  // Pass the selected customer's fname
                  } 
                  else {
                    handleCustomerAutocompleteChange('');  // Handle case where selection is cleared
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Customer Name"
                    fullWidth
                    size="small"
                    autoFocus
                    value={saleOrder.partyname || ''}  // Display the selected value in the input
                    onChange={handleCustomerInputChange}  // Handle manual input
                    error={!!saleOrderError.partyname}  // Handle validation errors
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        const value = e.target.value;
                        const selectedCustomer = customers.find(cust => cust.fname === value);
                        if (selectedCustomer) {
                          handleCustomerAutocompleteChange(selectedCustomer.fname);  // Set name when pressing Enter
                        }
                      }
                    }}
                  />
                )}
              />

            </Stack>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Bill To Address"
              variant="outlined"
              fullWidth
              size="small"
              name="billtoaddress"
              value={saleOrder.billtoaddress}
              onChange={handleSaleOrderCustomer}
              sx={{ backgroundColor: "white" }}
              error={!!saleOrderError.billtoaddress}
              InputLabelProps={{
                shrink: saleOrder.billtoaddress ? true : false,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">
                Ship To Address
              </InputLabel>
              <Select
                sx={{ backgroundColor: "white" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Ship To Address"
                name="shiptoaddress"
                value={saleOrder.shiptoaddress || ""}
                onChange={handleSaleOrderCustomer}
                error={!!saleOrderError.shiptoaddress}
              >
                <MenuItem value={""}>Select</MenuItem>
                {shipToAddresses.map((item) => (
                  <MenuItem key={item.id} value={item.address}>
                    {item.address}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <FormControlLabel
              control={<Checkbox />}
              label="Auto Generate"
              name="auto_generate"
              checked={!!saleOrder.auto_generate}
              onChange={handleSaleOrderCustomer}
            />
            <TextField
              variant="outlined"
              fullWidth
              size="small"
              label="Order No"
              placeholder="OR0001 (Sample)"
              sx={{ backgroundColor: "white" }}
              disabled={!!saleOrder.auto_generate}
              name="order_no"
              value={saleOrder.order_no}
              onChange={handleSaleOrderCustomer}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {saleOrder.auto_generate || isOrderAvailable ? (
                      <CheckCircleIcon color="success" />
                    ) : (
                      <CancelIcon color="error" />
                    )}
                  </InputAdornment>
                ),
              }}
              error={
                !!(
                  isOrderAvailable === false &&
                  saleOrder.auto_generate === false
                )
              }
            />
          </Grid>
        </Grid>

        <Grid
          container
          alignItems="flex-end"
          justifyContent="flex-start"
          style={{ padding: 10 }}
          spacing={1}
        >
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Order Type</InputLabel>
              <Select
                sx={{ backgroundColor: "white" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Order Type"
                name="order_type"
                value={saleOrder.order_type}
                onChange={handleSaleOrderCustomer}
              >
                <MenuItem value={"Primary"}>Primary</MenuItem>
                <MenuItem value={"Trial"}>Trial</MenuItem>
                <MenuItem value={"Supplementry"}>Supplementry</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Supplementary Order No"
              variant="outlined"
              fullWidth
              size="small"
              name="supplementry_orderno"
              value={saleOrder.supplementry_orderno}
              onChange={handleSaleOrderCustomer}
              sx={{ backgroundColor: "white" }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Validity Date"
              variant="outlined"
              type="date"
              fullWidth
              size="small"
              name="validitydate"
              value={saleOrder.validitydate}
              onChange={handleSaleOrderCustomer}
              sx={{ backgroundColor: "white" }}
              InputLabelProps={{ shrink: true }}
              error={!!saleOrderError.validitydate}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Salesperson/Ref. Name"
              variant="outlined"
              fullWidth
              size="small"
              name="salesperson"
              value={saleOrder.salesperson}
              onChange={handleSaleOrderCustomer}
              sx={{ backgroundColor: "white" }}
            />
          </Grid>
        </Grid>

        <Grid
          container
          alignItems="flex-end"
          justifyContent="flex-start"
          style={{ padding: 10 }}
          spacing={1}
        >
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Pay Terms"
              variant="outlined"
              fullWidth
              size="small"
              name="pay_terms"
              value={saleOrder.pay_terms}
              onChange={handleSaleOrderCustomer}
              sx={{ backgroundColor: "white" }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="PO Date"
              variant="outlined"
              type="date"
              fullWidth
              size="small"
              name="po_date"
              value={saleOrder.po_date}
              onChange={handleSaleOrderCustomer}
              sx={{ backgroundColor: "white" }}
              InputLabelProps={{ shrink: true }}
              error={!!saleOrderError.delivery_date}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              select
              label="Type"
              variant="outlined"
              fullWidth
              size="small"
              name="voucher_type"
              value={saleOrder.voucher_type}
              onChange={handleSaleOrderCustomer}
              sx={{ backgroundColor: "white" }}
              InputLabelProps={{
                shrink: true, // Ensures label does not overlap
              }}
            >
              <MenuItem value="order">Order</MenuItem>
              <MenuItem value="quotation">Quotation</MenuItem>
              <MenuItem value="proforma">Proforma</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="PO No."
              variant="outlined"
              fullWidth
              size="small"
              name="po_number"
              value={saleOrder.po_number}
              onChange={handleSaleOrderCustomer}
              sx={{ backgroundColor: "white" }}
              error={!!saleOrderError.po_number}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6} md={3}>
            <FormControlLabel
              disabled={!!(itemList[0].itemid !== "")}
              control={<Checkbox />}
              label="With GST"
              name="with_gst"
              checked={!!saleOrder.with_gst}
              onChange={handleSaleOrderCustomer}
            />
          </Grid> */}
          <Grid item xs={12} md={3}>
            <Stack direction={"column"}>
              <FormControlLabel
                disabled={!!(itemList[0].itemid !== "")}
                control={<Checkbox />}
                label="With GST"
                name="with_gst"
                checked={!!saleOrder.with_gst}
                onChange={handleSaleOrderCustomer}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    // Toggle the Checkbox value on Enter key press
                    handleSaleOrderCustomer({
                      target: { name: 'with_gst', value: 'on', type: 'checkbox', checked: !saleOrder.with_gst },
                    });
                  }
                }}
              />
              <Box
                sx={{
                  borderRadius: "5px",
                  backgroundColor: "white",
                  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)",
                }}
              >
                <FormControl component="fieldset">
                  <FormLabel
                    component="legend"
                    sx={{ fontWeight: "bolder", marginLeft: "20px" }}
                  >
                    Tax in
                  </FormLabel>
                  <RadioGroup
                    name="use-radio-group"
                    row
                    sx={{ marginLeft: "20px" }}
                  >
                    <FormControlLabel
                      checked={saleOrder.tax_type === "CGST+SGST"}
                      value="CGST_SGST"
                      label="CGST + SGST"
                      control={
                        <Radio
                          size="small"
                          onClick={() =>
                            setSaleOrder((prev) => ({
                              ...prev,
                              tax_type: "CGST+SGST",
                            }))
                          }
                        />
                      }
                    />
                    <FormControlLabel
                      checked={saleOrder.tax_type === "IGST"}
                      value="IGST"
                      label="IGST"
                      control={
                        <Radio
                          size="small"
                          onClick={() =>
                            setSaleOrder((prev) => ({
                              ...prev,
                              tax_type: "IGST",
                            }))
                          }
                        />
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Remarks"
              variant="outlined"
              placeholder="Leave a comment here"
              multiline
              rows={2}
              fullWidth
              size="small"
              name="remarks"
              value={saleOrder.remarks}
              onChange={handleSaleOrderCustomer}
              sx={{ backgroundColor: "white" }}
            />
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={6}
            md={3}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <FormControlLabel
              disabled={Boolean(!is_admin)}
              control={<Checkbox />}
              label="Approval Required"
              name="IsApproved"
              checked={!!saleOrder.IsApproved}
              onChange={handleSaleOrderCustomer}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  // Toggle the Checkbox value on Enter key press
                  handleSaleOrderCustomer({
                    target: { name: 'IsApproved', value: 'on', type: 'checkbox', checked: !saleOrder.IsApproved },
                  });
                }
              }}
            />
          </Grid>
        </Grid>

        <AddCardSales
          itemList={itemList}
          handleAutocompleteChange={handleAutocompleteChange}
          handleInputChange={handleInputChange}
          handleAddItem={handleAddItem}
          handleDeleteItem={handleDeleteItem}
          items={items}
          errors={itemListError}
          with_gst={saleOrder.with_gst}
        />

        <Grid container xs={12} md={12} alignItems={"center"}>
          <Grid container item xs={12} md={4}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography variant="body1" sx={{ color: "green" }}>
              Quantity: {totalQuantity? totalQuantity : 0}
            </Typography>
          </Grid>
          <Grid container item xs={12} md={4}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography variant="body1" sx={{ color: "green" }}>
              Tax: ₹{totalTax? totalTax : 0}
            </Typography>
          </Grid>
          <Grid container item xs={12} md={4}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography variant="body1" sx={{ color: "green" }}>
              Gross Amount: ₹{totalGrossAmount? totalGrossAmount : 0}
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={12}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <IconButton
              size="small"
              onClick={handleSubmit}
              sx={{
                borderRadius: 2,
                px: 2, // Horizontal padding
                py: 1, // Vertical padding
                backgroundColor: isEdit ? "success.main" : "primary.main", // Use theme primary color
                color: "white", // Icon color
                "&:hover": {
                  backgroundColor: isEdit ? "success.dark" : "primary.dark", // Darker shade on hover
                },
              }}
              title={isEdit ? "Update" : "Save"}
            >
              <SaveIcon sx={{ fontSize: 22 }} />
            </IconButton>
          </Grid>
        </Grid>
      </motion.div>
    </Box>
  );
}

export default memo(SalesOrderCard);
