import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import configServ from '../../../services/config';

import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    // DialogContentText,
    DialogActions,
    TextField,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    FormControlLabel,
    Checkbox,
    IconButton,
    Typography,
    Stack,
    Tooltip,
    Box,
    Tab,
} from '@mui/material'
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import EwayBill from './EwayBill/EwayBill';
import EInvoice from './EInvoice/EInvoice';

function EPopup({ handleClose, open, edata }) {

    const [formData, setFormData] = useState({})
    const [isEdit, setIsEdit] = useState(false)
    const [tabOpen, setTabOpen] = useState('ewaybill')

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData((state) => ({
            ...state,
            [name]: value
        }))
    }

    const handleChangeCheckbox = (e) => {
        const { name, checked } = e.target
        setFormData((state) => ({
            ...state,
            [name]: checked
        }))
    }

    const handleSubmit = async () => {
        console.log('submitted')
    }

    const clear = () => {
        setFormData({})
        // setEditData({})
        // setIsDone(false)
        setIsEdit(false)
    }

    const handleTabSwitch = (e, value) => {
        setTabOpen(value)
    }

    const abc = () => {
        console.log(formData)
    }


    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='lg'
                fullWidth={true}
                scroll='paper'
            >
                <DialogContent >
                    <TabContext value={tabOpen}>
                        <Stack
                            direction='row'
                            justifyContent='space-between'
                            alignItems='center'
                            sx={{
                                borderBottom: 1,
                                borderColor: 'divider'
                            }}
                        >
                            <TabList
                                onChange={handleTabSwitch}
                                value={tabOpen}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs example"
                            >
                                <Tab label="E-Way Bill" value={'ewaybill'} />
                                <Tab label="E-Invoice" value={'einvoice'} />
                            </TabList>
                            <IconButton onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Stack>
                        <TabPanel value={'ewaybill'} >
                            <EwayBill handleClose={handleClose} />
                        </TabPanel>
                        <TabPanel value={'einvoice'}>
                            <EInvoice handleClose={handleClose} edata={edata} />
                        </TabPanel>
                    </TabContext>
                </DialogContent>
                {/* <DialogActions>
                    <Stack
                        direction='row'
                        justifyContent={isEdit ? 'space-between' : 'flex-end'}
                        width='100%'
                        px={2}
                    >
                        <Box>
                            <Tooltip title='Reset'>
                                <IconButton onClick={clear} >
                                    <RotateLeftIcon />
                                </IconButton>
                            </Tooltip>
                            <Button onClick={() => { handleSubmit(); handleClose() }} autoFocus variant='contained'>
                                {isEdit ? 'Update' : 'Save'}
                            </Button>
                        </Box>
                    </Stack>
                </DialogActions> */}
            </Dialog>
        </>
    )
}

export default EPopup