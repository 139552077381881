import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Typography,
  IconButton,
  TextField,
  MenuItem,
  InputAdornment,
  Button,
  InputLabel,
  Select,
  FormControl,
  Box,
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Menu,
  Stack,
  Card,
  TablePagination,
} from "@mui/material";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import isBetween from "dayjs/plugin/isBetween";
import advancedFormat from "dayjs/plugin/advancedFormat";
import DownloadIcon from "@mui/icons-material/Download";
import SearchIcon from "@mui/icons-material/Search";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import dayjs from "dayjs";
import CardHeadMenu from "../../Card/CardHeadMenu/CardHeadMenu";
import { useDispatch, useSelector } from "react-redux";
import configServ from "../../../services/config";
import { LicenseInfo } from "@mui/x-license";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import Tooltip from "@mui/material/Tooltip";
import LoaderNew from "../../Loader/LoaderNew";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Swal from "sweetalert2";
import LoaderBackdrop from "../../Loader/LoaderBackdrop";
import SalesSummaryDialog1 from "./SalesSummaryDialog1";
import DownloadSalesSummaryPdf from "../../Download/DownloadSalesSummaryPdf ";

dayjs.extend(weekOfYear);
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(isBetween);
dayjs.extend(advancedFormat);

const SalesSummary = () => {
  LicenseInfo.setLicenseKey(
    "e0d9bb8070ce0054c9d9ecb6e82cb58fTz0wLEU9MzI0NzIxNDQwMDAwMDAsUz1wcmVtaXVtLExNPXBlcnBldHVhbCxLVj0y"
  );
  const { company_id, companyName } = useSelector(
    (state) => state.GlobalVariables
  );
  const [selectedDateRange, setSelectedDateRange] = useState([null, null]);
  const [salesOrders, setSalesOrders] = useState([]);
  const [filteredSalesOrders, setFilteredSalesOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [totalFilteredAmount, setTotalFilteredAmount] = useState(0);
  const [searchBy, setSearchBy] = useState("sales_Executive");
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const tableRef = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false); // Controls PDF generation state
  const [companyLogo, setCompanyLogo] = useState("");
  const [session, setSession] = useState("");
  const [selectedUserData, setSelectedUserData] = React.useState({
    customerId: null,
    companyId: null,
    startDate: null,
    endDate: null,
    sessionId: null,
  });
  const [openDialog, setOpenDialog] = React.useState(false);
  const [orderDetails, setOrderDetails] = React.useState(null); // Move orderDetails to parent
  const [orderItem, setOrderItem] = useState([]);
  const [expandedRow, setExpandedRow] = useState(null); // State to track expanded row
  const [sessions, setSessions] = useState([]);

  const headers = [
    "CUSTOMERS",
    "MOBILE NO.",
    "TOTAL INVOICES",
    "TOTAL AMOUNT",
    ...(!isGeneratingPDF ? ["Action"] : []),
  ];

  const handleDownloadPdf = () => {
    DownloadSalesSummaryPdf(paginatedSalesOrders, totalFilteredAmount,companyName,companyLogo,setOpenBackdrop);
  };

  const headerCellStyle = {
    fontSize: 14,
    fontWeight: "bold",
    backgroundColor: "#4CB5F5",
    color: "white",
  };

  const evenRowStyle = {
    background: "#f5f5f5",
  };

  const oddRowStyle = {
    background: "white",
  };

  const rowStyle = {
    "&:hover": {
      background: "#f1f9fe",
    },
  };

  // download excel
  const downloadExcel = () => {
    // Use filteredSalesOrders to only download filtered data
    setOpenBackdrop(true);
  
    // Map filtered sales orders to only include necessary fields for Excel
    const filteredData = filteredSalesOrders.map((order) => ({
      CUSTOMERS: order.name,
      MOBILE_NO: order.mobile,
      TOTAL_INVOICES: order.total_invoices,
      TOTAL_AMOUNT: formatCurrency(order.total_amount),
    }));
  
    // Create a new workbook and add a worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet([]); // Create an empty worksheet first
  
    // Add title and company name
    XLSX.utils.sheet_add_aoa(
      worksheet,
      [
        ["Sales Summary"], // Title
        [`Company Name: ${companyName}`], // Company Name
      ],
      { origin: "A1" } // Start from A1
    );
  
    // Add filtered data, starting from row 4 (after title and company name)
    XLSX.utils.sheet_add_json(worksheet, filteredData, { origin: "A4" });
  
    // Set column width for better readability
    const wscols = [
      { wch: 30 }, // CUSTOMERS
      { wch: 20 }, // MOBILE_NO
      { wch: 20 }, // TOTAL_INVOICES
      { wch: 20 }, // TOTAL_AMOUNT
    ];
    worksheet["!cols"] = wscols;
  
    // Append the worksheet to the workbook and download the Excel file
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sales Summary");
  
    const currentDate = new Date().toLocaleDateString().replace(/\//g, "-");
    XLSX.writeFile(workbook, `sales_summary_${currentDate}.xlsx`);
  
    // Success popup after Excel download
    setTimeout(() => {
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Your Excel file has been downloaded",
        showConfirmButton: false,
        timer: 1500,
      });
    }, 1000);
  
    setOpenBackdrop(false);
  };
  

  // Function to reset the date range
  const handleResetDateRange = () => {
    setSelectedDateRange([null, null]); // Reset the date range to null values
  };

  // Fetch the data when component mounts or date range changes
  useEffect(() => {
    const fetchSalesSummaryList = async () => {
      setLoading(true);
      try {
        const dataToSend = {
          company_id: company_id,
          start_date: selectedDateRange[0]
            ? selectedDateRange[0].format("YYYY-MM-DD")
            : "",
          end_date: selectedDateRange[1]
            ? selectedDateRange[1].format("YYYY-MM-DD")
            : "",
          session_id: session,
        };

        const res = await configServ.getSalesSummary(dataToSend);
        if (res.status === 200) {
          const data = res.data.map((item) => ({
            id: item.id,
            name: item.fname || "No data found",
            total_invoices: item.total_invoices || 0,
            mobile: item.mobile || "No data found",
            total_amount: item.total_amount || 0,
            customer_id: item.customer_id || "No data found",
          }));
          setSalesOrders(data);
          setCount(data.length);
        } else {
          setSalesOrders([]);
          setCount(0);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    fetchSalesSummaryList();
  }, [company_id, selectedDateRange, session]);

  // Filter sales orders based on search query
  useEffect(() => {
    const filteredOrders = salesOrders.filter((order) => {
      if (!searchQuery) return true;
      switch (searchBy) {
        case "sales_Executive":
          return order.name.toLowerCase().includes(searchQuery.toLowerCase());
        case "mobile":
          return order.mobile.toLowerCase().includes(searchQuery.toLowerCase());
        default:
          return true;
      }
    });
    setFilteredSalesOrders(filteredOrders);
  }, [salesOrders, searchQuery, searchBy]);


  // Paginated data
const paginatedSalesOrders = filteredSalesOrders.slice(
  page * rowsPerPage,
  page * rowsPerPage + rowsPerPage
);


  // Calculate the total for paginated sales orders
useEffect(() => {
  const total = paginatedSalesOrders.reduce((acc, order) => {
    const amount =
      typeof order.total_amount === "string"
        ? parseFloat(order.total_amount.replace(/[^0-9.-]+/g, ""))
        : order.total_amount;
    return acc + (isNaN(amount) ? 0 : amount);
  }, 0);
  setTotalFilteredAmount(total);
}, [paginatedSalesOrders]);

// Pagination controls
const handleChangePage = (event, newPage) => {
  setPage(newPage);
};

const handleChangeRowsPerPage = (event) => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
};



  const formatCurrency = (value) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 0, // Ensures no decimal places
      maximumFractionDigits: 0, // Ensures no decimal places
    }).format(value);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const assignCompanyLogo = async () => {
    try {
      const result = await configServ.company_logo({ company_id: company_id });
      if (result && result.image) {
        // Assuming the result.image is already a base64 string
        setCompanyLogo(`data:image/png;base64,${result.image}`);
      } else {
        setCompanyLogo(null); // Set as null if no logo
      }
    } catch (err) {
      console.log("Error fetching company logo:", err);
      setCompanyLogo(null); // Handle error by clearing logo
    }
  };

  useEffect(() => {
    assignCompanyLogo();
  }, []);
 

  const handleClickOpen = (
    customerId,
    companyId,
    startDate,
    endDate,
    sessionId
  ) => {
    setSelectedUserData({
      customerId,
      companyId,
      startDate,
      endDate,
      sessionId,
    });
    setOpenDialog(true); // Open dialog
  };
  const handleClose = () => {
    setOrderItem(null);
    setOrderDetails(null); // Reset orderDetails
    setOpenDialog(false);
    setExpandedRow(null);
    setSelectedUserData({
      customerId: null,
      companyId: null,
      startDate: null,
      endDate: null,
      sessionId: null,
    }); // Reset to object, not null
  };
  useEffect(() => {
    const fetchSessions = async () => {
      try {
        const dataToSend = {
          company_id: company_id,
        };
        const res = await configServ.getSessions(dataToSend);
        if (res.status === 200) {
          setSessions(res.data);
        } else {
          setSessions([]);
        }
      } catch (e) {
        console.log(e);
      }
    };

    fetchSessions();
  }, [company_id]);

  const handleSession = (params) => {
    const { name, value } = params.target;
    if (value === 0) {
      setSession(null);
    } else {
      setSession(value);
    }
  };

  return (
    <>
      <SalesSummaryDialog1
        open={openDialog}
        handleClose={handleClose}
        customerId={selectedUserData?.customerId}
        companyId={selectedUserData?.companyId}
        startDate={selectedUserData?.startDate}
        endDate={selectedUserData?.endDate}
        sessionId={selectedUserData?.sessionId}
        companyLogo={companyLogo}
        setOrderDetails={setOrderDetails} // Pass the setter function
        orderDetails={orderDetails} // Pass orderDetails as prop
        setOrderItem={setOrderItem}
        orderItem={orderItem}
        setExpandedRow={setExpandedRow}
        expandedRow={expandedRow}
      />

      <CardHeadMenu
        prefix={"Na"}
        suffix={"Na"}
        title={"Sales Summary"}
        showCreate={false}
      />
      <LoaderBackdrop open={openBackdrop} />
      <Grid
        container
        spacing={1} // Reduce spacing for tighter gap
        xs={12}
        alignItems="center"
        justifyContent={"space-between"}
        sx={{ flexDirection: { xs: "column", md: "row" } }}
      >
        {/* Left Section */}

        <Grid
          item
          xs={12} // Change to xs={12} to take full width on small devices
          md={4}
          container
          alignItems="center"
          justifyContent="flex-start"
          sx={{ flexDirection: "row" }}
        >
          <Grid item xs={10} md={6}>
            {" "}
            {/* Set to xs={8} to allow space for button */}
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              Sales Summary: {count}
            </Typography>
          </Grid>

          <Grid item xs={2} md={1} ml={1}>
            {" "}
            {/* Adjust width to fit button on small screens */}
            <Button
              variant="contained"
              color="primary"
              onClick={handleMenuClick}
              startIcon={<DownloadIcon sx={{ ml: 2 }} />}
              fullWidth // Make button full width for better UX on small screens
            />
            <Menu
              anchorEl={anchorEl}
              open={isMenuOpen}
              onClose={handleMenuClose}
            >
              <MenuItem
                onClick={() => {
                  downloadExcel();
                  handleMenuClose();
                }}
              >
                Excel Download
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleDownloadPdf();
                  handleMenuClose();
                }}
              >
                PDF Download
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>

        {/* Right Section */}
        <Grid
          item
          xs={12}
          md={8}
          container
          spacing={1} // Reduce spacing for tighter alignment
          alignItems="center"
          justifyContent="flex-start"
          sx={{ flexDirection: "row" }}
        >
          <Grid item xs={12} md={2}>
            <FormControl fullWidth size="small">
              <InputLabel variant="standard">Session</InputLabel>
              <Select
                fullWidth
                size="small"
                variant="standard"
                name="session"
                label="Session"
                value={session || ""}
                onChange={handleSession}
              >
                <MenuItem key={0} value={0}>
                  {"Select"}
                </MenuItem>
                {sessions.length > 0 &&
                  sessions.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.session}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6} md={2}>
            <FormControl fullWidth>
              <InputLabel id="searchBy-label">Search By</InputLabel>
              <Select
                fullWidth
                size="small"
                labelId="searchBy-label"
                id="searchBy"
                value={searchBy}
                onChange={(e) => setSearchBy(e.target.value)}
                label="Search By"
              >
                <MenuItem value="sales_Executive">CUSTOMERS</MenuItem>
                <MenuItem value="mobile">Mobile No.</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6} md={3}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              label="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={8} md={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateRangePicker
                startText="Start Date"
                endText="End Date"
                value={selectedDateRange}
                onChange={(newValue) => setSelectedDateRange(newValue)}
                renderInput={(startProps, endProps) => (
                  <TextField
                    {...startProps}
                    {...endProps}
                    fullWidth
                    size="small"
                    variant="outlined"
                    sx={{ height: 0 }} // Adjust the height of the date inputs
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={4} md={1} container justifyContent="center">
            <Tooltip title="Reset Date Range" placement="top">
              <Button
                variant="outlined"
                onClick={handleResetDateRange}
                sx={{
                  mt: 2,
                  backgroundColor: "green", // Set background color to green
                  color: "white", // Set text color to white
                  borderColor: "green",
                  "&:hover": {
                    backgroundColor: "darkgreen", // Change to dark green on hover
                    borderColor: "darkgreen", // Match border color to dark green on hover
                  },
                }}
                startIcon={<RotateLeftIcon sx={{ color: "white" }} />} // Change icon color to white
              ></Button>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>

      {/* table view */}
      <TableContainer
        component={Paper}
        sx={{
          display: { xs: "none", sm: "none", lg: "flex" }, // Corrected display logic
          flexDirection: "column",
          minHeight: "100vh", // Set full height
          mt: 2,
        }}
        ref={tableRef}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={headerCellStyle}>S.No.</TableCell>
              {headers.map((header, index) => (
                <TableCell key={index} sx={headerCellStyle}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow sx={{ width: "100%" }}>
                <TableCell
                  colSpan={6}
                  sx={{
                    textAlign: "center",
                    padding: 2,
                  }}
                >
                  <LoaderNew style={{ marginLeft: "200px" }} />
                </TableCell>
              </TableRow>
            ) : filteredSalesOrders.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6} sx={{ textAlign: "center" }}>
                  Data Not Found
                </TableCell>
              </TableRow>
            ) : (
              paginatedSalesOrders.map((row, index) => (
                <TableRow
                  key={row.id}
                  sx={{
                    ...rowStyle,
                    ...(index % 2 === 0 ? evenRowStyle : oddRowStyle),
                  }}
                >
                  <TableCell>{index + 1 + page * rowsPerPage}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.mobile}</TableCell>
                  <TableCell>{row.total_invoices}</TableCell>
                  <TableCell>{formatCurrency(row.total_amount)}</TableCell>
                  {!isGeneratingPDF && (
                    <TableCell>
                      <Stack direction={"row"}>
                        <Tooltip title="view" placement="top">
                          <IconButton
                            onClick={() => {
                              handleClickOpen(
                                row.customer_id, // Customer ID
                                company_id, // Company ID
                                selectedDateRange[0]
                                  ? selectedDateRange[0].format("YYYY-MM-DD")
                                  : "", // Start Date
                                selectedDateRange[1]
                                  ? selectedDateRange[1].format("YYYY-MM-DD")
                                  : "", // End Date
                                session // Session (if you have it available)
                              );
                            }}
                          >
                            <RemoveRedEyeIcon />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </TableCell>
                  )}
                </TableRow>
              ))
            )}

            {!loading && filteredSalesOrders.length > 0 && (
              <TableRow>
                <TableCell
                  colSpan={4}
                  sx={{ fontWeight: "bold", textAlign: "right" }}
                >
                  Total Amount:
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>
                  {formatCurrency(totalFilteredAmount)}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* mobile view card viewe */}
      <Grid
        container
        spacing={2}
        mt={3}
        sx={{ display: { xs: "flex", lg: "none" } }} // Visible only on mobile
      >
        {loading ? (
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "200px", // Optional: set a minimum height for better centering
            }}
          >
            <LoaderNew />
          </Grid>
        ) : filteredSalesOrders.length === 0 ? (
          <Typography align="center" sx={{ width: "100%" }} mt={3} color="red">
            Data Not Found
          </Typography>
        ) : (
          paginatedSalesOrders.map((row, index) => (
            <Grid item xs={12} key={row.id}>
              <Card sx={{ padding: 2, width: "100%" }}>
                <Typography variant="h6">
                  S.No. {index + 1 + page * rowsPerPage}
                </Typography>
                <Typography variant="body2">
                  <strong>Name:</strong> {row.name}
                </Typography>
                <Typography variant="body2">
                  <strong>Mobile:</strong> {row.mobile}
                </Typography>
                <Typography variant="body2">
                  <strong>Total Invoices:</strong> {row.total_invoices}
                </Typography>
                <Typography variant="body2">
                  <strong>Total Amount:</strong>{" "}
                  {formatCurrency(row.total_amount)}
                </Typography>
                {!isGeneratingPDF && (
                  <Stack direction={"row"}>
                    <Tooltip title="view" placement="top">
                      <IconButton
                        onClick={() => {
                          handleClickOpen(
                            row.customer_id,
                            company_id,
                            selectedDateRange[0]
                              ? selectedDateRange[0].format("YYYY-MM-DD")
                              : "",
                            selectedDateRange[1]
                              ? selectedDateRange[1].format("YYYY-MM-DD")
                              : "",
                            session
                          );
                        }}
                      >
                        <RemoveRedEyeIcon />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                )}
              </Card>
            </Grid>
          ))
        )}

        {/* Display total amount for mobile */}
        {!loading && filteredSalesOrders.length > 0 && (
          <Grid item xs={12}>
            <Card sx={{ padding: 2, fontWeight: "bold", width: "100%" }}>
              Total Amount: {formatCurrency(totalFilteredAmount)}
            </Card>
          </Grid>
        )}
      </Grid>

      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={filteredSalesOrders.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default SalesSummary;
