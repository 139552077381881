import React, { useState } from "react";
import BrandTable from "../Card/MaterEntryCards/BrandTable";
import BrandCard from "../Card/MaterEntryCards/BrandCard";
import { Stack, Paper } from "@mui/material";
import CardHeadMenu from "../Card/CardHeadMenu/CardHeadMenu";

//FUNCTION
const Brand = () => {
    //#region code
    const [isChanged, setIsChanged] = useState(false);
    const [editData, setEditData] = useState({});
    const [isCreate, setIsCreate] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const handleEdit = (data) => {
        setEditData(data)
        setIsCreate(true);
        setIsEdit(true);
    }

    const handlePopup = () => {
        setIsCreate(!isCreate);
    }

    //#endregion

    return (
        <>
            <CardHeadMenu
                prefix={"Na"}
                suffix={"Na"}
                title={"BRAND"}
                handlePopup={handlePopup}
                isEdit={isEdit}
                create={isCreate}
            />
            {isCreate &&
                <BrandCard isChanged={isChanged} setIsChanged={setIsChanged} editData={editData} setEditData={setEditData} />
            }
            <BrandTable isChanged={isChanged} handleEdit={handleEdit} />
        </>
    )
}
export default Brand