import React, { memo, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Box,
  Tooltip,
  CircularProgress,
  InputAdornment,
  TextField,
  Grid,
  FormControl,
  NativeSelect,
  InputLabel,
  Typography,
  IconButton,
} from "@mui/material";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import PrintTwoToneIcon from "@mui/icons-material/PrintTwoTone";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import SearchIcon from "@mui/icons-material/Search";
import Calculation from "../../utility/Calculations";
import configServ from "../../services/config";
import CheckAndReturn from "../../utility/CheckAndReturn";
import SearchBox from "../Search/SearchBox";
import LoaderNew from "../Loader/LoaderNew";
import { PlaylistAddCheck } from "@mui/icons-material";
import Swal from "sweetalert2";

//FUNCTION
const UnregisterPaymentTable = ({ handleEditData, isChanged }) => {
  //#region code
  const { user_id, company_id } = useSelector((state) => state.GlobalVariables);
  const [unRegisterPayments, setUnRegisterPayments] = useState([]);
  const [searchBy, setSearchBy] = useState("ledger_name");
  const [search, setSearch] = useState(null);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);
  const rowsPerPageOptions = [20, 50, 100, 200];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    fetchUnregisterPayments();
  }, [isChanged, page, rowsPerPage, search]);

  const fetchUnregisterPayments = async () => {
    try {
      const dataToSend = {
        company_id: company_id,
        search_by: searchBy,
        search: search,
        per_page: rowsPerPage,
        page: page,
      };
      const res = await configServ.getUnregisterPayments(dataToSend);
      if (res.status === 200) {
        setUnRegisterPayments(res.data);
        setCount(res.total);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const headers = [
    "NAME",
    "MODE",
    "Invoice No.",
    "AMOUNT",
    "TRANSACTION DATE",
    "STATUS",
    "ACTION",
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

  const headerCellStyle = {
    fontSize: 14, // Adjust the font size as needed
    fontWeight: "bold",
    backgroundColor: "#4CB5F5",
    color: "white",
  };

  const cellStyle = {
    py: 0,
    my: 0,
  };

  const evenRowStyle = {
    background: "#f5f5f5",
  };

  const oddRowStyle = {
    background: "white",
  };

  const rowStyle = {
    "&:hover": {
      background: "#f1f9fe",
    },
  };

  const handleEdit = (data) => {
    handleEditData(data);
  };

  const handleSearchBy = (event) => {
    try {
      setSearchBy(event.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const approvedHandle = async (row) => {
    console.log(row);
    Swal.fire({
      title: "Do you want to approve this payment?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Approve",
      denyButtonText: `Cancel`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const dataToSend = {
            // company_id: company_id, // Ensure `company_id` is available in scope
            customer_id: row.customer_id,
            id: row.id,
            user_id: user_id,
            paymentMode: row.payment_mode,
            amount: row.amount,
            company_id: company_id,
          };

          const res = await configServ.approvedUnregisterPayment(dataToSend); // Replace with your endpoint method

          if (res.status === 200) {
            Swal.fire(
              "Approved!",
              "Payment has been approved successfully.",
              "success"
            );
            // Optionally refresh data or update UI
            fetchUnregisterPayments();
          }
        } catch (error) {
          console.error("Error approving payment:", error);
          Swal.fire("Error", "Failed to approve the payment.", "error");
        }
      } else if (result.isDenied) {
        Swal.fire(
          "Action Cancelled",
          "Payment approval was not completed.",
          "info"
        );
      }
    });
  };

  //#endregion

  return (
    <>
      <Grid
        container
        alignItems="flex-end"
        justifyContent="flex-start"
        sx={{ py: 1, px: 2 }}
        spacing={1}
        direction={"row"}
      >
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
            Unregister Payments: {count}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box sx={{ minWidth: 120, marginLeft: "0px" }}>
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Search By
              </InputLabel>
              <NativeSelect
                defaultValue={"ledger_name"}
                onChange={handleSearchBy}
                inputProps={{
                  name: "searchBy",
                  id: "uncontrolled-native",
                }}
              >
                <option value={"ledger_name"}>Name</option>
              </NativeSelect>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <SearchBox search={search} setSearch={setSearch} />
        </Grid>
      </Grid>

      <Box>
        {/* Table View for Larger Screens */}
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <TableContainer component={Paper} elevation={0} sx={{ py: 1 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={headerCellStyle}>S.No.</TableCell>
                  {headers.map((header, index) => (
                    <TableCell key={index} style={headerCellStyle}>
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <Box textAlign={"center"}>
                        <LoaderNew />
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : unRegisterPayments.length > 0 ? (
                  unRegisterPayments.map((row, rowIndex) => (
                    <TableRow
                      key={rowIndex}
                      sx={{
                        ...rowStyle,
                        ...(rowIndex % 2 === 0 ? evenRowStyle : oddRowStyle),
                      }}
                    >
                      <TableCell sx={cellStyle}>
                        {page * rowsPerPage + rowIndex + 1}
                      </TableCell>
                      <TableCell sx={cellStyle}>{row.ledger_name}</TableCell>
                      <TableCell sx={cellStyle}>{row.payment_mode}</TableCell>
                      <TableCell sx={cellStyle}>{row.invoice_number || "no invoice found"}</TableCell>
                      <TableCell sx={cellStyle}>₹{row.amount}</TableCell>
                      <TableCell sx={cellStyle}>
                        {row.trans_date
                          ? CheckAndReturn.transformToDate2(
                              row.trans_date.toString()
                            )
                          : ""}
                      </TableCell>
                      <TableCell sx={cellStyle}>
                        {Boolean(row.payment_status) === true ? (
                          <Typography
                            variant="body1"
                            sx={{
                              color: "#4caf50", // Custom green color
                              fontWeight: 700,
                            }}
                          >
                            Approved
                          </Typography>
                        ) : (
                          <Typography
                            variant="body1"
                            color={"error"}
                            fontWeight={700}
                          >
                            Pending
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell sx={cellStyle}>
                        <Box
                          sx={{
                            borderRadius: 10,
                            width: "fit-content",
                            transition: "100ms",
                            cursor: "pointer",
                            padding: 0.5,
                          }}
                        >
                          <Tooltip title="Edit" placement="top">
                            <IconButton
                              disabled={Boolean(row.payment_status)} // Apply disabled to the IconButton
                              onClick={() => handleEdit(row)}
                            >
                              <EditTwoToneIcon color="primary" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Approve" placement="top">
                            <IconButton
                              disabled={Boolean(row.payment_status)}
                              onClick={() => approvedHandle(row)}
                            >
                              <PlaylistAddCheck color="primary" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <Box textAlign={"center"}>
                        <Typography variant="body1" color={"red"}>
                          No data found.
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 1 * emptyRows }}>
                    <TableCell colSpan={headers.length + 2} />
                  </TableRow>
                )}
              </TableBody>
            </Table>

            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Box>

        {/* Card View for Smaller Screens */}
        <Box sx={{ display: { xs: "block", md: "none" } }}>
          {loading ? (
            <Box textAlign="center">
              <LoaderNew />
            </Box>
          ) : unRegisterPayments.length > 0 ? (
            unRegisterPayments.map((row, index) => (
              <Paper
                key={index}
                elevation={3}
                sx={{
                  marginBottom: 2,
                  padding: 2,
                  borderRadius: 2,
                }}
              >
                <Typography variant="body1">
                  <strong>S.No:</strong> {page * rowsPerPage + index + 1}
                </Typography>
                <Typography variant="body1">
                  <strong>Ledger Name:</strong> {row.ledger_name}
                </Typography>
                <Typography variant="body1">
                  <strong>Payment Mode:</strong> {row.payment_mode}
                </Typography>
                <Typography variant="body1">
                  <strong>Invoice No.:</strong> {row.payment_mode}
                </Typography>
                <Typography variant="body1">
                  <strong>Amount:</strong> ₹{row.amount}
                </Typography>
                <Typography variant="body1">
                  <strong>Date:</strong>{" "}
                  {row.trans_date
                    ? CheckAndReturn.transformToDate2(row.trans_date.toString())
                    : ""}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: row.payment_status ? "#4caf50" : "error.main",
                    fontWeight: 700,
                  }}
                >
                  <strong>Status:</strong>{" "}
                  {row.payment_status ? "Approved" : "Pending"}
                </Typography>
                <Box mt={2}>
                  <Tooltip title="Edit">
                    <IconButton
                      disabled={Boolean(row.payment_status)}
                      onClick={() => handleEdit(row)}
                    >
                      <EditTwoToneIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Approve">
                    <IconButton
                      disabled={Boolean(row.payment_status)}
                      onClick={() => approvedHandle(row)}
                    >
                      <PlaylistAddCheck color="primary" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Paper>
            ))
          ) : (
            <Box textAlign="center">
              <Typography variant="body1" color="red">
                No data found.
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default memo(UnregisterPaymentTable);
