import React, { memo, useEffect, useState } from "react";
import QRCodeCanvas from "qrcode.react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardMedia,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import Carousel from "react-material-ui-carousel";
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { addItem, removeItem } from "../../../redux/reducers/Cart";
import ReactReadMoreReadLess from "react-read-more-read-less";
import Calculation from "../../../utility/Calculations";
import { DisabledByDefault } from "@mui/icons-material";

const ProductDetailsDialogs = ({ open, handleClose, dataDialog }) => {
  const { currency } = useSelector((state) => state.GlobalVariables);
  const cartValue = useSelector((state) => state.cart.cartValue);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // State variables
  const [images, setImages] = useState([]);
  const [itemDetails, setItemDetails] = useState(null);
  const [isInCart, setIsInCart] = useState(false);
  const [formData, setFormData] = useState({});
  const [qrCode, setQrCode] = useState("");
  const [image, setImage] = useState(
    "https://dummyimage.com/400x400/7e8eff/ffffff&text=Product+Image"
  );

  // Handle form data changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === "size_id" && itemDetails?.size) {
      const selectedSize = itemDetails.size.find((item) => item.id === value);
      setFormData((prev) => ({
        ...prev,
        item_size: selectedSize?.size || "",
      }));
    }
  };

  // Populate dialog data
  useEffect(() => {
    if (dataDialog) {
      const { image: images, qr_code, id } = dataDialog;
      setImages(images || []);
      setItemDetails(dataDialog);
      setQrCode(`http://demo.d-crm.in/product-info?itemId=${id}&uuid=${qr_code}`);
    }
  }, [dataDialog]);

  // Check if item is already in the cart
  useEffect(() => {
    const inCart = cartValue?.find((item) => item.id === dataDialog?.id);
    setIsInCart(Boolean(inCart));
  }, [cartValue, dataDialog]);

  // Handle adding/removing items from cart
  const manageCart = () => {
    if (!itemDetails) return;

    const dataToSend = {
      ...formData,
      id: itemDetails.id,
      company_id: itemDetails.company_id,
      itemname: itemDetails.item_name,
      hsn_code: itemDetails.hsncode,
      Taxrate: itemDetails.tax_rate,
      discount: itemDetails.discount,
      rate: itemDetails.rate_unit,
      item_code: itemDetails.item_code,
      issizeAdded: Boolean(itemDetails.issizeAdded),
    };

    if (!isInCart) {
      if (itemDetails.issizeAdded && !formData.item_size) {
        alert("Please select a size for the item");
        return;
      }
      dispatch(addItem(dataToSend));
    } else {
      dispatch(removeItem(itemDetails.id));
    }
  };

  const handleProductClose = () => {
    try {
      handleClose();
      setItemDetails(null);
      setImages([]);
      setIsInCart(false);
      setFormData({});
      setQrCode("");
      setImage(
        "https://dummyimage.com/400x400/7e8eff/ffffff&text=Product+Image"
      );
    }
    catch (e) {
      console.log(e);
    }
  }


  return (
    <Dialog open={open} onClose={handleProductClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        <Box display={'flex'} justifyContent={'flex-end'}>
          <IconButton onClick={handleProductClose} sx={{ fontSize: '36px' }}>
            <DisabledByDefault color="error" fontSize="36px" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container alignItems="center" spacing={3}>
          {/* Image Carousel Section */}
          <Grid item xs={12} md={6}>
            <Carousel
              autoPlay
              infiniteLoop
              showThumbs={false}
              NextIcon={<NavigateNextOutlinedIcon />}
              PrevIcon={<ArrowBackIosNewOutlinedIcon />}
            >
              {images?.length > 0 ? (
                images.map((img, index) => (
                  <Card key={index}>
                    <CardMedia
                      component="img"
                      alt="Product Image"
                      height="400px"
                      image={`data:image/png;base64,${img.image}`}
                      style={{ objectFit: "contain" }}
                    />
                  </Card>
                ))
              ) : (
                <Card>
                  <CardMedia
                    component="img"
                    alt="Default Image"
                    height="400px"
                    image={image}
                    style={{ objectFit: "contain" }}
                  />
                </Card>
              )}
            </Carousel>
          </Grid>

          {/* Details Section */}
          <Grid item xs={12} md={6}>
            <Grid container spacing={2} sx={{ py: 1, px:3, background: "#e3f3fc" }}>
              {/* Price Details */}
              <Grid item xs={12}>
                <Typography variant="h6">
                  <b>PRICE DETAILS</b>
                </Typography>
              </Grid>
              <Divider sx={{ width: "100%" }} />
              <Grid item xs={12}>
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                  <Typography variant="body1">Item Name</Typography>
                  <Typography variant="body1">
                    {itemDetails?.item_name || "N/A"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                  <Typography variant="body1">Brand</Typography>
                  <Typography variant="body1">{itemDetails?.brand || "N/A"}</Typography>
                </Box>
              </Grid>
              {/* Additional Details */}
              <Grid item xs={12}>
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                  <Typography variant="body1">Category</Typography>
                  <Typography variant="body1">
                    {itemDetails?.category || "N/A"}
                  </Typography>
                </Box>
              </Grid>
              <Divider sx={{ width: "100%" }} />
              <Grid item xs={12}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="body1">Price</Typography>
                  <Typography variant="body1">
                    ₹{itemDetails?.rate_unit || 0}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="body1">Discount</Typography>
                  <Typography variant="body1">
                    -
                      ₹
                      {Calculation.discountAmount(
                        itemDetails?.rate_unit || 0,
                        itemDetails?.discount || 0
                      )}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="body1">GST</Typography>
                  <Typography variant="body1">
                    +
                      ₹
                      {Calculation.taxAmount(
                        itemDetails?.rate_unit || 0,
                        itemDetails?.discount || 0,
                        itemDetails?.tax_rate || 0
                      )}
                  </Typography>
                </Box>
              </Grid>
              <Divider
                sx={{ backgroundColor: "#33333377", width: "100%" }}
              />
              <Grid item xs={12}>
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                  <Typography variant="body1">Total Amount</Typography>
                  <Typography variant="body1">
                    ₹
                    {Calculation.netAmount(
                      itemDetails?.rate_unit || 0,
                      itemDetails?.discount || 0,
                      itemDetails?.tax_rate || 0
                    )}
                  </Typography>
                </Box>
              </Grid>
              {/* Buttons */}
              <Grid container item justifyContent={'center'}>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    onClick={manageCart}
                    variant="contained"
                    color={isInCart ? "error" : "primary"}
                  >
                    {isInCart ? "Remove" : "Add to Cart"}
                  </Button>
                </Grid>
              </Grid>
              {/* {qrCode && (
                <Box mt={2} display="flex" justifyContent="center">
                  <QRCodeCanvas value={qrCode} />
                </Box>
              )} */}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default memo(ProductDetailsDialogs);

