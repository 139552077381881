import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import CustHeaderReBuild from "../../Components/header/Header/CustHeaderReBuild";
import CustomerSideBar from "../../Components/sidebar/CustomerSideBar"; // Sidebar for larger screens
import CustomerSideBarReBuild from "../../Components/sidebar/CustomerSideBarReBuild"; // Sidebar for smaller screens
import { SessionVerification } from "../../Components/SessionManagement/SessionManagement";
import { Box, Paper, Stack } from '@mui/material';
import Footer from "../../Components/Footer/Footer";
import { useSelector } from "react-redux";

const UserLayout = () => {
  //#region code
  const open = useSelector((state) => state.sideExpand.open);

  // State to manage sidebar visibility for the smaller screen version
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // Function to toggle the sidebar on smaller screens
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  //#endregion

  return (
    <>
      {/* Verify session */}
      <SessionVerification />

      {/* Header with toggleSidebar function */}
      <CustHeaderReBuild toggleSidebar={toggleSidebar} />

      {/* Sidebar for larger screens */}
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <CustomerSideBar toggleSidebar={toggleSidebar}/>
      </Box>

      {/* Sidebar for smaller screens */}
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <CustomerSideBarReBuild open={isSidebarOpen} toggleSidebar={toggleSidebar} />
      </Box>

      <Box
        sx={{
          marginLeft: {
            xs: 2,
            sm: 4,
            md: 8,
          },
          ...(open && { marginLeft: {xs: 0, sm:0, md:33} }),
          transition: "200ms",
          minHeight: "77vh", // This ensures the content takes up enough space
        }}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Paper
            elevation={0}
            sx={{
              width: "100%",
              mx: {xs:0, md:5},
              my: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              px: {xs:0, md:3},
              pt: 0,
              pb: 5,
            }}
          >
            <Outlet />
          </Paper>
        </Stack>

        {/* Full Width Footer */}
        {/* <Footer /> */}
      </Box>
    </>
  );
};

export default UserLayout;
