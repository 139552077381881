import React, { useEffect, useState } from "react";
import DashboardCard from "../../Components/Card/DashboardCard";
import { useSelector } from "react-redux";
import { CircularProgress, Grid, Button } from "@mui/material";
import configServ from "../../services/config";
// icons
import PeopleIcon from "@mui/icons-material/People";
import DescriptionIcon from "@mui/icons-material/Description";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import BalanceIcon from "@mui/icons-material/Balance";
import { useNavigate } from "react-router-dom";
import CheckAndReturn from "../../utility/CheckAndReturn";
import FiberNewIcon from "@mui/icons-material/FiberNew";

import BarGraph from "./BarGraph";
import VoiceCommand from "../../Components/VoiceRecognization/VoiceCommand ";

//FUNCTION
function Dashboard() {
  //#region code
  const { user_id, company_id } = useSelector((state) => state.GlobalVariables);
  const navigate = useNavigate();

  const [count, setCount] = useState({});

  const fetchDashboardCount = async () => {
    try {
      const userId = user_id;
      if (userId === null) {
        navigate("/");
      }
      const dataToSend = {
        user_id: user_id,
        company_id: company_id,
      };
      const result = await configServ.getDashboardCount(dataToSend);
      setCount(result);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchDashboardCount();
  }, []);

  // const handleNavigation = (navigate, link,) => {
  //   try {
  //     console.log("Navigating to:", link, "with state:"); // Debugging
  //     if (link) {
  //       navigate(link);
  //     } else {
  //       console.error("Navigation link is missing!");
  //     }
  //   } catch (e) {
  //     console.error("Error during navigation:", e);
  //   }
  // };

  // const handleNavigation = () => {
  //   alert("hello")
  //   const state = { isApprovedLocation: true, defaultStatus: "processing" };
  //   console.log("Navigating with state:", state);
  //   navigate("/sales-order", { state });
  // };

  const cardData = [
    {
      title: "CUSTOMERS",
      number: count.subscription ?? <CircularProgress />,
      icon: <PeopleIcon sx={{ color: "#fb6340" }} />,
      link: "/account-master-entry/customers",
    },
    {
      title: "ORDERS",
      number: count.orders ?? <CircularProgress />,
      icon: <DescriptionIcon sx={{ color: "#fb6340" }} />,
      link: "/sales-order",
    },
    {
      title: "PENDING ORDERS",
      number: count.pending_orders ?? <CircularProgress />,
      icon: <DescriptionIcon sx={{ color: "#fb6340" }} />,
      link: "/sales-order",
      // onClick: handleNavigation, // Attach the navigation function
    },
    {
      title: "NEW ORDERS",
      number: count.new_orders ?? <CircularProgress />,
      icon: <FiberNewIcon sx={{ color: "#fb6340" }} />,
      link: "/sales-order",
    },
    {
      title: "INVOICES",
      number: count.invoice ?? <CircularProgress />,
      icon: <DescriptionIcon sx={{ color: "#fb6340" }} />,
      link: "/invoice",
    },
    {
      title: "PAID",
      number: count.paid ?? <CircularProgress />,
      icon: <AttachMoneyIcon sx={{ color: "#fb6340" }} />,
    },
    {
      title: "UNPAID",
      number: count.unpaid ?? <CircularProgress />,
      icon: <MoneyOffIcon sx={{ color: "#fb6340" }} />,
    },
    {
      title: "OUTSTANDING",
      number:
        count.outstanding || count.outstanding === 0 ? (
          `₹${CheckAndReturn.makePositive(
            CheckAndReturn.roundToInteger(count.outstanding ?? 0)
          )}`
        ) : (
          <CircularProgress />
        ),
      icon: <BalanceIcon sx={{ color: "#fb6340" }} />,
      link: "/report/outstanding-report",
    },
  ];
  //#endregion

  return (
    <>
      <Grid
        container
        alignItems={"center"}
        justifyContent={"flex-start"}
        spacing={2}
        p={2}
        my={1}
        sx={{
          backgroundColor: "#6270e4",
          width: "100%",
        }}
      >
        {cardData.map((item, index) => (
          <DashboardCard index={index} data={item} onClick={item.onClick} />
        ))}
      </Grid>
      {/* <BarGraph /> */}
      {/* <VoiceCommand /> */}
      {/* <Button
        onClick={handleNavigation}
        sx={{ pointerEvents: "auto", cursor: "pointer" }}
      >
        krishna
      </Button> */}
    </>
  );
}

export default Dashboard;
