import React from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import Calculation from '../../utility/Calculations';
import CheckAndReturn from '../../utility/CheckAndReturn';

const DownloadOutstandingPdf = async (reportData) => {
    const date = Calculation._GetCurrentDate();
    const headers = ['NAME', 'ADDRESS', 'PHONE', 'INV AMOUNT', 'PAYMENT', 'RECEIPT', 'DUE AMOUNT'];

    const data = reportData.map(item => [
        item.customer_name ?? '',
        item.address ?? '',
        item.phone ?? '',
        (CheckAndReturn.makePositive(CheckAndReturn.roundToInteger(item.total_invoice_amount ?? 0))).toString(),
        (CheckAndReturn.makePositive(CheckAndReturn.roundToInteger(item.total_payment_amount ?? 0))).toString(),
        (CheckAndReturn.makePositive(CheckAndReturn.roundToInteger(item.total_receipt_amount ?? 0))).toString(),
        (CheckAndReturn.makePositive(CheckAndReturn.roundToInteger(item.due_amount ?? 0))).toString()
    ]);

    // Calculate totals
    const totalInvoiceAmount = reportData.reduce((sum, item) => sum + (Number(item.total_invoice_amount ?? 0)), 0);
    const totalPaymentAmount = reportData.reduce((sum, item) => sum + (Number(item.total_payment_amount ?? 0)), 0);
    const totalReceiptAmount = reportData.reduce((sum, item) => sum + (Number(item.total_receipt_amount ?? 0)), 0);
    const totalDueAmount = reportData.reduce((sum, item) => sum + (Number(item.due_amount ?? 0)), 0);

    // PDF
    const doc = new jsPDF();
    doc.autoTable({
        head: [headers],
        body: data
    });

    // Add totals paragraph
    const finalY = doc.autoTable.previous.finalY;
    doc.setFontSize(12);
    doc.text(`Invoice: Rs.${CheckAndReturn.makePositive(CheckAndReturn.roundToInteger(totalInvoiceAmount??0))}`, 14, finalY + 10);
    doc.text(`Payment: Rs.${CheckAndReturn.makePositive(CheckAndReturn.roundToInteger(totalPaymentAmount??0))}`, 14, finalY + 20);
    doc.text(`Receipt: Rs.${CheckAndReturn.makePositive(CheckAndReturn.roundToInteger(totalReceiptAmount??0))}`, 14, finalY + 30);
    doc.text(`Dues Amount: Rs.${CheckAndReturn.makePositive(CheckAndReturn.roundToInteger(totalDueAmount??0))}`, 14, finalY + 40);

    doc.save('OutstandingReport_' + date + '.pdf');
};

export default DownloadOutstandingPdf;
