import React, { memo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, IconButton, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import PurchaseBillItemTable from './PurchaseBillItemTable';
import useDeviceDetect from '../../../Detect/useDeviceDetect ';
import PurchaseBillItemsCard from './PurchaseBillItemsCard';

//FUNCTION
const PurchaseBillItemsPopup = memo(({ openPurchaseBillItems, handlePurchaseBillItemsClose, purchaseBillItems }) => {
    const { isMobile } = useDeviceDetect();

    return (
        <>
            <Dialog
                open={openPurchaseBillItems}
                onClose={handlePurchaseBillItemsClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={false}
                PaperProps={{
                    sx: {
                        width: '80%',
                        height: '80%',
                    },
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box flex={1} textAlign="center">
                            <Typography variant="h5" color="#1e61b4" fontWeight={700}>
                                Purchase Bill Items
                            </Typography>
                        </Box>
                        <Box position="absolute" right={16}>
                            <IconButton onClick={handlePurchaseBillItemsClose}>
                                <DisabledByDefaultIcon color="error" />
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    {
                        isMobile === true ?
                            <Box component={Paper} sx={{ padding: '16px', backgroundColor: '#fff', overflowY: 'auto', maxHeight: '80vh' }}>
                                {purchaseBillItems.length > 0 ? (
                                    <PurchaseBillItemsCard purchaseBillItems={purchaseBillItems} />
                                ) : (
                                    <Typography variant="h6" align="center" color="red">
                                        No items found.
                                    </Typography>
                                )}
                            </Box>
                            :
                            <PurchaseBillItemTable purchaseBillItems={purchaseBillItems} />
                    }
                </DialogContent>
            </Dialog>
        </>
    )
});

export default memo(PurchaseBillItemsPopup);
