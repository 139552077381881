import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    company_type: ''
}

export const AdminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        assignType: (state, action) => {
            state.company_type = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { assignType } = AdminSlice.actions

export default AdminSlice.reducer