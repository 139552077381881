import React from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useNavigate } from 'react-router-dom';

const CartEmpty = () => {
  const navigate = useNavigate();

  const handleContinue = () => {
    navigate('/product'); // Navigate to the shop or product page
  };

  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="70vh"
        textAlign="center"
      >
        <ShoppingCartIcon sx={{ fontSize: 100, color: '#ccc' }} />
        <Typography variant="h5" gutterBottom sx={{ mt: 2, color: '#555' }}>
          Your Cart is Empty
        </Typography>
        <Typography variant="body1" color="textSecondary" sx={{ mb: 3 }}>
          Looks like you haven't added anything to your cart yet.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleContinue}
        >
          Continue
        </Button>
      </Box>
    </Container>
  );
};

export default CartEmpty;
