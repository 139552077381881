import React, { useState, useEffect } from "react";
import { styled } from "@mui/system";
import { Card, CardMedia, Grid, Typography } from "@mui/material";
//import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import Carousel from 'react-material-ui-carousel';
import configServ from "../../services/config";
import productImage from "../../Components/Assets/images/product.jpg";
import defaultImage from "../../Components/Assets/images/defaultImage.png";

const Wrapper = styled("div")`
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: url(${productImage});
  background-size: cover;
  background-position: center;
`;

const ProductContainer = styled(Grid)`
  width: 60rem;
  min-height: 25rem;
  max-height: 90vh;
  overflow: hidden;
  margin: 2rem;
  border-radius: 10px;
  overflow-y: auto; /* Add this line to make it scrollable */;
  background-color: #FEFEF1;
`;

const RightContentContainer = styled(Grid)`
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
`;

const LeftContentContainer = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProductInfo = () => {
  const [productData, setProductData] = useState({});

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const itemId = urlParams.get("itemId");

    const fetchData = async () => {
      try {
        const data = await configServ.getitemdetail({ id: itemId });
        const itemImages = await configServ.get_item_img({ product_id: data[0].id });

        setProductData({
          productName: data[0].item_name,
          brand: data[0].brand,
          price: data[0].costprice,
          description: data[0].description,
          imageUrls: itemImages.data.map((image) => `data:image/png;base64,${image.image}`),
        });
      } catch (error) {
        console.error("Error in fetchData:", error);
      }
    };

    if (itemId) {
      fetchData();
    }
  }, []);

  const getImageSources = () => {
    if (!productData.imageUrls || productData.imageUrls.length === 0) {
      return [defaultImage];
    } else {
      return productData.imageUrls;
    }
  };

  return (
    <Wrapper>
      <ProductContainer container>
        {/* Right Content (Product Image Carousel) */}
        <LeftContentContainer item xs={12} md={6}>
          <Carousel
            autoPlay infiniteLoop transitionTime={500} showThumbs={false}
            sx={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              flex: 2
            }}
            NextIcon={<NavigateNextOutlinedIcon />}
            PrevIcon={<ArrowBackIosNewOutlinedIcon />}
          >
            {getImageSources().map((imageSrc, index) => (
              <Card>
                <CardMedia
                  component="img"
                  alt='product'
                  height='500px'
                  image={imageSrc}
                  style={{ objectFit: 'contain', marginTop: '10px' }}
                />
              </Card>
              // <div key={index} style={{ marginTop: '20px', marginLeft: '20px' }}>
              //   <img alt={`Product Image ${index + 1}`} src={imageSrc} style={{ objectFit: 'contain' }} />
              // </div>
            ))}
          </Carousel>
        </LeftContentContainer>

        {/* Left Content (Product Info) */}
        <RightContentContainer item xs={12} md={6} spacing={0}>
          <Typography variant="h4" align="center" style={{ margin: '1rem' }}>
            <b>Product Info</b>
          </Typography>
          <br />
          <Typography variant="caption" display="block" color={'blue'} sx={{ marginLeft: '1rem', padding: 0, fontSize: '14px' }} gutterBottom>
            Product Name
          </Typography>
          <Typography variant="h4" display="block" style={{ marginLeft: '1rem', padding: 0 }}>
            {productData.productName}
          </Typography>
          <br />
          <Typography variant="caption" display="block" color={'blue'} sx={{ marginLeft: '1rem', padding: 0, fontSize: '14px' }} gutterBottom>
            Brand
          </Typography>
          <Typography variant="h5" display="block" style={{ marginLeft: '1rem', padding: 0 }}>
            {productData.brand}
          </Typography>
          <br />
          <Typography variant="caption" display="block" color={'blue'} sx={{ marginLeft: '1rem', padding: 0, fontSize: '14px' }} gutterBottom>
            Price
          </Typography>
          <Typography variant="h6" display="block" color={'#118C4F'} style={{ marginTop: 0, marginRight: '1rem', marginLeft: '1rem' }}>
            ₹{productData.price}
          </Typography>
          <br />
          <Typography variant="caption" display="block" color={'blue'} sx={{ marginLeft: '1rem', padding: 0, fontSize: '14px' }} gutterBottom>
            Description
          </Typography>
          <Typography variant="body2" gutterBottom style={{ marginTop: 0, marginRight: '1rem', marginLeft: '1rem' }}>
            {productData.description}
          </Typography>
        </RightContentContainer>
      </ProductContainer>
    </Wrapper>
  );
};

export default ProductInfo;
