import React from "react";
import "./LoaderBackdrop.css";

const LoaderBackdrop = ({ open }) => {
  return (
    <div className={`backdrop ${open ? "open" : ""}`}>
      <div className="loading">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

export default LoaderBackdrop;
