import React, { useEffect, useState } from "react";
import { Container } from 'react-bootstrap';
import { Stack, Paper, Button } from "@mui/material";
import PurchaseBillCard from "../../../Components/Card/TransactionCards/PurchaseBill/PurchaseBillCard";
import PurchaseBillTable from "../../../Components/Card/TransactionCards/PurchaseBill/PurchaseBillTable";

//FUNCTION
const PurchaseBill = () => {
    //#region code
    const [openPopup, setOpenPopup] = useState(true);
    const [editData, setEditData] = useState({});
    const [isChanged, setIsChanged] = useState(false);

    const handleEdit = (data) => {
        setEditData(data[0]);
    }
    //#endregion

    return (
        <>
            <Container>
                <PurchaseBillCard editData={editData} setEditData={setEditData} isChanged={isChanged} setIsChanged={setIsChanged} />
            </Container>

            <Stack direction='column' justifyContent='flex-start' width='100%' my={2}>
                <PurchaseBillTable handleEditData={handleEdit} isChanged={isChanged} />
            </Stack>
        </>
    )
}

export default PurchaseBill;