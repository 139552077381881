import React, { useState, useEffect } from "react";
// import { Form, Row, Col, FormCheck, Button} from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import "./Login.css";
import http from "../../services/http";
import { useDispatch, useSelector } from "react-redux";
import { triggerLoader } from "../../redux/reducers/LoaderTrigger";
import Cookies from "universal-cookie/cjs/Cookies";
import { decodeToken } from "react-jwt";
import AlertDialog from "../../Components/AlertDialog/AlertDialog";
import LoginIcon from "@mui/icons-material/Login";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";
import {
  Box,
  Typography,
  Container,
  TextField,
  FormControlLabel,
  Grid,
  Checkbox,
  Button,
  CircularProgress,
  InputAdornment,
  IconButton,
} from "@mui/material";
import configServ from "../../services/config";
import { assignType } from "../../redux/reducers/Admin";
import {
  rSetAddress,
  rSetCompanyId,
  rSetCompanyName,
  rSetCurrency,
  rSetEmail,
  rSetId,
  rSetIsAdmin,
  rSetIsSuper,
  rSetLogin,
  rSetName,
  rSetToken,
  rSetUserId,
  rSetUserName,
} from "../../redux/reducers/GlobalVariables";
import { decrypt, encrypt } from "../../redux/encryption";

const Login = () => {
  //#region code
  const lightMode = useSelector((state) => state.GlobalVariables.lightMode);
  const [validationErrors, setValidationErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const { isUserAdmin } = useSelector((state) => state.user);
  const [title, setTitle] = useState("Alert");
  const [dialogContent, setDialogContent] = useState("");
  const [buttonType, setButtonType] = useState(null);
  const [rememberMe, setRememberMe] = useState(false);
  const [location, setLocation] = useState(null);
  const [weatherData, setWeatherData] = useState(null);
  const API_KEY = process.env.REACT_APP_API_KEY;

  console.log("API Key:", API_KEY);
  
  const [formData, setFormData] = useState({});

  // Location detect karna
  useEffect(() => {
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setLocation({ latitude, longitude });
          },
          (error) => {
            console.error("Error getting location:", error);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    };
    console.log("location=", location);

    getLocation();
  }, []);
  
  const fetchLocation = async () => {
    try {
      const { latitude, longitude } = location;
      const response = await fetch(
        `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=${API_KEY}`
      );
      const data = await response.json();
      setWeatherData(data.name);
    } catch (error) {
      console.error("Error fetching weather data:", error);
    }
  };

  useEffect(() => {
    if (location) {
      // fetchLocation();
    }
  }, [location, API_KEY]);

  const handleOpenDialog = (heading, content, type) => {
    setTitle(heading);
    setDialogContent(content);
    setButtonType(type);
    setLoginError(true);
  };

  const handleCloseDialog = () => {
    setLoginError(false);
    setTitle("Alert");
    setDialogContent("");
    setButtonType(null);
  };

  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cookie = new Cookies();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const logout = () => {
    //persistor.purge(); // This will remove all data persisted by redux-persist
    localStorage.removeItem("persist:dcrm");
    localStorage.clear();
  };

  useEffect(() => {
    const data = cookie.get("dcrm_login");
    if (data) {
      const dcrm_login = decrypt(data);
      if (dcrm_login) {
        setFormData((prev) => ({
          email: dcrm_login.email,
          password: dcrm_login.password,
        }));
        setRememberMe(true);
      }
    }

    if (localStorage.getItem("session") === "expired") {
      logout();
      handleOpenDialog(
        "Session Expired",
        "Session expired. Please login to continue",
        "error"
      );
    }
  }, []);

  // to fetch data from text box
  const handleChange = (e) => {
    const { name, value } = e.target;

    //Update form data
    setFormData((state) => ({
      ...state,
      [name]: value,
    }));

    // Reset the validation error when the user starts typing
    if (validationErrors[name]) {
      setValidationErrors((prevValidationError) => ({
        ...prevValidationError,
        [name]: "",
      }));
    }
  };

  const validate = async () => {
    const errors = {};
    if (!formData.email) {
      errors.email = "required";
    }
    if (!formData.password) {
      errors.password = "required";
    }
    setValidationErrors(errors);
    return Object.values(errors).every((error) => error === "");
  };

  // to handle login
  const handleLogin = async (e) => {
    e.preventDefault();

    const isValid = await validate();
    if (isValid === false) {
      return false;
    }

    const dataToSend = {
      email: formData.email,
      password: formData.password,
    };

    try {
      dispatch(triggerLoader());
      const result = await configServ.login({
        formData,
        location: weatherData,
      });
      if (result.success) {
        dispatch(rSetLogin(true));
        localStorage.setItem("dcrm_token", result.success.token);

        const oneHour = 6.5 * 60 * 60 * 1000; // 1 hour in milliseconds
        const expirationDate = new Date(Date.now() + oneHour);
        cookie.set("dcrm_token", result.success.token, {
          expires: expirationDate,
        });
        // cookie.set("userType", "user", {
        //   expires: expirationDate,
        // });

        if (rememberMe) {
          const dcrm_login = encrypt(dataToSend);
          const oneWeek = 6 * 29.5 * 60 * 60 * 1000; // 1 week in milliseconds
          const expirationDateLogin = new Date(Date.now() + oneWeek);
          cookie.set("dcrm_login", dcrm_login, {
            expires: expirationDateLogin,
          });
        } else {
          cookie.remove("dcrm_login");
        }

        dispatch(rSetEmail(result.success.email));
        dispatch(rSetUserId(result.success.customerId));
        dispatch(assignType(result.success.company_type));
        dispatch(rSetCurrency(result.success.currency));
        dispatch(rSetCompanyId(result.success.company_id));
        // const data = {
        //     id: result.success.company_id,
        // }
        // const res = await configServ.getCompanyById(data);
        // if (res.status === 200) {
        //     dispatch(rSetCompanyName(res.data.CompanyName));
        // }
        dispatch(rSetCompanyName(result.success.company_name));
        dispatch(rSetId(result.success.id));
        dispatch(rSetUserName(result.success.name));
        dispatch(rSetName(result.success.name));
        dispatch(rSetAddress(result.success.address));
        dispatch(rSetIsSuper(false));
        dispatch(rSetIsAdmin(false));

        navigate("/product", { replace: true });
      } else {
        handleOpenDialog("Error", "Invalid Login credentials", "error");
      }
    } catch (err) {
      console.log(err);
      handleOpenDialog("Error", "Invalid Login credentials", "error");
    } finally {
      dispatch(triggerLoader());
    }
  };

  // const checkedLoggedIn = () => {
  //     if (cookie.get('dcrm_token') !== undefined) {
  //         if (cookie.get('userType') === 'customer') {
  //             navigate('/customer-dashboard')
  //         }
  //         else {
  //             navigate('/Dashboard')
  //         }
  //     }
  // }

  // useEffect(() => {
  //     checkedLoggedIn()
  // }, []);
  //#endregion

  return (
    <>
      <AlertDialog
        open={loginError}
        setOpen={setLoginError}
        title={title}
        message={dialogContent}
        buttonTitle="Ok"
        buttonType={buttonType}
      />

      <Box
        sx={{
          boxShadow: 3,
          borderRadius: 2,
          px: 4,
          py: 6,
          marginTop: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#ffffff",
        }}
      >
        {/* <Typography component="h1" variant="h5">
                    <AccountCircleTwoToneIcon />Login
                </Typography> */}

        <Typography variant="h5">
          <b>Welcome to Dovetail-crm!</b>
        </Typography>
        <Typography variant="caption">Please sign in to continue</Typography>

        <form onSubmit={handleLogin}>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"center"}
            spacing={1}
          >
            <Grid item xs={12} md={10} mb={1} mt={3}>
              <TextField
                size="small"
                fullWidth
                id="email"
                label="Email/Username/Phone"
                name="email"
                autoComplete="email"
                value={formData.email}
                autoFocus
                onChange={handleChange}
                error={!!validationErrors.email}
              />
            </Grid>
            <Grid item xs={12} md={10}>
              <TextField
                size="small"
                fullWidth
                name="password"
                label="Password"
                value={formData.password}
                type={showPassword ? "text" : "password"}
                id="password"
                onChange={handleChange}
                error={!!validationErrors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}{" "}
                        {/* Toggle icons */}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            {/* Remember Me Checkbox */}

            <FormControlLabel
              control={
                <Checkbox
                  checked={rememberMe}
                  onChange={handleRememberMeChange}
                  size="small" // Makes the checkbox smaller
                />
              }
              label="Remember Me"
              sx={{
                fontSize: "0.875rem",
                fontWeight: 600,
                color: "rgba(0, 0, 0, 0.87)", // Darkens the label text
                marginLeft: 0,
                display: "flex",
                alignItems: "center",
                flex: 1,
              }}
            />

            <Grid item xs={12} md={10} my={1}>
              <Button
                type="submit"
                fullWidth
                size="small"
                variant="contained"
                startIcon={<LoginIcon sx={{ margin: "5px 10px" }} />}
                sx={{ borderRadius: "20px" }}
              >
                Login
              </Button>
            </Grid>

            {/* <Grid item>
                                <Link to='/register'>
                                    <Typography variant="body2" color='primary'>Don't have an account? Register</Typography>
                                </Link>
                            </Grid> */}
            <Grid
              container
              item
              alignContent={"center"}
              justifyContent={"flex-end"}
            >
              <Link to="/forgetPassword">
                <Typography variant="body2" color="primary">
                  Forgot Password
                </Typography>
              </Link>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};
export default Login;
