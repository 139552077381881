import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Box,
  Tooltip,
  IconButton,
} from '@mui/material';

import NestedTable from './NestedTable';

import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';

const DynamicTable = ({ data, pagination = true }) => {
  const rowsPerPageOptions = [20, 50, 100, 200];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
  const [nestedId, setNestedId] = useState('');
  const [expanded, setExpanded] = useState(false);

  const headers = ['CUSTOMER', 'INVOICE NUMBER', 'INVOICE DATE', 'TOTAL AMOUNT', 'DISPATCH DETAIL', 'ACTION'];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  const paginatedData = pagination
    ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : data;

  const headerCellStyle = {
    fontSize: 14, // Adjust the font size as needed
    fontWeight: 'bold',
    backgroundColor: '#4CB5F5',
    color: 'white',
  };

  const iconCellStyle = {
    width: 40, // Adjust the width as needed
  };

  const handleNestedTable = (id) => {
    setNestedId(id)
    setExpanded(true)
    // console.log(id)
  }
  const handleClose = () => {
    setExpanded(false)
  }

  return (
    <>
      {expanded && (<NestedTable open={expanded} handleClose={handleClose} id={nestedId} />)}
      <TableContainer component={Paper} sx={{ px: 5 }} elevation={0}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell style={headerCellStyle}>
                {/* <IconButton disabled>
                <VisibilityIcon />
              </IconButton> */}
              </TableCell>
              <TableCell sx={headerCellStyle}>S.No.</TableCell>
              {headers.map((header, index) => (
                <TableCell key={index} style={headerCellStyle}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                <TableCell style={iconCellStyle}>
                  <Tooltip title='View details' arrow>
                    <IconButton onClick={() => { handleNestedTable(row.id) }}>
                      <VisibilityIcon color='primary' />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell>{page * rowsPerPage + rowIndex + 1}</TableCell>
                <TableCell>{row.customer?.fname || 'NA'}</TableCell>
                <TableCell>{row.invoice_number || 'NA'}</TableCell>
                <TableCell>{row.date || 'NA'}</TableCell>
                <TableCell>{row.total_amount || 'NA'}</TableCell>
                {/* <TableCell>{row.dispatch ? 'Dispatched' : 'Pending'}</TableCell> */}
                <TableCell>{row.dispatch ? 'Dispatched' : 'Pending'}</TableCell>
                <TableCell>
                  <Box sx={{ borderRadius: 10, width: 'fit-content', transition: '100ms', cursor: 'pointer', padding: 0.5, ":hover": { backgroundColor: '#99999955' } }}>
                    <Tooltip title='Edit' arrow>
                      <EditIcon />
                    </Tooltip>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 1 * emptyRows }}>
                <TableCell colSpan={headers.length + 2} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        {pagination && (
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </TableContainer>
    </>
  );
};

export default DynamicTable;
