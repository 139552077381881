import React from "react";
import { Outlet } from "react-router-dom";

//FUNCTION
const Transaction = () => {
    return (
        <>
            <Outlet />
        </>
    )
}

export default Transaction;