import React, { memo, useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CheckAndReturn from "../../../../utility/CheckAndReturn";
import { useNavigate } from "react-router-dom";

//FUNCTION
const OutstandingCustomerCard = ({ outstandingCustomerData }) => {
  //#region code
  const navigate = useNavigate();
  const [invoiceAmount, setInvoiceAmount] = useState(0);
  const [receiptAmount, setReceiptAmount] = useState(0);
  const [paymentAmount, setPaymentAmount] = useState(0);

  useEffect(() => {
    let totalInvoiceAmount = 0;
    let totalReceiptAmount = 0;
    let totalPaymentAmount = 0;
    if (outstandingCustomerData !== null) {
      outstandingCustomerData.ledger.forEach((row) => {
        if (row.type === "sale") {
          totalInvoiceAmount += Number(row.amount ?? 0);
        } else if (Boolean(row.is_credit) === false) {
          totalReceiptAmount += Number(row.amount ?? 0);
        } else if (Boolean(row.is_credit) === true) {
          totalPaymentAmount += Number(row.amount ?? 0);
        }
      });
      setInvoiceAmount(totalInvoiceAmount);
      setReceiptAmount(totalReceiptAmount);
      setPaymentAmount(totalPaymentAmount);
    }

    return () => {
      setInvoiceAmount(0);
      setReceiptAmount(0);
      setPaymentAmount(0);
    };
  }, [outstandingCustomerData]);

  const handleInvoiceClick = (params) => {
    try {
      const data = params;
      navigate("/invoice", { state: { invoiceData: data } });
    } catch (e) {
      console.log(e);
    }
  };

  const handleVoucherClick = (params) => {
    try {
      if (params.type === "receipt" || params.type === "payment") {
        navigate("/transaction/create-vouchers", {
          state: { entity_number: params.entity_number, entity: "customer" },
        });
      } else {
        navigate("/transaction/journal-entry", {
          state: { entity_number: params.entity_number, entity: "customer" },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleCreditDebit = (params) => {
    try {
      if (Boolean(params.is_credit) === false) {
        return <span style={{ color: "green" }}>Cr</span>;
      } else if (Boolean(params.is_credit) === true) {
        return <span style={{ color: "red" }}>Dr</span>;
      }
    } catch (e) {
      console.log(e);
    }
  };
  //#endregion

  return (
    <>
      <Box flex={1} textAlign="center">
        <Typography
          variant="h5"
          color="#1e61b4"
          fontSize={{ xs: "16px", md: "22px" }}
          fontWeight={700}
        >
          {outstandingCustomerData?.name ?? ""},{" "}
          {CheckAndReturn.capitalizeWords(
            outstandingCustomerData?.address ?? ""
          )}
        </Typography>
      </Box>
      {outstandingCustomerData !== null &&
        outstandingCustomerData.customer !== undefined &&
        outstandingCustomerData.customer !== null && (
          <Grid container alignItems={"center"} spacing={1} my={2}>
            <Grid item xs={12} sm={3} md={3} columnSpacing={0}>
              <TextField
                readonly
                fullWidth
                size="small"
                label={
                  <span>
                    Firm Name<span style={{ color: "red" }}>*</span>
                  </span>
                }
                name="fname"
                value={outstandingCustomerData.customer.fname || ""}
                sx={{ backgroundColor: "white" }}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3} columnSpacing={0}>
              <TextField
                readonly
                fullWidth
                size="small"
                label={
                  <span>
                    Contact Name<span style={{ color: "red" }}>*</span>
                  </span>
                }
                name="contact_name"
                value={outstandingCustomerData.customer.contact_name || ""}
                sx={{ backgroundColor: "white" }}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3} columnSpacing={0}>
              <TextField
                readonly
                fullWidth
                size="small"
                label={
                  <span>
                    Mobile Number<span style={{ color: "red" }}>*</span>
                  </span>
                }
                name="mobile"
                type="number"
                value={outstandingCustomerData.customer.mobile || ""}
                sx={{ backgroundColor: "white" }}
              />
            </Grid>
            {outstandingCustomerData.customer.email && (
              <Grid item xs={12} sm={3} md={3} columnSpacing={0}>
                <TextField
                  readonly
                  fullWidth
                  size="small"
                  label={<span>Email</span>}
                  name="email"
                  value={outstandingCustomerData.customer.email || ""}
                  sx={{ backgroundColor: "white" }}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={3} md={3} columnSpacing={0}>
              <TextField
                readonly
                fullWidth
                size="small"
                label={
                  <span>
                    Address<span style={{ color: "red" }}>*</span>
                  </span>
                }
                name="address"
                value={outstandingCustomerData.customer.address || ""}
                sx={{ backgroundColor: "white" }}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3} columnSpacing={0}>
              <TextField
                readonly
                fullWidth
                size="small"
                label={
                  <span>
                    Country<span style={{ color: "red" }}>*</span>
                  </span>
                }
                name="country"
                value={outstandingCustomerData.customer.country || ""}
                sx={{ backgroundColor: "white" }}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3} columnSpacing={0}>
              <TextField
                readonly
                fullWidth
                size="small"
                label={
                  <span>
                    State<span style={{ color: "red" }}>*</span>
                  </span>
                }
                name="State"
                value={outstandingCustomerData.customer.State || ""}
                sx={{ backgroundColor: "white" }}
              />
            </Grid>
            {outstandingCustomerData.customer.Pin && (
              <Grid item xs={12} sm={3} md={3} columnSpacing={0}>
                <TextField
                  readonly
                  fullWidth
                  type="number"
                  size="small"
                  label={<span>Pin</span>}
                  name="Pin"
                  value={outstandingCustomerData.customer.Pin || ""}
                  sx={{ backgroundColor: "white" }}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={3} md={3} columnSpacing={0}>
              <TextField
                readonly
                fullWidth
                type="text"
                size="small"
                label={
                  <span>
                    Customer Type<span style={{ color: "red" }}>*</span>
                  </span>
                }
                name="type"
                value={outstandingCustomerData.customer.type || ""}
                defaultValue={0}
                sx={{ backgroundColor: "white" }}
              />
            </Grid>
            {outstandingCustomerData.customer.pan_no && (
              <Grid item xs={12} sm={3} md={3} columnSpacing={0}>
                <TextField
                  readonly
                  fullWidth
                  size="small"
                  label="PAN/NO"
                  name="pan_no"
                  value={outstandingCustomerData.customer.pan_no || ""}
                  sx={{ backgroundColor: "white" }}
                />
              </Grid>
            )}
            {outstandingCustomerData.customer.gstin && (
              <Grid item xs={12} sm={3} md={3} columnSpacing={0}>
                <TextField
                  readonly
                  fullWidth
                  size="small"
                  label="GSTIN/UIN"
                  name="gstin"
                  value={outstandingCustomerData.customer.gstin || ""}
                  sx={{ backgroundColor: "white" }}
                />
              </Grid>
            )}
            {/* {
                        outstandingCustomerData.customer.is_credit_days &&
                        <Grid item xs={12} sm={3} md={3} columnSpacing={0}>
                            <FormControlLabel
                                readonly
                                control={<Checkbox />}
                                name="is_credit_days"
                                checked={Boolean(outstandingCustomerData.customer.is_credit_days)}
                                label="Check credit days"
                            />
                        </Grid>
                    } */}
            {outstandingCustomerData.customer.credit_period && (
              <Grid item xs={12} sm={3} md={3} columnSpacing={0}>
                <TextField
                  readonly
                  fullWidth
                  label="Credit Period"
                  size="small"
                  type="number"
                  name="credit_period"
                  InputProps={{
                    endAdornment: "d",
                  }}
                  value={outstandingCustomerData.customer.credit_period || ""}
                  sx={{ backgroundColor: "white" }}
                />
              </Grid>
            )}
            {outstandingCustomerData.customer.credit_limit && (
              <Grid item xs={12} sm={3} md={3} columnSpacing={0}>
                <TextField
                  readonly
                  fullWidth
                  label="Credit Limit"
                  size="small"
                  type="number"
                  name="credit_limit"
                  value={outstandingCustomerData.customer.credit_limit || ""}
                  sx={{ backgroundColor: "white" }}
                />
              </Grid>
            )}
          </Grid>
        )}

      {outstandingCustomerData !== null ? (
        outstandingCustomerData.ledger.map((item) => (
          <Card
            sx={{
              marginTop:"3px",
              marginBottom: "16px",
              padding: "1px",
              backgroundColor: "#f9f9ff",
              width: "100%", // Set the card to take full width
              maxWidth: "100vw", // Ensure the card doesn't exceed the viewport width
              boxSizing: "border-box", // Make sure padding is included within width
            }}
            key={item.id}
          >
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Stack direction="row" spacing={1} sx={{ width: "100%" }}>
                    <Typography variant="body1" color="textSecondary">
                    <strong>  Type:</strong>
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1} sx={{ width: "100%" }}>
                    {item.type === "sale" ? (
                      <Typography
                        variant="body1"
                        className="linkStyle"
                        onClick={() => handleInvoiceClick(item.entity_number)}
                        sx={{ flexGrow: 1 }} // Ensure full width for this element
                      >
                        {`Bill to(${item.entity_number ?? ""})`}
                      </Typography>
                    ) : (
                      <Typography
                        variant="body1"
                        className="linkStyle"
                        onClick={() => handleVoucherClick(item)}
                        sx={{ flexGrow: 1 }} // Ensure full width for this element
                      >
                        {item.type === "receipt"
                          ? `Payment(${item.entity_number ?? ""})`
                          : `Received from(${item.entity_number ?? ""})`}
                      </Typography>
                    )}
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Stack direction="row" spacing={1} sx={{ width: "100%" }}>
                    <Typography variant="body1" color="textSecondary">
                    <strong>  Date:</strong>
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1} sx={{ width: "100%" }}>
                    <Typography variant="body1" sx={{ flexGrow: 1 }}>
                      {CheckAndReturn.convertDateFormat(
                        item.type === "sale" ? item.date ?? "" : item.date ?? ""
                      )}
                    </Typography>
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Stack direction="row" spacing={1} sx={{ width: "100%" }}>
                    <Typography variant="body1" color="textSecondary">
                      <strong>Amount:</strong>
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1} sx={{ width: "100%" }}>
                    <Typography
                      variant="body1"
                      sx={{  flexGrow: 1 }}
                    >
                      ₹{CheckAndReturn.roundToInteger(item.amount ?? 0)}{" "}
                      {handleCreditDebit(item)}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ))
      ) : (
        <Typography variant="h6" align="center" color="red">
          No Data found.
        </Typography>
      )}

      <Grid container spacing={1} mb={1}>
        <Grid container item xs={12} md={12} justifyContent={"center"}>
          <Stack direction={"row"}>
            <Typography
              variant="body1"
              sx={{ fontSize: "16px", fontWeight: 500 }}
            >
              Invoice:
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontSize: "16px", fontWeight: 500, color: "green" }}
            >
              ₹{CheckAndReturn.roundToInteger(invoiceAmount ?? 0)}
            </Typography>
          </Stack>
        </Grid>
        <Grid container item xs={12} md={12} justifyContent={"center"}>
          <Stack direction={"row"}>
            <Typography
              variant="body1"
              sx={{ fontSize: "16px", fontWeight: 500 }}
            >
              Payment:
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontSize: "16px", fontWeight: 500, color: "green" }}
            >
              ₹{CheckAndReturn.roundToInteger(paymentAmount ?? 0)}
            </Typography>
          </Stack>
        </Grid>
        <Grid container item xs={12} md={12} justifyContent={"center"}>
          <Stack direction={"row"}>
            <Typography
              variant="body1"
              sx={{ fontSize: "16px", fontWeight: 500 }}
            >
              Receipt:
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontSize: "16px", fontWeight: 500, color: "green" }}
            >
              ₹{CheckAndReturn.roundToInteger(receiptAmount ?? 0)}
            </Typography>
          </Stack>
        </Grid>
        <Grid container item xs={12} md={12} justifyContent={"center"}>
          <Stack direction={"row"}>
            <Typography
              variant="body1"
              sx={{ fontSize: "16px", fontWeight: 500 }}
            >
              Dues:
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontSize: "16px", fontWeight: 500, color: "green" }}
            >
              ₹
              {CheckAndReturn.roundToInteger(
                (invoiceAmount ?? 0) - (receiptAmount ?? 0)
              )}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default memo(OutstandingCustomerCard);
