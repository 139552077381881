import { Dialog, DialogContent, DialogTitle, Divider, Paper, Stack } from '@mui/material'
import React, { useState } from 'react'
import AddCity from '../../Components/city/AddCity'
import CityTable from '../../Components/city/CityTable';

//FUNCTION
const City = ({ open, handleClose }) => {
    //#region code
    const [isChanged, setIsChanged] = useState(false);
    const [editData, setEditData] = useState(null);

    const handleEdit = (data) => {
        setEditData(data);
    }
    //#endregion
    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='lg'
                fullWidth={true}
            >
                <DialogContent>
                    <Stack
                        direction='column'
                        justifyContent='center'
                        alignItems='center'
                    >
                        <Paper
                            elevation={0}
                            sx={{
                                width: '100%',
                                // mt: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: 3,
                                // pb:5
                            }}
                        >
                            <AddCity isChanged={isChanged} setIsChanged={setIsChanged} editData={editData} setEditData={setEditData} />
                        </Paper>
                        <Divider />
                        <CityTable handleEdit={handleEdit} isChanged={isChanged} />
                    </Stack>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default City
