import { Box, FormControl, InputLabel, NativeSelect } from '@mui/material'
import React from 'react'

const SearchByBox = ({ searchByArray, handleSearchBy }) => {
    return (
        <>
            <Box sx={{ minWidth: 120, marginLeft: '0px' }}>
                <FormControl fullWidth>
                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Search By
                    </InputLabel>
                    <NativeSelect
                        onChange={handleSearchBy}
                        inputProps={{
                            name: 'searchBy',
                            id: 'uncontrolled-native',
                        }}
                    >
                        {
                            searchByArray.map((item, index) => (
                                <option key={index} value={item.value}>{item.label}</option>
                            ))
                        }
                    </NativeSelect>
                </FormControl>
            </Box>
        </>
    )
}

export default SearchByBox
