import {
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { memo } from "react";
import { Link } from "react-router-dom";

function DashboardCard({ index,  data, onClick  }) {
  return (
    <>
      <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
        <Card
          component={Link}
          to={data.link}
          sx={{
            width: "100%",
            height: "100%",
            ":hover": {
              transform: "scale(1.03)",
              cursor: "pointer",
            },
            transition: "150ms",
            textDecoration: "none",
          }}
          onClick={onClick} // Handle navigation here
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <CardContent
              sx={{
                flex: 3,
                background: "white",
                borderRadius: "10px",
                border: "1px solid black",
              }}
            >
              <Stack
                sx={{
                  width: "100%",
                }}
              >
                <Stack direction="column">
                  <Stack direction={"row"}>
                    {data.icon}
                    <Typography
                      variant="body2"
                      fontWeight="bold"
                      color="text.secondary"
                      alignItems={"center"}
                      justifyContent={"center"}
                      mx={2}
                    >
                      {data.title}
                    </Typography>
                  </Stack>
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    {data.number}
                  </Typography>
                </Stack>
              </Stack>
            </CardContent>
          </Stack>
        </Card>
      </Grid>
    </>
  );
}

export default memo(DashboardCard);
