import React, { useState } from "react";
import { Container } from "@mui/material";
import JournalEntryCard from "../../../Components/JournalEntry/JournalEntryCard";
import JournalEntryTable from "./JournalEntryTable";

//FUNCTION
const JournalEntry = () => {
  //#region code
  const [editData, setEditData] = useState({})
  const [isChanged, setIsChanged] = useState(false)

  const handleEdit = (data) => {
      setEditData(data[0]);
  }

  //#endregion
  return (
      <>
          <Container>
              <JournalEntryCard editData={editData} setEditData={setEditData} isChanged={isChanged} setIsChanged={setIsChanged} />
              <JournalEntryTable handleEditData={handleEdit} isChanged={isChanged} />
          </Container>
      </>
  )
}

export default JournalEntry;
