import React, { useEffect, useState } from 'react';
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import Constants from '../../utility/Constants';
import configServ from '../../services/config';
import CheckAndReturn from '../../utility/CheckAndReturn';
import Swal from 'sweetalert2';
import PopUpTitle from '../Dialog/PopUpTitle';
import { triggerLoader } from '../../redux/reducers/LoaderTrigger';

const CustomerDirectCashPopUp = ({ open, close }) => {
    //#region code
    const dispatch = useDispatch();
    const { user_id, company_id, companyCountry, companyState, } = useSelector((state) => state.GlobalVariables);

    const initialFormData = {
        openingamount: 0,
        type: 'Direct Cash',
        country: companyCountry,
        State: companyState,
        is_credit_days: false,
        status: true,
        send_mail: false,
    };
    const [formData, setFormData] = useState(initialFormData);
    const [validationErrors, setValidationErrors] = useState({});
    const [stateList, setStateList] = useState([]);
    const [isCustomerName, setIsCustomerName] = useState(false);
    const [sameAsBillToAddress, setSameAsBillToAddress] = useState(false);

    const fetchStates = async () => {
        try {
            const country = await configServ.getCountryId({
                "country": companyCountry
            });
            if (country.status === 200) {
                const countryId = country.data;
                const res = await configServ.getState({ "country": countryId });
                if (res.status === 200) {
                    setStateList(res.data);
                }
                else {
                    setStateList([]);
                }
            }
        }
        catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        if (formData.country) {
            fetchStates();
        }
    }, [formData.country]);

    const checkCustomerName = async () => {
        try {
            const dataToSend = {
                company_id: company_id,
                fname: formData.fname,
            }
            const res = await configServ.checkCustomerName(dataToSend);
            if (res.status === 200) {
                setIsCustomerName(true);
            }
            else {
                setIsCustomerName(false);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        if (formData.fname) {
            checkCustomerName();
        }
        else {
            setIsCustomerName(false)
        }
    }, [formData.fname]);

    const handleChange = (e) => {
        try {
            const { name, value } = e.target;

            //Update form data
            setFormData((state) => ({
                ...state,
                [name]: value,
            }));

            // Reset the validation error when the user starts typing
            if (validationErrors[name]) {
                setValidationErrors((prevValidationError) => ({
                    ...prevValidationError,
                    [name]: '',
                }));
            }
        }
        catch (e) {
            console.log(e);
        }
    };

    const handleChangeCheckbox = (e) => {
        try {
            const { checked } = e.target;

            setSameAsBillToAddress(checked);
            if (checked === true) {
                setFormData((prev) => ({
                    ...prev,
                    shipToAddress: formData.address
                }));
            }
            else {
                setFormData((prev) => ({
                    ...prev,
                    shipToAddress: ''
                }));
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleFormValidation = async () => {
        try {
            const errors = {};
            if (CheckAndReturn.checkError(formData.fname)) {
                errors.fname = "required";
            }
            else if (!isCustomerName) {
                errors.fname = "required";
            }
            if (CheckAndReturn.checkError(formData.type)) {
                errors.type = "required";
            }
            if (CheckAndReturn.checkError(formData.State)) {
                errors.State = "required";
            }
            if (CheckAndReturn.checkError(formData.address)) {
                errors.address = "required";
            }
            if (CheckAndReturn.checkError(formData.shipToAddress)) {
                errors.shipToAddress = "required";
            }
            setValidationErrors(errors);
            return Object.values(errors).every(error => error === "");
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleSubmit = async () => {
        try {
            dispatch(triggerLoader());
            const dataToSend = {
                ...formData,
                company_id: company_id,
                user_id: user_id,
                contact_name: formData.fname,
            };

            const error = await handleFormValidation();
            if (error === false) {
                return false;
            }
            const result = await configServ.addCustomer(dataToSend);
            if (result.status === 200) {
                handleClose();
                Swal.fire({
                    title: "Success!",
                    text: "Customer created successfully!",
                    icon: "success"
                });
            }
            else {
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: "Failed to create customer!",
                });
            }
        }
        catch (e) {
            console.log(e);
        }
        finally {
            dispatch(triggerLoader());
        }
    }

    const handleReset = () => {
        try {
            setFormData(initialFormData);
            setValidationErrors({});
            setIsCustomerName(false);
            setSameAsBillToAddress(false);
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleClose = () => {
        try {
            handleReset();
            close();
        }
        catch (e) {
            console.log(e);
        }
    }

    //#endregion

    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth={'lg'}>
                <PopUpTitle title={'CUSTOMER'} handleClose={handleClose} />
                <DialogContent>
                    <Grid container alignItems={'center'} spacing={1} bgcolor={'#e3f3fc'} p={3}>
                        <Grid item xs={12} sm={6} md={6} columnSpacing={0}>
                            <TextField
                                fullWidth
                                size="small"
                                label={<span>Firm Name<span style={{ color: 'red' }}>*</span></span>}
                                name="fname"
                                onChange={handleChange}
                                value={CheckAndReturn.checkTextNullAndUndefined(formData.fname)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {isCustomerName ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />}
                                        </InputAdornment>
                                    )
                                }}
                                error={!!validationErrors.fname}
                                sx={{ backgroundColor: "white" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} columnSpacing={0}>
                            <FormControl fullWidth size="small">
                                <InputLabel id="customer-type">Customer Type<span style={{ color: 'red' }}>*</span></InputLabel>
                                <Select
                                    labelId="customer-type"
                                    label="Customer Type"
                                    name="type"
                                    value={CheckAndReturn.checkTextNullAndUndefined(formData.type)}
                                    onChange={handleChange}
                                    error={!!validationErrors.type}
                                    sx={{ backgroundColor: "white" }}
                                >
                                    {
                                        Constants.customerTypes.map((item, index) => (
                                            <MenuItem key={index} value={item.value}>{item.label} </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} columnSpacing={0}>
                            <FormControl fullWidth size="small">
                                <InputLabel error={!!validationErrors.State}>State<span style={{ color: 'red' }}>*</span></InputLabel>
                                <Select
                                    required
                                    name="State"
                                    label="State"
                                    value={CheckAndReturn.checkTextNullAndUndefined(formData.State)}
                                    onChange={handleChange}
                                    error={!!validationErrors.State}
                                    sx={{ backgroundColor: "white" }}
                                >
                                    {stateList.map((item, index) => {
                                        return (
                                            <MenuItem key={index} value={item.State}>
                                                {item.State}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} columnSpacing={0}>
                            <TextField
                                fullWidth
                                size="small"
                                label={<span>Bill To Address<span style={{ color: 'red' }}>*</span></span>}
                                name="address"
                                onChange={handleChange}
                                value={CheckAndReturn.checkTextNullAndUndefined(formData.address)}
                                error={!!validationErrors.address}
                                sx={{ backgroundColor: "white" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField
                                fullWidth
                                size="small"
                                label={
                                    <span>Mobile Number
                                        {/* <span style={{ color: 'red' }}>*</span> */}
                                    </span>
                                }
                                name="mobile"
                                type="number"
                                onChange={handleChange}
                                value={CheckAndReturn.checkTextNullAndUndefined(formData.mobile)}
                                // error={!!validationErrors.mobile}
                                sx={{ backgroundColor: "white" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} columnSpacing={0}>
                            <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
                                <FormControlLabel
                                    control={<Checkbox />}
                                    name="same_as_bill_to_address"
                                    checked={Boolean(sameAsBillToAddress)}
                                    onChange={handleChangeCheckbox}
                                    label="Same as Bill to Address"
                                    sx={{ fontWeight: "bold" }}
                                />
                                <TextField
                                    fullWidth
                                    size="small"
                                    label={<span>Ship To Address<span style={{ color: 'red' }}>*</span></span>}
                                    name="shipToAddress"
                                    onChange={handleChange}
                                    value={CheckAndReturn.checkTextNullAndUndefined(formData.shipToAddress)}
                                    error={!!validationErrors.shipToAddress}
                                    sx={{ backgroundColor: "white" }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' onClick={handleClose} color="error" startIcon={<CloseIcon />} sx={{ mx: 2 }}>
                        Cancel
                    </Button>
                    <Button variant='contained' onClick={handleSubmit} color="primary" startIcon={<SaveIcon />} sx={{ mx: 2 }}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CustomerDirectCashPopUp
