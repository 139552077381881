import React, { memo, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { Grid, Stack, Typography } from '@mui/material';
import CheckAndReturn from '../../../utility/CheckAndReturn';
import { useNavigate } from 'react-router-dom';
import configServ from '../../../services/config';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#4CB5F5',//theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

//FUNCTION
const AccountMasterLedgerTable = ({ accountMaster }) => {
    //#region code
    const navigate = useNavigate();
    const [paidAmount, setPaidAmount] = useState(0);
    const [receivedAmount, setReceivedAmount] = useState(0);

    useEffect(() => {
        let totalPaidAmount = 0;
        let totalReceivedAmount = 0;

        accountMaster.forEach(row => {
            totalPaidAmount += Number(Boolean(row.is_credit) === true ? row.amount ?? 0 : 0);
            totalReceivedAmount += Number(Boolean(row.is_credit) === false ? row.amount ?? 0 : 0);
        });

        setPaidAmount(CheckAndReturn.roundToInteger(totalPaidAmount));
        setReceivedAmount(CheckAndReturn.roundToInteger(totalReceivedAmount));

        return () => {
            setPaidAmount(0);
            setReceivedAmount(0);
        }
    }, [accountMaster]);

    const LinkTableCell = styled(StyledTableCell)({
        cursor: 'pointer',
        textDecoration: 'none',
        '&:hover': {
            color: 'darkblue',
            textDecoration: 'underline',
        },
    });

    // const fetchAccountName = async(params) => {
    //   try{
    //     const dataToSend = {
    //         entity_id: params.entity_id,
    //         entity_type: params.entity_type
    //     }
    //     const res = await configServ.getAccountName(dataToSend);
    //     if(res.status === 200){

    //     }
    //     else{
    //         return '';
    //     }
    //   }
    //   catch(e){
    //     console.log(e);
    //   }
    // }

    const handleVoucherClick = (voucher, entity, voucher_type) => {
        try {
            if (voucher_type === 'journal') {
                navigate('/transaction/journal-entry', { state: { voucher_no: voucher, entity: entity } });
            }
            else {
                navigate('/transaction/create-vouchers', { state: { voucher_no: voucher, entity: entity } });
            }
        }
        catch (e) {
            console.log(e);
        }
    }
    //#endregion

    return (
        <>
            <Grid container spacing={1} mt={2}>
                <Grid container item xs={12} md={4} justifyContent={{ xs: 'center', md: 'flex-start' }}>
                    <Stack direction={'row'}>
                        <Typography variant='body1' sx={{ fontSize: '16px', fontWeight: 600 }}>
                            Paid Amount:
                        </Typography>
                        <Typography variant='body1' sx={{ fontSize: '16px', fontWeight: 600, color: 'green' }}>
                            ₹{paidAmount}
                        </Typography>
                    </Stack>
                </Grid>
                <Grid container item xs={12} md={4} justifyContent={{ xs: 'center', md: 'center' }}>
                    <Stack direction={'row'}>
                        <Typography variant='body1' sx={{ fontSize: '16px', fontWeight: 600 }}>
                            Received Amount:
                        </Typography>
                        <Typography variant='body1' sx={{ fontSize: '16px', fontWeight: 600, color: 'green' }}>
                            ₹{receivedAmount}
                        </Typography>
                    </Stack>
                </Grid>
                <Grid container item xs={12} md={4} justifyContent={{ xs: 'center', md: 'flex-end' }}>
                    <Stack direction={'row'}>
                        <Typography variant='body1' sx={{ fontSize: '16px', fontWeight: 600 }}>
                            Balance:
                        </Typography>
                        <Typography variant='body1' sx={{ fontSize: '16px', fontWeight: 600, color: 'green' }}>
                            ₹{receivedAmount - paidAmount}
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>

            <TableContainer component={Paper}>
                <Box sx={{ overflowX: 'auto' }}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="left">S.No</StyledTableCell>
                                <StyledTableCell align="left">Date</StyledTableCell>
                                <StyledTableCell align="left"> Type</StyledTableCell>
                                <StyledTableCell align="left"> Voucher Type</StyledTableCell>
                                <StyledTableCell align="left">Credit/Debit</StyledTableCell>
                                <StyledTableCell align="left">Voucher</StyledTableCell>
                                <StyledTableCell align="left">Amount</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {accountMaster.length > 0 ? (
                                accountMaster.map((row, index) => (
                                    <StyledTableRow key={index}>
                                        <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                        <StyledTableCell align="left">{CheckAndReturn.transformToDate3(row.date ?? '')}</StyledTableCell>
                                        <StyledTableCell align="left">{row.type ?? ''}</StyledTableCell>
                                        <StyledTableCell align="left">{row.voucher_type ?? ''}</StyledTableCell>
                                        <StyledTableCell align="left">{Boolean(row.is_credit) === true ? 'Credit' : 'Debit'}</StyledTableCell>
                                        {
                                            (row.entity_type !== 'sale' && row.entity_type !== 'purchase') ?
                                                <LinkTableCell
                                                    align="left"
                                                    onClick={() => handleVoucherClick(row.voucher_no, row.entity_type, row.voucher_type)}
                                                >
                                                    {row.voucher_no !== '' ? (row.voucher_type === 'receipt' ? `Received from(${row.voucher_no ?? ''})` : `Payment(${row.voucher_no ?? ''})`) : ''}
                                                </LinkTableCell>
                                                :
                                                <StyledTableCell align="left">{row.voucher_no}</StyledTableCell>
                                        }
                                        <StyledTableCell align="left">₹{CheckAndReturn.roundToInteger(row.amount)}</StyledTableCell>
                                    </StyledTableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={12} sx={{ textAlign: 'center', color: 'red', fontSize: '16px' }}>
                                        <div>
                                            <p><b>No Item Found.</b></p>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Box>
            </TableContainer>
        </>
    );
};

export default memo(AccountMasterLedgerTable);
