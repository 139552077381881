import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    Box,
    Tooltip,
    IconButton,
    Grid,
    FormControl,
    InputLabel,
    NativeSelect,
    TextField,
    InputAdornment,
    Typography,
    CircularProgress
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useSelector } from 'react-redux';
import configServ from '../../../services/config';
import DeactivateBatch from '../../masterEntry/ItemEditOptions/DeactivateBatch';

//FUNCTION
const BatchTable = ({  isChangedData, pagination = true }) => {
    //#region code
    const { company_id, user_id } = useSelector((state) => state.GlobalVariables);

    const rowsPerPageOptions = [20, 50, 100, 200];
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
    const [searchBy, setSearchBy] = useState('batch_name');
    const [batchList, setBatchList] = useState([]);
    const [filterBatchList, setFilterBatchList] = useState([]);
    const [count, setCount] = useState(0);
    const [deactivateOpen, setDeactivateOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [loading, setLoading] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const fetchBatch = async () => {
        try {
            setLoading(true);
            const res = await configServ.getBatch({ "company_id": company_id });
            if (res.status === 200) {
                setBatchList(res.data);
                setFilterBatchList(res.data);
                setCount(res.data.length);
            }
            else {
                setBatchList([]);
                setFilterBatchList([]);
                setCount(0);
            }
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchBatch()
    }, [isChangedData]);

    useEffect(() => {
        fetchBatch()
    }, [isChanged]);

    const headers = ['BATCH NAME', 'UUID', 'STATUS', 'ACTION'];

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

    const paginatedData = pagination
        ? filterBatchList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : filterBatchList;

    const headerCellStyle = {
        fontSize: 14, // Adjust the font size as needed
        fontWeight: 'bold',
        backgroundColor: '#4CB5F5', // Set the header background color to blue
        color: 'white',
    };

    const evenRowStyle = {
        background: '#f5f5f5',
    };

    const oddRowStyle = {
        background: 'white',
    };

    const rowStyle = {
        '&:hover': {
            background: '#f1f9fe',
        },
    };

    const handleSearchBy = (event) => {
        try {
            setSearchBy(event.target.value);
        }
        catch (error) {
            console.log(error);
        }
    }

    const handleSearch = (event) => {
        try {
            const search = event.target.value;
            let data = [];
            if (search === '') {
                data = batchList;
            }
            else if (searchBy === 'batch_name') {
                data = batchList.filter(x => x.batch_name.toLowerCase().includes(search.toLowerCase()))
            }

            setFilterBatchList(data);
            setCount(data.length);
        }
        catch (error) {
            console.log(error);
        }
    }

    const handleBatch = (params) => {
        try {
            const batchData = batchList.filter(x => x.id === params)[0];
            return batchData.batch_name;
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleChange = () => {
        try {
            setIsChanged(!isChanged);
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleUpdate = (params) => {
        try {
            setSelectedItem({
                "product_line_item_id": "",
                "product_id": "",
                "batch_id": params.id,
                "company_id": company_id,
                "uuid": params.uuid,
                "deactivated_by": user_id
            });
            setDeactivateOpen(true);
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleDeactivate = () => {
        try {
            setDeactivateOpen(false);
            setSelectedItem({});
        }
        catch (e) {
            console.log(e);
        }
    }
    //#endregion

    if (loading) {
        return (
            <Box
                sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            >
                <CircularProgress />
            </Box>
        )
    }
    else {
        return (
            <>
            <DeactivateBatch deactivateOpen={deactivateOpen} handleDeactivate={handleDeactivate} selectedItem={selectedItem} isChanged={handleChange} />
                {/* search starts here */}
                <Grid container alignItems="flex-end" justifyContent="flex-start" style={{ padding: 10, paddingTop: 0 }} spacing={1} direction={'row'}>
                    <Grid item xs={12} md={6} ml={4} justifyContent={'flex-start'}>
                        <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>Batch List:{count}</Typography>
                    </Grid>
                    <Grid item xs={4} md={2}>
                        <Box sx={{ minWidth: 120, marginLeft: '10px' }}>
                            <FormControl
                                fullWidth
                            >
                                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                    Search By
                                </InputLabel>
                                <NativeSelect

                                    onChange={handleSearchBy}
                                    inputProps={{
                                        name: 'searchBy',
                                        id: 'uncontrolled-native',
                                    }}
                                >
                                    <option value={'batch_name'}>Batch Name</option>
                                </NativeSelect>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={3} mr={{ xs: 0, md: 4 }} justifyContent={'flex-end'}>
                        <TextField
                            id="outlined-basic"
                            size="small"
                            fullWidth
                            placeholder="search"
                            variant="outlined"
                            onChange={handleSearch}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon style={{ fill: 'grey' }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
                {/* search ends here */}

                {/* Batch Table starts here */}
                <TableContainer component={Paper} sx={{ px: 5 }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={headerCellStyle}>S.No.</TableCell>
                                {headers.map((header, index) => (
                                    <TableCell key={index} style={headerCellStyle}>
                                        {header}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedData.map((row, rowIndex) => (
                                <TableRow
                                    key={rowIndex}
                                    sx={{
                                        ...rowStyle,
                                        ...(rowIndex % 2 === 0 ? evenRowStyle : oddRowStyle),
                                    }}
                                >
                                    <TableCell>{page * rowsPerPage + rowIndex + 1}</TableCell>
                                    <TableCell>{row.batch_name}</TableCell>
                                    <TableCell>{row.uuid}</TableCell>
                                    <TableCell>{row.status === 1 ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />}</TableCell>
                                    <TableCell>
                                        <div>
                                            <Tooltip title='Edit' arrow>
                                                <IconButton onClick={() => 
                                                    { 
                                                        handleUpdate(row); 
                                                        scrollToTop() 
                                                        }}
                                                >
                                                <EditIcon color='primary' />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 1 * emptyRows }}>
                                    <TableCell colSpan={headers.length + 2} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    {pagination && (
                        <TablePagination
                            rowsPerPageOptions={rowsPerPageOptions}
                            component="div"
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    )}
                </TableContainer>
                {/* Batch Table ends here */}
            </>
        );
    }
};

export default BatchTable;
