import React, { memo, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Box,
  Tooltip,
  IconButton,
  Grid,
  Typography,
  NativeSelect,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  Stack,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import configServ from "../../../services/config";
import { useSelector } from "react-redux";
import useDeviceDetect from "../../../Components/Detect/useDeviceDetect ";

//FUNCTION
const UnitTable = ({ handleEdit, isChanged, pagination = true }) => {
  //#region code
  const { company_id } = useSelector((state) => state.GlobalVariables);
  const rowsPerPageOptions = [20, 50, 100, 200];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
  const [count, setCount] = useState(0);
  const [unitList, setUnitList] = useState([]);
  const [searchBy, setSearchBy] = useState("units_name");
  const [search, setSearch] = useState("");
  const { isMobile } = useDeviceDetect();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const fetchUnitList = async () => {
    try {
      const dataToSend = {
        company_id: company_id,
        search_by: searchBy,
        search: search,
        per_page: rowsPerPage,
        page: page,
      };
      const res = await configServ.getUnit(dataToSend);
      if (res.status === 200) {
        setUnitList(res.data);
        setCount(res.total);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // const fetchSimpleUnitList = async () => {
  //   try {
  //     const result = await configServ.getSimpleUnit({ "company_id": company_id })
  //     setSimpleUnitList(result)
  //   }
  //   catch (err) {
  //     console.log(err)
  //   }
  // }

  useEffect(() => {
    fetchUnitList();
    //fetchSimpleUnitList();
  }, [isChanged, page, rowsPerPage, search]);

  const headers = [
    "UNIT NAME",
    "DERIVE UNIT",
    "CONVERSION FACTOR",
    "TYPE OF UNIT",
    "DESCRIPTION",
    "STATUS",
    "PRIMARY",
    "ACTION",
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

  // const paginatedData = pagination
  //   ? filterUnitList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  //   : filterUnitList;

  const handleSearchBy = (event) => {
    try {
      setSearchBy(event.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (event) => {
    try {
      const data = event.target.value;
      if (data === "") {
        setSearch(null);
      } else {
        setSearch(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const headerCellStyle = {
    fontSize: 14, // Adjust the font size as needed
    fontWeight: "bold",
    backgroundColor: "#4CB5F5",
    color: "white",
  };

  const cellStyle = {
    py: 0,
    my: 0,
  };

  const evenRowStyle = {
    background: "#f5f5f5",
  };

  const oddRowStyle = {
    background: "white",
  };

  const rowStyle = {
    "&:hover": {
      background: "#f1f9fe",
    },
  };
  //#endregion

  return (
    <>
      {/* search starts here */}
      <Grid
        container
        alignItems="flex-end"
        justifyContent="flex-start"
        sx={{ pt: 1 }}
        spacing={1}
        direction={"row"}
      >
        <Grid item xs={12} md={6} justifyContent={"flex-start"}>
          <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
            Unit List: {count}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box sx={{ minWidth: 120, marginLeft: "0px" }}>
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Search By
              </InputLabel>
              <NativeSelect
                onChange={handleSearchBy}
                inputProps={{
                  name: "searchBy",
                  id: "uncontrolled-native",
                }}
              >
                <option value={"units_name"}>Unit Name</option>
              </NativeSelect>
            </FormControl>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          mr={{ xs: 0, md: 0 }}
          justifyContent={"flex-end"}
        >
          <TextField
            id="outlined-basic"
            size="small"
            fullWidth
            placeholder="search"
            variant="outlined"
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ fill: "grey" }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      {/* search ends here */}

      {isMobile ? (
        <>
          <Grid container spacing={2}>
            {unitList.length > 0 ? (
              unitList.map((row, rowIndex) => (
                <Grid item xs={12} key={rowIndex}>
                  <Paper elevation={3} sx={{ p: 2 }}>
                    <Stack spacing={1}>
                      <Box sx={{ fontWeight: "bold" }}>
                        <strong>S No.:</strong> {page * rowsPerPage + rowIndex + 1}
                      </Box>
                      <Box>
                        <strong>Unit Name:</strong> {row.units_name || "N/A"}
                      </Box>
                      <Box>
                        <strong>Derived Unit:</strong> {row.derive_unit || "N/A"}
                      </Box>
                      <Box>
                        <strong>Conversion Factor:</strong> {row.conversionfactor || "N/A"}
                      </Box>
                      <Box>
                        <strong>Type of Units:</strong> {row.typeofunits}
                      </Box>
                      <Box>
                        <strong>Description:</strong> {row.description}
                      </Box>
                      <Box>
                        <strong>Status:</strong>{" "}
                        {row.status === 1 ? (
                          <CheckCircleIcon color="success" />
                        ) : (
                          <CancelIcon color="error" />
                        )}
                      </Box>
                      <Box>
                        <strong>Primary:</strong>{" "}
                        {row.isPrimary === 1 ? (
                          <CheckCircleIcon color="success" />
                        ) : (
                          <CancelIcon color="error" />
                        )}
                      </Box>
                      <Box>
                        <Tooltip title="Edit" arrow>
                          <IconButton
                            onClick={() => {
                              handleEdit(row);
                              scrollToTop();
                            }}
                          >
                            <EditIcon color="primary" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Stack>
                  </Paper>
                </Grid>
              ))
            ) : (
              <Grid item xs={12} textAlign="center">
                <Box sx={{ color: "red", fontWeight: 600 }}>No data found.</Box>
              </Grid>
            )}
            {pagination && (
              <Grid item xs={12}>
                <TablePagination
                  rowsPerPageOptions={rowsPerPageOptions}
                  component="div"
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Grid>
            )}
          </Grid>
        </>
      ) : (
        <>
          <TableContainer component={Paper} sx={{ py: 1 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={headerCellStyle}>S.No.</TableCell>
                  {headers.map((header, index) => (
                    <TableCell key={index} style={headerCellStyle}>
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {unitList.map((row, rowIndex) => (
                  <TableRow
                    key={rowIndex}
                    sx={{
                      ...rowStyle,
                      ...(rowIndex % 2 === 0 ? evenRowStyle : oddRowStyle),
                    }}
                  >
                    <TableCell sx={cellStyle}>
                      {page * rowsPerPage + rowIndex + 1}
                    </TableCell>
                    <TableCell sx={cellStyle}>{row.units_name}</TableCell>
                    <TableCell sx={cellStyle}>
                      {row.derive_unit || "N/A"}
                    </TableCell>
                    <TableCell sx={cellStyle}>
                      {row.conversionfactor || "N/A"}
                    </TableCell>
                    <TableCell sx={cellStyle}>{row.typeofunits}</TableCell>
                    <TableCell sx={cellStyle}>{row.description}</TableCell>
                    <TableCell sx={cellStyle}>
                      {row.status === 1 ? (
                        <CheckCircleIcon color="success" />
                      ) : (
                        <CancelIcon color="error" />
                      )}
                    </TableCell>
                    <TableCell sx={cellStyle}>
                      {row.isPrimary === 1 ? (
                        <CheckCircleIcon color="success" />
                      ) : (
                        <CancelIcon color="error" />
                      )}
                    </TableCell>
                    <TableCell sx={cellStyle}>
                      <Box
                        sx={{
                          borderRadius: 10,
                          width: "fit-content",
                          transition: "100ms",
                          cursor: "pointer",
                          padding: 0.5,
                          ":hover": { backgroundColor: "#99999955" },
                        }}
                        onClick={() => {
                          handleEdit(row);
                          scrollToTop();
                        }}
                      >
                        <Tooltip title="Edit" arrow>
                          <EditIcon color="primary" />
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 1 * emptyRows }}>
                    <TableCell colSpan={headers.length + 2} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {pagination && (
              <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </TableContainer>
        </>
      )}
    </>
  );
};

export default memo(UnitTable);
