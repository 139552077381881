import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import Calculation from '../../utility/Calculations';

const DownloadGstInvoiceReport = async (reportData) => {
    // Define the header row for the Excel file
    const headers = [
        'GSTIN',
        'PARTY NAME',
        'BILL NO',
        'DATE',
        'INVOICE VALUE',
        'TAXABLE VALUE',
        'CGST',
        'SGST',
        'IGST'
    ];

    // Map reportData to an array of rows
    const data = reportData.map(item => [
        item.gstin,
        item.fname,
        item.invoice_number,
        item.date,
        item.total_amount,
        item.taxable_amt,
        item.cgst,
        item.sgst,
        item.igst
    ]);

    // Create a new workbook
    const wb = XLSX.utils.book_new();
    // Add a new worksheet to the workbook
    const ws = XLSX.utils.aoa_to_sheet([headers, ...data]);
    // Add the worksheet to the workbook
    const reportName = 'GstInvoiceReport_' + Calculation.GetCurrentDateString();
    XLSX.utils.book_append_sheet(wb, ws, reportName);

    // Convert the workbook to a binary string
    const wbout = XLSX.write(wb, { type: 'binary', bookType: 'xlsx' });

    // Create a Blob from the binary string
    const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });

    // Save the Blob as a file
    saveAs(blob, reportName + '.xlsx');
};

// Convert a string to ArrayBuffer
const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
};

export default DownloadGstInvoiceReport;
