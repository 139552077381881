import React from 'react';
import QRCode from 'qrcode.react';
import { Box, Grid, Stack } from '@mui/material';

const QRCodesPrintComponent = React.forwardRef((props, ref) => {
    const { lineItems, itemData } = props;
    return (
        <>
            <div ref={ref} style={{ marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Grid container alignItems={'center'} justifyContent={'space-between'}>
                    {lineItems.map((selectedItem, index) => (
                        <Grid item md={4} border={'1px dashed black'}>
                            <Stack direction={'column'} sx={{ m: 1, p: 0 }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <QRCode value={`http://demo.d-crm.in/product-info?itemId=${selectedItem.product_id}&lineItemId=${selectedItem.id}&uuid=${selectedItem.uuid}`} />
                                </Box>
                                <p style={{ textAlign: 'center', margin: 0 }}>{itemData.item_name}</p>
                                <p style={{ textAlign: 'center', margin: 0 }}>{selectedItem.uuid}</p>
                            </Stack>
                        </Grid>
                    ))}
                </Grid>
            </div>
        </>
    )
}
);

export default QRCodesPrintComponent;